import React from "react";
import { connect } from "react-redux";
import MainMenu from "../../components/mainMenu";
import Invitation from "../../components/workers/invitation";
import { setActiveMenuPosition } from "../../actions/menu";
import { logoutAction } from "../../actions/session";
import {
  changeInvitationEmail,
  changeInvitationChatAdminPermission,
  changeInvitationAdminPermission,
  hideError,
  createInvitation,
} from "../../actions/workers";

const InvitationView = (props) => {
  const {
    session,
    menu,
    onLogout,
    workers,
    hideError,
    setActiveMenuPosition,
    changeInvitationEmail,
    changeInvitationAdminPermission,
    changeInvitationChatAdminPermission,
    createInvitation,
  } = props;

  return (
    <div>
      <MainMenu
        needShowPrivateItems={session.isAuthenticated}
        menu={menu}
        onLogout={onLogout}
        setActiveMenuPosition={setActiveMenuPosition}
        userDetails={session.userDetails}
      />
      <Invitation
        workers={workers}
        changeEmail={changeInvitationEmail}
        changeAdminPermission={changeInvitationAdminPermission}
        changeChatAdminPermission={changeInvitationChatAdminPermission}
        createInvitation={createInvitation}
        hideError={hideError}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    menu: state.menu,
    users: state.users,
    session: state.session,
    workers: state.workers,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    hideError: () => {
      hideError()(dispatch);
    },
    changeInvitationEmail: (e, data) => {
      changeInvitationEmail(data.value)(dispatch);
    },
    changeInvitationAdminPermission: (value) => {
      changeInvitationAdminPermission(value)(dispatch);
    },
    changeInvitationChatAdminPermission: (value) => {
      changeInvitationChatAdminPermission(value)(dispatch);
    },
    createInvitation: (invitation) => {
      createInvitation(invitation)(dispatch);
    },
    setActiveMenuPosition(activeMenu) {
      setActiveMenuPosition(activeMenu)(dispatch);
    },
    onLogout() {
      logoutAction()(dispatch);
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InvitationView);
