import { fakeUsersActionTypes } from "../../constants/actions/fakeUsers";
import { commonConstants } from "../../constants/common";
import { sessionActionTypes } from "../../constants/actions/session";
import { Api } from "../../utils/api";
import { prepareToSave } from "../../utils/helpers";
import { usersActionTypes } from "../../constants/actions/users";
import { ChatApi } from "../../utils/chat_api";
import { getUserInfo } from "../../utils/auth";
import audioSrc from "../../assets/media/Ring.wav";
import { pick } from "lodash";
import store from "../../store";

var messageHeader = "API request failed";

export function setWorkerId(id) {
  return function (dispatch) {
    dispatch({
      type: fakeUsersActionTypes.FAKE_USER_SET_WORKER_ID,
      data: id,
    });
  };
}

export function setFakeUserId(id) {
  return function (dispatch) {
    dispatch({
      type: fakeUsersActionTypes.FAKE_USER_SET_ID,
      data: id,
    });
  };
}

export function changeName(name) {
  return function (dispatch) {
    dispatch({
      type: fakeUsersActionTypes.CHANGE_FAKE_USER_NAME,
      data: name,
    });
  };
}
export function changeAddress(addreas) {
  return function (dispatch) {
    dispatch({
      type: fakeUsersActionTypes.CHANGE_FAKE_USER_ADDRESS,
      data: addreas,
    });
  };
}

export function changeLocation(location) {
  return function (dispatch) {
    dispatch({
      type: fakeUsersActionTypes.CHANGE_FAKE_USER_CHANGE_LOCATION,
      data: location,
    });
  };
}

export function changeLastName(lastName) {
  return function (dispatch) {
    dispatch({
      type: fakeUsersActionTypes.CHANGE_FAKE_USER_LAST_NAME,
      data: lastName,
    });
  };
}

export function changeEmail(email) {
  return function (dispatch) {
    dispatch({
      type: fakeUsersActionTypes.CHANGE_FAKE_USER_EMAIL,
      data: email,
    });
  };
}

export function changeAge(age) {
  return function (dispatch) {
    dispatch({
      type: fakeUsersActionTypes.CHANGE_FAKE_USER_AGE,
      data: age,
    });
  };
}

export function changeAbout(about) {
  return function (dispatch) {
    dispatch({
      type: fakeUsersActionTypes.CHANGE_FAKE_USER_ABOUT,
      data: about,
    });
  };
}
export function changeNote(note) {
  return function (dispatch) {
    dispatch({
      type: fakeUsersActionTypes.CHANGE_FAKE_USER_NOTE,
      data: note,
    });
  };
}

export function changeEducation(item) {
  return function (dispatch) {
    dispatch({
      type: fakeUsersActionTypes.CHANGE_FAKE_USER_EDUCATION,
      data: item,
    });
  };
}

export function changeEthnicity(item) {
  return function (dispatch) {
    dispatch({
      type: fakeUsersActionTypes.CHANGE_FAKE_USER_ETHNICITY,
      data: item,
    });
  };
}

export function changeFamilyPlans(item) {
  return function (dispatch) {
    dispatch({
      type: fakeUsersActionTypes.CHANGE_FAKE_USER_FAMILY_PLANS,
      data: item,
    });
  };
}

export function changeHeight(item) {
  return function (dispatch) {
    dispatch({
      type: fakeUsersActionTypes.CHANGE_FAKE_USER_HEIGHT,
      data: item,
    });
  };
}

export function changeInterestedIn(items) {
  return function (dispatch) {
    dispatch({
      type: fakeUsersActionTypes.CHANGE_FAKE_USER_INTERESTED_IN,
      data: items,
    });
  };
}

export function changeWork(item) {
  return function (dispatch) {
    dispatch({
      type: fakeUsersActionTypes.CHANGE_FAKE_USER_WORK,
      data: item,
    });
  };
}

export function changeZodiacSign(item) {
  return function (dispatch) {
    dispatch({
      type: fakeUsersActionTypes.CHANGE_FAKE_USER_ZODIAC_SIGN,
      data: item,
    });
  };
}

export function changePolitics(item) {
  return function (dispatch) {
    dispatch({
      type: fakeUsersActionTypes.CHANGE_FAKE_USER_POLITICS,
      data: item,
    });
  };
}

export function changeReligious(item) {
  return function (dispatch) {
    dispatch({
      type: fakeUsersActionTypes.CHANGE_FAKE_USER_RELIGIOUS,
      data: item,
    });
  };
}

export function changeTags(items) {
  return function (dispatch) {
    dispatch({
      type: fakeUsersActionTypes.CHANGE_FAKE_USER_TAGS,
      data: items,
    });
  };
}

export function changeGender(gender) {
  return function (dispatch) {
    dispatch({
      type: fakeUsersActionTypes.CHANGE_FAKE_USER_GENDER,
      data: gender,
    });
  };
}

export function changeSubscription(name) {
  return function (dispatch) {
    dispatch({
      type: fakeUsersActionTypes.CHANGE_FAKE_USER_SUBSCRIPTION,
      data: name,
    });
  };
}

export function onAddImage(item) {
  return function (dispatch) {
    dispatch({
      type: fakeUsersActionTypes.ADD_FAKE_USER_PHOTO,
      data: item,
    });
  };
}

export function onRemoveImage(userId, item) {
  return function (dispatch) {
    Api.removeAvatarPhoto(userId, item).then(() => {
      dispatch({
        type: fakeUsersActionTypes.REMOVE_FAKE_USER_PHOTO,
        data: item,
      });
    });
  };
}
export function onRemoveMoment(id) {
  return function (dispatch) {
    ChatApi.removeMoment(id).then((data) => {
      dispatch({
        type: fakeUsersActionTypes.REMOVE_MOMENT,
        data: id,
      });
    });
  };
}

export function onChangeItem(item, type, action) {
  let actionType;
  switch (type) {
    case commonConstants.BOOK_ITEM:
      if (action === commonConstants.ACTION_ADD) {
        actionType = fakeUsersActionTypes.ADD_FAKE_USER_BOOK;
      } else {
        actionType = fakeUsersActionTypes.REMOVE_FAKE_USER_BOOK;
      }
      break;
    case commonConstants.MOVIE_ITEM:
      if (action === commonConstants.ACTION_ADD) {
        actionType = fakeUsersActionTypes.ADD_FAKE_USER_MOVIE;
      } else {
        actionType = fakeUsersActionTypes.REMOVE_FAKE_USER_MOVIE;
      }
      break;
    case commonConstants.MUSIC_ITEM:
      if (action === commonConstants.ACTION_ADD) {
        actionType = fakeUsersActionTypes.ADD_FAKE_USER_MUSIC;
      } else {
        actionType = fakeUsersActionTypes.REMOVE_FAKE_USER_MUSIC;
      }
      break;
    case commonConstants.TV_SHOW_ITEM:
      if (action === commonConstants.ACTION_ADD) {
        actionType = fakeUsersActionTypes.ADD_FAKE_USER_TV_SHOW;
      } else {
        actionType = fakeUsersActionTypes.REMOVE_FAKE_USER_TV_SHOW;
      }
      break;
    case commonConstants.SPORT_TEAM_ITEM:
      if (action === commonConstants.ACTION_ADD) {
        actionType = fakeUsersActionTypes.ADD_FAKE_USER_SPORT_TEAM;
      } else {
        actionType = fakeUsersActionTypes.REMOVE_FAKE_USER_SPORT_TEAM;
      }
      break;
    default:
      break;
  }
  return function (dispatch) {
    dispatch({
      type: actionType,
      data: item,
    });
  };
}

export function createUser(workerId, user, callback = () => {}) {
  user = prepareToSave(user);
  const createParams = {
    owner_id: workerId,
    ...user,
    language: user?.language ? user?.language : [],
  };

  return function (dispatch) {
    dispatch({
      type: fakeUsersActionTypes.CREATE_FAKE_USER_REQUEST,
    });
    return Api.createFakeUser(createParams)
      .then((response) => {
        var errorMessage =
          "Unknown error. HTTP Status code: " + response.status;
        if (response.status === 401) {
          dispatch({
            type: fakeUsersActionTypes.CREATE_FAKE_USER_FAILURE,
            data: { header: messageHeader },
          });
          dispatch({
            type: sessionActionTypes.LOGOUT_REQUEST,
          });
          return;
        }
        if (response.status !== 201) {
          dispatch({
            type: fakeUsersActionTypes.CREATE_FAKE_USER_FAILURE,
            data: { errors: [errorMessage], header: messageHeader },
          });
          return;
        }
        dispatch({
          type: fakeUsersActionTypes.CREATE_FAKE_USER_SUCCESS,
        });

        return response.json();
      })
      .then((json) => {
        callback();
        dispatch({
          type: fakeUsersActionTypes.GET_FAKE_USER_SUCCESS,
          data: {
            user: {
              ...json,
              interestedIn:
                json.interested_in &&
                json.interested_in.split(",").map((x) => Number(x)),
              location: json.location,
            },
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: fakeUsersActionTypes.CREATE_FAKE_USER_FAILURE,
          data: { errors: [error], header: messageHeader },
        });
      });
  };
}

export function updateUser(uid, user) {
  const values = [
    "id",
    "firstName",
    "lastName",
    "fullName",
    "email",
    "about",
    "age",
    "height",
    "work",
    "education",
    "zodiacSign",
    "interestedIn",
    "familyPlans",
    "politics",
    "religion",
    "ethnicity",
    "address",
    "gender",
    "tags",
    "tvShows",
    "movies",
    "music",
    "books",
    "sportsTeams",
    "language",
    "location",
    "subscription",
    "city",
    "zip_code",
    "country",
    "display_name",
    "username",
    "note",
  ];

  user = prepareToSave(pick(user, values));

  const updateParams = {
    ...user,
    language: user?.language ? user?.language : [],
  };

  return async function (dispatch) {
    dispatch({
      type: fakeUsersActionTypes.SAVE_FAKE_USER_REQUEST,
    });

    try {
      const response = await Api.updateFakeUser(uid, updateParams);
      if (response.ok) {
        dispatch({
          type: fakeUsersActionTypes.SAVE_FAKE_USER_SUCCESS,
        });
        return (window.location.href = "/#/moderators");
      } else {
        dispatch({
          type: fakeUsersActionTypes.SAVE_FAKE_USER_FAILURE,
          data: { errors: [response.statusText], header: messageHeader },
        });
      }
    } catch (error) {
      dispatch({
        type: fakeUsersActionTypes.SAVE_FAKE_USER_FAILURE,
        data: { errors: [error.message], header: messageHeader },
      });
    }
  };
}

export function hideError() {
  return function (dispatch) {
    dispatch({
      type: fakeUsersActionTypes.HIDE_ERROR,
    });
  };
}

export function clearUser() {
  return function (dispatch) {
    dispatch({
      type: fakeUsersActionTypes.CLEAR_USER,
    });
  };
}

export function getPresets() {
  return function (dispatch) {
    dispatch({
      type: fakeUsersActionTypes.GET_FAKE_USER_PRESETS_REQUEST,
    });

    return Api.getFakeUsersPresets()
      .then((response) => {
        if (response.status === 401) {
          dispatch({
            type: fakeUsersActionTypes.GET_FAKE_USER_PRESETS_FAILURE,
            data: { errors: ["Authorization failed"], header: messageHeader },
          });
          dispatch({
            type: sessionActionTypes.LOGOUT_REQUEST,
          });
          return;
        }

        if (response.status !== 200) {
          dispatch({
            type: fakeUsersActionTypes.GET_FAKE_USER_PRESETS_FAILURE,
            data: {
              errors: ["Unexpected API response"],
              header: messageHeader,
            },
          });
          return;
        }

        response
          .json()
          .then((json) => {
            if (!json.defaultPickers) {
              dispatch({
                type: fakeUsersActionTypes.GET_FAKE_USER_PRESETS_FAILURE,
                data: {
                  errors: ["Unexpected API response"],
                  header: messageHeader,
                },
              });
              return;
            }
            dispatch({
              type: fakeUsersActionTypes.GET_FAKE_USER_PRESETS_SUCCESS,
              data: json.defaultPickers,
            });
          })
          .catch((error) => {
            dispatch({
              type: fakeUsersActionTypes.GET_FAKE_USER_PRESETS_FAILURE,
              data: { errors: [error], header: messageHeader },
            });
            return error;
          });
      })
      .catch((error) => {
        console.log("fail", error);
        dispatch({
          type: fakeUsersActionTypes.GET_FAKE_USER_PRESETS_FAILURE,
          data: { errors: [error], header: messageHeader },
        });
      });
  };
}

export function getFakeUserDetails(id) {
  return function (dispatch) {
    dispatch({
      type: fakeUsersActionTypes.GET_FAKE_USER_REQUEST,
    });
    return Api.getFakeUser(id)
      .then((response) => {
        if (response.status === 401) {
          dispatch({
            type: fakeUsersActionTypes.GET_FAKE_USER_FAILURE,
            data: { errors: ["Authorization failed"], header: messageHeader },
          });
          dispatch({
            type: sessionActionTypes.LOGOUT_REQUEST,
          });
          return;
        }

        if (response.status === 404) {
          dispatch({
            type: fakeUsersActionTypes.GET_FAKE_USER_FAILURE,
            data: {
              errors: ["Given user is not found"],
              header: messageHeader,
            },
          });
          return;
        }

        if (response.status !== 200) {
          dispatch({
            type: fakeUsersActionTypes.GET_FAKE_USER_FAILURE,
            data: {
              errors: ["Unexpected API response"],
              header: messageHeader,
            },
          });
          return;
        }

        response
          .json()
          .then((json) => {
            if (!json) {
              dispatch({
                type: fakeUsersActionTypes.GET_FAKE_USER_FAILURE,
                data: {
                  errors: ["Unexpected API response"],
                  header: messageHeader,
                },
              });
              return;
            }
            dispatch({
              type: fakeUsersActionTypes.GET_FAKE_USER_SUCCESS,
              data: {
                user: {
                  ...json,
                  interestedIn:
                    json.interested_in &&
                    json.interested_in.split(",").map((x) => Number(x)),
                  location: json.location,
                },
              },
            });
          })
          .catch((error) => {
            console.log("fail 2", error);
            dispatch({
              type: fakeUsersActionTypes.GET_FAKE_USER_FAILURE,
              data: { errors: [error], header: messageHeader },
            });
            return error;
          });
      })
      .catch((error) => {
        console.log("fail====>", error);
        dispatch({
          type: usersActionTypes.EDIT_USER_DETAILS_FAILURE,
          data: { errors: [error], header: messageHeader },
        });
      });
  };
}

export function getWorkerFakeUsers(id) {
  return function (dispatch) {
    dispatch({
      type: fakeUsersActionTypes.GET_WORKER_FAKE_USERS_REQUEST,
    });
    return Api.getWorkerFakeUsers(id)
      .then((response) => {
        switch (response.status) {
          case 402: {
            dispatch({
              type: fakeUsersActionTypes.GET_WORKER_FAKE_USERS_FAILURE,
              data: { errors: ["Authorization failed"], header: messageHeader },
            });
            dispatch({
              type: sessionActionTypes.LOGOUT_REQUEST,
            });
            return;
          }
          case 200: {
            response
              .json()
              .then((json) => {
                if (!json) {
                  dispatch({
                    type: fakeUsersActionTypes.GET_WORKER_FAKE_USERS_FAILURE,
                    data: {
                      errors: ["Unexpected API response"],
                      header: messageHeader,
                    },
                  });
                  return;
                }

                dispatch({
                  type: fakeUsersActionTypes.GET_WORKER_FAKE_USERS_SUCCESS,
                  data: json,
                });
              })
              .catch((error) => {
                dispatch({
                  type: fakeUsersActionTypes.GET_WORKER_FAKE_USERS_FAILURE,
                  data: { errors: [error], header: messageHeader },
                });
                return error;
              });
          }
          default:
            {
              dispatch({
                type: fakeUsersActionTypes.GET_WORKER_FAKE_USERS_FAILURE,
                data: {
                  errors: ["Unexpected API response"],
                  header: messageHeader,
                },
              });
              return;
            }
            // if (response.status === 401) {
            //   dispatch({
            //     type: fakeUsersActionTypes.GET_WORKER_FAKE_USERS_FAILURE,
            //     data: { errors: ['Authorization failed'], header: messageHeader }
            //   });
            //   dispatch({
            //     type: sessionActionTypes.LOGOUT_REQUEST,
            //   });
            //   return;
            // }

            if (response.status !== 200) {
            }

          // response.json().then(json => {

          //   if (!json) {
          //     dispatch({
          //       type: fakeUsersActionTypes.GET_WORKER_FAKE_USERS_FAILURE,
          //       data: { errors: ['Unexpected API response'], header: messageHeader }
          //     });
          //     return;
          //   }

          //   dispatch({
          //     type: fakeUsersActionTypes.GET_WORKER_FAKE_USERS_SUCCESS,
          //     data: json
          //   });
          // }).catch(error => {
          //   dispatch({
          //     type: fakeUsersActionTypes.GET_WORKER_FAKE_USERS_FAILURE,
          //     data: { errors: [error], header: messageHeader }
          //   });
          //   return error;
          // });
        }
      })
      .catch((error) => {
        console.log("fail", error);
        dispatch({
          type: fakeUsersActionTypes.GET_WORKER_FAKE_USERS_FAILURE,
          data: { errors: [error], header: messageHeader },
        });
      });
  };
}

export const reFetchWorkerFakeUsers = (id) => {
  return (dispatch, workers) => {
    const userDetails = getUserInfo();
    const userId = id ?? userDetails?.id;
    Api.getWorkerFakeUsers(userId)
      .then((response) => {
        if (response.status === 401) {
          dispatch({
            type: fakeUsersActionTypes.GET_WORKER_FAKE_USERS_FAILURE,
            data: { errors: ["Authorization failed"], header: messageHeader },
          });
          dispatch({
            type: sessionActionTypes.LOGOUT_REQUEST,
          });
          return;
        }

        if (response.status !== 200) {
          dispatch({
            type: fakeUsersActionTypes.GET_WORKER_FAKE_USERS_FAILURE,
            data: {
              errors: ["Unexpected API response"],
              header: messageHeader,
            },
          });
          return;
        }

        response
          .json()
          .then((json) => {
            if (!json) {
              dispatch({
                type: fakeUsersActionTypes.GET_WORKER_FAKE_USERS_FAILURE,
                data: {
                  errors: ["Unexpected API response"],
                  header: messageHeader,
                },
              });
              return;
            }

            let isUpdateState = false;
            const newFakeUsers = json?.fake_users ?? [];
            const previousFakeUsers = workers?.fakeUsers ?? [];
            const previousUsersIds = previousFakeUsers.map((user) => user.id);

            newFakeUsers.forEach((user) => {
              if (!previousUsersIds.includes(user.id)) isUpdateState = true;
            });

            if (newFakeUsers?.length !== previousFakeUsers?.length)
              isUpdateState = true;

            if (!isUpdateState) {
              return;
            }

            dispatch({
              type: fakeUsersActionTypes.GET_WORKER_FAKE_USERS_SUCCESS,
              data: json,
            });

            if (
              isUpdateState &&
              newFakeUsers?.length >= previousFakeUsers?.length
            ) {
              const sound = new Audio(audioSrc);
              sound.play();
            }
          })
          .catch((error) => {
            dispatch({
              type: fakeUsersActionTypes.GET_WORKER_FAKE_USERS_FAILURE,
              data: { errors: [error], header: messageHeader },
            });
            return error;
          });
      })
      .catch((error) => {
        console.log("fail", error);
        dispatch({
          type: fakeUsersActionTypes.GET_WORKER_FAKE_USERS_FAILURE,
          data: { errors: [error], header: messageHeader },
        });
      });
  };
};

export function resetSettings() {
  return function (dispatch) {
    dispatch({
      type: fakeUsersActionTypes.FAKE_USER_RESET_SETTINGS,
    });
  };
}
export function setStockImage(images) {
  return function (dispatch) {
    dispatch({
      type: fakeUsersActionTypes.GET_STOCK_IMAGE,
      data: images,
    });
  };
}

export function uploadAvatarPhoto(userId, params, type, callback) {
  return function (dispatch) {
    Api.uploadAvatarPhoto(userId, params, type).then((res) => {
      const createdBy = {
        created_by: store.getState()?.session?.userDetails?.id,
        created_by_id: store.getState()?.session?.userDetails?.id,
        created_by_full_name: store.getState()?.session?.userDetails?.fullName,
      };
      if (res?.reason) callback(res);
      else if (res.url)
        dispatch({
          type: fakeUsersActionTypes.ADD_FAKE_USER_PHOTO,
          data: { ...createdBy, ...res, file: res.url },
        });
    });
  };
}

export function uploadStory(userId, params) {
  return function (dispatch) {
    ChatApi.uploadStory(userId, params?.id ? "" : params.file, params?.id)
      .then((image) => {
        dispatch({
          type: fakeUsersActionTypes.ADD_STORY,
          data: image,
        });
      })
      .catch((err) => console.log(err));
  };
}

export async function uploadStockImage(userId, params) {
  try {
    const image = await ChatApi.uploadStockImage(userId, params?.file);
    return image;
  } catch (err) {
    console.error(err);
    return null;
  }
}

export function uploadMoment(userId, params) {
  return function (dispatch) {
    ChatApi.uploadMoment(userId, params)
      .then((image) => {
        dispatch({
          type: fakeUsersActionTypes.ADD_MOMENT,
          data: image,
        });
        dispatch(getMoments(userId));
      })
      .catch((err) => console.log(err));
  };
}

export function getStories(moderatorId) {
  return function (dispatch) {
    ChatApi.getStories(moderatorId)
      .then((stories) => {
        dispatch({
          type: fakeUsersActionTypes.INIT_STORIES,
          data: stories,
        });
      })
      .catch(console.warn);
  };
}

export function resetCurrentFakeUser() {
  return (dispatch) => {
    dispatch({
      type: fakeUsersActionTypes.RESET_FAKE_USER,
    });
  };
}

export const updateCreateModerator = (payload) => {
  return (dispatch) => {
    dispatch({
      type: fakeUsersActionTypes.UPDATE_CREATE_MODERATOR,
      data: payload,
    });
  };
};

export function uploadScheduleStories(userId, params) {
  return function (dispatch) {
    ChatApi.uploadScheduleStories(
      userId,
      params?.id ? "" : params.file,
      params?.id,
      params.publishAt
    )
      .then((image) => {
        dispatch({
          type: fakeUsersActionTypes.ADD_SCHEDULE_STORY,
          data: image,
        });
        dispatch(getAllScheduleStories(userId));
      })
      .catch((err) => console.log(err));
  };
}

export function getAllScheduleStories(moderatorID) {
  return function (dispatch) {
    ChatApi.getAllScheduleStories(moderatorID)
      .then((stories) => {
        if (stories) {
          dispatch({
            type: fakeUsersActionTypes.INIT_SCHEDULE_STORIES,
            data: stories,
          });
        }
      })
      .catch((err) => {
        console.error("Error Raised :getAllScheduleStories:", err);
      });
  };
}

export function getAllStories() {
  return function (dispatch) {
    ChatApi.getAllStories()
      .then((stories) => {
        dispatch({
          type: fakeUsersActionTypes.INIT_STORIES,
          data: stories,
        });
      })
      .catch(console.warn);
  };
}

export function getMoments(moderatorId) {
  return function (dispatch) {
    ChatApi.getMoments(moderatorId)
      .then((stories) => {
        dispatch({
          type: fakeUsersActionTypes.INIT_MOMENTS,
          data: stories,
        });
      })
      .catch(console.warn);
  };
}

export function getScheduleMoments(moderatorId) {
  return function (dispatch) {
    ChatApi.getScheduleMoments(moderatorId)
      .then((image) => {
        dispatch({
          type: fakeUsersActionTypes.INIT_SCHEDULE_MOMENT,
          data: image,
        });
      })
      .catch(console.error);
  };
}

export function uploadScheduleMoment(userId, params) {
  return function (dispatch) {
    ChatApi.uploadScheduleMoment(
      userId,
      params?.id ? "" : params.file,
      params?.id,
      params.publishAt,
      params.description
    )
      .then((data) => {
        dispatch({
          type: fakeUsersActionTypes.ADD_SCHEDULE_MOMENT,
          data: { node: data?.insertMoment?.moment },
        });
        dispatch(getScheduleMoments(userId));
      })
      .catch(console.error);
  };
}

export function onRemoveScheduleMoment(id) {
  return function (dispatch) {
    const userId = store.getState().fakeUsers?.currentUser?.id;
    ChatApi.onRemoveScheduleMoment(id)
      .then((data) => {
        dispatch({
          type: fakeUsersActionTypes.REMOVE_SCHEDULE_MOMENT,
          data: id,
        });
        dispatch(getScheduleMoments(userId));
      })
      .catch(console.error);
  };
}

export function getAllUserMoments() {
  return function (dispatch) {
    ChatApi.getAllUserMoments()
      .then((image) => {
        dispatch({
          type: fakeUsersActionTypes.INIT_ALL_MOMENTS,
          data: image,
        });
      })
      .catch(console.warn);
  };
}

export function uploadAllUserMoments(userId, params) {
  return function (dispatch) {
    ChatApi.uploadAllUserMoments(userId, params)
      .then((image) => {
        dispatch({
          type: fakeUsersActionTypes.ADD_ALL_MOMENTS,
          data: image,
        });
        setTimeout(() => {
          dispatch(getAllUserMoments());
        }, 1000);
      })
      .catch(console.error);
  };
}

export function onRemoveUserAllMoments(id) {
  return function (dispatch) {
    ChatApi.onRemoveUserAllMoments(id)
      .then((data) => {
        dispatch({
          type: fakeUsersActionTypes.REMOVE_ALL_MOMENTS,
          data: id,
        });
        setTimeout(() => {
          dispatch(getAllUserMoments());
        }, 1500);
      })
      .catch(console.error);
  };
}
