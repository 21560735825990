import { client, clientUpload } from "./client";
import { gql } from "@apollo/client";
import _ from "lodash";
import { baseUrl } from "./api";
import { getUserInfo } from "./auth";
import { chatActionTypes } from "../constants/actions/chat";
import { isVideo } from "./helpers";
import moment from "moment";

export class ChatApi {
  static canWorkerChat = async () => {
    return await client.query({
      fetchPolicy: "no-cache",
      query: gql`
        query {
          chatQueueSettings {
            worker {
              id
              username
            }
            isAllowed
          }
        }
      `,
    });
  };

  static canWorkerPaste = async () => {
    return await client.query({
      fetchPolicy: "no-cache",
      query: gql`
        query {
          chatPasteSettings {
            worker {
              id
              username
            }
            isAllowed
          }
        }
      `,
    });
  };

  static chatMessageLengthSetting = async () => {
    return await client.query({
      fetchPolicy: "no-cache",
      query: gql`
        query {
          chatMessageLengthSetting {
            minMessageLength
          }
        }
      `,
    });
  };

  static getHistoryWithDialogue = async (
    moderatorId,
    dialogue_id,
    isChatter
  ) => {
    const response = await client.query({
      fetchPolicy: "no-cache",
      query: gql`
              query {
                messagesForWorkers(id:${dialogue_id}) {
                  edges {
                    node {
                      receiverWorker {
                        id
                        username
                        fullName
                      }
                      content
                      id
                      timestamp
                      messageType
                      userId {
                        id
                        username
                      }
                      roomId {
                        id
                      }
                      privatePhotoRequestId
                      requestStatus
                      senderWorker {
                        id
                        username
                        languageIdCode
                      }
                    }
                  }
                }
              }  
              `,
    });

    return _.map(response.data.messagesForWorkers.edges, (item) => {
      return {
        id: item?.node?.id,
        created_at: item?.node?.timestamp,
        recipient_id: item?.node?.userId?.id,
        privatePhotoRequestId:
          item?.node?.privatePhotoRequestId > 0
            ? item?.node?.privatePhotoRequestId
            : null,
        requestStatus: item?.node?.requestStatus,
        room: item?.node?.roomId,
        messages: item?.node?.content,
        messageType: item?.node?.messageType || "",
        url: "",
        ir: item?.node?.read,
        languageIdCode: item?.node?.senderWorker?.languageIdCode,
        sender: item?.node?.senderWorker,
        recieved_message: item?.node?.content,
      };
    });
  };

  static listChatDialogue = (id, external_id, callback) => {
    client
      .query({
        fetchPolicy: "no-cache",
        query: gql`
          query {
            rooms {
              edges {
                node {
                  messageSet(last: 100) {
                    edges {
                      node {
                        timestamp
                      }
                    }
                  }
                  id
                  notesSet {
                    edges {
                      node {
                        content
                        forRealUser
                      }
                    }
                  }
                  name
                  unread
                  userId {
                    id
                    username
                    fullName
                  }
                  target {
                    id
                    username
                    fullName
                  }
                }
              }
            }
          }
        `,
      })
      .then((result) => {
        const dialoguesData = _.map(
          _.filter(
            result.data?.rooms?.edges,
            (room) => room.node.userId.id === id || room.node.target.id === id
          ),
          (room) => {
            const moderatorNote = _.find(
              room.node.notesSet.edges,
              (item) => !item.node.forRealUser
            );
            const userNote = _.find(
              room.node.notesSet.edges,
              (item) => item.node.forRealUser
            );
            return {
              _id: room.node.id,
              name:
                room.node.userId.id === id
                  ? room.node.target.fullName
                  : room.node.userId.fullName,
              unread_messages_count: room.node.unread,
              occupants_ids: [],
              friendId:
                room.node.userId.id === id
                  ? room.node.target.id
                  : room.node.userId.id,
              user_id: id,
              latestTime: "",
              moderatorNote: moderatorNote ? moderatorNote.node.content : "",
              userNote: userNote ? userNote.node.content : "",
            };
          }
        );
        callback(null, {
          items: dialoguesData,
        });
      })
      .catch((err) => console.log(err));
  };

  static createDialogue = (fakeUserId, userName, callback) => {
    client
      .mutate({
        mutation: gql`
                mutation {
                  createChatAllModerator( moderatorId: "${fakeUserId}", userName: "${userName}" ) {
                        room {
                            id
                            notesSet {
                              edges {
                                node {
                                  content
                                  forRealUser
                                }
                              }
                            }
                            name
                            userId{
                                username
                                id
                                fullName
                            }
                            target
                            {
                                username
                                id
                                fullName
                            }
                        }
                    }
                }
            `,
      })
      .then((result) => {
        const moderatorNote = _.find(
          result.data.createChatAllModerator.room.notesSet.edges,
          (item) => !item.node.forRealUser
        );
        const userNote = _.find(
          result.data.createChatAllModerator.room.notesSet.edges,
          (item) => item.node.forRealUser
        );

        const dialoguesData = {
          _id: result.data.createChatAllModerator.room.id,
          name:
            result.data.createChatAllModerator.room.userId.id === fakeUserId
              ? result.data.createChatAllModerator.room.target.fullName
              : result.data.createChatAllModerator.room.userId.fullName,
          unread_messages_count: 0,
          occupants_ids: [],
          friendId:
            result.data.createChatAllModerator.room.userId.id === fakeUserId
              ? result.data.createChatAllModerator.room.target.id
              : result.data.createChatAllModerator.room.userId.id,
          user_id: fakeUserId,
          latestTime: "",
          moderatorNote: moderatorNote ? moderatorNote.node.content : "",
          userNote: userNote ? userNote.node.content : "",
        };
        callback(null, dialoguesData);
      })
      .catch((err) => console.log(err));
  };

  static sendTextMessage = async (dialogue_id, text, id, messageStrType) => {
    const response = messageStrType
      ? await client.mutate({
          mutation: gql`
                mutation {
                  sendMessageAllModerator(moderatorId: "${id}", messageStr: "${text}", roomId: ${dialogue_id},messageStrType:"${
            messageStrType || ""
          }") {
                  message {
                      id
                      timestamp
                      roomId {
                        lastModified
                      }
                      userId {
                        id
                        username
                      }
                      content
                      timestamp
                      read
                    }
                  }
                }
            `,
        })
      : await client.mutate({
          mutation: gql`
                mutation {
                  sendMessageAllModerator(moderatorId: "${id}", messageStr: "${text}", roomId: ${dialogue_id}) {
                  message {
                      id
                      timestamp
                      roomId {
                        lastModified
                      }
                      userId {
                        id
                        username
                      }
                      content
                      timestamp
                      read
                    }
                  }
                }
            `,
        });
    return {
      id: response.data.sendMessageAllModerator.message.id,
      created_at: response.data.sendMessageAllModerator.message.timestamp,
      recipient_id: response.data.sendMessageAllModerator.message.userId.id,
      message:
        response.data.sendMessageAllModerator.message.userId.id !== id
          ? response.data.sendMessageAllModerator.message.content
          : "",
      url: "",
      ir: response.data.sendMessageAllModerator.message.read,
      recieved_message:
        response.data.sendMessageAllModerator.message.userId.id === id
          ? response.data.sendMessageAllModerator.message.content
          : "",
    };
  };

  static sendProfileVisit = async (realUserID, moderatorID) => {
    return await client.mutate({
      mutation: gql`
        mutation {
          profileVisit(
            visiting: "${realUserID}"
            visitor: "${moderatorID}"
          ) {
            isVisited
            isNotificationSent
          }
        }
      `,
    });
  };

  static sendNotification = async (userId) => {
    return await client.mutate({
      mutation: gql`
                mutation {
                  sendNotification(userId: "${userId}", notificationSetting: "STLIKE") {
                    sent
                  }
                }
            `,
    });
  };
  static addMessageReceiveListener = async (token, id, roomId) => {
    const observerRoom = await client.subscribe({
      query: gql`
            subscription {
                onNewMessage(token:"${token}", moderatorId: "${id}", roomId: ${roomId}){
                    message{
                      id
                      roomId {
                        id
                      }
                      userId {
                        id
                        username
                      }
                      content
                      timestamp
                      read
                    }
                }
              }
        `,
    });
    return observerRoom;
  };

  static privatePhotoRequestStatus = async (roomId) => {
    return await client.query({
      query: gql`
        query {
          privatePhotoRequestStatus(roomId: ${roomId}) {
            id
            status
            requestedUser {
              id
            }
            userToView {
              id
            }
          }
        }
      `,
    });
  };

  static privatePhotoRequestStatusListener = async (roomId) => {
    console.log("privatePhotoRequestStatusListener", { roomId });
    const observerRoom = await client.subscribe({
      query: gql`
        subscription{
          privatePhotoRequest(roomId:${roomId}){
            requestId
            roomId
            userToView
            requestedUser
            status
          }
        }
    `,
    });
    return observerRoom;
  };

  static getMessagesStatisticsWorker = async (month, workerId) => {
    return await client.query({
      fetchPolicy: "no-cache",
      query: gql`
              query{ 
                  messagesStatistics(workerId:"${workerId}", month:${month}){
                    day
                    sentCount
                    receivedCount
                  }
                }
            `,
    });
  };

  static getMessagesStatistics = async (workerId, month) => {
    return await client.query({
      fetchPolicy: "no-cache",
      query: gql`
                query{
                  messagesStatistics(workerId:"${workerId}",month:${month}){
                    day
                    sentCount
                    receivedCount
                  }
                }
            `,
    });
  };

  static sameDayMessagesStatistics = async (
    workerId,
    day = moment.utc().date()
  ) => {
    return await client.query({
      fetchPolicy: "no-cache",
      query: gql`
                query{
                    sameDayMessagesStatistics(workerId:"${workerId}", day: ${day}){
                        sentCount
                        receivedCount
                        hour
                    }  
                }
            `,
    });
  };

  static sameWeekMessagesStatistics = async (workerId) => {
    return await client.query({
      fetchPolicy: "no-cache",
      query: gql`
                query{
                    sameWeekMessagesStatistics(workerId:"${workerId}"){
                        sentCount
                        receivedCount
                        date
                    }  
                }
            `,
    });
  };

  static createNotes = async (roomId, content, forRealUser) => {
    return await client.mutate({
      mutation: gql`
                mutation {
                  createNotes(roomId: ${roomId}, content: "${content}", forRealUser: ${forRealUser}) {
                    notes {
                      id
                      content
                      roomId {
                        userId {
                          username
                          email
                          id
                        }
                      }
                    }
                  }
                }
            `,
    });
  };

  static getStories = async (moderatorId) => {
    const response = await client.query({
      query: gql`
                query{
                  allUserStories(user_Id: "${moderatorId}") {
                    edges {
                      node {
                        id
                        file
                        createdBy {
                          id
                        }
                        pk
                        thumbnail
                        likesCount
                        commentsCount
                        likes {
                          edges {
                            node {
                              id
                              user {
                                id
                                username
                                avatarPhotos {
                                  url
                                }
                                avatar{
                                  id
                                  url
                                }
                                lastSeen
                                fullName
                              }
                            }
                          }
                        }
                        comments {
                          edges {
                            node {
                              id
                              user {
                                id
                                username
                                avatarPhotos {
                                  url
                                }
                                lastSeen
                                fullName
                              }
                              commentDescription
                              createdDate
                              likes {
                                edges {
                                  node {
                                    id
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
            `,
    });
    return _.map(response.data.allUserStories.edges, (item) => ({
      file: baseUrl + "/media/" + (item?.node?.thumbnail || item?.node?.file),
      video: isVideo(item?.node?.file)
        ? baseUrl + "/media/" + item?.node?.file
        : "",
      id: item.node.id,
      pk: item.node.pk,
      likesCount: item.node.likesCount,
      commentsCount: item.node.commentsCount,
      comments: item.node.comments.edges,
      likes: item.node.likes.edges,
      createdBy: item.node?.createdBy,
    }));
  };

  static __getAllScheduleStories = async () => {
    const response = await client.query({
      query: gql`
        query {
          allUserMultiStories {
            user {
              id
              fullName
              avatar {
                url
                id
              }
              avatarIndex
              avatarPhotos {
                url
                id
              }
            }
            batchNumber
            stories {
              edges {
                cursor
                node {
                  createdDate
                  publishAt
                  file
                  fileType
                  id
                  pk
                  thumbnail
                  commentsCount
                  comments {
                    pageInfo {
                      endCursor
                      hasNextPage
                      hasPreviousPage
                      startCursor
                    }
                    edges {
                      cursor
                      node {
                        id
                        pk
                        createdDate
                        commentDescription
                        objectId
                        pk
                        user {
                          id
                          fullName
                          avatarIndex
                          avatarPhotos {
                            url
                            id
                          }
                          avatar {
                            id
                            url
                          }
                        }
                      }
                    }
                  }
                  likesCount
                  likes {
                    pageInfo {
                      endCursor
                      hasNextPage
                      hasPreviousPage
                      startCursor
                    }
                    edges {
                      cursor
                      node {
                        id
                        pk
                        objectId
                        user {
                          id
                          fullName
                          avatarIndex
                          avatarPhotos {
                            url
                            id
                          }
                          avatar {
                            id
                            url
                          }
                        }
                      }
                    }
                  }
                  user {
                    id
                    fullName
                    avatar {
                      url
                      id
                    }
                    avatarIndex
                    avatarPhotos {
                      url
                      id
                    }
                  }
                }
              }
              pageInfo {
                endCursor
                hasNextPage
                hasPreviousPage
                startCursor
              }
            }
          }
        }
      `,
    });
    const flattenedStories = _.flatten(
      _.map(response.data.allUserMultiStories, (userMultiStory) => {
        return _.map(userMultiStory.stories.edges, (item) => item);
      })
    );

    return flattenedStories;
  };

  static getAllStories = async () => {
    const response = await client.query({
      query: gql`
        query {
          allUserMultiStories {
            user {
              id
              fullName
              avatar {
                url
                id
              }
              avatarIndex
              avatarPhotos {
                url
                id
              }
            }
            batchNumber
            stories {
              edges {
                cursor
                node {
                  createdDate
                  publishAt
                  file
                  fileType
                  id
                  pk
                  thumbnail
                  commentsCount
                  comments {
                    pageInfo {
                      endCursor
                      hasNextPage
                      hasPreviousPage
                      startCursor
                    }
                    edges {
                      cursor
                      node {
                        id
                        pk
                        createdDate
                        commentDescription
                        objectId
                        pk
                        user {
                          id
                          fullName
                          avatarIndex
                          avatarPhotos {
                            url
                            id
                          }
                          avatar {
                            id
                            url
                          }
                        }
                      }
                    }
                  }
                  likesCount
                  likes {
                    pageInfo {
                      endCursor
                      hasNextPage
                      hasPreviousPage
                      startCursor
                    }
                    edges {
                      cursor
                      node {
                        id
                        pk
                        objectId
                        user {
                          id
                          fullName
                          avatarIndex
                          avatarPhotos {
                            url
                            id
                          }
                          avatar {
                            id
                            url
                          }
                        }
                      }
                    }
                  }
                  user {
                    id
                    fullName
                    avatar {
                      url
                      id
                    }
                    avatarIndex
                    avatarPhotos {
                      url
                      id
                    }
                  }
                }
              }
              pageInfo {
                endCursor
                hasNextPage
                hasPreviousPage
                startCursor
              }
            }
          }
        }
      `,
    });
    const flattenedStories = _.flatten(
      _.map(response.data.allUserMultiStories, (userMultiStory) => {
        return _.map(userMultiStory.stories.edges, (item) => item);
      })
    );

    return flattenedStories;
  };

  static getMoments = async (moderatorId) => {
    const response = await client.query({
      query: gql`
                query{
                  allUserMoments(user_Id:"${moderatorId}"){
                    edges{
                      node{
                        id
                        pk
                        file
                        momentDescription
                        like
                        comment
                      }
                    }
                  }
                }
            `,
    });
    return _.map(response.data.allUserMoments.edges, (item) => ({
      file: baseUrl + "/media/" + item.node.file,
      video: isVideo(item?.node?.file)
        ? baseUrl + "/media/" + item?.node?.file
        : "",
      id: item.node.pk,
      momentDescription: item.node.momentDescription,
      likes: item.node.like,
      comments: item.node.comment,
      moment: true,
    }));
  };

  static getScheduleMoments = async (moderatorId) => {
    const response = await client.query({
      query: gql`
                query{
                  userScheduledMoments(userId:"${moderatorId}"){
                    id
                    pk
                    file
                    momentDescription
                    like
                    comment
                    publishAt
                    createdBy {
                      id
                      fullName
                    }
                  }
                }
            `,
    });
    return response?.data?.userScheduledMoments;
  };

  static moderatorsInQueue = async (workerId) => {
    return await client.query({
      fetchPolicy: "no-cache",
      query: gql`
        query {
          moderatorsInQueue {
            isAssigned
            moderator {
              username
            }
          }
        }
      `,
    });
  };

  static uploadStory = async (moderatorId, image, stockImageId) => {
    const response = await clientUpload.mutate({
      mutation: gql`
        mutation InsertStory(
          $stockImageId: Int
          $moderatorId: String
          $file: Upload
        ) {
          insertStory(
            stockImageId: $stockImageId
            moderatorId: $moderatorId
            file: $file
          ) {
            story {
              file
              createdDate
              id
              createdBy {
                id
                fullName
              }
            }
          }
        }
      `,
      variables: {
        file: image,
        moderatorId,
        stockImageId,
      },
    });
    return {
      id: response.data.insertStory.story.id,
      file: baseUrl + "/media/" + response.data.insertStory.story.file,
    };
  };

  static uploadScheduleStories = async (
    moderatorId,
    image,
    stockImageId,
    publishAt
  ) => {
    const response = await clientUpload.mutate({
      mutation: gql`
        mutation ScheduleStory(
          $file: Upload
          $moderatorId: String
          $publishAt: DateTime
          $stockImageId: Int
        ) {
          insertStory(
            moderatorId: $moderatorId
            file: $file
            publishAt: $publishAt
            stockImageId: $stockImageId
          ) {
            story {
              id
              file
              publishAt
              createdDate
              createdBy {
                id
                fullName
              }
              thumbnail
              isPublished
            }
          }
        }
      `,
      variables: {
        file: image,
        moderatorId,
        publishAt: publishAt,
        stockImageId,
      },
    });

    return {
      id: response.data.insertStory.story.id,
      file: baseUrl + "/media/" + response.data.insertStory.story.file,
      publishAt: response.data.insertStory.story.publishAt,
    };
  };

  static uploadStockImage = async (moderatorId, image) => {
    const response = await clientUpload.mutate({
      mutation: gql`
        mutation ($file: Upload!, $moderatorId: String!) {
          uploadStockImage(file: $file, moderatorId: $moderatorId) {
            stockImage {
              id
              file
              createdDate
            }
          }
        }
      `,
      variables: {
        file: image,
        moderatorId,
      },
    });
    return {
      id: response.data.uploadStockImage.stockImage.id,
      file: response.data.uploadStockImage.stockImage.file,
      // file:
      //   baseUrl + "/media/" + response.data.uploadStockImage.stockImage.file,
    };
  };

  static uploadMoment = async (moderatorId, data, stockImageId) => {
    const response = await clientUpload.mutate({
      mutation: gql`
        mutation InsertMoment(
          $title: String!
          $momentDescription: String!
          $user: String!
          $allowComment: Boolean
          $file: Upload
          $moderatorId: String
          $stockImageId: Int
        ) {
          insertMoment(
            Title: $title
            momentDescription: $momentDescription
            user: $user
            allowComment: $allowComment
            file: $file
            moderatorId: $moderatorId
            stockImageId: $stockImageId
          ) {
            moment {
              file
              id
              pk
              momentDescription
            }
          }
        }
      `,
      variables: {
        file: data?.id ? "" : data?.file,
        momentDescription: data?.description,
        user: moderatorId,
        moderatorId,
        stockImageId: data?.id,
        title: "moment",
      },
    });
    return {
      id: response.data.insertMoment?.moment?.pk,
      file: baseUrl + "/media/" + response.data.insertMoment?.moment?.file,
      momentDescription: response.data.insertMoment?.moment?.momentDescription,
    };
  };

  static uploadScheduleMoment = async (moderatorId, data, stockImageId) => {
    const response = await clientUpload.mutate({
      mutation: gql`
        mutation ScheduleMoment(
          $title: String!
          $momentDescription: String!
          $user: String!
          $allowComment: Boolean
          $file: Upload
          $moderatorId: String
          $stockImageId: Int
          $publishAt: DateTime!
        ) {
          insertMoment(
            Title: $title
            momentDescription: $momentDescription
            user: $user
            allowComment: $allowComment
            file: $file
            moderatorId: $moderatorId
            stockImageId: $stockImageId
            publishAt: $publishAt
          ) {
            moment {
              file
              id
              pk
              momentDescription
              publishAt
            }
          }
        }
      `,
      variables: {
        file: data?.id ? "" : data?.file,
        momentDescription: data?.description,
        user: moderatorId,
        moderatorId,
        stockImageId: data?.id,
        title: "moment",
        publishAt: data.publishAt,
      },
    });
    return {
      id: response.data.insertMoment?.moment?.pk,
      file: baseUrl + "/media/" + response.data.insertMoment?.moment?.file,
      momentDescription: response.data.insertMoment?.moment?.momentDescription,
      publishAt: response.data.insertMoment.moment.publishAt,
    };
  };

  static removeMoment = async (id) => {
    const response = await client.mutate({
      mutation: gql`
                mutation {
                  deleteMoment(id: ${id}) 
                    {
                      id
                      success
                    }
                  }
            `,
    });
    return response;
  };

  static onRemoveScheduleMoment = async (id) => {
    const response = await client.mutate({
      mutation: gql`
                mutation {
                  deleteMoment(id: ${id}) 
                    {
                      id
                      success
                    }
                  }
            `,
    });
    return response;
  };

  static chatsInQueue = async () => {
    const response = await client.query({
      query: gql`
        query {
          chatsInQueue {
            dateCreated
            id
            isAssigned
            clearedAt
            moderator {
              id
              username
            }
            roomId
            updatedAt
            worker {
              id
              username
            }
          }
        }
      `,
    });
    return response;
  };

  static updateUserOnlineStatus = async ({ isOnline }) => {
    const userInfo = getUserInfo();
    const userId = userInfo?.id;
    try {
      const response = await client.mutate({
        mutation: gql`
        mutation {
          updateProfile(id: "${userId}", isOnline: ${isOnline}) {
            id
          }
        }
        `,
      });

      return response?.data?.updateProfile?.id;
    } catch (err) {
      console.log("Something went wrong while updatting profile status!");
      console.log(err);
      return false;
    }
  };

  static deleteMessage = async ({ messageId }) => {
    try {
      const response = await client.mutate({
        mutation: gql`
        mutation {
          deleteUserMessages(messageId: "${messageId}") {
            message
            success
          }
        }
        `,
      });

      return response?.data?.deleteUserMessages;
    } catch (err) {
      console.log("Something went wrong while deleting message!");
      console.log(err);
      return false;
    }
  };

  static getAllRealGifts = async () => {
    try {
      const response = await client.query({
        query: gql`
          query {
            allRealGift {
              id
              giftName
              picture
              cost
              type
            }
          }
        `,
      });

      return _.map(response?.data?.allRealGift, (gift) => ({
        id: gift.id,
        giftName: gift.giftName,
        picture: gift.picture && baseUrl + "/media/" + gift.picture,
        cost: gift.cost,
        type: gift.type,
      }));
    } catch (err) {
      console.log("Something went wrong while fetching gifts");
      console.log(err);
      return false;
    }
  };

  static getAllVirtualGifts = async () => {
    try {
      const response = await client.query({
        query: gql`
          query {
            allVirtualGift {
              id
              giftName
              picture
              cost
              type
            }
          }
        `,
      });

      return _.map(response?.data?.allVirtualGift, (gift) => ({
        id: gift.id,
        giftName: gift.giftName,
        picture: gift.picture && baseUrl + "/media/" + gift.picture,
        cost: gift.cost,
        type: gift.type,
      }));
    } catch (err) {
      console.log("Something went wrong while fetching gifts");
      console.log(err);
      return false;
    }
  };

  static sendGift = async ({ giftId, senderId, receiverId }) => {
    try {
      const response = await client.mutate({
        mutation: gql`
          mutation {
            giftPurchase(giftId: ${giftId}, senderId: "${senderId}", receiverId: "${receiverId}") {
              giftPurchase {
                id
              }
              msg
            }
          }
        `,
      });

      return response?.data?.giftPurchase;
    } catch (err) {
      console.log("Something went wrong while fetching gifts");
      console.log(err);
      return { error: err?.message };

      // return false;
    }
  };

  static messagesForWorkers = async () => {
    const response = await client.query({
      query: gql`
        query {
          messagesForWorkers(id: 782) {
            edges {
              node {
                content
                timestamp
                read
                isDeleted
                messageType
                requestStatus
                privatePhotoRequestId
                id
                roomId {
                  id
                }
                userId {
                  email
                  username
                }
              }
            }
          }
        }
      `,
    });

    return response;
  };

  static getAllScheduleStories = async (moderatorId) => {
    const response = await client.query({
      query: gql`
        query {
          userScheduledStories ${
            moderatorId ? `(userId:"${moderatorId}")` : ""
          } {
            id
            pk
            file
            createdBy {
              id
              fullName
            }
            publishAt
            isPublished
            createdDate
            thumbnail
            likesCount
            user {
              id
              fullName
              avatar {
                url
                id
              }
              avatarIndex
              avatarPhotos {
                url
                id
              }
            }
            likes {
              pageInfo {
                endCursor
                hasNextPage
                hasPreviousPage
                startCursor
              }
              edges {
                cursor
                node {
                  id
                  pk
                  objectId
                  user {
                    id
                    fullName
                    avatarIndex
                    avatarPhotos {
                      url
                      id
                    }
                    avatar {
                      id
                      url
                    }
                  }
                }
              }
            }
            commentsCount
            comments {
              pageInfo {
                endCursor
                hasNextPage
                hasPreviousPage
                startCursor
              }
              edges {
                cursor
                node {
                  id
                  pk
                  createdDate
                  commentDescription
                  objectId
                  pk
                  user {
                    id
                    fullName
                    avatarIndex
                    avatarPhotos {
                      url
                      id
                    }
                    avatar {
                      id
                      url
                    }
                  }
                }
              }
            }
          }
        }
      `,
    });
    const flattenedStories = _.flatten(
      _.map(response.data, (userMultiStory) => {
        return _.map(userMultiStory, (item) => item);
      })
    );

    return response.data.userScheduledStories;
  };

  static getAllStories = async () => {
    const response = await client.query({
      query: gql`
        query {
          allUserMultiStories {
            user {
              id
              fullName
              avatar {
                url
                id
              }
              avatarIndex
              avatarPhotos {
                url
                id
              }
            }
            batchNumber
            stories {
              edges {
                cursor
                node {
                  createdDate
                  publishAt
                  file
                  createdBy {
                    id
                    fullName
                  }
                  fileType
                  id
                  pk
                  thumbnail
                  commentsCount
                  comments {
                    pageInfo {
                      endCursor
                      hasNextPage
                      hasPreviousPage
                      startCursor
                    }
                    edges {
                      cursor
                      node {
                        id
                        pk
                        createdDate
                        commentDescription
                        objectId
                        pk
                        user {
                          id
                          fullName
                          avatarIndex
                          avatarPhotos {
                            url
                            id
                          }
                          avatar {
                            id
                            url
                          }
                        }
                      }
                    }
                  }
                  likesCount
                  likes {
                    pageInfo {
                      endCursor
                      hasNextPage
                      hasPreviousPage
                      startCursor
                    }
                    edges {
                      cursor
                      node {
                        id
                        pk
                        objectId
                        user {
                          id
                          fullName
                          avatarIndex
                          avatarPhotos {
                            url
                            id
                          }
                          avatar {
                            id
                            url
                          }
                        }
                      }
                    }
                  }
                  user {
                    id
                    fullName
                    avatar {
                      url
                      id
                    }
                    avatarIndex
                    avatarPhotos {
                      url
                      id
                    }
                  }
                }
              }
              pageInfo {
                endCursor
                hasNextPage
                hasPreviousPage
                startCursor
              }
            }
          }
        }
      `,
    });
    const flattenedStories = _.flatten(
      _.map(response.data.allUserMultiStories, (userMultiStory) => {
        return _.map(userMultiStory.stories.edges, (item) => item);
      })
    );

    return flattenedStories;
  };

  static uploadScheduleStories = async (
    moderatorId,
    image,
    stockImageId,
    publishAt
  ) => {
    const response = await clientUpload.mutate({
      mutation: gql`
        mutation ScheduleStory(
          $file: Upload
          $moderatorId: String
          $publishAt: DateTime
          $stockImageId: Int
        ) {
          insertStory(
            moderatorId: $moderatorId
            file: $file
            publishAt: $publishAt
            stockImageId: $stockImageId
          ) {
            story {
              id
              file
              publishAt
              createdDate
              thumbnail
              isPublished
            }
          }
        }
      `,
      variables: {
        file: image,
        moderatorId,
        publishAt: publishAt,
        stockImageId,
      },
    });

    return {
      id: response.data.insertStory.story.id,
      file: baseUrl + "/media/" + response.data.insertStory.story.file,
      publishAt: response.data.insertStory.story.publishAt,
    };
  };

  // No Idea why It got duplicate name
  /* static __getScheduleMoments = async () => {
    const response = await client.query({
      query: gql`
        query {
          allUserMoments {
            edges {
              cursor
              node {
                pk
                createdDate
                publishAt
                id
                createdBy {
                  id
                  fullName
                }
                momentDescription
                file
                like
                user {
                  id
                }
              }
            }
          }
        }
      `,
    });

    const flattenedStories = _.flatten(
      _.map(response.data.allUserMoments, (moments) => moments)
    );

    return flattenedStories;
  }; */

  static onRemoveScheduleMoment = async (id) => {
    const response = await client.mutate({
      mutation: gql`
                mutation {
                  deleteMoment(id: ${id}) 
                    {
                      id
                      success
                    }
                  }
            `,
    });
    return response;
  };

  static uploadScheduleMoment = async (
    moderatorId,
    image,
    stockImageId,
    publishAt,
    description
  ) => {
    const response = await clientUpload.mutate({
      mutation: gql`
        mutation ScheduleMoment(
          $title: String!
          $momentDescription: String!
          $user: String!
          $allowComment: Boolean
          $file: Upload
          $moderatorId: String
          $stockImageId: Int
          $publishAt: DateTime!
        ) {
          insertMoment(
            Title: $title
            momentDescription: $momentDescription
            user: $user
            allowComment: $allowComment
            file: $file
            moderatorId: $moderatorId
            stockImageId: $stockImageId
            publishAt: $publishAt
          ) {
            moment {
              file
              id
              pk
              momentDescription
              publishAt
            }
          }
        }
      `,
      variables: {
        file: image,
        momentDescription: description,
        user: moderatorId,
        moderatorId,
        stockImageId,
        title: "moment",
        publishAt: publishAt,
      },
    });
    return response.data;
  };

  static getAllUserMoments = async () => {
    const response = await client.query({
      query: gql`
        query {
          allUserMoments {
            edges {
              cursor
              node {
                pk
                createdDate
                publishAt
                id
                createdBy {
                  id
                  fullName
                }
                momentDescription
                file
                like
                user {
                  id
                }
              }
            }
          }
        }
      `,
    });

    const flattenedStories = _.flatten(
      _.map(response.data.allUserMoments, (moments) => moments)
    );

    return flattenedStories;
  };

  static uploadAllUserMoments = async (moderatorId, data, stockImageId) => {
    const response = await clientUpload.mutate({
      mutation: gql`
        mutation InsertMoment(
          $title: String!
          $momentDescription: String!
          $user: String!
          $allowComment: Boolean
          $file: Upload
          $moderatorId: String
          $stockImageId: Int
        ) {
          insertMoment(
            Title: $title
            momentDescription: $momentDescription
            user: $user
            allowComment: $allowComment
            file: $file
            moderatorId: $moderatorId
            stockImageId: $stockImageId
          ) {
            moment {
              file
              id
              pk
              momentDescription
            }
          }
        }
      `,
      variables: {
        file: data?.id ? "" : data?.file,
        momentDescription: data?.description,
        user: moderatorId,
        moderatorId,
        stockImageId: data?.id,
        title: "moment",
      },
    });
    return {
      id: response.data.insertMoment?.moment?.pk,
      file: baseUrl + "/media/" + response.data.insertMoment?.moment?.file,
      momentDescription: response.data.insertMoment?.moment?.momentDescription,
    };
  };

  static onRemoveUserAllMoments = async (id) => {
    const response = await client.mutate({
      mutation: gql`
                mutation {
                  deleteMoment(id: ${id}) 
                    {
                      id
                      success
                    }
                  }
            `,
    });
    return response;
  };
}
