import { Component } from "react";
import React from "react";
import PropTypes from "prop-types";
import ImageUpload from "./index";

class AddChatImage extends Component {
    handle = () => {

    }
    render() {
        return (
            <ImageUpload
                onUpload={this.props.onUpload}
                onError={this.onError}
                onLoad={this.onLoad}
                ref={this.props.innerRef}
                height={this.props.height}
                disabled={this.props.disabled}
                header={this.props.header}
                folder={this.props.folder}
                moderatorId={this.props.moderatorId}
                getStockImages={this.props.getStockImages}
                stockImages={this.props.stockImages}
                onChange={this.props.onChange}
            />
        )
    }
}

export default React.forwardRef((props, ref) => <AddChatImage
    innerRef={ref} {...props}
/>);

AddChatImage.propTypes = {
};
