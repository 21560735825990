import { pendingChatActionTypes } from "../../constants/actions/pendingChat";
import UserApi from "../../utils/users";
var messageHeader = "API request failed";

export function getUsersInRoom(roomId) {
  return function (dispatch) {
    // dispatch({
    //     type: pendingChatActionTypes.GET_ROOM_USER_MAIN_REQUEST
    // });
    return UserApi.getUsersInRoom(roomId)
      .then((response) => {
        if (response.data.room) {
          dispatch({
            type: pendingChatActionTypes.GET_USERS_IN_ROOM_SUCCESS,
            data: { data: response.data.room },
          });
        }
      })
      .catch((error) => {
        console.log("fail", error);
        dispatch({
          type: pendingChatActionTypes.GET_ROOM_USER_MAIN_FAILURE,
          data: { errors: [error], header: messageHeader },
        });
      });
  };
}
