import React, { useEffect } from "react";
import { connect } from "react-redux";
import MainMenu from "../../components/mainMenu";
import WorkersList from "../../components/workers/WorkersList";
import { setActiveMenuPosition } from "../../actions/menu";
import { logoutAction } from "../../actions/session";
import { hideError, getWorkersList } from "../../actions/workers";

const WorkerView = (props) => {
  const {
    workers,
    menu,
    session,
    onLogout,
    setActiveMenuPosition,
    getWorkersList,
  } = props;

  useEffect(() => {
    if (workers.loading) {
      getWorkersList(workers?.limit, 0);
    }
    if (menu.activeMenu !== "workers") {
      setActiveMenuPosition("workers");
    }
  }, [workers.limit]);

  return (
    <div>
      <MainMenu
        needShowPrivateItems={session.isAuthenticated}
        menu={menu}
        onLogout={onLogout}
        setActiveMenuPosition={setActiveMenuPosition}
        userDetails={session.userDetails}
      />
      <WorkersList />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    menu: state.menu,
    users: state.users,
    session: state.session,
    workers: state.workers,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getWorkersList: (limit, offset) => {
      getWorkersList(limit, offset)(dispatch);
    },
    hideError: () => {
      hideError()(dispatch);
    },
    setActiveMenuPosition(activeMenu) {
      setActiveMenuPosition(activeMenu)(dispatch);
    },
    onLogout() {
      logoutAction()(dispatch);
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WorkerView);
