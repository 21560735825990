import { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import {
  Button,
  Checkbox,
  Dropdown,
  Grid,
  Image,
  Input,
  Popup,
} from "semantic-ui-react";

import downArrow from "../../assets/images/downArrow.png";
import UserApi from "../../utils/users";
import { getPresets } from "../../actions/fakeUsers";

const SortByFilter = ({ onFilter, onClearFilter }) => {
  const [filterMenu, setFilterMenu] = useState(false);
  const [filter, setFilter] = useState({});
  const [countries, setCountries] = useState([]);
  const dispatch = useDispatch();
  const {
    fakeUsers: { presets },
  } = useSelector((state) => state);

  const getAllCountries = async () => {
    const allCountries = await UserApi.allCountries();
    setCountries(allCountries?.data?.allCountries);
  };

  useEffect(() => {
    dispatch(getPresets());
    getAllCountries();
  }, []);

  const ageOptions = presets?.ages?.map(({ id, age }, index) => ({
    key: index,
    value: id,
    text: age,
  }));
  const ethnicityOptions = presets?.ethnicity?.map(
    ({ id, ethnicity }, index) => ({
      key: index,
      value: id,
      text: ethnicity,
    })
  );
  const countryOptions = countries?.map(({ id, fullName }, index) => ({
    key: index,
    value: id,
    text: fullName,
  }));

  const onFilterHandler = () => {
    let filterString = [];
    for (const key in filter) {
      filterString.push({ key, value: filter[key] });
    }

    onFilter(filterString);
    // Implement Sort By Filter on Parent

    setFilterMenu(false);
  };

  const hourArray = Array.from({ length: 24 }).map((_, index) => ({
    key: index,
    text: `${index + 1} ${index === 0 ? "hour" : "hours"} ago`,
    value: moment()
      .subtract(index + 1, "hours")
      .toISOString(),
  }));

  const dayArray = [
    { key: 0, text: "Today", value: moment().toISOString() },
    {
      key: 1,
      text: "Yesterday",
      value: moment().subtract(1, "days").toISOString(),
    },
    {
      key: 2,
      text: "2 day's ago",
      value: moment().subtract(2, "days").toISOString(),
    },
    {
      key: 3,
      text: "3 day's ago",
      value: moment().subtract(3, "days").toISOString(),
    },
    {
      key: 4,
      text: "4 day's ago",
      value: moment().subtract(4, "days").toISOString(),
    },
    {
      key: 5,
      text: "5 day's ago",
      value: moment().subtract(5, "days").toISOString(),
    },
    {
      key: 6,
      text: "6 day's ago",
      value: moment().subtract(6, "days").toISOString(),
    },
    {
      key: 7,
      text: "7 day's ago",
      value: moment().subtract(7, "days").toISOString(),
    },
    {
      key: 8,
      text: "2 weeks ago",
      value: moment().subtract(2, "weeks").toISOString(),
    },
    {
      key: 9,
      text: "3 weeks ago",
      value: moment().subtract(3, "weeks").toISOString(),
    },
    {
      key: 10,
      text: "1 month ago",
      value: moment().subtract(1, "month").toISOString(),
    },
  ];

  const getDayLabel = (value) => {
    switch (value) {
      case 0:
        return "Today";
      case 1:
        return "Yesterday";
      default:
        const filterVal =
          value <= 7
            ? value
            : value <= 14
            ? "2 weeks"
            : value <= 21
            ? "3 weeks"
            : value < 32
            ? "1 month"
            : undefined;
        return dayArray?.find((item) => item?.text.includes(filterVal))?.text;
    }
  };

  const clearFilter = () => {
    // window.location.reload();
    onClearFilter();
    setFilterMenu(false);
  };

  return (
    <Popup
      on={"click"}
      position="bottom right"
      open={filterMenu}
      onOpen={() => setFilterMenu(true)}
      onClose={() => setFilterMenu(false)}
      trigger={
        <Dropdown
          text="Sort by"
          className="icon sortBox"
          floating
          labeled
          button
          icon={
            <div className="iconBox">
              <Image src={downArrow} width="10px" height="5px" />
            </div>
          }
        />
      }
    >
      <div style={{ width: "800px" }}>
        <Grid className="filterMenu">
          <Grid.Row>
            <Grid.Column width={7}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "70%",
                }}
              >
                <Checkbox
                  className="radioBox"
                  label="Online Users"
                  checked={filter?.s_online === true}
                  onClick={() => setFilter({ ...filter, s_online: true })}
                />
                <Checkbox
                  className="radioBox"
                  label="Offline Users"
                  checked={filter?.s_online === false}
                  onClick={() => setFilter({ ...filter, s_online: false })}
                />
              </div>
            </Grid.Column>
            <Grid.Column width={9}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
                className="dropContainer"
              >
                <p>Last Login:</p>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                  className="dropdownView"
                >
                  <Dropdown
                    style={{ width: "140px" }}
                    text={`${
                      hourArray.find((item) =>
                        item.text.includes(
                          parseInt(
                            Math.abs(
                              new Date(filter.last_seen_to) -
                                new Date(filter.last_seen_from)
                            ) / 3600000
                          ).toString()
                        )
                      )?.text || "By Hour"
                    }`}
                    selection
                    options={hourArray}
                    onChange={(e, data) =>
                      setFilter({
                        ...filter,
                        last_seen_from: data?.value,
                        last_seen_to: moment().toISOString(),
                      })
                    }
                    className="icon filterBox"
                    floating
                    labeled
                    button
                    icon={
                      <div className="iconBox">
                        <Image src={downArrow} width="10px" height="5px" />
                      </div>
                    }
                  />
                  <Dropdown
                    style={{ width: "140px" }}
                    text={`${
                      getDayLabel(
                        parseInt(
                          Math.abs(
                            new Date(filter.last_seen_to) -
                              new Date(filter.last_seen_from)
                          ) / 86400000
                        )
                      ) || "By Day"
                    }`}
                    selection
                    options={dayArray}
                    onChange={(e, data) =>
                      setFilter({
                        ...filter,
                        last_seen_from: data?.value,
                        last_seen_to: moment().toISOString(),
                      })
                    }
                    className="icon filterBox"
                    floating
                    labeled
                    button
                    icon={
                      <div className="iconBox">
                        <Image src={downArrow} width="10px" height="5px" />
                      </div>
                    }
                  />
                </div>
              </div>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={7}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: "25px",
                }}
                className="dropContainer"
              >
                <p>By Age:</p>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                  className="dropdownView"
                >
                  <Dropdown
                    selection
                    options={ageOptions}
                    onChange={(e, data) => {
                      setFilter({ ...filter, age_from: data?.value });
                    }}
                    text={`${
                      ageOptions?.find(
                        (item) => item?.value === filter?.age_from
                      )?.text || "From"
                    }`}
                    value={filter?.age_from}
                    className="icon filterBox"
                    floating
                    labeled
                    button
                    icon={
                      <div className="iconBox">
                        <Image src={downArrow} width="10px" height="5px" />
                      </div>
                    }
                  />
                  <Dropdown
                    selection
                    onChange={(e, data) => {
                      setFilter({ ...filter, age_to: data?.value });
                    }}
                    options={ageOptions}
                    text={`${
                      ageOptions?.find((item) => item?.value === filter?.age_to)
                        ?.text || "To"
                    }`}
                    value={filter?.age_to}
                    className="icon filterBox"
                    floating
                    labeled
                    button
                    icon={
                      <div className="iconBox">
                        <Image src={downArrow} width="10px" height="5px" />
                      </div>
                    }
                  />
                </div>
              </div>
            </Grid.Column>
            <Grid.Column width={9}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
                className="dropContainer"
              >
                <p>Joined Date:</p>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                  className="dropdownView"
                >
                  <Dropdown
                    selection
                    style={{ width: "140px" }}
                    text={`${
                      hourArray.find((item) =>
                        item.text.includes(
                          parseInt(
                            Math.abs(
                              new Date(filter.join_date_to) -
                                new Date(filter.join_date_from)
                            ) / 3600000
                          ).toString()
                        )
                      )?.text || "By Hour"
                    }`}
                    options={hourArray}
                    onChange={(e, data) =>
                      setFilter({
                        ...filter,
                        join_date_from: data?.value,
                        join_date_to: moment().toISOString(),
                      })
                    }
                    className="icon filterBox"
                    floating
                    labeled
                    button
                    icon={
                      <div className="iconBox">
                        <Image src={downArrow} width="10px" height="5px" />
                      </div>
                    }
                  />
                  <Dropdown
                    selection
                    style={{ width: "140px" }}
                    text={`${
                      getDayLabel(
                        parseInt(
                          Math.abs(
                            new Date(filter.join_date_to) -
                              new Date(filter.join_date_from)
                          ) / 86400000
                        )
                      ) || "By Day"
                    }`}
                    options={dayArray}
                    onChange={(e, data) =>
                      setFilter({
                        ...filter,
                        join_date_from: data?.value,
                        join_date_to: moment().toISOString(),
                      })
                    }
                    className="icon filterBox"
                    floating
                    labeled
                    button
                    icon={
                      <div className="iconBox">
                        <Image src={downArrow} width="10px" height="5px" />
                      </div>
                    }
                  />
                </div>
              </div>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={7}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: "25px",
                }}
                className="dropContainer"
              >
                <p>Gender</p>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "70%",
                  }}
                >
                  <Checkbox
                    className="radioBox"
                    label="Male"
                    checked={filter?.gender === 1}
                    onClick={() => setFilter({ ...filter, gender: 1 })}
                  />
                  <Checkbox
                    className="radioBox"
                    label="Female"
                    checked={filter?.gender === 2}
                    onClick={() => setFilter({ ...filter, gender: 2 })}
                  />
                  <Checkbox
                    className="radioBox"
                    label="Both"
                    checked={filter?.gender === 3}
                    onClick={() => setFilter({ ...filter, gender: 3 })}
                  />
                </div>
              </div>
            </Grid.Column>
            <Grid.Column width={9}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
                className="dropContainer"
              >
                <p>By Country:</p>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "80%",
                  }}
                  className="dropdownView country-dropdown"
                >
                  <Dropdown
                    selection
                    options={countryOptions}
                    onChange={(e, data) => {
                      setFilter({
                        ...filter,
                        country: countryOptions.find(
                          (item) => item.value === data?.value
                        )?.text,
                      });
                    }}
                    text={`${
                      countryOptions?.find(
                        (item) => item?.text === filter?.country
                      )?.text || "Select here"
                    }`}
                    value={filter?.country}
                    className="icon filterBox"
                    floating
                    labeled
                    button
                    icon={
                      <div className="iconBox">
                        <Image src={downArrow} width="10px" height="5px" />
                      </div>
                    }
                  />
                  <p style={{ margin: "0 12px" }}>Or</p>
                  <Input
                    fluid
                    placeholder="Search Country"
                    className="filterInput searchBox"
                  />
                </div>
              </div>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={7}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-Start",
                  alignItems: "center",
                }}
              >
                <Button
                  className="ui blue"
                  onClick={() => {
                    clearFilter();
                  }}
                >
                  Clear Filter
                </Button>
                <Button
                  className="filterButton"
                  onClick={() => {
                    onFilterHandler();
                  }}
                >
                  Filter
                </Button>
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    </Popup>
  );
};

export default memo(SortByFilter);
