export const commonConstants = {
  DEFAULT_MENU_POSITION: {
    activeMenu: "dashboard",
  },
  DEFAULT_USER: {
    value: "",
    name: "",
    text: 18,
    gender: "male",
    about: "",
  },
  SUPER_ADMIN_ROLE: "SUPER_ADMIN",
  ADMIN_ROLE: "ADMIN",
  CHATTER_ROLE: "CHATTER",
  REGULAR_ROLE: "REGULAR",
  BOOK_ITEM: "book",
  MOVIE_ITEM: "movie",
  MUSIC_ITEM: "music",
  TV_SHOW_ITEM: "tvShow",
  SPORT_TEAM_ITEM: "team",
  ACTION_ADD: "add",
  ACTION_REMOVE: "remove",
  INTERESTED_IN_OPTIONS: [
    { key: 1, text: "Serious Men", value: 1 },
    { key: 2, text: "Serious Women", value: 2 },
    { key: 3, text: "Serious Both", value: 3 },
    { key: 4, text: "Casual Men", value: 4 },
    { key: 5, text: "Casual Women", value: 5 },
    { key: 6, text: "Casual Both", value: 6 },
    { key: 7, text: "New friends Men", value: 7 },
    { key: 8, text: "New friends Women", value: 8 },
    { key: 9, text: "New friends Both", value: 9 },
    { key: 10, text: "Room mates Men", value: 10 },
    { key: 11, text: "Room mates Women", value: 11 },
    { key: 12, text: "Room mates Both", value: 12 },
    { key: 13, text: "Business contacts Men", value: 13 },
    { key: 14, text: "Business contacts Women", value: 14 },
    { key: 15, text: "Business contacts Both", value: 15 },
  ],
};

export const ageOptions = [
  {
    value: 1,
    text: 18,
  },
  {
    value: 2,
    text: 19,
  },
  {
    value: 3,
    text: 20,
  },
  {
    value: 4,
    text: 21,
  },
  {
    value: 5,
    text: 22,
  },
  {
    value: 6,
    text: 23,
  },
  {
    value: 7,
    text: 24,
  },
  {
    value: 8,
    text: 25,
  },
  {
    value: 9,
    text: 26,
  },
  {
    value: 10,
    text: 27,
  },
  {
    value: 11,
    text: 28,
  },
  {
    value: 12,
    text: 29,
  },
  {
    value: 13,
    text: 30,
  },
  {
    value: 14,
    text: 31,
  },
  {
    value: 15,
    text: 32,
  },
  {
    value: 16,
    text: 33,
  },
  {
    value: 17,
    text: 34,
  },
  {
    value: 18,
    text: 35,
  },
  {
    value: 19,
    text: 36,
  },
  {
    value: 20,
    text: 37,
  },
  {
    value: 21,
    text: 38,
  },
  {
    value: 22,
    text: 39,
  },
  {
    value: 23,
    text: 40,
  },
  {
    value: 24,
    text: 41,
  },
  {
    value: 25,
    text: 42,
  },
  {
    value: 26,
    text: 43,
  },
  {
    value: 27,
    text: 44,
  },
  {
    value: 28,
    text: 45,
  },
  {
    value: 29,
    text: 46,
  },
  {
    value: 30,
    text: 47,
  },
  {
    value: 31,
    text: 48,
  },
  {
    value: 32,
    text: 49,
  },
  {
    value: 33,
    text: 50,
  },
  {
    value: 34,
    text: 51,
  },
  {
    value: 35,
    text: 52,
  },
  {
    value: 36,
    text: 53,
  },
  {
    value: 37,
    text: 54,
  },
  {
    value: 38,
    text: 55,
  },
  {
    value: 39,
    text: 56,
  },
  {
    value: 40,
    text: 57,
  },
  {
    value: 41,
    text: 58,
  },
  {
    value: 42,
    text: 59,
  },
];

export const zodiacSignOptions = [
  { text: "Aquarius", value: "Aquarius" },
  { text: "Pisces", value: "Pisces" },
  { text: "Aries", value: "Aries" },
  { text: "Taurus", value: "Taurus" },
  { text: "Gemini", value: "Gemini" },
  { text: "Cancer", value: "Cancer" },
  { text: "Leo", value: "Leo" },
  { text: "Virgo", value: "Virgo" },
  { text: "Libra", value: "Libra" },
  { text: "Scorpio", value: "Scorpio" },
  { text: "Sagittarius", value: "Sagittarius" },
  { text: "Capricorn", value: "Capricorn" },
];

export const heightOptions = [
  {
    value: 1,
    text: 140,
  },
  {
    value: 2,
    text: 143,
  },
  {
    value: 3,
    text: 146,
  },
  {
    value: 4,
    text: 148,
  },
  {
    value: 5,
    text: 151,
  },
  {
    value: 6,
    text: 153,
  },
  {
    value: 7,
    text: 156,
  },
  {
    value: 8,
    text: 158,
  },
  {
    value: 9,
    text: 161,
  },
  {
    value: 10,
    text: 163,
  },
  {
    value: 11,
    text: 166,
  },
  {
    value: 12,
    text: 168,
  },
  {
    value: 13,
    text: 171,
  },
  {
    value: 14,
    text: 173,
  },
  {
    value: 15,
    text: 176,
  },
  {
    value: 16,
    text: 179,
  },
  {
    value: 17,
    text: 181,
  },
  {
    value: 18,
    text: 184,
  },
  {
    value: 19,
    text: 186,
  },
  {
    value: 20,
    text: 189,
  },
  {
    value: 21,
    text: 191,
  },
  {
    value: 22,
    text: 194,
  },
  {
    value: 23,
    text: 196,
  },
  {
    value: 24,
    text: 199,
  },
  {
    value: 25,
    text: 201,
  },
  {
    value: 26,
    text: 204,
  },
  {
    value: 27,
    text: 207,
  },
  {
    value: 28,
    text: 209,
  },
  {
    value: 29,
    text: 212,
  },
  {
    value: 30,
    text: 214,
  },
  {
    value: 31,
    text: 217,
  },
  {
    value: 32,
    text: 219,
  },
  {
    value: 33,
    text: 222,
  },
  {
    value: 34,
    text: 224,
  },
  {
    value: 35,
    text: 227,
  },
  {
    value: 36,
    text: 229,
  },
  {
    value: 37,
    text: 232,
  },
  {
    value: 38,
    text: 234,
  },
  {
    value: 39,
    text: 237,
  },
  {
    value: 40,
    text: 240,
  },
  {
    value: 41,
    text: 242,
  },
  {
    value: 42,
    text: 245,
  },
  {
    value: 43,
    text: 247,
  },
  {
    value: 44,
    text: 668,
  },
];
