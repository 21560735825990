import { pendingChatActionTypes } from "../constants/actions/pendingChat";
import { isAuthenticated } from '../utils/auth';

const initialState = {
    // users: [],
    // search: '',
    // currentPageSize: 0,
    // sort_column: '',
    // direction: 0,
    // next: '',
    // previous: '',
    // currentUser: {
    //     main: {},
    //     details: {}
    // },
    // newUser: {},
    loading: false,
    error: {
        visible: false,
        header: '',
        messages: []
    },
    usersInRooom : {}
};

export default function pendingChats(state = initialState, action) {
    switch (action.type) {
        case pendingChatActionTypes.GET_ROOM_USER_MAIN_REQUEST:
            return {
                ...state,
                loading: true
            };
        case pendingChatActionTypes.GET_ROOM_USER_MAIN_FAILURE:
            
            return {
                ...state,
                error: {
                    ...state.error,
                    header: action.data.header && action.data.header !== '' ? action.data.header : '',
                    messages: action.data.errors && action.data.errors.length > 0 ? action.data.errors : [],
                    visible: true
                },
                loading: false
            };
        case pendingChatActionTypes.GET_USERS_IN_ROOM_SUCCESS:
            // console.log('in success' , action.data)
            return {
                ...state,
                usersInRooom: action.data,
                loading: false
            };
    
        default:
            return state;
    
    }
}