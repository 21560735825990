import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Card,
  Form,
  Header,
  Icon,
  Image,
  Message,
  Modal,
  Segment,
} from "semantic-ui-react";
import { getMoments, getStories, uploadStory } from "../../actions/fakeUsers";
import { selectFakeUsersState } from "../../store/selectors/fakeUsersSelectors";
import UserApi from "../../utils/users";
import AddNewPhoto from "../imageUpload/addNewPhoto";
import { useFormik } from "formik";
import * as yup from "yup";
import { getStockImages } from "../../actions/chat";

const AddCommentModalFormState = yup.object().shape({
  comment: yup.string().required("Comment text is requiered!"),
});

const AddCommentModal = ({
  isModalOpen = false,
  handleClose,
  handleSuccess,
  momentId,
}) => {
  const [momentComments, setMomentComments] = useState([]);
  // const [comments, setComments] = useState([]);

  const [state, setState] = useState({
    errors: [],
    isSubmitting: false,
  });

  const initialValues = {
    comment: "",
  };

  const getMomentComments = async () => {
    const res = await UserApi.getMomentComments(momentId);
    if (res) {
      setMomentComments(res);
    }
  };

  useEffect(() => {
    if (momentId) {
      getMomentComments(momentId);
    }
  }, [momentId, isModalOpen]);

  const handleFormSubmit = async (values) => {
    setState({
      ...state,
      errors: [],
      isSubmitting: true,
    });
    const res = await UserApi.addCommnetOnStory(momentId, values.comment);

    if (!res) {
      setState({
        ...state,
        isSubmitting: false,
        errors: [res?.message ?? "Something went wrong while posting comment!"],
      });
      return;
    }
    setState({
      ...state,
      isSubmitting: false,
      errors: [],
    });
    formik.resetForm();
    handleSuccess();
  };

  const formik = useFormik({
    initialValues,
    validationSchema: AddCommentModalFormState,
    validateOnChange: true,
    validateOnBlur: true,
    validateOnMount: false,
    onSubmit: handleFormSubmit,
  });

  const handleModalClose = () => {
    formik.resetForm();
    handleClose();
  };

  return (
    <Modal open={isModalOpen} onClose={handleModalClose}>
      <Header icon="comments" content="Comments" />
      <Modal.Content>
        <ul style={{ listStyle: "none", padding: "0", margin: "0" }}>
          {momentComments.length === 0 && (
            <Header>No Comments for this Moment</Header>
          )}
          {momentComments?.map((comment, index) => (
            <li
              key={index}
              style={{
                display: "flex",
                padding: "5px 0px",
              }}
            >
              <Image avatar src={comment?.user?.avatarPhotos[0]?.url || ""} />
              <div>
                <Header as="a">{comment?.user?.username}</Header>
                <p style={{ color: "gray" }}>
                  <small>{comment?.commentDescription}</small>
                  <br />
                  <small>
                    Commented at:{" "}
                    {new Date(comment?.createdDate).toLocaleString()}
                  </small>
                </p>
              </div>
            </li>
          ))}
        </ul>
        {/* <Form style={{ padding: 0 }}>
          <Form.Group widths="equal">
            <Form.TextArea
              label="Comment"
              name="comment"
              placeholder="Write your comment here"
              value={formik.values.comment}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.comment && formik.errors.comment}
            />
          </Form.Group>
        </Form> */}
      </Modal.Content>
      <Modal.Actions>
        {state?.errors?.length
          ? state.errors.map((error) => (
              <Message
                style={{ display: "block", textAlign: "start" }}
                error={true}
              >
                {error}{" "}
              </Message>
            ))
          : null}
        <Button color="red" onClick={() => handleModalClose()}>
          <Icon name="remove" /> Cancel
        </Button>
        {/* <Button
          loading={state.isSubmitting}
          type="submit"
          color="green"
          onClick={formik.handleSubmit}
        >
          <Icon name="checkmark" /> Submit
        </Button> */}
      </Modal.Actions>
    </Modal>
  );
};

const LikeModal = ({
  isModalOpen = false,
  handleClose,
  handleSuccess,
  momentId,
  likes,
}) => {
  const [momentLikes, setMomentLikes] = useState([]);
  const [state, setState] = useState({
    errors: [],
    isSubmitting: false,
  });

  // const initialValues = {
  //   comment: "",
  // };

  const handleLikeMoment = async (values) => {
    setState({
      ...state,
      errors: [],
      isSubmitting: true,
    });
    const res = await UserApi.addLikeOnStory(momentId);

    if (!res) {
      setState({
        ...state,
        isSubmitting: false,
        errors: [res?.message ?? "Something went wrong while posting like!"],
      });
      handleSuccess();
      return;
    }
    setState({
      ...state,
      isSubmitting: false,
      errors: [],
    });
    handleSuccess();
  };

  const handleModalClose = () => {
    handleClose();
  };

  const getMomentLikes = async () => {
    const res = await UserApi.getMomentLikes(momentId);
    if (res) {
      setMomentLikes(res);
    }
  };

  useEffect(() => {
    if (momentId) {
      getMomentLikes(momentId);
    }
  }, [momentId, isModalOpen]);

  return (
    <Modal open={isModalOpen} onClose={handleModalClose}>
      <Header icon="like" content="Likes" />
      <Modal.Content>
        <ul style={{ listStyle: "none", padding: "0", margin: "0" }}>
          {momentLikes.length === 0 && (
            <Header>No Likes for this Moment</Header>
          )}
          {momentLikes?.map((like, index) => (
            <li
              key={index}
              style={{
                display: "flex",
                padding: "5px 0px",
              }}
            >
              <Image avatar src={like?.user?.avatarPhotos[0]?.url || ""} />
              <div>
                <Header as="a">{like?.user?.username}</Header>
                <p style={{ color: "gray" }}>
                  <small>
                    Last seen at:{" "}
                    {new Date(like?.user?.lastSeen).toLocaleString()}
                  </small>
                </p>
              </div>
            </li>
          ))}
        </ul>
      </Modal.Content>
      <Modal.Actions>
        {state?.errors?.length
          ? state.errors.map((error) => (
              <Message
                style={{ display: "block", textAlign: "start" }}
                error={true}
              >
                {error}{" "}
              </Message>
            ))
          : null}
        <Button color="red" onClick={() => handleModalClose()}>
          <Icon name="remove" /> Cancel
        </Button>
        {/* <Button
          loading={state.isSubmitting}
          type="submit"
          color="green"
          onClick={handleLikeMoment}
        >
          <Icon name="like" /> Like
        </Button> */}
      </Modal.Actions>
    </Modal>
  );
};

const FakeUserMoments = () => {
  const dispatch = useDispatch();
  const fakeUsersState = useSelector(selectFakeUsersState);
  const userId = fakeUsersState?.currentUser?.id;
  const moments = fakeUsersState.moments;
  const [likeOpen, setLikeOpen] = useState(false);
  const [commentOpen, setCommentOpen] = useState(false);
  const [story, setStory] = useState({});

  const [state, setState] = useState({
    isDirty: false,
    storyId: "",
    isDeleteModalOpen: false,
    errors: [],
    userId,
    isLoading: false,
  });
  useEffect(() => {
    if (userId) dispatch(getMoments(userId));
  }, []);

  const removeUserStory = async (storyID) => {
    setState({
      ...state,
      isDeleteModalOpen: true,
      momentId: storyID,
      errors: [],
    });
  };

  const handleDeleteStory = async () => {
    setState({
      ...state,
      isLoading: true,
    });
    const res = await UserApi.removeUserStory({
      momentId: state.momentId,
    });
    if (!res) {
      setState({
        ...state,
        isLoading: false,
        errors: [
          res?.message ?? "Something went wrong while removing user story!",
        ],
      });
      return;
    }
    if (res?.errors) {
      setState({
        ...state,
        isLoading: false,
        errors: res?.errors ?? [],
      });
      return;
    }
    setState({
      ...state,
      isLoading: false,
      isDeleteModalOpen: false,
      errors: [],
    });
    dispatch(getStories(userId));
  };

  const onUploadStory = (params) => {
    dispatch(uploadStory(userId, params));
  };

  const handleCommentModalClose = () => {
    setCommentOpen(false);
  };

  const hanldeAddCommentSuccess = () => {
    setCommentOpen(false);
    dispatch(getStories(userId));
  };

  const handleLikeModal = (state) => {
    setLikeOpen(state);
  };

  const handleLikeModalClose = () => {
    setLikeOpen(false);
  };

  const handleLikeSuccess = () => {
    setLikeOpen(false);
    dispatch(getStories(userId));
  };

  return (
    <>
      <Modal open={state.isDeleteModalOpen}>
        <Header icon="archive" content="Are you sure want to delete ?" />
        <Modal.Content>
          <p>The file should be not recovered!</p>
        </Modal.Content>
        <Modal.Actions>
          {state?.errors?.length
            ? state.errors.map((error) => (
                <Message
                  style={{ display: "block", textAlign: "start" }}
                  error={true}
                >
                  {error}{" "}
                </Message>
              ))
            : null}
          <Button
            color="red"
            onClick={() => {
              setState({
                ...state,
                errors: [],
                isDeleteModalOpen: false,
                isLoading: false,
              });
            }}
          >
            <Icon name="remove" /> Cancel
          </Button>
          <Button color="green" onClick={handleDeleteStory}>
            <Icon name="checkmark" /> Delete
          </Button>
        </Modal.Actions>
      </Modal>
      <Segment
        inverted={state.isDirty && fakeUsersState.avatar_photos.length === 0}
        tertiary={state.isDirty && fakeUsersState.avatar_photos.length === 0}
        secondary={state.isDirty && fakeUsersState.avatar_photos.length > 0}
        color={
          state.isDirty && fakeUsersState.avatar_photos.length === 0
            ? "red"
            : "black"
        }
      >
        <Header
          size="large"
          color={
            state.isDirty && fakeUsersState.photos.length === 0
              ? "red"
              : "black"
          }
        >
          Moments
        </Header>
        {moments.length === 0 && <Header>No Moments selected</Header>}
        <Card.Group itemsPerRow={4}>
          {moments.map((image, index) => {
            return (
              <Card key={index} className="user-card-main-container">
                <div className="user-moment-image">
                  <Image src={image.file} centered />
                  <div>
                    <button
                      style={{
                        width: 30,
                        height: 30,
                        background: "#ffffff",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        position: "absolute",
                        borderRadius: "10px",
                        top: 4,
                        right: 79,
                        border: "none",
                        zIndex: 10,
                      }}
                      type="button"
                      onClick={() => {
                        setStory(image);
                        handleLikeModal(true);
                      }}
                    >
                      <div
                        style={{
                          position: "absolute",
                          top: "-5px",
                          right: "-3px",
                          width: "17px",
                          height: "17px",
                          borderRadius: "50%",
                          backgroundColor: "gold",
                          fontSize: "10px",
                          display: "grid",
                          placeItems: "center",
                          zIndex: 10,
                        }}
                      >
                        {image?.likes || 0}
                      </div>
                      <div>
                        <Icon
                          className="create-fake-moderator-remove-moment-image-icon"
                          link
                          corner
                          name={"like"}
                        />
                      </div>
                    </button>
                    <button
                      style={{
                        width: 30,
                        height: 30,
                        background: "#ffffff",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        position: "absolute",
                        borderRadius: "10px",
                        top: 5,
                        right: 42,
                        border: "none",
                        zIndex: 10,
                      }}
                      type="button"
                      onClick={() => {
                        setStory(image);
                        setCommentOpen(!commentOpen);
                      }}
                    >
                      <div
                        style={{
                          position: "absolute",
                          top: "-5px",
                          right: "-3px",
                          width: "17px",
                          height: "17px",
                          borderRadius: "50%",
                          backgroundColor: "gold",
                          fontSize: "10px",
                          display: "grid",
                          placeItems: "center",
                        }}
                      >
                        {image?.comments || 0}
                      </div>
                      <div>
                        <Icon
                          className="create-fake-moderator-remove-moment-image-icon"
                          link
                          corner
                          name={"comments"}
                        />
                      </div>
                    </button>
                    <button
                      style={{
                        width: 30,
                        height: 30,
                        background: "#ffffff",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        position: "absolute",
                        borderRadius: "10px",
                        top: 5,
                        right: 5,
                        border: "none",
                      }}
                      type="button"
                      onClick={() => removeUserStory(image?.id)}
                    >
                      <div>
                        <Icon
                          className="create-fake-moderator-remove-moment-image-icon"
                          link
                          corner
                          name={"trash"}
                        />
                      </div>
                    </button>
                  </div>
                </div>
              </Card>
            );
          })}
          <Card>
            <AddNewPhoto
              onUpload={onUploadStory}
              height={"200px"}
              header={"Add new image"}
              stockImage={true}
              stockImages={fakeUsersState?.stockImages}
              moderatorId={userId}
              getStockImages={(id) => dispatch(getStockImages(id))}
              folder={userId || "images"}
            />
          </Card>
        </Card.Group>
      </Segment>

      <AddCommentModal
        isModalOpen={commentOpen}
        momentId={story?.id}
        handleClose={handleCommentModalClose}
        handleSuccess={hanldeAddCommentSuccess}
      />

      <LikeModal
        isModalOpen={likeOpen}
        momentId={story?.id}
        handleClose={handleLikeModalClose}
        handleSuccess={handleLikeSuccess}
      />
    </>
  );
};

export default FakeUserMoments;
