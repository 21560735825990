import React from "react";
import { connect } from "react-redux";
import MainMenu from "../../components/mainMenu";
import { getAdminsList, clearAdmin } from "../../actions/admins";
import { setActiveMenuPosition } from "../../actions/menu";
import { logoutAction } from "../../actions/session";
import { useEffect } from "react";
import AdminsList from "../../components/admins/list";

const AdminView = (props) => {
  const { getAdminsList, admins, menu, onLogout, session } = props;
  // console.log(admins?.loading, "admins?.loading");

  useEffect(() => {
    getAdminsList(
      admins?.limit,
      admins?.offset,
      admins?.direction,
      admins?.orderedField
    );
    // console.log(admins?.admins, "admins-working-effect");
    // if (admins?.loading) {
    //   console.log(`working`);
    // }
    if (menu.activeMenu !== "admins") {
      setActiveMenuPosition("admins");
    }
  }, []);

  return (
    <div>
      <MainMenu
        needShowPrivateItems={session.isAuthenticated}
        menu={menu}
        onLogout={onLogout}
        setActiveMenuPosition={setActiveMenuPosition}
        userDetails={session.userDetails}
      />
      <AdminsList />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    menu: state.menu,
    admins: state.admins,
    session: state.session,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    clearAdmin: () => {
      clearAdmin()(dispatch);
    },
    getAdminsList: (limit, offset, ordered, orderedField, fields, search) => {
      getAdminsList(
        limit,
        offset,
        ordered,
        orderedField,
        fields,
        search
      )(dispatch);
    },
    setActiveMenuPosition(activeMenu) {
      setActiveMenuPosition(activeMenu)(dispatch);
    },
    onLogout() {
      logoutAction()(dispatch);
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminView);
