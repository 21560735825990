import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Accordion, Grid, Image, Modal, Popup, Tab } from "semantic-ui-react";
import { setErrorChat } from "../../actions/chat";
import { ChatApi } from "../../utils/chat_api";
import cancelVector from "../../../src/assets/images/closeBlackVector.png";
import "./common.css";

const SendGiftSection = ({
  senderId,
  receiverId,
  refreshChat,
  removeCurrentChats,
  currentChat,
  setOpenGift,
  setGift,
  openGift,
}) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [realGifts, setRealGifts] = useState([]);
  const [virtualGifts, setVirtualGifts] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    // getRealGifts();
    getVirtualGifts();
  }, [senderId]);

  const handleClick = (e, props) => {
    const { index } = props;
    const newIndex = activeIndex === index ? -1 : index;
    setActiveIndex(newIndex);
  };

  const handleGiftSend = async (giftId) => {
    const res = await ChatApi.sendGift({
      giftId: giftId,
      senderId: senderId,
      receiverId: receiverId,
    });
    if (!res?.error) {
      // refreshChat();
      removeCurrentChats();
    } else {
      dispatch(setErrorChat("API request failed", [res?.error]));
    }
    setOpenGift();
  };

  const getRealGifts = async () => {
    const res = await ChatApi.getAllRealGifts();
    if (res) {
      setRealGifts(res);
    }
  };

  const getVirtualGifts = async () => {
    const res = await ChatApi.getAllVirtualGifts();
    if (res?.length) {
      setVirtualGifts(res);
    }
  };

  return (
    <div>
      {/* <Popup open={openGift} style={{ borderRadius: "20px" }} position='top center' size={"huge"}> */}
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <p style={{ fontSize: "20px", fontWeight: "700", color: "#333333" }}>
          Send Gifts
        </p>
        <Image
          src={cancelVector}
          height={"25px"}
          width={"25px"}
          onClick={() => setOpenGift(false)}
        />
      </div>

      <Grid
        columns={3}
        style={{ width: "750px", height: window.innerHeight / 1.4 }}
      >
        <Grid.Row
          style={{
            height: window.innerHeight / 1.5,
            overflowY: "scroll",
            paddingTop: 0,
            paddingRight: "10px",
          }}
        >
          {virtualGifts.map((gift) => (
            <Grid.Column>
              <div>
                <div
                  style={{
                    width: "230px",
                    height: "230px",
                    overflow: "none",
                    cursor: "pointer",
                    marginTop: "20px",
                  }}
                  onClick={() => {
                    setOpenGift();
                    setGift(gift);
                  }}
                  // onClick={() => handleGiftSend(gift)}
                >
                  <img
                    src={gift.picture}
                    alt={gift.giftName}
                    style={{
                      width: "230px",
                      height: "230px",
                      objectFit: "cover",
                      borderRadius: "15px",
                      boxShadow: "rgba(0, 0, 0, 0.15) 0px 4px 8px",
                    }}
                  />
                </div>
              </div>
              <p
                style={{
                  fontSize: "14px",
                  fontWeight: "500",
                  paddingTop: "15px",
                  color: "#333333",
                }}
              >
                {gift.giftName}
              </p>
            </Grid.Column>
          ))}
        </Grid.Row>
      </Grid>
      {/* </Popup> */}
    </div>
  );
};

export default SendGiftSection;
