import { HashRouter as Router, Route, Redirect } from "react-router-dom";

import SignIn from "../containers/auth/signIn";
import SignUp from "../containers/auth/signUp";
import Dashboard from "../containers/dashboard";
import Users from "../containers/users/list";
import NewInvitation from "../containers/workers/invitation";
import NewUser from "../containers/users/new";
import FakeUser from "../containers/fakeUsers";
import { getUserDetails, isAllowedRole } from "../utils/auth";
import { commonConstants } from "../constants/common";
import Moderators from "../containers/moderators/list";
import pendingchats from "../containers/pendingChats/index";
import moderatorsList from "../containers/moderatorsList";
import Admins from "../containers/admins/list";
import Workers from "../containers/workers/list";
import CreateModeratorView from "../containers/moderators/invitation";
import ChatUser from "../containers/users/chatUser/chatUser";
import ContactUs from "../containers/contactUs/index";
import EditWoker from "../containers/EditWoker";
import EditUser from "../containers/Edituser";
import EditAdmin from "../containers/EditAdmin";
import { useSelector } from "react-redux";

const PrivateRoute = ({
  roles: Roles,
  userDetails: UserDetails,
  component: Component,
  ...rest
}) => {
  const { userListing, adminListing } = useSelector((state) => state.users);
  return (
    <Route
      {...rest}
      render={function (props) {
        const details = UserDetails();
        if (!details) {
          return (
            <Redirect
              to={{
                pathname: "/signIn",
                state: { from: props.location },
              }}
            />
          );
        }

        if (!isAllowedRole(Roles, details)) {
          return (
            <Redirect
              to={{
                pathname: "/dashboard",
              }}
            />
          );
        }

        if (
          props.location.pathname !== "" &&
          ((props.location.pathname.startsWith("/users") &&
            userListing?.isLoading === false &&
            !userListing?.isAllowed) ||
            (props.location.pathname.startsWith("/admin") &&
              adminListing?.isLoading === false &&
              !adminListing?.isAllowed))
        ) {
          props.history.push("/dashboard");
        }

        return <Component {...props} />;
      }}
    />
  );
};

const Routing = () => (
  <Router>
    <div>
      <Route path="/signIn" component={SignIn} />
      <Route path="/signUp" component={SignUp} />
      <Route path="/contactUs" component={ContactUs} />
      <PrivateRoute
        exact
        path="/"
        component={Dashboard}
        roles={[commonConstants.REGULAR_ROLE, commonConstants.ADMIN_ROLE]}
        userDetails={getUserDetails}
      />
      <PrivateRoute
        exact
        path="/dashboard"
        component={Dashboard}
        roles={[commonConstants.REGULAR_ROLE, commonConstants.ADMIN_ROLE]}
        userDetails={getUserDetails}
      />
      <PrivateRoute
        exact
        path="/chat/:id"
        component={ChatUser}
        roles={[commonConstants.REGULAR_ROLE, commonConstants.ADMIN_ROLE]}
        userDetails={getUserDetails}
      />
      <PrivateRoute
        exact
        path="/workers/newInvitation"
        component={NewInvitation}
        roles={[commonConstants.ADMIN_ROLE]}
        userDetails={getUserDetails}
      />
      {/* This route is used for creating moderators */}
      <PrivateRoute
        exact
        path="/create/moderator"
        component={CreateModeratorView}
        roles={[commonConstants.REGULAR_ROLE, commonConstants.ADMIN_ROLE]}
        userDetails={getUserDetails}
      />
      {/* This route is not being used for creating moderators */}
      <PrivateRoute
        exact
        path="/workers/:workerId/fakeUsers/new"
        component={FakeUser}
        roles={[commonConstants.REGULAR_ROLE, commonConstants.ADMIN_ROLE]}
        userDetails={getUserDetails}
      />
      <PrivateRoute
        exact
        path="/workers/:workerId/fakeUsers/edit/:id"
        component={FakeUser}
        roles={[commonConstants.REGULAR_ROLE, commonConstants.ADMIN_ROLE]}
        userDetails={getUserDetails}
      />
      <PrivateRoute
        exact
        path="/workers/:workerId/edit/:id"
        component={FakeUser}
        roles={[commonConstants.REGULAR_ROLE, commonConstants.ADMIN_ROLE]}
        userDetails={getUserDetails}
      />
      {/* This route is used for editing worker */}
      <PrivateRoute
        exact
        path="/workers/:pageName(edit|view)/:id"
        component={EditWoker}
        roles={[commonConstants.REGULAR_ROLE, commonConstants.ADMIN_ROLE]}
        userDetails={getUserDetails}
      />

      <PrivateRoute
        exact
        path="/users/new"
        component={NewUser}
        roles={[commonConstants.REGULAR_ROLE, commonConstants.ADMIN_ROLE]}
        userDetails={getUserDetails}
      />
      <PrivateRoute
        exact
        path="/users"
        component={Users}
        roles={[commonConstants.REGULAR_ROLE, commonConstants.ADMIN_ROLE]}
        userDetails={getUserDetails}
      />
      <PrivateRoute
        exact
        path="/users/edit/:id"
        component={EditUser}
        roles={[commonConstants.REGULAR_ROLE, commonConstants.ADMIN_ROLE]}
        userDetails={getUserDetails}
      />

      <PrivateRoute
        exact
        path="/admins"
        component={Admins}
        roles={[commonConstants.REGULAR_ROLE, commonConstants.ADMIN_ROLE]}
        userDetails={getUserDetails}
      />
      <PrivateRoute
        exact
        path="/admins/:pageName(edit|view)/:id"
        component={EditAdmin}
        roles={[commonConstants.REGULAR_ROLE, commonConstants.ADMIN_ROLE]}
        userDetails={getUserDetails}
      />
      <PrivateRoute
        exact
        path="/workers"
        component={Workers}
        roles={[commonConstants.REGULAR_ROLE, commonConstants.ADMIN_ROLE]}
        userDetails={getUserDetails}
      />
      <PrivateRoute
        exact
        path="/moderators"
        component={Moderators}
        roles={[commonConstants.REGULAR_ROLE, commonConstants.ADMIN_ROLE]}
        userDetails={getUserDetails}
      />
      <PrivateRoute
        exact
        path="/pendingchats"
        component={pendingchats}
        roles={[commonConstants.REGULAR_ROLE, commonConstants.ADMIN_ROLE]}
        userDetails={getUserDetails}
      />
      <PrivateRoute
        exact
        path="/moderators/edit/:id"
        component={moderatorsList}
        roles={[commonConstants.REGULAR_ROLE, commonConstants.ADMIN_ROLE]}
        userDetails={getUserDetails}
      />
    </div>
  </Router>
);

export default Routing;
