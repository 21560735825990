import { gql } from "@apollo/client";
// import { gql } from 'react-apollo';
import { client, clientUpload } from "./client";
import { log } from "util";

class UserApi {
  static canVisitAdminListing = async () => {
    return await client.query({
      fetchPolicy: "no-cache",
      query: gql`
        query {
          adminListing {
            admin {
              id
            }
            isAllowed
            createdAt
            updatedAt
          }
        }
      `,
    });
  };

  static canVisitUserListing = async () => {
    return await client.query({
      fetchPolicy: "no-cache",
      query: gql`
        query {
          userListing {
            user {
              id
              username
              email
            }
            isAllowed
          }
        }
      `,
    });
  };

  static removeUserProfileImage = async ({ imageId, moderatorId = "" }) => {
    try {
      const res = await client.mutate({
        mutation: gql`
              mutation {
                  deleteAvatarPhoto(id: "${imageId}", moderatorId: "${moderatorId}" ) {
                    success
                    message
                  }
                }
              `,
      });

      return res.data.deleteAvatarPhoto;
    } catch (err) {
      console.log("Error occured while requesting to server");
      console.log("More details: ", err);
      return null;
    }
  };

  static removeUserPrivateImage = async ({ imageId, moderatorId = "" }) => {
    try {
      const res = await client.mutate({
        mutation: gql`
              mutation {
                  deletePrivatePhoto(id: "${imageId}", moderatorId: "${moderatorId}" ) {
                    success
                    message
                  }
                }
              `,
      });
      return res.data.deletePrivatePhoto;
    } catch (err) {
      console.log("Error occured while requesting to server");
      console.log("More details: ", err);
      return null;
    }
  };

  static removeUserMoment = async ({ momentId }) => {
    try {
      const res = await client.mutate({
        mutation: gql`
          mutation {
            deleteMoment(id:  "${momentId}") {
              id
              success
            }
          }
        `,
      });

      return res?.data?.deleteMoment?.moment?.id;
    } catch (err) {
      return err;
    }
  };

  static removeUserStory = async ({ momentId, userId }) => {
    try {
      const res = await client.mutate({
        mutation: gql`
          mutation {
            deleteStory(id: "${momentId}") {
              success
            }
          }
        `,
      });

      return res.data.deleteStory;
    } catch (err) {
      console.log("Error occured while making request to server2");
      console.log("More details: ", err);
      let errors = [];
      if (err?.message && err?.message?.includes("["))
        errors = eval(err?.message ?? "") ?? [];
      else if (err?.message) errors.push(err?.message);
      else errors.push("Error occured while making request to server3");

      return {
        errors,
      };
    }
  };

  static addCommnetOnMoment = async (momentId, comment, fakeUserId) => {
    try {
      const res = await client.mutate({
        mutation: gql`
        mutation {
          commentMoment(momentId: ${momentId}, commentDescription: "${comment}", moderatorId: "${fakeUserId}" ) {
            comment {
              commentDescription
              id
              like
              pk
              report
              createdDate
            }
          }
        }
        `,
      });
      return res.data.commentMoment.comment.id;
    } catch (err) {
      console.log("Error occured while making request to server4");
      console.log("More details: ", err);
      return null;
    }
  };

  static addLikeOnMoment = async (momentId, fakeUserId) => {
    try {
      const res = await client.mutate({
        mutation: gql`
          mutation {
            likeMoment(momentId: ${momentId}, moderatorId: "${fakeUserId}" ) {
              like {
                id
                momemt {
                  Title
                  comment
                }
                user {
                  avatar {
                    url
                  }
                  fullName
                  id
                }
              }
        
            }
          }
        `,
      });

      return res.data.likeMoment.like.id;
    } catch (err) {
      console.log("Error occured while making request to server5");
      console.log("More details: ", err);
      return null;
    }
  };

  static getMomentLikes = async (momentId) => {
    try {
      const res = await client.query({
        query: gql`
          query {
            getMomentLikes(momentPk: ${momentId}) {
              momemt {
                id
              }
              id
              createdAt
              user {
                id
                username
                fullName
                avatarPhotos {
                  url
                }
                avatar {
                  id
                  url
                }
                lastSeen
              }
            }
          }
        `,
      });

      return res.data.getMomentLikes;
    } catch (err) {
      console.log("Error occured while making request to server6");
      console.log("More details: ", err);
      return null;
    }
  };

  static getMomentComments = async (momentId) => {
    try {
      const res = await client.query({
        query: gql`
          query {
            getMomentComments(momentPk: ${momentId}) {
              id
              user {
                id
                username
                fullName
                avatarPhotos {
                  url
                }
                lastSeen
              }
              commentDescription
              createdDate
              like
            }
          }
        `,
      });

      return res.data.getMomentComments;
    } catch (err) {
      console.log("Error occured while making request to server7");
      console.log("More details: ", JSON.stringify(err));
      return null;
    }
  };

  static updateWorkerInfo = async (
    id,
    firstName,
    lastName,
    email,
    language,
    about,
    pasteAccess,
    address,
    location
  ) => {
    try {
      const res = await client.mutate({
        mutation: gql`
          mutation {
            updateProfile(id: ${id}, firstName: ${firstName}, lastName: ${lastName}, email: ${email}, language: ${language}, about: ${about}, address: ${address}, location: ${location}, pasteAcess: ${pasteAccess}) {
              id
            }
          }
        `,
      });
      return res?.data?.updateProfile;
    } catch (error) {
      console.log(error);
    }
  };

  static updateProfileImageIndex = async ({ userId, avatarIndex }) => {
    try {
      const res = await client.mutate({
        mutation: gql`
        mutation {
            updateProfile(id: "${userId}", avatarIndex: ${avatarIndex}) {
             id
            }
        }
        `,
      });

      return res.data.updateProfile.id;
    } catch (err) {
      console.log("Error occured while making request to server8");
      console.log("More details: ", err);
      let errors = [];
      if (err?.message && err?.message?.includes("["))
        errors = eval(err?.message ?? "") ?? [];
      else if (err?.message) errors.push(err?.message);
      else errors.push("Error occured while making request to server9");

      return {
        errors,
      };
    }
  };

  static getPrivateImages = async (userId) => {
    const res = await client.query({
      query: gql`
        query {
          privateUserPhotos(id: "${userId}") {
            id
            file
            createdBy {
              id
              fullName
            }
          }
        }
      `,
    });
    return res;
  };

  static getStockImages = async (userId) => {
    if (userId) {
      const res = await client.query({
        query: gql`
          query {
            stockImages(userId:"${userId}"){
              id
              file
              createdDate
            }
          }
        `,
      });
      return res;
    }
  };

  static deleteStockImages = async (id) => {
    const res = await client.mutate({
      mutation: gql`
        mutation {
          deleteStockImage(id:"${id}"){
            success
          }
        }
      `,
    });
    return res;
  };

  static uploadPrivatePicture = async (image, userId, stockImageId) => {
    console.log(userId);
    try {
      const response = await clientUpload.mutate({
        mutation: gql`
          mutation CreatePrivatePhoto(
            $file: Upload
            $stockImageId: Int
            $userId: String
          ) {
            createPrivatePhoto(
              file: $file
              stockImageId: $stockImageId
              userId: $userId
            ) {
              obj {
                file
              }
            }
          }
        `,
        variables: {
          file: image,
          userId: userId,
          stockImageId: parseInt(stockImageId),
        },
      });
      return response.data;
    } catch (error) {
      console.log(error.message);
    }
    // return {
    //   id: response.data.insertStory.story.id,
    //   file: baseUrl + "/media/" + response.data.insertStory.story.file,
    // };
  };

  static requestUserPrivatePhotos = async (receiverId, moderatorId) => {
    try {
      const res = await client.mutate({
        mutation: gql`
          mutation {
            requestUserPrivatePhotos(
              receiverId: "${receiverId}",
              moderatorId: "${moderatorId}"
            ) {
              msg
              success
            }
          }
        `,
      });

      return res.data.requestUserPrivatePhotos;
    } catch (err) {
      console.log("Error While requestUserPrivatePhotos: ", err);
      return null;
    }
  };

  static cancelPrivatePhotoRequest = async (receiverId, moderatorId) => {
    try {
      const res = await client.mutate({
        mutation: gql`
          mutation {
            cancelPrivatePhotoRequest(userId: "${receiverId}", moderatorId: "${moderatorId}") {
              message
              success
            }
          }
        `,
      });

      return res.data.cancelPrivatePhotoRequest;
    } catch (err) {
      console.log("Error While cancelPrivatePhotoRequest: ", err);
      return null;
    }
  };

  static privatePhotoDecision = async (decision, requestId) => {
    try {
      const res = await client.mutate({
        mutation: gql`
        mutation {
          privatePhotoDecision(decision: "${decision}", requestId: ${requestId}) {
            success
          }
        }
        `,
      });

      return res.data.privatePhotoDecision;
    } catch (err) {
      console.log("Error occured while making request to server10");
      console.log("More details: ", err);
      return null;
    }
  };

  static addCommnetOnStory = async (storyId, comment, fakeUserId) => {
    try {
      const res = await client.mutate({
        mutation: gql`
          mutation {
            genericComment(objectId: ${storyId}, objectType: "story", commentDescription: "${comment}", moderatorId :"${fakeUserId}" ) {
              genericComment {
                id
                commentDescription
              }
            }
          }
        `,
      });

      return res.data.genericComment.genericComment.id;
    } catch (err) {
      console.log("Error occured while making request to server11");
      console.log("More details: ", err);
      return null;
    }
  };

  static addLikeOnStory = async (storyId, fakeUserId) => {
    try {
      const res = await client.mutate({
        mutation: gql`
          mutation {
            genericLike(objectId: ${storyId}, objectType: "story", moderatorId : "${fakeUserId}" ) {
              genericLike {
                id
              }
            }
          }
        `,
      });

      return res?.data?.genericLike?.genericLike?.id;
    } catch (err) {
      console.log("Error occured while making request to server12");
      console.log("More details: ", err);
      return null;
    }
  };

  static getCurrentUserMoments = async (userId) => {
    const res = await client.query({
      query: gql`
        query {
          currentUserMoments {
            id
            file
            user {
              username
            }
            momentDescription
            like
            comment
            pk
            momentDescriptionPaginated
          }
        }
      `,
    });
    return res;
  };
  static getCurrentUserStories = async (userId) => {
    const res = await client.query({
      query: gql`
        query {
          currentUserStories {
            id
            thumbnail
            file
            fileType
            storyForReport {
              id
              ReportMsg
            }

            likesCount
            commentsCount
          }
        }
      `,
    });
    return res;
  };
  static getUserStories = async (userId) => {
    const res = await client.query({
      query: gql`
        query {
          userStories(userId:"${userId}"){
            likes{
              id
              pk
              objectId
              user{
                id
                avatar{
                  id
                  url
                }
              }
            }
            id
            createdDate
            thumbnail
            
          }
        }
      `,
    });
    return res;
  };
  static getUserMoments = async (userId) => {
    const res = await client.query({
      query: gql`
        query {
          allUserMoments(user_Id:"${userId}"){
            edges{
              node{
                id
                like
                comment
                momentDescription
                createdDate
                file
                pk
                
              }
            }
          }
        }
      `,
    });
    return res;
  };
  static getAllStories = async (id) => {
    const res = await client.query({
      query: gql`
        query {
          allUserStories(user_Id:"${id}"){
            edges {
              node {
                comments {
                  edges {
                    node {
                      user {
                        avatar {
                          id
                          url
                        }
                        fullName
                      }
                    }
                  }
                }
                commentsCount
                file
                fileType
                likesCount
                pk
                thumbnail
                likes {
                  edges {
                    node {
                      id
                      pk
                      user {
                        avatar {
                          id
                          url
                        }
                        fullName
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `,
    });
    return res;
  };
  static getAllMoments = async () => {
    const res = await client.query({
      query: gql`
        query {
          allMoments {
            momentDescriptionPaginated
            user {
              id
              username
            }
            file
            like
            comment
            id
            Title
            pk
            momentDescription
          }
        }
      `,
    });
    return res;
  };

  static getUser = async (userId) => {
    const res = await client.query({
      query: gql`
        query {
          user(id:"${userId}"){
            isModerator
            photosQuota
            gender
            avatarPhotos{
              url
              id
              type
            }
            avatarIndex
            id
            username
            fullName
            firstName
            lastName
            email
            gender
            about
            location
            age
            language
            state
            country
            address
            politics
            pasteAccess
            languageIdCode
            zodiacSign
            height
            ethinicity
            interestedIn
            religion
            education
            music
            tvShows
            books
            sportsTeams
            movies
            work
            isOnline
            familyPlans
            avatar{
              url
            }
            city
            
          }
        }
`,
    });
    return res;
  };

  static getUsersInRoom = async (roomId) => {
    const response = await client.query({
      query: gql`
      query {
        room (id:"${roomId}"){
          id
          userId{
            username
            id
          }
          target{
            username
            id
          }
        }
      }   
    `,
    });
    return response;
  };

  static likeComment = async (commentId) => {
    const res = gql`
    mutation LikeComment($commentId: String) {
      likeComment(commentId: "${commentId}") {
        commentLike {
          id
          comment {
            id
            like
          }
          user {
            avatar {
              id
              url
            }
            fullName
          }
        }
      }
    }
    `;
    return res;
  };

  static allCountries = async () => {
    const res = await client.query({
      query: gql`
        query {
          allCountries {
            fullName
            id
            name
          }
        }
      `,
    });
    return res;
  };

  static getChatHistoryFromRoom = async () => {
    const res = await client.query({
      query: gql`
        query {
          messagesForWorkers(id: 782) {
            edges {
              node {
                content
                timestamp
                read
                isDeleted
                messageType
                requestStatus
                privatePhotoRequestId
                id
                roomId {
                  id
                }
                userId {
                  email
                  username
                }
              }
            }
          }
        }
      `,
    });
    return res;
  };
}

export default UserApi;
