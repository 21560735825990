import _ from "lodash";
import PropTypes from "prop-types";

import React, { Component, memo, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Checkbox,
  Dimmer,
  Card,
  Grid,
  Icon,
  Label,
  List,
  Loader,
  Popup,
  Segment,
  Rating,
  IconGroup,
} from "semantic-ui-react";
import "./worker.css";
import { Api, baseUrl } from "../../utils/api";
import { ChatApi } from "../../utils/chat_api";
import Notes from "../chat/notes";
import ChatUsers from "../chat/userList";
import AddChatImage from "../chatImageUpload/addNewPhoto";
import ErrorMessage from "../errorMessage";

import Photos_4 from "../../../src/assets/images/Rectangle 18.png";

import SliderParent from "./slider/sliderParent";
import UserApi from "../../utils/users";
import Photo from "../chatImageUpload/photo";
import ShareLocation from "../chat/shareLocation";
import SendGiftSection from "../chat/sendGiftSection";
import UserInfo from "../chat/userInfo";
import MessageBox from "../chat/messageBox";
import StoryMomentModal from "../chat/storyMomentModal";
import { getChatImageUrl } from "../../utils/helpers";
import { isAllowedRole } from "../../utils/auth";
import { commonConstants } from "../../constants/common";
import Timer from "../common/Timer";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getChatHistory } from "../../actions/chat";

const FakeUserListItem = ({
  item,
  currentFakeUser,
  selectChatFakeUser,
  changeFakeUserOnlineStatus,
  workerId,
  usersList,
  closeUsersList,
  openUsersList,
  chatDialogues,
  index,
}) => {
  const [totalUsersHasUnreadMessage, setTotalUsersHasUnreadMessage] =
    useState(0);
  const isActive = currentFakeUser.id === item.id;

  useEffect(() => {
    if (index === 0 && !chatDialogues && !currentFakeUser?.id) {
      selectChatFakeUser(item.id);
    }
    if (chatDialogues) {
      setTotalUsersHasUnreadMessage(
        _.filter(
          chatDialogues,
          (chatDialogue) => chatDialogue.unread_messages_count > 0
        ).length
      );
    }
  }, [chatDialogues]);
  const showUsers = (id) => {
    selectChatFakeUser(id);
    // getAllDialogue(currentFakeUser.id);
  };
  // useEffect(() => {
  //   let unSubscribingRoom;
  //   if (item.id) {
  //     messageReceiveListener(item.id, currentChatId).then((e) => (unSubscribingRoom = e));
  //   }
  //   return () => {
  //     if (unSubscribingRoom) {
  //       unSubscribingRoom.unsubscribe();
  //     }
  //   };
  // }, [item.id]);

  const fullName = item?.first_name
    ? `${item.first_name} ${item.last_name} `
    : undefined;
  return (
    <List.Item
      active={isActive}
      onClick={() => showUsers(item.id)}
      key={item.id}
    >
      {
        <List.Content floated="right">
          <Link to={"/workers/" + workerId + "/fakeUsers/edit/" + item.id}>
            <Icon link name="pencil" size="large" color="blue" />
          </Link>
        </List.Content>
      }
      {
        <List.Content floated="right">
          <Popup
            trigger={
              <Icon
                link
                name="chat"
                size="large"
                color={usersList && isActive ? "yellow" : "blue"}
                onClick={(e) => {
                  usersList && isActive ? closeUsersList() : openUsersList();
                }}
              />
            }
            content="Start new chat"
            position="left center"
          />
        </List.Content>
      }
      <List.Content floated="right">
        <Checkbox
          slider
          checked={item.online}
          onChange={(e) => {
            e.stopPropagation();
            changeFakeUserOnlineStatus(item.id, !item.online);
          }}
        />
      </List.Content>
      <List.Content floated="right">
        <Label color={totalUsersHasUnreadMessage > 0 ? "yellow" : "grey"}>
          {totalUsersHasUnreadMessage}
        </Label>
      </List.Content>
      <List.Content>
        <List.Header>
          {item.fullName ?? fullName ?? item.name ?? item.username}
        </List.Header>
      </List.Content>
    </List.Item>
  );
};

const ChatInboxItem = ({
  dialogue,
  allUsers,
  selectChat,
  currentChat,
  item,
  index,
}) => {
  const [state, setState] = useState({
    address: {
      loading: true,
      city: "",
      state: "",
      country: "",
    },
  });
  const user = allUsers[dialogue.friendId];
  const currentUnreadMessages = dialogue.unread_messages_count;
  const active = currentChat == dialogue._id;
  const locationdata = user?.location?.split(",");
  let params = {
    chat_dialog_id: dialogue._id,
    sort_desc: "date_sent",
    limit: 100,
    skip: 0,
  };

  const getAddressData = async () => {
    const data = await Api.getAddressDataFromLatLng({
      lat: locationdata[0],
      lng: locationdata[1],
    });

    if (data.status === "OK") {
      const addressData = data?.results?.[0];
      let tempAddress = { ...state.address };

      for (let address of addressData?.address_components) {
        const addTypes = address.types;
        if (addTypes.includes("locality")) {
          tempAddress.city = address.long_name;
        }

        if (addTypes.includes("country")) {
          tempAddress.country = address.long_name;
        }
        if (addTypes.includes("administrative_area_level_1")) {
          tempAddress.state = address.long_name;
        }
      }
      if (
        !state.address.city ||
        !state.address.state ||
        !state.address.country
      ) {
        setState({ ...state, address: tempAddress, loading: false });
      }
    }
  };

  useEffect(() => {
    if (index === 0 && !currentChat) {
      selectChat(dialogue._id, dialogue.occupants_ids);
    }

    if (!locationdata) return;
    getAddressData();
  }, [locationdata]);

  if (!user) return <List.Item active={active}>Loading user data...</List.Item>;
  const clickHandler = () => selectChat(dialogue._id, dialogue.occupants_ids);
  return (
    <List.Item active={active} onClick={clickHandler}>
      {
        <List.Content floated="right">
          <Link to={"/users/edit/" + item.friendId}>
            <Icon link name="pencil" size="large" color="blue" />
          </Link>
        </List.Content>
      }
      <List.Content>
        <List.Content floated="right">
          <Label color={currentUnreadMessages > 0 ? "yellow" : "grey"}>
            {currentUnreadMessages}
          </Label>
        </List.Content>

        <List.Header>
          {dialogue.name ? dialogue.name : "No name found"}
        </List.Header>
        <List.Content floated="left" style={{ color: "rgba(0, 0, 0, .7)" }}>
          {user.location ? (
            <div style={{ marginTop: "5px", fontSize: "14px" }}>
              <p style={{ marginBottom: "0px" }}>
                {state.address.city}
                {state.address.city ? "," : ""} {state.address.state}
                {state.address.state ? "," : ""} {state.address.country}
              </p>
            </div>
          ) : (
            ""
          )}
        </List.Content>
      </List.Content>
    </List.Item>
  );
};

const ChatInboxList = ({
  allUsers,
  selectChat,
  currentChat,
  workerId,
  chatDialogues = [],
  userId,
}) => {
  return (
    <List divided relaxed selection>
      {chatDialogues.map((dialogue, i) => (
        <>
          <ChatInboxItem
            workerId={workerId}
            item={dialogue}
            currentChat={currentChat}
            key={dialogue._id}
            selectChat={selectChat}
            dialogue={dialogue}
            allUsers={allUsers}
            moderatorId={userId}
            index={i}
          />
        </>
      ))}
    </List>
  );
};

/************************************
 *
 *  MAIN CLASS COMPONENT WORKER DASHBOARD
 *
 ************************************/

let updateFakeUsersInterval = null;
let prevFakeUsers = [];

function UserModeratorComponent(props) {
  const note = props?.notesKey === "userNote" ? true : false;
  return (
    <div>
      <div className="moderator_container">
        {props.currentFakeUser?.avatar?.url ? (
          <img
            style={{
              width: "85%",
              border: "2px solid #d5d5d5",
              maxHeight: "291px",
            }}
            src={props.currentFakeUser?.avatar?.url}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = Photos_4;
            }}
            alt=""
          />
        ) : null}
      </div>
      <Segment className="photos_container">
        <Card>
          <Card.Content className="photos_content">
            <Card.Header className="photos_header">
              <div>Photo's</div>
              <div className="tab_content">
                <span
                  className={`tab_name ${
                    !props.privateFakeUserPhoto ? "active" : ""
                  }`}
                  style={{
                    borderRadius: "5px 0px 0px 5px",
                  }}
                  onClick={() =>
                    props.setState({
                      [note ? "privatePhoto" : "privateFakeUserPhoto"]: false,
                      selectedUser: props.currentFakeUser?.avatar_photos?.find(
                        (item) => item?.is_admin_approved
                      ),
                    })
                  }
                >
                  Public
                </span>
                <span
                  className={`tab_name ${
                    props.privateFakeUserPhoto ? "active" : ""
                  }`}
                  style={{ borderRadius: "0px 5px 5px 0px" }}
                  onClick={() =>
                    props.setState({
                      [note ? "privatePhoto" : "privateFakeUserPhoto"]: true,
                      selectedUser: props.currentFakeUser?.avatar_photos?.find(
                        (item) => !item?.is_admin_approved
                      ),
                    })
                  }
                >
                  Private
                </span>
              </div>
            </Card.Header>

            <SliderParent
              data={props.currentFakeUser?.avatarPhotos?.filter((item) =>
                props.privateFakeUserPhoto
                  ? item?.type === "PRIVATE"
                  : item?.type === "PUBLIC"
              )}
              onSelect={props.onSelectUser}
            />
          </Card.Content>
        </Card>
      </Segment>
      {/* <Dropdown
        className="UserDropdown"
        text="User Info"
        onClick={() =>
          props.setState({
            [note ? "userInfoOpen" : "fakeUserInfoOpen"]:
              !props.fakeUserInfoOpen,
          })
        }
        icon={!props.fakeUserInfoOpen ? "caret down" : "caret up"}
      >
        <Dropdown.Menu></Dropdown.Menu>
      </Dropdown> */}
      <div>
        {/* {props.fakeUserInfoOpen && ( */}
        <UserInfo
          user={props.currentFakeUser}
          activeIndex={props.activeUserIndex ? props.activeUserIndex : 0}
          setActiveIndex={(activeIndex) =>
            props.setState({
              [note ? "activeIndex" : "activeUserIndex"]: activeIndex,
            })
          }
          presets={props.presets}
        />
        {/* )} */}
        {/* {fakeUserInfoOpen && this.renderUserInfo(currentFakeUser, (activeIndex) => this.setState({ activeUserIndex: activeIndex }), activeUserIndex)} */}
      </div>
      <div className="UserStory">
        <span className="story">Story</span>
        <span className="view">View All</span>
      </div>
      <Card.Description className="slider_content img">
        {props.renderImage(props.allStories, props.likeable, props.commentable)}
      </Card.Description>
      <div className="UserMoment">
        <span className="moment">Moment</span>
        <span className="view1">View All</span>
      </div>
      <Card.Description className="slider_content img">
        {props.renderImage(props.allMoments, props.likeable, props.commentable)}
      </Card.Description>
      <Notes
        id={props.currentChatId}
        value={props.opponent ? props.opponent?.[props.notesKey] : ""}
        saveValue={(roomId, content, callback) =>
          props.createNotes(
            roomId,
            content,
            note,
            props.currentFakeUser.id,
            props.realUser,
            callback
          )
        }
      />
    </div>
  );
}

const UserWorkerComponent = memo((props) => {
  const note = props?.notesKey === "userNote" ? true : false;
  const history = useHistory();
  const realUserId = props?.opponent?.friendId;

  const dispatch = useDispatch();
  const { moderatorId, currentChat, privatePhotoRequest } = useSelector(
    ({ chat }) => {
      const { currentChat, chatsInQueue } = chat;
      const moderatorId = chatsInQueue?.find(
        (ele) => ele.roomId === Number(currentChat)
      )?.moderator?.id;

      const privatePhotoRequest = chat?.privatePhotoRequest[currentChat];

      return {
        moderatorId,
        currentChat: Number(currentChat),
        privatePhotoRequest,
      };
    },
    shallowEqual
  );

  useEffect(() => {
    if (!currentChat) return;

    let subscribedRoom;
    const fn = async () => {
      const observerRoom = await ChatApi.privatePhotoRequestStatusListener(
        currentChat
      );

      subscribedRoom = observerRoom.subscribe(async (res) => {
        const result = res?.data?.privatePhotoRequest;
        if (result?.status && ["A", "R"].includes(result?.status))
          reFetchChatMessages();
      });
    };

    fn();

    return () => {
      if (subscribedRoom) subscribedRoom.unsubscribe();
    };
  }, [currentChat]);

  const reFetchChatMessages = () => {
    if (currentChat !== null && currentChat !== "") {
      dispatch(getChatHistory(moderatorId, currentChat, Boolean(moderatorId)));
    }
  };

  const handleProfilePicClick = (e) => {
    history.push(`/users/edit/${realUserId}`);
  };

  const requestUserPrivatePhotosHandler = (e) => {
    console.log("Lock Icon Clicked", { realUserId, moderatorId });
    if (realUserId && moderatorId) {
      UserApi.requestUserPrivatePhotos(realUserId, moderatorId).then(
        (res) => {
          if (res?.msg?.includes("created")) reFetchChatMessages();
        },
        (err) => {
          console.error("Error Occured while ::requestUserPrivatePhotos:: ", {
            err,
          });
        }
      );
    }
  };

  const cancelRequestForPrivatePhotoHandler = () => {
    UserApi.cancelPrivatePhotoRequest(realUserId, moderatorId).then((res) => {
      if (res?.success) reFetchChatMessages();
    });
  };

  return (
    <div>
      <div className="moderator_container">
        {props.currentFakeUser?.avatarPhotos?.length > 0 ? (
          <img
            onClick={handleProfilePicClick}
            style={{
              width: "85%",
              border: "2px solid #d5d5d5",
              maxHeight: "291px",
            }}
            src={props.currentFakeUser?.avatarPhotos[0]?.url}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = Photos_4;
            }}
            alt=""
          />
        ) : null}
      </div>
      <Segment className="photos_container">
        <Card>
          <Card.Content className="photos_content">
            <Card.Header className="photos_header">
              <div>Photo's</div>
              <div className="tab_content">
                <span
                  className={`tab_name ${
                    !props.privateFakeUserPhoto ? "active" : ""
                  }`}
                  style={{
                    borderRadius: "5px 0px 0px 5px",
                  }}
                  onClick={() =>
                    props.setState({
                      [note ? "privatePhoto" : "privateFakeUserPhoto"]: false,
                      selectedUser: props.currentFakeUser?.avatar_photos?.find(
                        (item) => item?.is_admin_approved
                      ),
                    })
                  }
                >
                  Public
                </span>
                <span
                  className={`tab_name ${
                    props.privateFakeUserPhoto ? "active" : ""
                  }`}
                  style={{ borderRadius: "0px 5px 5px 0px" }}
                  onClick={() =>
                    props.setState({
                      [note ? "privatePhoto" : "privateFakeUserPhoto"]: true,
                      selectedUser: props.currentFakeUser?.avatar_photos?.find(
                        (item) => !item?.is_admin_approved
                      ),
                    })
                  }
                >
                  Private
                </span>
                {props.currentFakeUser?.avatarPhotos?.filter(
                  (item) => item?.type === "PRIVATE"
                )?.length > 0 &&
                  privatePhotoRequest !== undefined &&
                  (["R", "C", null].includes(privatePhotoRequest.status) ? (
                    <Icon
                      name="lock"
                      style={{
                        cursor: "pointer",
                        marginLeft: "5px",
                      }}
                      title="Request Private Photos Album"
                      onClick={requestUserPrivatePhotosHandler}
                    />
                  ) : privatePhotoRequest.status === "A" ? (
                    <Icon
                      name="unlock"
                      title="Request Approved \nYou can see Private Photos"
                    />
                  ) : privatePhotoRequest.status === "P" ? (
                    <IconGroup
                      title="Cancel Private Photo Request"
                      onClick={cancelRequestForPrivatePhotoHandler}
                      style={{ marginLeft: "5px", cursor: "pointer" }}
                    >
                      <Icon name="lock" />
                      <Icon color="red" corner name="cancel" />
                    </IconGroup>
                  ) : null)}
              </div>
            </Card.Header>

            <SliderParent
              data={props.currentFakeUser?.avatarPhotos?.filter((item) =>
                props.privateFakeUserPhoto
                  ? item?.type === "PRIVATE"
                  : item?.type === "PUBLIC"
              )}
              onSelect={props.onSelectUser}
            />
          </Card.Content>
        </Card>
      </Segment>
      <div>
        <UserInfo
          user={props.currentFakeUser}
          activeIndex={props.activeUserIndex ? props.activeUserIndex : 0}
          setActiveIndex={(activeIndex) =>
            props.setState({
              [note ? "activeIndex" : "activeUserIndex"]: activeIndex,
            })
          }
          presets={props.presets}
        />
      </div>
      <div className="UserStory">
        <span className="story">Story</span>
        <span className="view">View All</span>
      </div>
      <Card.Description className="slider_content img">
        {props.renderImage(props.allStories, props.likeable, props.commentable)}
      </Card.Description>
      <div className="UserMoment">
        <span className="moment">Moment</span>
        <span className="view1">View All</span>
      </div>
      <Card.Description className="slider_content img">
        {props.renderImage(props.allMoments, props.likeable, props.commentable)}
      </Card.Description>
      <Notes
        id={props.currentChatId}
        value={props.opponent ? props.opponent?.[props.notesKey] : ""}
        saveValue={(roomId, content, callback) =>
          props.createNotes(
            roomId,
            content,
            note,
            props.currentFakeUser.id,
            props.realUser,
            callback
          )
        }
      />
    </div>
  );
});

export default class WorkerDashboard extends Component {
  constructor() {
    super();
    this.updateMessages = this.updateMessages.bind(this);
    this.imageRef = React.createRef();
  }

  messagesEnd = null;

  state = {
    usersList: false,
    messages: [],
    userInfoOpen: false,
    fakeUserInfoOpen: false,
    photos: { public: [], private: [] },
    privatePhoto: false,
    allStories: [],
    allMoments: [],
    currentUserStories: [],
    currentUserMoments: [],
    privateFakeUserPhoto: false,
    selectedUser: {},
    moderatorPrivateUser: [],
    user: {},
    currentFakeUser: {},
    openGift: false,
    currentFakeUserId: "",
    currentUserDetails: {},
    ifAssigned: false,
    isWriting: false,
  };

  onStartNewChat = (fakeUserId, realUserId) => {
    this.props.startNewChat(fakeUserId, realUserId);
    this.closeUsersList();
  };

  onUpload = (params) => {
    this.props.onSendAttachment(
      this.props.chat.currentChat,
      this.props.chat.currentFakeUser.id,
      this.props.chat.chatMessage,
      params
    );
  };

  openUsersList = () => {
    this.setState({ usersList: true });
  };

  closeUsersList = () => {
    this.setState({ usersList: false });
  };

  getCurrentUserStories = async (id) => {
    const currentUserStories = await ChatApi.getStories(id);
    this.setState({ currentUserStories: currentUserStories });
  };
  getUserStories = async (id) => {
    const fakeUserStories = await ChatApi.getStories(id);
    this.setState({ allStories: fakeUserStories || [] });
  };

  getCurrentUserMoments = async (id) => {
    const currentUserMoments = await ChatApi.getMoments(id);
    this.setState({ currentUserMoments: currentUserMoments });
  };
  getUserMoments = async (id) => {
    const fakeUserMoments = await ChatApi.getMoments(id);
    this.setState({ allMoments: fakeUserMoments || [] });
  };

  getUserPrivateImages = async (id) => {
    const userPhotos = await UserApi.getPrivateImages(
      this.props?.session?.userDetails?.id
    );
    return userPhotos;
  };

  getUserDetails = async (id) => {
    const user = await UserApi.getUser(id);
    return user?.data?.user || {};
  };

  disableSend = () => {
    return (
      !this.props.chat.currentChat ||
      this.props.chat.chatMessage === "" ||
      this.props.chat?.customMessageLength <
        this.props.chat?.chatMessageLength?.minMessageLength ||
      0
    );
  };

  async componentDidMount() {
    this.props.getPresets();
    this.setState({ ifAssigned: false });

    this.props.checkWorkerCanPaste();
    this.props.getDynamicChatLength();

    const currentFakeUser = this.props.chat.currentFakeUsers.find(
      (item, index) => item.id === this.props.chat.currentFakeUser.id
    );

    const user = await this.getUserDetails(this.props.session.userDetails?.id);

    this.setState({ user: user });

    const chatsInQueue = this.props.chat?.chatsInQueue || [];
    const chatInitiatedWithId = this.props?.chat.currentFakeUser?.id;
    console.log(
      `Chat is ${
        Boolean(chatInitiatedWithId) ? "already" : "not"
      } Initiated ::: `,
      { chatInitiatedWithId }
    );
    if (!Boolean(chatInitiatedWithId) && chatsInQueue.length > 0) {
      const randomChatIndx = Math.ceil(
        Math.random() * (chatsInQueue.length - 1)
      );
      const clickedRoomId = sessionStorage.getItem("clickedRoomId");
      const automaticAssignedChat =
        chatsInQueue.find((chat) => chat.roomId === clickedRoomId) ||
        chatsInQueue.find((item) => item.isAssigned) ||
        chatsInQueue[chatsInQueue.length >= 0 ? randomChatIndx : 0];
      console.log({
        clickedRoomId,
        automaticAssignedChat,
        randomChatIndx,
        len: chatsInQueue.length,
        chatsInQueue,
      });
      if (
        automaticAssignedChat &&
        automaticAssignedChat?.moderator?.id &&
        automaticAssignedChat?.userDetails?.id
      ) {
        setTimeout(() => {
          sessionStorage.removeItem("clickedRoomId");
        }, 1000 * 60);
        this.props.selectChatFakeUser(automaticAssignedChat?.moderator?.id);
        this.props.startNewChat(
          automaticAssignedChat?.moderator?.id,
          automaticAssignedChat?.userDetails
        );
      } else {
        if (!automaticAssignedChat) {
          console.warn("No assigned chat");
        }
        if (!automaticAssignedChat?.moderator?.id) {
          console.warn("No moderater present");
        }
        if (!automaticAssignedChat?.userDetails?.id) {
          console.warn("No real user present");
        }
      }
    }

    this.props.getWorkerFakeUsers(
      this.props.session.userDetails?.id ||
        this.props.session.userDetails?.user_id
    );

    updateFakeUsersInterval = setInterval(() => {
      this.props.reFetchWorkerFakeUsers(
        this.props.session.userDetails?.id ||
          this.props.session.userDetails?.user_id,
        this.props.workers
      );
    }, 10000);

    if (this.props.chat && this.props.chat.chatsInQueue[0]) {
      const id = this.props.chat.chatsInQueue[0]?.moderator?.id;
      if (id) {
        const getUserDetailsDataRes = await this.getUserDetails(id);
        this.getCurrentUserMoments(id);
        this.getCurrentUserStories(id);
        // this.setState({ currentUserDetails: getUserDetailsDataRes });
      }
    }
  }

  componentWillUnmount() {
    if (updateFakeUsersInterval) clearInterval(updateFakeUsersInterval);
  }

  updateMessages(data) {
    this.setState({ ...this.state, messages: data });
  }

  async componentDidUpdate(prevProps) {
    if (
      !this.props.chat.currentFakeUser.id &&
      this.props.chat.currentFakeUsers?.length &&
      prevProps.chat.realUser &&
      !this.props.chat.realUser
    ) {
      window.location.reload();
    }

    if (
      this.props.chat.fakeUserChatDialogues !==
      prevProps.chat.fakeUserChatDialogues
    ) {
      if (
        this.props.currentFakeUserId !== 0 &&
        Object.keys(this.props.chat.fakeUserChatDialogues)?.length > 0
      ) {
        const id =
          this.props.chat.realUser ||
          this.props.chat.fakeUserChatDialogues[this.props.currentFakeUserId][0]
            ?.friendId;
        if (id) {
          const getUserDetailsDataRes = await this.getUserDetails(id);
          this.getCurrentUserMoments(id);
          this.getCurrentUserStories(id);
          this.setState({ currentUserDetails: getUserDetailsDataRes });
        }
      }
    }

    if (
      this.props.chat.currentFakeUser?.id !==
        prevProps.chat?.currentFakeUser?.id ||
      !this.state?.currentFakeUser?.id
    ) {
      if (this.props.chat.currentFakeUser?.id) {
        const fakeUser = await this.getUserDetails(
          this.props.chat.currentFakeUser?.id
        );
        this.setState({ currentFakeUser: fakeUser || {} });
        if (fakeUser?.id) {
          this.getUserStories(fakeUser?.id);
          this.getUserMoments(fakeUser?.id);
        }
        this.props.getAllDialogue(this.props.chat.currentFakeUser?.id);
      }
    }

    if (!this.props.chat?.currentChat) {
      const currentFakeUser = this.props.chat.currentFakeUsers.find(
        (item, index) => item.id === this.props.chat.currentFakeUser.id
      );
      const dialogue =
        this.props.chat.fakeUserChatDialogues[
          (currentFakeUser && currentFakeUser?.id) ||
            this.props.chat.currentFakeUser?.id
        ];
      if (dialogue?.length) {
        const user =
          dialogue.find(
            (item) => item?.friendId === this.props.chat?.realUser
          ) || dialogue[0];
        dialogue?.length &&
          this.props.selectChat(user?._id, user?.occupants_ids);
      }
    } else if (this.state.ifAssigned === false) {
      this.setState({ ifAssigned: true });
      const roomId = this.props.chat?.currentChat;
      const moderatorId = this.props.chat?.currentFakeUser?.id;
      if (roomId && moderatorId) {
        console.log("AssignChat", { roomId, moderatorId });
        this.props.sendAssignChat(Number(roomId), moderatorId);
      }
    }
    if (this.props.chat?.allFakeUsers !== prevProps.chat?.allFakeUsers) {
      this.props.updateFakeUsers();
    }
  }

  // SEND MESSAGES
  handleMessageSend = (msg, moderatorId, messageType) => {
    this.props.sendTextMessage(
      this.props.chat.currentChat,
      msg || this.props.chat.chatMessage,
      moderatorId || this.props.chat.currentFakeUser.id,
      this.props.workers,
      messageType
    );
    this.setState({ currentUserDetails: {} });
  };

  componentWillReceiveProps(prevProps) {
    if (this.props.chat.allFakeUsers !== prevProps.chat.allFakeUsers) {
      this.props.updateFakeUsers();
    }
  }

  onSelectUser = (user) => {};

  renderImage = (data = [], likeable, commentable) => {
    return data?.slice(0, 3)?.map((item) => {
      return item?.node?.file || item?.file || item?.thumbnail ? (
        item?.file?.includes(".mp4") ? (
          <video
            width={"120px"}
            height={"150px"}
            style={{ objectFit: "cover" }}
            key={item?.id}
            alt=""
            onClick={() =>
              this.setState({ story: item, likeable, commentable })
            }
          >
            <source src={getChatImageUrl(item?.file)} />
          </video>
        ) : (
          <img
            width={"120px"}
            height={"150px"}
            style={{ objectFit: "cover" }}
            key={item?.id}
            src={getChatImageUrl(item?.file)}
            alt=""
            onClick={() =>
              this.setState({ story: item, likeable, commentable })
            }
          />
        )
      ) : null;
    });
  };

  shareLocation = async (moderatorId, text, messageType) => {
    this.handleMessageSend(text, moderatorId, messageType);
  };

  fakeUser = async (id) => {
    return await this.getUserDetails(id);
  };

  sendGift = async (senderId, receiverId, id) => {
    const res = await ChatApi.sendGift({
      giftId: id,
      senderId: senderId,
      receiverId: receiverId,
    });
    if (!res?.error) {
      this.handleMessageSend();
      this.props.removeCurrentChats();
    }
    this.setState({ openGift: false });
    this.setState({ gift: {} });
  };
  render() {
    const workerId =
      this.props?.session?.userDetails?.id ||
      this.props?.session?.userDetails?.user_id;

    const {
      user,
      currentFakeUser,
      usersList,
      messages,
      privateFakeUserPhoto,
      fakeUserInfoOpen,
      activeUserIndex,
      allStories,
      allMoments,
      privatePhoto,
      userInfoOpen,
      activeIndex,
      openGift,
      currentUserDetails,
    } = this.state;
    const chatUsers = {
      ...this.props.users,
      users: this.props.users.users.filter((user) => user.roles.length === 0),
    };

    const chats = Object.entries(this.props.chat.allChats)
      .filter(
        ([key, value]) =>
          this.props.chat.currentFakeUser.external_id &&
          key.includes(this.props.chat.currentFakeUser.external_id) &&
          key.split("___").length === 2
      )
      .map(([key, value]) => {
        const chatParts = key.split("___");
        const userId =
          chatParts[0] !== this.props.chat.currentFakeUser.external_id
            ? chatParts[0]
            : chatParts[1];
        return {
          fakeUserId: this.props.chat.currentFakeUser.external_id,
          chatId: key,
          userId: userId,
          history: value.history,
          notes: value.notes,
        };
      });

    const allPreparedChats = Object.entries(this.props.chat.allChats)
      .filter(
        ([key, value]) => key.split("___").length === 2 && key.includes("x0x")
      )
      .map(([key, value]) => {
        const chatParts = key.split("___");
        const userId = !chatParts[0].includes("x0x")
          ? chatParts[0]
          : chatParts[1];
        const fakeUserId = chatParts[0].includes("x0x")
          ? chatParts[0]
          : chatParts[1];
        return {
          fakeUserId: fakeUserId,
          chatId: key,
          userId: userId,
          history: value.history,
        };
      });

    const currentChat = chats.find(
      (item, index) => item.chatId === this.props.chat.currentChat
    );

    if (currentChat) {
      let unreadMessages = currentChat?.history
        ? Object.entries(currentChat.history)
            .filter(
              ([key, value]) =>
                !value.ir &&
                value.uid !== this.props.chat.currentFakeUser.external_id
            )
            .map(([key, value]) => key)
        : [];
      let unreadMessage = currentChat.history
        ? Object.entries(currentChat.history).reduce((acc, [key, value]) => {
            if (
              !value.ir &&
              value.uid !== this.props.chat.currentFakeUser.external_id
            ) {
              acc.push(key);
            }
            return acc;
          }, [])
        : [];
      if (unreadMessages && unreadMessages?.length > 0) {
        this.props.readChatMessages(
          this.props.chat.currentFakeUser.external_id,
          currentChat.chatId,
          [unreadMessages[0]]
        );
      }
    }

    const currentRealUser =
      currentChat && this.props.chat.allUsers[currentChat.userId];
    const currentFakeUsers = this.props.chat.currentFakeUsers;
    prevFakeUsers = currentFakeUsers;

    const currentChatDialogues =
      this.props.chat.fakeUserChatDialogues[
        currentFakeUser && currentFakeUser.id
      ];

    const currentChatId = this.props.chat.currentChat;
    const filteredDialogues = (currentChatDialogues || []).filter(
      (x) => x._id === currentChatId
    );

    const opponent = filteredDialogues.slice(-1)[0];

    const moderatorId = opponent?.user_id || currentFakeUser.id;

    const currentChatMessages = (this.props.chat.allChats || {})[
      this.props.chat.currentChat
    ];

    const fakeUserDetails = {};
    const currentFakeUserId = this.props.chat?.currentFakeUser?.id;
    const currentUser = this.props.chat?.currentFakeUsers?.find(
      (item) => item.id === currentFakeUserId
    );
    if (currentUser) {
      fakeUserDetails.fullName = currentUser.fullName;
      fakeUserDetails.avatar_photos = currentUser.avatar_photos;
    }

    const isAssigned =
      this.props.chat.chatsInQueue.some((curChat) => {
        return curChat?.worker?.id === user?.id;
      }) ||
      this.props.session?.userDetails?.privileges?.includes("ADMIN") ||
      this.props.chat?.realUser;

    const handleSendAction = (e) => {
      e.preventDefault();
      if (this.imageRef.current?.state?.src) {
        this.imageRef.current.handleUpload();
      } else {
        this.state?.gift?.id
          ? this.sendGift(
              opponent?.user_id,
              opponent?.friendId,
              this.state?.gift?.id,
              "G"
            )
          : this.handleMessageSend(`${this.props.chat.chatMessage}`, "", "C");
      }
    };

    return (
      <>
        <div style={{ display: "none" }}>
          <List
            divided
            relaxed
            selection
            verticalAlign="middle"
            key={currentFakeUserId}
          >
            {_.sortBy(currentFakeUsers, (item) => {
              return item.latestTime;
            })
              .reverse()
              .map((item, index) => (
                <FakeUserListItem
                  messageReceiveListener={this.props.messageReceiveListener}
                  currentFakeUser={this.props.chat.currentFakeUser}
                  allPreparedChats={allPreparedChats}
                  workerId={workerId}
                  currentChatId={currentChatId}
                  item={item}
                  key={item?.id}
                  index={index}
                  selectChatFakeUser={this.props.selectChatFakeUser}
                  usersList={usersList}
                  closeUsersList={this.closeUsersList}
                  openUsersList={this.openUsersList}
                  changeFakeUserOnlineStatus={
                    this.props.changeFakeUserOnlineStatus
                  }
                  getAllDialogue={this.props.getAllDialogue}
                  chatDialogues={this.props.chat.fakeUserChatDialogues[item.id]}
                />
              ))}
          </List>
        </div>
        {isAssigned ? (
          <Dimmer.Dimmable
            dimmed={
              this.props.chat.loading ||
              openGift ||
              this.state.modal ||
              this.state?.image
            }
            style={{ height: window.innerHeight - 55 }}
          >
            <Dimmer
              active={openGift || this.state.modal || this.state?.image}
              className="giftDimmer"
            ></Dimmer>
            <Dimmer active={this.props.chat.loading} inverted>
              <Loader>Loading</Loader>
            </Dimmer>
            {this.props.chat.error && this.props.chat.error.visible && (
              <ErrorMessage
                error={this.props.chat.error}
                hideError={this.props.hideError}
              />
            )}
            <Grid columns={2} stretched>
              <Grid.Row style={{ paddingBottom: 0 }}>
                <Grid.Column width={4} floated="right">
                  <Segment
                    style={{
                      padding: 0,
                      maxHeight: window.innerHeight - 70,
                      height: window.innerHeight - 70,
                      overflowY: "scroll",
                    }}
                  >
                    <div style={{ display: "none" }}>
                      <List
                        divided
                        relaxed
                        selection
                        verticalAlign="middle"
                        key={currentFakeUserId}
                      >
                        {_.sortBy(currentFakeUsers, (item) => item.latestTime)
                          .reverse()
                          .map((item, index) =>
                            currentFakeUserId === item?.id ? (
                              <FakeUserListItem
                                messageReceiveListener={
                                  this.props.messageReceiveListener
                                }
                                currentFakeUser={
                                  this.props.chat.currentFakeUser
                                }
                                allPreparedChats={allPreparedChats}
                                workerId={workerId}
                                currentChatId={currentChatId}
                                item={item}
                                key={item?.id}
                                index={index}
                                selectChatFakeUser={
                                  this.props.selectChatFakeUser
                                }
                                usersList={usersList}
                                closeUsersList={this.closeUsersList}
                                openUsersList={this.openUsersList}
                                changeFakeUserOnlineStatus={
                                  this.props.changeFakeUserOnlineStatus
                                }
                                getAllDialogue={this.props.getAllDialogue}
                                chatDialogues={
                                  this.props.chat.fakeUserChatDialogues[item.id]
                                }
                              />
                            ) : null
                          )}
                      </List>
                    </div>
                    <div className="cardWrapper">
                      <div className="card_inner">
                        <div className="card_header">
                          Keyword info for Session 86989
                        </div>
                        <div className="card_body">
                          <h4 className="card_body_heding">DEFAULT</h4>
                          <p className="card_body_description">
                            ABC Dating app, rated 12 chat without sex! Only with
                            longer dialogues, if can be a little sexy!
                          </p>
                        </div>
                        <div className="card_footer">
                          Customer use 14 Rating{" "}
                          <Rating maxRating={5} clearable icon="star" />
                        </div>
                      </div>
                    </div>
                    <div className="samedave">
                      <div className="title_div">
                        <span> MOD</span>
                      </div>
                      <div className="name_div">
                        <p>{currentFakeUser?.fullName || ""}</p>
                      </div>
                    </div>
                    <UserModeratorComponent
                      title={"Moderator"}
                      notesKey={"moderatorNote"}
                      setState={(data) => this.setState(data)}
                      onSelectUser={this.onSelectUser}
                      presets={this.props.presets}
                      renderImage={this.renderImage}
                      createNotes={this.props.createNotes}
                      currentFakeUser={currentFakeUser}
                      privateFakeUserPhoto={privateFakeUserPhoto}
                      fakeUserInfoOpen={fakeUserInfoOpen}
                      activeUserIndex={activeUserIndex}
                      allStories={allStories}
                      allMoments={allMoments}
                      currentChatId={currentChatId}
                      opponent={opponent}
                      likeable={false}
                      commentable={false}
                      realUser={this.props.chat?.usersCache}
                    />
                  </Segment>
                </Grid.Column>

                <Grid.Column width={8}>
                  {usersList && currentFakeUser && (
                    <div
                      style={{
                        height: "calc(100vh - 70px)",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <div>
                        <Button
                          icon="close"
                          onClick={(e) => this.closeUsersList()}
                        />
                      </div>
                      <ChatUsers
                        fakeUserId={this.props.chat.currentFakeUser.id}
                        startNewChat={this.onStartNewChat}
                        chat={this.props.chat}
                        users={chatUsers}
                        getUsersList={this.props.getUsersList}
                        hideError={this.props.hideError}
                        sortUsersList={this.props.sortUsersList}
                        onSearchValueChange={this.props.onSearchValueChange}
                        searchUsersList={this.props.searchUsersList}
                      />
                    </div>
                  )}
                  {this.props.chat?.clearedAt && (
                    <Timer
                      key={this.props.chat.clearedAt}
                      className="timer"
                      prefixText="Remain Time "
                      deadline={this.props.chat.clearedAt}
                    />
                  )}
                  {/* MessageBox */}
                  {!usersList && (
                    <MessageBox
                      currentChat={currentChatMessages}
                      opponent={opponent}
                      fakeUserId={this.props.chat.currentFakeUser.id}
                      appendChatHistory={this.props.appendChatHistory}
                      refreshChat={this.props.refreshChat}
                      fakeUser={currentFakeUser}
                      // messages={messages}
                      // currentChatId={currentChatId}
                      // session={this.props.session}
                      // userDetails={user}
                      // currentChatRoom={this.props.chat?.currentChat}
                    />
                  )}

                  {!usersList && (
                    <>
                      <Card className="messageBox">
                        <Popup
                          content={
                            this.props.chat?.customMessageLength <= 2
                              ? `Message should be minimum ${this.props.chat?.chatMessageLength?.minMessageLength} characters long!`
                              : `remaining ${
                                  (this.props.chat?.chatMessageLength
                                    ?.minMessageLength || 0) -
                                  this.props.chat?.customMessageLength
                                } characters`
                          }
                          style={{
                            background: "#E2B747",
                            color: "white",
                            marginBottom: "25px",
                            zIndex: 99,
                          }}
                          disabled={
                            this.props.chat?.customMessageLength >=
                            (this.props.chat?.chatMessageLength
                              ?.minMessageLength || 0)
                          }
                          open={this.state.isWriting}
                          pinned
                          className="messagePopUp"
                          trigger={<div></div>}
                        />
                        <textarea
                          onFocus={() => this.setState({ isWriting: true })}
                          onBlur={() => this.setState({ isWriting: false })}
                          onPaste={(e) => {
                            const isAllowed =
                              this.props.chat?.chatPasteSettings?.[0]
                                ?.isAllowed;
                            const USER_ROLE = isAllowedRole(
                              [
                                commonConstants.ADMIN_ROLE,
                                commonConstants.SUPER_ADMIN_ROLE,
                              ],
                              this.props.session?.userDetails
                            );

                            console.log("Pasting Allowed ? ", {
                              isAllowed,
                              USER_ROLE,
                            });

                            if (USER_ROLE === undefined && !isAllowed)
                              e.preventDefault();
                          }}
                          placeholder="Type here"
                          position="top left"
                          disabled={!this.props.chat.currentChat}
                          value={this.props.chat.chatMessage || ""}
                          onChange={this.props.changeChatMessage}
                          maxLength="300"
                          minLength={
                            this.props.chat?.chatMessageLength
                              ?.minMessageLength || 0
                          }
                          rows={5}
                        ></textarea>

                        <Grid columns={12}>
                          <Grid.Row style={{ paddingTop: 0 }}>
                            <Grid.Column width={4} style={{ padding: "3px" }}>
                              <AddChatImage
                                folder={
                                  currentFakeUser
                                    ? currentFakeUser.external_id
                                    : "images"
                                }
                                ref={this.imageRef}
                                disabled={!Boolean(this.props.chat.currentChat)}
                                height={"90px"}
                                header={"Attach Image"}
                                onChange={(data) => {
                                  this.setState({ image: data });
                                }}
                                onUpload={this.onUpload}
                                getStockImages={this.props.getStockImages}
                                stockImages={this.props.stockImages}
                                moderatorId={moderatorId}
                              />
                            </Grid.Column>
                            <Grid.Column width={4} style={{ padding: "3px" }}>
                              <div style={{ height: "90px" }}>
                                {this.state?.gift?.picture ? (
                                  <Photo
                                    height={"90px"}
                                    width={"98%"}
                                    src={this.state?.gift?.picture}
                                    onClick={() => {}}
                                    className="SendGitImgageSelected"
                                  />
                                ) : null}
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "end",
                                  paddingTop: "10px",
                                }}
                              >
                                <Popup
                                  onOpen={() =>
                                    this.setState({ openGift: true })
                                  }
                                  onClose={() =>
                                    this.setState({ openGift: false })
                                  }
                                  open={openGift}
                                  style={{ borderRadius: "20px" }}
                                  position="top center"
                                  size={"huge"}
                                  on={"click"}
                                  trigger={
                                    <Button
                                      className="sendGift"
                                      disabled={
                                        !Boolean(this.props.chat.currentChat)
                                      }
                                      onClick={() =>
                                        this.setState({ openGift: true })
                                      }
                                    >
                                      Send Gift
                                    </Button>
                                  }
                                >
                                  {openGift ? (
                                    <SendGiftSection
                                      receiverId={opponent?.friendId}
                                      senderId={opponent?.user_id}
                                      openGift={openGift}
                                      setGift={(data) =>
                                        this.setState({ gift: data })
                                      }
                                      refreshChat={this.props.refreshChat}
                                      setOpenGift={() => {
                                        this.setState({ openGift: false });
                                      }}
                                      removeCurrentChats={
                                        this.props.removeCurrentChats
                                      }
                                    />
                                  ) : null}
                                </Popup>
                              </div>
                            </Grid.Column>
                            <Grid.Column
                              width={5}
                              style={{
                                padding: "3px",
                                display: "flex",
                                alignItems: "end",
                              }}
                            >
                              <Popup
                                onOpen={() => this.setState({ modal: true })}
                                onClose={() => this.setState({ modal: false })}
                                open={this?.state?.modal}
                                style={{ borderRadius: "20px" }}
                                position="top center"
                                size={"huge"}
                                on={"click"}
                                trigger={
                                  <Button
                                    className="shareLocation"
                                    onClick={() =>
                                      this.setState({ modal: true })
                                    }
                                    disabled={
                                      !Boolean(this.props.chat.currentChat)
                                    }
                                  >
                                    Share Location
                                  </Button>
                                }
                              >
                                {this?.state?.modal ? (
                                  <ShareLocation
                                    moderatorId={moderatorId}
                                    shareLocation={(text) =>
                                      this.shareLocation(
                                        moderatorId,
                                        text,
                                        "GL"
                                      )
                                    }
                                    setOpen={() =>
                                      this.setState({
                                        modal: !this?.state?.modal,
                                      })
                                    }
                                    open={this?.state?.modal}
                                    currentFakeUser={currentFakeUser}
                                  />
                                ) : null}
                              </Popup>
                            </Grid.Column>
                            <Grid.Column
                              width={3}
                              style={{
                                padding: "3px",
                                display: "flex",
                                alignItems: "end",
                              }}
                            >
                              <Button
                                className="send"
                                type="button"
                                onClick={handleSendAction}
                                disabled={
                                  !this.props.chat.currentChat ||
                                  this.props.chat.chatMessage === "" ||
                                  this.props.chat?.customMessageLength <
                                    (this.props.chat?.chatMessageLength
                                      ?.minMessageLength || 0)
                                }
                              >
                                Send
                              </Button>
                            </Grid.Column>
                          </Grid.Row>
                        </Grid>
                      </Card>
                    </>
                  )}
                </Grid.Column>
                <Grid.Column
                  width={4}
                  floated="left"
                  style={{
                    maxHeight: window.innerHeight - 70,
                    height: window.innerHeight - 70,
                    overflowY: "scroll",
                    overflowX: "hidden",
                    padding: "0px 10px 0px 0px",
                  }}
                >
                  <Grid.Column
                    width={8}
                    style={{
                      height: "100%",
                      overflowY: "scroll",
                      overflowX: "hidden",
                      padding: "0px 5px 0px 0px",
                    }}
                  >
                    <List
                      onClick={() => {
                        console.log("List Clicked", opponent);
                        this.props.sendProfileVisit(
                          opponent.friendId,
                          opponent.user_id
                        );
                      }}
                      onScroll={() => {
                        console.log("Scrolled...");
                      }}
                    >
                      <div className="yannmartin">
                        {this.state.currentUserDetails?.fullName
                          ? this.state.currentUserDetails?.fullName
                          : this.state.currentUserDetails?.username}
                      </div>
                      <UserWorkerComponent
                        title={"User"}
                        notesKey={"userNote"}
                        setState={(data) => this.setState(data)}
                        onSelectUser={this.onSelectUser}
                        presets={this.props?.presets}
                        renderImage={this.renderImage}
                        createNotes={this.props?.createNotes}
                        currentFakeUser={currentUserDetails}
                        privateFakeUserPhoto={privatePhoto}
                        fakeUserInfoOpen={userInfoOpen}
                        activeUserIndex={activeIndex}
                        allStories={this?.state?.currentUserStories}
                        allMoments={this?.state?.currentUserMoments}
                        currentChatId={currentChatId}
                        opponent={opponent}
                        likeable={true}
                        commentable={true}
                        realUser={this.props.chat?.usersCache}
                      />
                    </List>
                  </Grid.Column>
                </Grid.Column>

                {this?.state?.story ? (
                  <StoryMomentModal
                    open={this?.state?.story ? true : false}
                    setOpen={() => this.setState({ story: "" })}
                    item={this?.state?.story}
                    refetchMoments={() => {
                      this.getCurrentUserMoments(currentUserDetails?.id);
                      this.getUserMoments(currentFakeUser?.id);
                    }}
                    refetchStories={() => {
                      this.getCurrentUserStories(currentUserDetails?.id);
                      this.getUserStories(currentFakeUser?.id);
                    }}
                    likeable={this.state.likeable}
                    commentable={this.state.commentable}
                    currentFakeUser={currentFakeUser}
                  />
                ) : null}
              </Grid.Row>
            </Grid>
          </Dimmer.Dimmable>
        ) : (
          <></>
        )}
      </>
    );
  }
}

WorkerDashboard.propTypes = {
  chat: PropTypes.shape({
    loading: PropTypes.bool,
    error: PropTypes.shape({
      visible: PropTypes.bool,
    }),
  }).isRequired,
  session: PropTypes.shape({
    userDetails: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};
