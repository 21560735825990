import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Card,
  Header,
  Icon,
  Image,
  Message,
  Modal,
  Segment,
} from "semantic-ui-react";
import { setStockImage, uploadStockImage } from "../../actions/fakeUsers";
import { selectFakeUsersState } from "../../store/selectors/fakeUsersSelectors";
import UserApi from "../../utils/users";
import MediaUpload from "../MediaUpload";
import videoIcon from "../../assets/images/videoIcon.png";
import { baseUrl } from "../../utils/api";
import { isVideo } from "../../utils/helpers";

const VideoSection = () => {
  return <div>StockImageSection</div>;
};

function VideoComponent({ file }) {
  const [play, setPlay] = useState(false);
  return (
    <div
      style={{
        position: "relative",
      }}
    >
      {!play ? (
        <div
          style={{
            position: "absolute",
            height: "100%",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backdropFilter: "blur(5px)",
            cursor: "pointer",
          }}
        >
          <Image
            src={videoIcon}
            style={{
              height: "25px",
              width: "25px",
              marginTop: "5px",
            }}
          />
        </div>
      ) : null}
      <video
        src={file}
        centered
        width={"100%"}
        onClick={(e) => {
          if (e.target.paused) {
            e.target.play();
            setPlay(true);
          } else {
            e.target.pause();
            setPlay(false);
          }
        }}
      >
        <source src={file} type="video/mp4" />
      </video>
    </div>
  );
}

const StockImageSection = () => {
  const fakeUsersState = useSelector(selectFakeUsersState);
  const userId = fakeUsersState?.currentUser?.id;

  const [stockImages, setStockImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState({});
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [errors, setErrors] = useState(false);
  const dispatch = useDispatch();
  const getStockImages = async () => {
    const res = await UserApi.getStockImages(userId);
    if (res?.data?.stockImages) {
      dispatch(setStockImage(res?.data?.stockImages));
      setStockImages(res?.data?.stockImages);
    }
  };

  const onUploadImage = async (params) => {
    await uploadStockImage(userId, params);
    getStockImages();
  };

  const handleDeleteStory = async (imgId) => {
    try {
      const res = await UserApi.deleteStockImages(imgId);
      if (res?.data?.deleteStockImage?.success) {
        getStockImages();
        setIsDeleteModalOpen(false);
      }
    } catch (error) {
      setErrors([error.message]);
    }
  };

  const isImage = (url) => {
    const imgTypes = [
      ".png",
      ".jpg",
      ".jpeg",
      ".apng",
      ".avif",
      ".gif",
      ".jfif",
      ".pjpeg",
      ".pjp",
      ".svg",
      ".webp",
      ".PNG",
      ".JPG",
      ".JPEG",
      ".APNG",
      ".AVIF",
      ".GIF",
      ".JFIF",
      ".PJPEG",
      ".PJP",
      ".SVG",
      ".WEBP",
    ];
    for (let i = 0; i < imgTypes.length; i++) {
      const imgType = imgTypes[i];
      if (String(url).includes(imgType)) {
        return true;
      }
    }
    return false;
  };

  useEffect(() => {
    if (userId) {
      getStockImages();
    }
  }, [userId]);

  return (
    <>
      <Modal open={isDeleteModalOpen}>
        <Header icon="archive" content="Are you sure want to delete ?" />
        <Modal.Content>
          <p>The file should be not recovered!</p>
        </Modal.Content>
        <Modal.Actions>
          {errors?.length &&
            errors.map((error) => (
              <Message
                style={{ display: "block", textAlign: "start" }}
                error={true}
              >
                {error}{" "}
              </Message>
            ))}
          <Button
            color="red"
            onClick={() => {
              setIsDeleteModalOpen(false);
            }}
          >
            <Icon name="remove" /> Cancel
          </Button>
          <Button
            color="green"
            onClick={() => {
              handleDeleteStory(selectedImage.id);
            }}
          >
            <Icon name="checkmark" /> Delete
          </Button>
        </Modal.Actions>
      </Modal>

      <Segment>
        <Header size="large">Stock Images</Header>
        {/* {stories.length === 0 && <Header>No images</Header>} */}
        <Card.Group itemsPerRow={4}>
          {stockImages.map((image, index) => {
            const getImageUrl = (fileUrl) => {
              if (fileUrl?.startsWith("https://djbbbm5n90p1g.cloudfront.net"))
                return fileUrl;

              return baseUrl + fileUrl;
            };
            return (
              <Card key={index} className="user-card-main-container">
                <div className="user-moment-image">
                  {isVideo(getImageUrl(image?.file)) ? (
                    <VideoComponent file={getImageUrl(image?.file)} />
                  ) : (
                    <Image src={getImageUrl(image?.file)} centered />
                  )}
                  {/* <div>
                    <button
                      style={{
                        width: 30,
                        height: 30,
                        background: "#ffffff",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        position: "absolute",
                        borderRadius: "10px",
                        top: 5,
                        right: 5,
                        border: "none",
                      }}
                      type="button"
                      onClick={() => {
                        setSelectedImage(image);
                        setIsDeleteModalOpen(true);
                      }}
                    >
                      <div>
                        <Icon
                          className="create-fake-moderator-remove-moment-image-icon"
                          link
                          corner
                          name={"trash"}
                        />
                      </div>
                    </button>
                  </div> */}
                </div>
              </Card>
            );
          })}
          <Card>
            <MediaUpload
              onUpload={onUploadImage}
              height={"200px"}
              header={"Add new image"}
            />
          </Card>
        </Card.Group>
      </Segment>
    </>
  );
};

export default StockImageSection;
