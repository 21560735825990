import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

import {
  loginAction,
  changePassword,
  changeEmail,
} from "../../actions/session";

import MainMenu from "../../components/mainMenu";
import SignIn from "../../components/auth/signIn";

const SignInView = (props) => {
  const submitLogin = () => {
    var credentials = {
      email: props?.session?.email,
      password: props?.session?.password,
    };
    props.login(credentials);
  };

  const { from } = props.location.state || {
    from: { pathname: "/dashboard" },
  };

  if (props?.session?.isAuthenticated) {
    return <Redirect to={from} />;
  }

  return (
    <div>
      <MainMenu
        needShowPrivateItems={props.session.isAuthenticated}
        menu={props.menu}
        onLogout={() => {}}
        setActiveMenuPosition={() => {}}
      />
      <SignIn
        onSubmit={submitLogin}
        session={props.session}
        changePassword={props.changePassword}
        changeEmail={props.changeEmail}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    session: state?.session,
    menu: state?.menu,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    login: (credentials) => {
      loginAction(credentials)(dispatch);
    },
    changePassword: (event, data) => {
      dispatch(changePassword(data.value));
    },
    changeEmail: (event, data) => {
      dispatch(changeEmail(data.value));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignInView);
