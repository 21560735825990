import React, { useState } from "react";
import { Button, Image, Message, Modal } from "semantic-ui-react";
import currentLocation from "../../assets/images/currentLocation.png";
import check from "../../assets/images/check.png";
import cancelVector from "../../assets/images/closeBlackVector.png";
import "./common.css";
import MapContainer from "../mapContainer";
import { isEmpty } from "lodash";
import { getFullAddressFromLatLng } from "../../utils/helpers";

const options = {
  enableHighAccuracy: true,
  timeout: 5000,
  maximumAge: 0,
};

const ShareLocation = ({ shareLocation, setOpen, currentFakeUser }) => {
  // console.log("currentfakeuser",currentFakeUser)
  const [errors, setErrors] = useState([]);
  const [location, setLocation] = useState([
    currentFakeUser?.location[0] ?? 0,
    currentFakeUser?.location[1] ?? 0,
  ]);
  const [address, setAddress] = useState(currentFakeUser.address);
  const [currentLocationSelected, setCurrentLocationSelected] = useState(false);

  const handleGetCurrentLatLng = async () => {
    async function success(pos) {
      const crd = pos.coords;
      const address = await getFullAddressFromLatLng(
        crd.latitude,
        crd.longitude
      );
      console.log(pos.coords);
      setAddress(address.formattedAddress);
      setLocation([crd.latitude, crd.longitude]);
      setCurrentLocationSelected(true);
    }
    function error(err) {
      setErrors([err.message]);
    }
    navigator.geolocation.getCurrentPosition(success, error, options);
  };

  return (
    <div style={{ width: "750px" }}>
      <Modal.Header>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <p style={{ fontSize: "20px", fontWeight: "700", color: "#333333" }}>
            Share Location
          </p>
          <Image
            src={cancelVector}
            height={"25px"}
            width={"25px"}
            onClick={() => setOpen(false)}
          />
        </div>
      </Modal.Header>
      <Modal.Content image style={{ paddingBottom: 0 }}>
        <div
          style={{
            position: "relative",
            width: "100%",
          }}
        >
          {currentLocationSelected ? (
            <MapContainer
              showCity={false}
              mapStyle={{
                height: "calc(100vh - 400px)",
              }}
              latLng={{
                lat: location[0] ?? 0,
                lng: location[1] ?? 0,
              }}
              formattedAddress={address}
              handelChange={(e) => {
                setLocation([e.latLng.lat, e.latLng.lng]);
                setCurrentLocationSelected(false);
              }}
            />
          ) : (
            <div>
              <MapContainer
                showCity={false}
                mapStyle={{
                  height: "calc(100vh - 400px)",
                }}
                latLng={{
                  lat: location[0],
                  lng: location[1],
                }}
                formattedAddress={address}
                handelChange={(e) => {
                  setLocation([e.latLng.lat, e.latLng.lng]);
                  setCurrentLocationSelected(false);
                }}
              />
            </div>
          )}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "10px",
            marginRight: "15px",
          }}
        >
          {/* <Button
            id="currentLocation"
            color="blue"
            style={{ background: "#21AFDC" }}
            onClick={handleGetCurrentLatLng}
          >
            <span className="closeButton" style={{ width: "160px" }}>
              <Image src={currentLocation} height={"18px"} width={"18px"} />
              Use current location
            </span>
          </Button> */}
          <Modal.Actions
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "flex-end",
            }}
          >
            <Button
              color="black"
              onClick={() => setOpen(false)}
              style={{ background: "#505050", marginRight: "15px" }}
            >
              Close
            </Button>
            <Button
              onClick={() => {
                shareLocation(`${location[0]},${location[1]}`);
              }}
              positive
            >
              <span className="closeButton" style={{ width: "120px" }}>
                <Image src={check} height={"18px"} width={"18px"} />
                Share Location
              </span>
            </Button>
          </Modal.Actions>
        </div>
        {!isEmpty(errors) && (
          <>
            {errors.map((error, index) => (
              <Message key={index} style={{ display: "block" }} error={true}>
                <span style={{ color: "inherit", fontSize: "1rem" }}>
                  {error}
                </span>
              </Message>
            ))}
          </>
        )}
      </Modal.Content>
    </div>
  );
};

export default ShareLocation;
