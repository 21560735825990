import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import {
  Button,
  Segment,
  Dimmer,
  Loader,
  Form,
  Header,
  Image,
  Card,
  Grid,
  Message,
  Step,
  Icon,
} from "semantic-ui-react";
import ErrorMessage from "../errorMessage";
import { withRouter, Link } from "react-router-dom";
import PropTypes from "prop-types";
import Tags from "./tagsComponent";
import { commonConstants } from "../../constants/common";
import {
  getWorkerFakeUsers,
  updateCreateModerator,
} from "../../actions/fakeUsers";
import AddMoment from "./AddMoment";
import FakeUserPhotos from "./FakeUserPhotos";

import "./index.css";
import FakeUserStories from "./FakeUserStories";
import StockImageSection from "../moderators/StockImageSection";
import MapContainer from "../mapContainer";
import { includes } from "lodash";
import FakeUserMoments from "./fakeUserMoments";
import { getModeratorsList } from "../../actions/moderators";

const requiredFields = [
  { field: "fullName", validateFn: (value) => value?.length > 0 },
  { field: "age", validateFn: (value) => !!value },
  { field: "gender", validateFn: (value) => !!value },
];

const CreateModerator = (props) => {
  const [state, setState] = useState({
    isDirty: false,
    address: "",
    geometry: { lat: 0, lng: 0 },
    addressParts: {
      city: "",
      country: "",
      country_code: "",
      zip_code: "",
    },
    errors: [],
  });

  const [subscriptionOptions, setSubscriptionOptions] = useState([]);
  const [genderOptions, setGenderOptions] = useState([]);
  const [ethnicityOptions, setEthnicityOptions] = useState([]);
  const [zodiacSignsOptions, setZodiacSignsOptions] = useState([]);
  const [familyOptions, setFamilyOptions] = useState([]);
  const [politicsOptions, setPoliticsOptions] = useState([]);
  const [religiousOptions, setReligiousOptions] = useState([]);
  const [tagsOptions, setTagsOptions] = useState([]);
  const [ageOptions, setAgeOptions] = useState([]);
  const [heightOptions, setHeightOptions] = useState([]);
  const [active, setActive] = useState(0);
  const dispatch = useDispatch();

  const saveUser = async (currentUser) => {
    let errors = [];
    if (!currentUser?.fullName) {
      errors.push("Please enter name!");
    }
    if (
      currentUser?.gender !== 1 &&
      currentUser?.gender !== 2 &&
      currentUser?.gender !== 3
    ) {
      errors.push("Please select gender!");
    }
    if (currentUser?.ethnicity === 0 || !currentUser?.ethnicity) {
      errors.push("Please select Ethnicity!");
    }
    if (!currentUser?.interestedIn?.length) {
      errors.push("Please select interested in!");
    }
    if (!currentUser?.tags?.length) {
      errors.push("Please select tags!");
    }
    if (!currentUser?.subscription) {
      errors.push("Please select subscription plan");
    }
    if (!state?.address || !state?.geometry?.lat || !state?.geometry?.lat) {
      errors.push("Please enter addresss!");
    }
    if (errors.length) {
      setState((state) => ({ ...state, errors }));
      return;
    }
    localStorage.setItem("Newmoderaotr",'created');
    let { age = 18, fullName = "" } = currentUser;
    let updatedName = fullName.trim().replace(" ", "_");

    props.createUser(
      props.userDetails.id,
      {
        ...currentUser,
        subscription_id: currentUser?.subscription,
        email: `${updatedName}${age}@i69app.com`,
        display_name: `${updatedName}_${age}`,
        username: `${updatedName}_${age}@i69app.com`,
        address: state.address,
        country: state.addressParts?.country ?? "",
        city: state.addressParts?.sublocality ?? state.addressParts.locality,
        country_code: state.addressParts?.country_code ?? "",
        zip_code: state.addressParts?.postal_code,
        location: [state.geometry.lat, state.geometry.lng],
      },

      () => {
        setActive(1);
        dispatch(getModeratorsList());
      }
    );
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setState((state) => ({ ...state, errors: [] }));
    }, 10000);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  useEffect(() => {
    if (!props?.presets?.loading) {
      const presetsValue = props?.presets?.value;
      setSubscriptionOptions(
        presetsValue?.subscriptions?.map((item, index) => ({
          key: index,
          value: item?.id,
          text: item?.name,
        }))
      );
      setAgeOptions(
        presetsValue?.ages?.map((item, index) => ({
          key: index,
          value: item?.id,
          text: item?.age,
        }))
      );
      setHeightOptions(
        presetsValue?.heights?.map((item, index) => ({
          key: index,
          value: item?.id,
          text: item?.height,
        }))
      );
      setGenderOptions(
        presetsValue?.genders
          ?.map((item, index) => ({
            key: index,
            value: item?.id,
            text: item?.gender,
          }))
          ?.sort((a, b) => a?.value - b?.value)
      );
      setEthnicityOptions(
        presetsValue?.ethnicity?.map((item, index) => ({
          key: index,
          value: item?.id,
          text: item?.ethnicity,
        }))
      );
      setZodiacSignsOptions(
        presetsValue?.zodiacSigns?.map((item, index) => ({
          key: index,
          value: item?.id,
          text: item?.zodiacSign,
        }))
      );
      setFamilyOptions(
        presetsValue?.family?.map((item, index) => {
          return { key: index, value: item?.id, text: item?.familyPlans };
        })
      );
      setPoliticsOptions(
        presetsValue?.politics?.map((item, index) => {
          return { key: index, value: item?.id, text: item?.politics };
        })
      );
      setReligiousOptions(
        presetsValue?.religious?.map((item, index) => {
          return { key: index, value: item?.id, text: item?.religious };
        })
      );
      setTagsOptions(
        presetsValue?.tags?.map((item, index) => {
          return { key: index, value: item?.id, text: item?.tag };
        })
      );
      
    }
    
  }, [props.presets.value]);

  return (
    <Segment.Group>
      <Dimmer.Dimmable as={Segment} dimmed={props?.fakeUsers.loading}>
        <Dimmer active={props?.fakeUsers.loading} inverted>
          <Loader>Loading</Loader>
        </Dimmer>
        {props?.fakeUsers?.error && props.fakeUsers?.error.visible && (
          <ErrorMessage
            error={props?.fakeUsers?.error}
            hideError={props.hideError}
          />
        )}
        <Segment textAlign="center">
          <Step.Group>
            <Step active={active === 0} disabled={active !== 0}>
              <Icon name="user" />
              <Step.Content>
                <Step.Title>Create Moderator</Step.Title>
                <Step.Description>Fill Moderator Information</Step.Description>
              </Step.Content>
            </Step>
            <Step active={active === 1} disabled={active !== 1}>
              <Icon name="photo" />
              <Step.Content>
                <Step.Title>Moments, Stories</Step.Title>
                <Step.Description>Upload Moments and Stories</Step.Description>
              </Step.Content>
            </Step>
          </Step.Group>
        </Segment>
        {active === 0 && (
          <Segment padded>
            <Form>
              <Segment secondary>
                <Form.Group widths="equal">
                  <Form.Input
                    fluid
                    label={
                      "Name " +
                      (requiredFields.some(
                        (item, i) => item?.field === "fullName"
                      )
                        ? "(*)"
                        : "")
                    }
                    placeholder="Name"
                    value={props.fakeUsers?.currentUser?.fullName || ""}
                    onChange={props.changeName}
                    error={
                      state.errors.length !== 0 &&
                      props.fakeUsers?.currentUser?.fullName?.length === 0
                    }
                  />
                  <Form.Input
                    fluid
                    label={
                      "Work " +
                      (requiredFields.some((item, i) => item.field === "work")
                        ? "(*)"
                        : "")
                    }
                    placeholder="Work"
                    value={props.fakeUsers?.currentUser?.work || ""}
                    onChange={props.changeWork}
                  />
                  <Form.Input
                    fluid
                    label={
                      "Education " +
                      (requiredFields.some(
                        (item, i) => item.field === "education"
                      )
                        ? "(*)"
                        : "")
                    }
                    placeholder="Education"
                    value={props.fakeUsers?.currentUser?.education || ""}
                    onChange={props.changeEducation}
                  />
                </Form.Group>

                <Form.Group widths="equal">
                  <Form.Select
                    fluid
                    search
                    selection
                    label="Subscription (*)"
                    placeholder="Subscription"
                    options={subscriptionOptions}
                    value={
                      props.fakeUsers?.currentUser &&
                      props.fakeUsers?.currentUser?.subscription
                        ? props.fakeUsers?.currentUser?.subscription
                        : "SILVER"
                    }
                    onChange={props.changeSubscription}
                    error={
                      state.errors.length !== 0 &&
                      !includes(
                        subscriptionOptions.map((item) => item?.value),
                        props?.fakeUsers?.currentUser?.subscription
                      )
                    }
                  />
                  <Form.Select
                    fluid
                    search
                    selection
                    label="Age"
                    placeholder="Age"
                    options={ageOptions}
                    value={
                      props.fakeUsers?.currentUser &&
                      props.fakeUsers?.currentUser?.age
                        ? props.fakeUsers?.currentUser?.age
                        : 18
                    }
                    onChange={props.changeAge}
                    error={
                      state.isDirty && props.fakeUsers?.currentUser?.age === 0
                    }
                  />

                  <Form.Select
                    fluid
                    search
                    selection
                    label={"Gender"}
                    options={genderOptions}
                    placeholder="Gender"
                    onChange={props.changeGender}
                    value={
                      props.fakeUsers?.currentUser &&
                      props.fakeUsers?.currentUser?.gender
                        ? props.fakeUsers?.currentUser?.gender
                        : ""
                    }
                    error={
                      state.errors.length !== 0 &&
                      props.fakeUsers?.currentUser?.gender !== 1 &&
                      props.fakeUsers?.currentUser?.gender !== 2 &&
                      props.fakeUsers?.currentUser?.gender !== 3
                    }
                  />
                  <Form.Select
                    fluid
                    search
                    label="Height"
                    options={heightOptions}
                    placeholder="Height"
                    onChange={props.changeHeight}
                    value={
                      props.fakeUsers?.currentUser &&
                      props.fakeUsers?.currentUser?.height
                        ? props.fakeUsers?.currentUser?.height
                        : 140
                    }
                  />
                  <Form.Select
                    fluid
                    search
                    selection
                    label={"Ethnicity (*)"}
                    options={ethnicityOptions}
                    placeholder="Ethnicity"
                    onChange={props.changeEthnicity}
                    value={
                      props?.fakeUsers?.currentUser &&
                      props?.fakeUsers?.currentUser?.ethnicity
                        ? props.fakeUsers?.currentUser?.ethnicity
                        : null
                    }
                    error={
                      state.errors.length !== 0 &&
                      !props?.fakeUsers?.currentUser?.ethnicity
                    }
                  />
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Select
                    fluid
                    search
                    selection
                    label={
                      "Zodiac Sign " +
                      (requiredFields.some(
                        (item, i) => item.field === "zodiacSign"
                      )
                        ? "(*)"
                        : "")
                    }
                    options={zodiacSignsOptions}
                    placeholder="Zodiac Sign"
                    onChange={props.changeZodiacSign}
                    value={
                      props?.fakeUsers?.currentUser &&
                      props?.fakeUsers?.currentUser?.zodiacSign
                        ? props?.fakeUsers?.currentUser?.zodiacSign
                        : ""
                    }
                  />
                  <Form.Select
                    fluid
                    search
                    selection
                    label={
                      "Family plans " +
                      (requiredFields.some(
                        (item, i) => item?.field === "familyPlans"
                      )
                        ? "(*)"
                        : "")
                    }
                    options={familyOptions}
                    placeholder="Family plans"
                    onChange={props.changeFamilyPlans}
                    value={
                      props?.fakeUsers?.currentUser &&
                      props?.fakeUsers?.currentUser?.familyPlans
                        ? props?.fakeUsers?.currentUser?.familyPlans
                        : ""
                    }
                  />
                  <Form.Select
                    fluid
                    search
                    selection
                    label={
                      "Politics " +
                      (requiredFields.some(
                        (item, i) => item?.field === "politics"
                      )
                        ? "(*)"
                        : "")
                    }
                    options={politicsOptions}
                    placeholder="Politics"
                    onChange={props.changePolitics}
                    value={
                      props?.fakeUsers?.currentUser &&
                      props?.fakeUsers?.currentUser?.politics
                        ? props?.fakeUsers?.currentUser?.politics
                        : ""
                    }
                  />
                  <Form.Select
                    fluid
                    search
                    selection
                    label={
                      "Religious Beliefs " +
                      (requiredFields.some(
                        (item, i) => item?.field === "religion"
                      )
                        ? "(*)"
                        : "")
                    }
                    options={religiousOptions}
                    placeholder="Religious Beliefs"
                    onChange={props.changeReligious}
                    value={
                      props?.fakeUsers?.currentUser &&
                      props?.fakeUsers?.currentUser?.religion
                        ? props?.fakeUsers?.currentUser?.religion
                        : ""
                    }
                  />
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Select
                    fluid
                    label={"Interested In (*)"}
                    multiple
                    search
                    selection
                    options={commonConstants.INTERESTED_IN_OPTIONS}
                    placeholder="Interested In"
                    onChange={props.changeInterestedIn}
                    value={props?.fakeUsers?.currentUser?.interestedIn}
                    error={
                      state?.errors?.length !== 0 &&
                      !props?.fakeUsers?.currentUser?.interestedIn?.length
                    }
                  />
                  <Form.Select
                    fluid
                    label={"Tags (*)"}
                    multiple
                    search
                    selection
                    options={tagsOptions}
                    placeholder="Tags"
                    onChange={props.changeTags}
                    value={
                      props?.fakeUsers?.currentUser &&
                      props?.fakeUsers?.currentUser?.tags
                        ? props?.fakeUsers?.currentUser?.tags
                        : ""
                    }
                    error={
                      state?.errors?.length !== 0 &&
                      !props?.fakeUsers?.currentUser?.tags?.length
                    }
                  />
                </Form.Group>

                <Form.Group>
                  <Form.TextArea
                    width="16"
                    fluid
                    label={
                      "About" +
                      (requiredFields.some((item, i) => item?.field === "about")
                        ? "(*)"
                        : "")
                    }
                    placeholder="About"
                    value={props?.fakeUsers?.currentUser?.about || ""}
                    onChange={(e) => {
                      props.updateCreateModerator({
                        about: e.target.value,
                      });
                    }}
                  />
                </Form.Group>
                <Segment>
                  <div
                    style={{
                      width: "100%",
                      position: "relative",
                    }}
                  >
                    <MapContainer
                      latLng={{
                        lat: this?.state?.geometry?.lat ?? 0,
                        lng: this?.state?.geometry?.lng ?? 0,
                      }}
                      formattedAddress={state.address ?? ""}
                      addressParts={state.addressParts}
                      handelChange={(e) => {
                        setState((state) => ({
                          ...state,
                          address: e.formattedAddress,
                          geometry: {
                            lat: e.latLng.lat,
                            lng: e.latLng.lng,
                          },
                          addressParts: e.addressParts,
                        }));
                      }}
                      mapStyle={{
                        height: "600px",
                      }}
                    />
                  </div>
                </Segment>

                <Header size="large">Additional fields </Header>
                <Grid columns="equal">
                  <Grid.Row>
                    <Grid.Column>
                      <Tags
                        isRequired={requiredFields.some(
                          (item, i) => item.field === "books"
                        )}
                        tags={props?.fakeUsers?.currentUser?.books}
                        item="book"
                        type={commonConstants.BOOK_ITEM}
                        header="Books"
                        onChangeTag={props.onChangeItem}
                      />
                    </Grid.Column>
                    <Grid.Column>
                      <Tags
                        tags={props?.fakeUsers?.currentUser?.music}
                        item="music"
                        type={commonConstants.MUSIC_ITEM}
                        header="Music"
                        onChangeTag={props.onChangeItem}
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column>
                      <Tags
                        tags={props?.fakeUsers?.currentUser?.movies}
                        item="movie"
                        type={commonConstants.MOVIE_ITEM}
                        header="Movies"
                        onChangeTag={props.onChangeItem}
                      />
                    </Grid.Column>
                    <Grid.Column>
                      <Tags
                        tags={props?.fakeUsers?.currentUser?.tvShows}
                        item="TV show"
                        type={commonConstants.TV_SHOW_ITEM}
                        header="TV Shows"
                        onChangeTag={props.onChangeItem}
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column>
                      <Tags
                        tags={props?.fakeUsers?.currentUser?.sportsTeams}
                        item="team"
                        type={commonConstants.SPORT_TEAM_ITEM}
                        header="Sports Teams"
                        onChangeTag={props.onChangeItem}
                      />
                    </Grid.Column>
                    <Grid.Column></Grid.Column>
                  </Grid.Row>
                </Grid>
              </Segment>
              {state?.errors?.length
                ? state.errors.map((error) => (
                    <Message style={{ display: "block" }} error={true}>
                      {error}{" "}
                    </Message>
                  ))
                : null}
              <Segment secondary textAlign="center">
                <Link to={"/moderators"}>
                  <Button size="large" content="Cancel" />
                </Link>
                <Button
                  size="large"
                  color="blue"
                  content="Next"
                  onClick={(e, data) => {
                    e.preventDefault();
                    saveUser(props?.fakeUsers?.currentUser);
                  }}
                />
              </Segment>
            </Form>
          </Segment>
        )}
        {active === 1 && (
          <Segment>
            <Segment
              inverted={
                state.isDirty &&
                props?.fakeUsers?.currentUser?.avatar_photos?.length === 0
              }
              tertiary={
                state?.isDirty &&
                props?.fakeUsers?.currentUser?.avatar_photos?.length === 0
              }
              secondary={
                state?.isDirty &&
                props?.fakeUsers?.currentUser?.avatar_photos?.length > 0
              }
              color={
                state?.isDirty &&
                props?.fakeUsers?.currentUser?.avatar_photos?.length === 0
                  ? "red"
                  : "black"
              }
            >
              <Header
                size="large"
                color={
                  state?.isDirty &&
                  props?.fakeUsers?.currentUser?.photos?.length === 0
                    ? "red"
                    : "black"
                }
              >
                Photos (*)
              </Header>
              {props?.fakeUsers?.currentUser?.avatar_photos?.length === 0 && (
                <Header>No photos selected</Header>
              )}
              <FakeUserPhotos />
            </Segment>

            <FakeUserStories />
            {/* <FakeUserMoments /> */}
            <Segment
              inverted={
                state?.isDirty &&
                props?.fakeUsers?.currentUser?.avatar_photos?.length === 0
              }
              tertiary={
                state?.isDirty &&
                props?.fakeUsers?.currentUser?.avatar_photos?.length === 0
              }
              secondary={
                state?.isDirty &&
                props?.fakeUsers?.currentUser?.avatar_photos?.length > 0
              }
              color={
                state?.isDirty &&
                props?.fakeUsers?.currentUser?.avatar_photos?.length === 0
                  ? "red"
                  : "black"
              }
            >
              <Header
                size="large"
                color={
                  state?.isDirty &&
                  props?.fakeUsers?.currentUser?.photos?.length === 0
                    ? "red"
                    : "black"
                }
              >
                Moment
              </Header>
              {props?.moments?.length === 0 && (
                <Header>No moments selected</Header>
              )}
              <Card.Group itemsPerRow={4}>
                {props?.moments?.map((image, index) => {
                  return (
                    <Card key={index}>
                      <div className="moments-list-moment-card-image-container">
                        <Image src={image?.file || image?.file_url} centered />
                      </div>
                      <Card.Content className="moments-list-card-bottom-content">
                        <p style={{ marginBottom: 20, wordBreak: "break-all" }}>
                          {image?.momentDescription}
                        </p>
                        <div className="ui two buttons add-moment-button">
                          <Button
                            basic
                            color="red"
                            onClick={(e, data) => {
                              e.preventDefault();
                              props.onRemoveMoment(image?.id);
                            }}
                          >
                            Remove
                          </Button>
                        </div>
                      </Card.Content>
                    </Card>
                  );
                })}
                <AddMoment
                  onUpload={props.onUploadMoment}
                  height={"200px"}
                  header={"Add new image"}
                  folder={props?.fakeUsers?.currentUser?.id || "images"}
                  stockImages={props?.fakeUsers?.stockImages}
                  moderatorId={props?.fakeUsers?.currentUser?.id}
                />
              </Card.Group>
            </Segment>
            <StockImageSection />
            <Segment secondary textAlign="center">
              <Link to={"/moderators"}>
                <Button size="large" content="Cancel" />
              </Link>
              <Link to={"/moderators"}>
                <Button size="large" color="blue" content="Save" />
              </Link>
            </Segment>
          </Segment>
        )}
      </Dimmer.Dimmable>
    </Segment.Group>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    getWorkerFakeUsers(id) {
      getWorkerFakeUsers(id)(dispatch);
    },
    updateCreateModerator: (payload) => {
      updateCreateModerator(payload)(dispatch);
    },
  };
};

export default connect(null, mapDispatchToProps)(withRouter(CreateModerator));

CreateModerator.propTypes = {
  fakeUsers: PropTypes.shape({
    loading: PropTypes.bool,
    error: PropTypes.shape({
      visible: PropTypes.bool,
    }),
    currentUser: PropTypes.shape({
      fullName: PropTypes.string,
      lastName: PropTypes.string,
      work: PropTypes.string,
      education: PropTypes.string,
      age: PropTypes.number,
      gender: PropTypes.number,
      subscription: PropTypes.string,
      height: PropTypes.number,
      ethnicity: PropTypes.string,
      zodiacSign: PropTypes.string,
      familyPlans: PropTypes.string,
      politics: PropTypes.string,
      religion: PropTypes.string,
      tags: PropTypes.array,
      interestedIn: PropTypes.array,
      books: PropTypes.array,
      movies: PropTypes.array,
      music: PropTypes.array,
      tvShows: PropTypes.array,
      sportsTeams: PropTypes.array,
    }).isRequired,
  }).isRequired,
  onChangeItem: PropTypes.func.isRequired,
  onAddNewPhoto: PropTypes.func.isRequired,
  onRemovePhoto: PropTypes.func.isRequired,
  changeName: PropTypes.func.isRequired,
  changeLastName: PropTypes.func.isRequired,
  changeGender: PropTypes.func.isRequired,
  changeAbout: PropTypes.func.isRequired,
  changeAge: PropTypes.func.isRequired,
  changeTags: PropTypes.func.isRequired,
  changeInterestedIn: PropTypes.func.isRequired,
  changePolitics: PropTypes.func.isRequired,
  changeSubscription: PropTypes.func.isRequired,
  changeReligious: PropTypes.func.isRequired,
  changeFamilyPlans: PropTypes.func.isRequired,
  changeZodiacSign: PropTypes.func.isRequired,
  changeHeight: PropTypes.func.isRequired,
  changeEducation: PropTypes.func.isRequired,
  changeEthnicity: PropTypes.func.isRequired,
  changeWork: PropTypes.func.isRequired,
  createUser: PropTypes.func.isRequired,
};
