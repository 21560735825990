export const sessionActionTypes = {
  LOGIN_REQUEST: "LOGIN_REQUEST",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_FAILURE: "LOGIN_FAILURE",
  LOGOUT_REQUEST: "LOGOUT_REQUEST",
  CHANGE_EMAIL: "CHANGE_EMAIL",
  CHANGE_PASSWORD: "CHANGE_PASSWORD",
  SIGN_UP_CHANGE_PASSWORD: "SIGN_UP_CHANGE_PASSWORD",
  SIGN_UP_CHANGE_REPEAT_PASSWORD: "SIGN_UP_CHANGE_REPEAT_PASSWORD",
  SIGN_UP_CHANGE_LAST_NAME: "SIGN_UP_CHANGE_LAST_NAME",
  SIGN_UP_CHANGE_FIRST_NAME: "SIGN_UP_CHANGE_FIRST_NAME",
  SIGN_UP_CHANGE_LANGUAGES: "SIGN_UP_CHANGE_LANGUAGES",
  SIGN_UP_REQUEST: "SIGN_UP_REQUEST",
  SIGN_UP_FAILURE: "SIGN_UP_FAILURE",
  SIGN_UP_SUCCESS: "SIGN_UP_SUCCESS",
  SET_SIGN_UP_KEY_REQUEST: "SET_SIGN_UP_KEY_REQUEST",
  SET_SIGN_UP_KEY_FAILURE: "SET_SIGN_UP_KEY_FAILURE",
  SET_SIGN_UP_KEY_SUCCESS: "SET_SIGN_UP_KEY_SUCCESS",
  CAN_VISIT_USER_LISTING_REQUEST: "CAN_VISIT_USER_LISTING_REQUEST",
  CAN_VISIT_USER_LISTING_FAILURE: "CAN_VISIT_USER_LISTING_FAILURE",
  CAN_VISIT_USER_LISTING_SUCCESS: "CAN_VISIT_USER_LISTING_SUCCESS",
  CAN_VISIT_ADMIN_LISTING_REQUEST: "CAN_VISIT_ADMIN_LISTING_REQUEST",
  CAN_VISIT_ADMIN_LISTING_SUCCESS: "CAN_VISIT_ADMIN_LISTING_SUCCESS",
  CAN_VISIT_ADMIN_LISTING_FAILURE: "CAN_VISIT_ADMIN_LISTING_FAILURE",
};
