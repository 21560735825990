import _ from "lodash";
import moment from "moment";
import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import {
  Divider,
  Dropdown,
  Grid,
  Header,
  Icon,
  Image,
  Modal,
  Popup,
  Segment,
} from "semantic-ui-react";
import { getToken } from "../../utils/auth";
import { ChatApi } from "../../utils/chat_api";
import { isImage, isVideo } from "../../utils/helpers";
import ChatBoxContent from "../imageUpload/chatBoxContent";
import audioSrc from "../../assets/media/Ring.wav";
import videoIcon from "../../../src/assets/images/videoIcon.png";

const MessageBox = ({
  currentChat,
  opponent,
  fakeUserId,
  chat,
  session,
  appendChatHistory,
  refreshChat,
  userDetails,
  fakeUser,
  currentChatRoom,
}) => {
  const videoRef = useRef();
  const [messages, setMessages] = useState([]);
  const messagesEndRef = useRef(null);
  const [previewUrl, setPreviewUrl] = useState("");
  const [videoPlay, setVideoPlay] = useState(false);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const scrollToBottom = useCallback(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messagesEndRef]);

  useEffect(() => {
    if (videoRef.current?.currentTime === videoRef.current?.duration) {
      setVideoPlay(false);
    }
  }, [videoRef.current?.currentTime]);

  useEffect(() => {
    setTimeout(() => {
      scrollToBottom();
    }, 1000);
  }, [messages, scrollToBottom]);

  useEffect(() => {
    if (currentChat !== null && currentChat !== "") {
      refreshChat();
    }
  }, []);

  useEffect(() => {
    setMessages(_.sortBy(currentChat, (item) => new moment(item.created_at)));
  }, [currentChat]);

  useEffect(() => {
    if (!currentChat) return;
    let unSubscribingRoom;
    const fn = async () => {
      const roomId = currentChat[0]?.room?.id;
      if (roomId) {
        const observerRoom = await ChatApi.addMessageReceiveListener(
          getToken(),
          fakeUserId,
          roomId
        );

        unSubscribingRoom = observerRoom.subscribe(async (result) => {
          const message = {
            id: result.data.onNewMessage.message?.id,
            created_at: result.data.onNewMessage.message.timestamp,
            room: result.data.onNewMessage.message.roomId,
            message: result.data.onNewMessage.message.content,
            recipient_id: result.data.onNewMessage.message.userId.id,
          };

          appendChatHistory(roomId, message);
          const sound = new Audio(audioSrc);
          await sound.play();
        });
      }
    };
    fn();
    return () => {
      if (unSubscribingRoom) {
        unSubscribingRoom.unsubscribe();
      }
    };
  }, [currentChat, fakeUserId]);

  const showVideo = (url) => {
    setPreviewUrl(url);
    setShowPreviewModal(true);
  };

  const handleMessageDelete = async (message) => {
    const res = await ChatApi.deleteMessage({ messageId: message.id });
    if (res?.success) {
      refreshChat();
    }
  };

  const user = opponent;
  const processContent = (string) => {
    const parts = string.split(" ");
    const url = parts[0];

    const content = parts?.slice(1)?.join(" ");
    return { url: url, msg: content };
  };

  return (
    <>
      <Segment
        style={{
          maxHeight: window.innerHeight - 290,
          height: window.innerHeight - 290,
          overflowX: "hidden",
          marginBottom: 0,
        }}
        color="teal"
        id="parentContaier"
      >
        <Grid columns={2} className="asdasdasdada">
          {_.map(messages, (value) => {
            let content = value.message || value.recieved_message;

            let url = content?.includes("media/chat_files");
            let map = content?.includes("lat") && content?.includes("lng");
            const date = value.created_at ? new Date(value.created_at) : "";
            const { url: uri, msg } = processContent(content);
            const video = isVideo(uri);
            const popUp = (
              <>
                {url && value?.messageType !== "G" ? (
                  <>
                    <Popup
                      trigger={
                        <Segment
                          size="tiny"
                          className={`${
                            value.recipient_id !== opponent?.user_id
                              ? value.recipient_id === null
                                ? "leftSide"
                                : "rightSide"
                              : "leftSide"
                          }`}
                          style={{
                            position: "relative",
                            background:
                              value.recipient_id !== opponent?.user_id
                                ? value.recipient_id === null
                                  ? "#E7E7E7"
                                  : "#79BE80"
                                : "#E7E7E7",
                            paddingTop: url ? 10 : 2,
                          }}
                        >
                          <Dropdown
                            pointing={"right"}
                            style={{
                              position: "absolute",
                              top: "5px",
                              right: "5px",
                            }}
                            trigger={<Icon name="ellipsis vertical" />}
                            options={[
                              {
                                key: "delete",
                                text: "Delete",
                                value: "delete",
                              },
                            ]}
                            onChange={(e, data) => {
                              if (data.value === "delete") {
                                handleMessageDelete(value);
                              }
                            }}
                          />

                          <ChatBoxContent
                            showVideo={showVideo}
                            content={uri}
                            privatePhotoRequestId={value.privatePhotoRequestId}
                            requestStatus={value.requestStatus}
                            messageType={value?.messageType}
                            height={video ? "100px" : "185px"}
                            width={"200px"}
                            id={value.id}
                            recipient_id={value?.recipient_id}
                            user_id={opponent?.user_id}
                          />
                          <Divider hidden fitted />
                        </Segment>
                      }
                      content={user && user.name ? user.name : "Undefined"}
                      basic
                      mouseEnterDelay={1000}
                      on="hover"
                      size={"mini"}
                    />
                    <Popup
                      trigger={
                        <Segment
                          size="tiny"
                          className={`${
                            value.recipient_id !== opponent?.user_id
                              ? value.recipient_id === null
                                ? "leftSide"
                                : "rightSide"
                              : "leftSide"
                          }`}
                          style={{
                            position: "relative",
                            background:
                              value.recipient_id !== opponent?.user_id
                                ? value.recipient_id === null
                                  ? "#E7E7E7"
                                  : "#79BE80"
                                : "#E7E7E7",
                            paddingTop: url ? 10 : 2,
                          }}
                        >
                          <ChatBoxContent
                            showVideo={showVideo}
                            content={msg}
                            privatePhotoRequestId={value.privatePhotoRequestId}
                            requestStatus={value.requestStatus}
                            messageType={value?.messageType}
                            height={"185px"}
                            width={"200px"}
                            id={value.id}
                            languageId={value?.languageIdCode}
                            recipient_id={value?.recipient_id}
                            user_id={opponent?.user_id}
                          />
                          <Divider hidden fitted />
                        </Segment>
                      }
                      content={user && user.name ? user.name : "Undefined"}
                      basic
                      mouseEnterDelay={1000}
                      on="hover"
                      size={"mini"}
                    />
                  </>
                ) : (
                  <Popup
                    trigger={
                      <Segment
                        size="tiny"
                        className={`${
                          value.recipient_id !== opponent?.user_id
                            ? value.recipient_id === null
                              ? "leftSide"
                              : "rightSide"
                            : "leftSide"
                        }`}
                        style={{
                          position: "relative",
                          background:
                            value.recipient_id !== opponent?.user_id
                              ? value.recipient_id === null
                                ? "#E7E7E7"
                                : "#79BE80"
                              : "#E7E7E7",
                          paddingTop: url ? 10 : 2,
                        }}
                      >
                        {value.sender && (
                          <Dropdown
                            pointing={"right"}
                            style={{
                              position: "absolute",
                              top: "5px",
                              right: "5px",
                            }}
                            trigger={<Icon name="ellipsis vertical" />}
                            options={[
                              {
                                key: "delete",
                                text: "Delete",
                                value: "delete",
                              },
                            ]}
                            onChange={(e, data) => {
                              if (data.value === "delete") {
                                handleMessageDelete(value);
                              }
                            }}
                          />
                        )}

                        <ChatBoxContent
                          showVideo={showVideo}
                          content={value.message || value.recieved_message}
                          privatePhotoRequestId={value.privatePhotoRequestId}
                          requestStatus={value.requestStatus}
                          messageType={value?.messageType}
                          height={"185px"}
                          width={"200px"}
                          id={value.id}
                          languageId={value?.languageIdCode}
                          recipient_id={value?.recipient_id}
                          user_id={opponent?.user_id}
                        />
                        <Divider hidden fitted />
                      </Segment>
                    }
                    content={user && user.name ? user.name : "Undefined"}
                    basic
                    mouseEnterDelay={1000}
                    on="hover"
                    size={"mini"}
                  />
                )}
              </>
            );
            if (value.recipient_id == opponent?.user_id) {
              return value?.id ? (
                <Grid.Row
                  textAlign={value.url ? "center" : "left"}
                  key={value.id}
                >
                  <Grid.Column width={url ? 6 : 8}>
                    <Header
                      sub
                      floated="left"
                      content={`${fakeUser?.fullName || ""}`}
                    />
                    <Header
                      sub
                      floated="left"
                      style={{ color: "#3A3A3A" }}
                      content={`${date ? date.toLocaleString() : ""}`}
                    />
                    {popUp}
                    <Header
                      sub
                      floated="right"
                      style={{ color: "#3A3A3A" }}
                      content={`${value?.languageIdCode || ""}`}
                    />
                  </Grid.Column>

                  <Grid.Column></Grid.Column>
                </Grid.Row>
              ) : null;
            } else {
              const user = opponent;
              return value?.id ? (
                <Grid.Row
                  textAlign={value.url ? "center" : "left"}
                  key={value.id}
                >
                  <Grid.Column></Grid.Column>
                  <Grid.Column>
                    <Header
                      sub
                      floated="right"
                      content={`${user?.name || ""}`}
                    />
                    <Header
                      sub
                      floated="right"
                      style={{ color: "#3A3A3A", fontWeight: 400 }}
                      content={`${date ? date.toLocaleString() : ""}`}
                    />
                    {popUp}
                    <Header
                      sub
                      floated="right"
                      style={{ color: "#3A3A3A" }}
                      content={`${value?.languageIdCode || ""}`}
                    />
                  </Grid.Column>
                </Grid.Row>
              ) : null;
            }
          })}
        </Grid>
        <div style={{ float: "left", clear: "both" }} />
        <div ref={messagesEndRef} />
      </Segment>
      <Modal
        onClose={() => setShowPreviewModal(false)}
        open={showPreviewModal}
        size={"small"}
        style={{
          zIndex: 9999,
        }}
      >
        {isImage(previewUrl) ? (
          <Image src={previewUrl} style={{ width: "320" }} />
        ) : (
          <>
            {!videoPlay ? (
              <div
                style={{
                  position: "absolute",
                  height: window?.innerHeight / 2,
                  width: "inherit",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Image
                  src={videoIcon}
                  style={{ height: "40px", width: "40px", marginTop: "5px" }}
                />
              </div>
            ) : null}
            <video
              controls
              style={{ width: "inherit", maxHeight: window?.innerHeight / 2 }}
              ref={videoRef}
              onClick={() => setVideoPlay(!videoPlay)}
            >
              <source src={previewUrl} />
            </video>
          </>
        )}
      </Modal>
    </>
  );
};

export default memo(MessageBox);
