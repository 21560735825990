import { adminsActionTypes } from "../../constants/actions/admins";
import { sessionActionTypes } from "../../constants/actions/session";
import { Api } from "../../utils/api";

const messageHeader = "API request failed";

export function getAdminsList(
  limit,
  offset,
  ordered,
  orderedField,
  fields = [],
  search = ""
) {
  return function (dispatch) {
    dispatch({
      type: adminsActionTypes.GET_ADMINS_LIST_REQUEST,
    });
    return Api.getAdmins(limit, offset, ordered, orderedField, fields, search)
      .then((response) => {
        var errorMessage =
          "Unknown error. HTTP Status code: " + response.status;
        if (response.status === 401) {
          dispatch({
            type: adminsActionTypes.GET_ADMINS_LIST_FAILURE,
            data: { errors: ["Authorization failed"], header: messageHeader },
          });
          dispatch({
            type: sessionActionTypes.LOGOUT_REQUEST,
          });
          return;
        }
        if (response.status !== 200) {
          dispatch({
            type: adminsActionTypes.GET_ADMINS_LIST_FAILURE,
            data: { errors: [errorMessage], header: messageHeader },
          });
          return;
        }
        response
          .json()
          .then((json) => {
            if (!json.results) {
              dispatch({
                type: adminsActionTypes.GET_ADMINS_LIST_FAILURE,
                data: {
                  errors: ["Unexpected API response"],
                  header: messageHeader,
                },
              });
              return;
            }
            dispatch({
              type: adminsActionTypes.GET_ADMINS_LIST_SUCCESS,
              data: {
                admins: json.results,
                next: json.next,
                previous: json.previous,
                currentPageSize: "",
                offset: "",
                size: json.count,
              },
            });
          })
          .catch((error) => {
            dispatch({
              type: adminsActionTypes.GET_ADMINS_LIST_FAILURE,
              data: { errors: [error], header: messageHeader },
            });
            return error;
          });
      })
      .catch((error) => {
        console.log("fail", error);
        dispatch({
          type: adminsActionTypes.GET_ADMINS_LIST_FAILURE,
          data: { errors: [error], header: messageHeader },
        });
      });
  };
}

export function sortByAdminsList(
  limit,
  offset,
  ordered,
  orderedField = undefined,
  fields = []
) {
  return function (dispatch) {
    dispatch({
      type: adminsActionTypes.GET_ADMINS_LIST_REQUEST,
    });
    return Api.sortUsersByRoles(
      limit,
      offset,
      ordered,
      orderedField,
      "admin",
      fields
    )
      .then((response) => {
        var errorMessage =
          "Unknown error. HTTP Status code: " + response.status;
        if (response.status === 401) {
          dispatch({
            type: adminsActionTypes.GET_ADMINS_LIST_FAILURE,
            data: { errors: ["Authorization failed"], header: messageHeader },
          });
          dispatch({
            type: sessionActionTypes.LOGOUT_REQUEST,
          });
          return;
        }
        if (response.status !== 200) {
          dispatch({
            type: adminsActionTypes.GET_ADMINS_LIST_FAILURE,
            data: { errors: [errorMessage], header: messageHeader },
          });
          return;
        }
        response
          .json()
          .then((json) => {
            if (!json.results) {
              dispatch({
                type: adminsActionTypes.GET_ADMINS_LIST_FAILURE,
                data: {
                  errors: ["Unexpected API response"],
                  header: messageHeader,
                },
              });
              return;
            }
            dispatch({
              type: adminsActionTypes.GET_ADMINS_LIST_SUCCESS,
              data: {
                admins: json.results,
                next: json.next,
                previous: json.previous,
                currentPageSize: "",
                offset: "",
                size: json.count,
              },
            });
          })
          .catch((error) => {
            dispatch({
              type: adminsActionTypes.GET_ADMINS_LIST_FAILURE,
              data: { errors: [error], header: messageHeader },
            });
            return error;
          });
      })
      .catch((error) => {
        console.log("fail", error);
        dispatch({
          type: adminsActionTypes.GET_ADMINS_LIST_FAILURE,
          data: { errors: [error], header: messageHeader },
        });
      });
  };
}

export const getAdminListByAdminId = (id) => {
  return function (dispatch) {
    Api.getCreatedByListByAdminId(id, "admin").then((res) => {
      if (!res.results) {
        dispatch({
          type: adminsActionTypes.GET_ADMINS_LIST_FAILURE,
          data: {
            errors: ["Unexpected API response"],
            header: messageHeader,
          },
        });
        return;
      }
      dispatch({
        type: adminsActionTypes.GET_ADMINS_LIST_SUCCESS,
        data: {
          admins: res.results,
          next: res.next,
          previous: res.previous,
          currentPageSize: "",
          offset: "",
          size: res.count,
        },
      });
    });
  };
};

export function onSearchValueChange(value) {
  return function (dispatch) {
    dispatch({
      type: adminsActionTypes.SEARCH_VALUE_CHANGE,
      data: value,
    });

    if (value === "") {
      getAdminsList()(dispatch);
    }
  };
}

export function searchAdminsList(value) {
  return function (dispatch) {
    dispatch({
      type: adminsActionTypes.SEARCH_ADMINS_LIST_REQUEST,
    });

    return Api.searchUsers(value, "/admin/")
      .then((response) => {
        var errorMessage =
          "Unknown error. HTTP Status code: " + response.status;
        if (response.status === 401) {
          dispatch({
            type: adminsActionTypes.SEARCH_ADMINS_LIST_FAILURE,
            data: { errors: ["Authorization failed"], header: messageHeader },
          });
          dispatch({
            type: sessionActionTypes.LOGOUT_REQUEST,
          });
          return;
        }
        if (response.status !== 200) {
          dispatch({
            type: adminsActionTypes.SEARCH_ADMINS_LIST_FAILURE,
            data: { errors: [errorMessage], header: messageHeader },
          });
          return;
        }
        response
          .json()
          .then((json) => {
            if (!json) {
              dispatch({
                type: adminsActionTypes.SEARCH_ADMINS_LIST_FAILURE,
                data: {
                  errors: ["Unexpected API response"],
                  header: messageHeader,
                },
              });
              return;
            }
            dispatch({
              type: adminsActionTypes.SEARCH_ADMINS_LIST_SUCCESS,
              data: json,
            });
          })
          .catch((error) => {
            dispatch({
              type: adminsActionTypes.SEARCH_ADMINS_LIST_FAILURE,
              data: { errors: [error], header: messageHeader },
            });
            return error;
          });
      })
      .catch((error) => {
        console.log("fail", error);
        dispatch({
          type: adminsActionTypes.SEARCH_ADMINS_LIST_FAILURE,
          data: { errors: [error], header: messageHeader },
        });
      });
  };
}

export function clearAdmin() {
  return function (dispatch) {
    dispatch({
      type: adminsActionTypes.CLEAR_ADMIN,
    });
  };
}

export const updateAdminsField = (payload) => {
  return (dispatch) => {
    dispatch({
      type: adminsActionTypes.UPDATE_ADMINS_STORE_FIELD,
      data: payload,
    });
  };
};
