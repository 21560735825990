import { contactActionTypes } from "../constants/actions/contactUs";
import { isAuthenticated } from '../utils/auth';

const initialState = {
    isAuthFailed: false,
    isAuthenticated: isAuthenticated(),    
    email: "",
    name: "",
    contactus: {
        email: "",
        password: "",
        repeat_password: "",
        name: "",
        email: "",
        message: "",
        invitation_key: "",
        finished: false,
        failed: false,      
    }
};

export default function contact(state = initialState, action) {
    
    switch (action.type) {
        case contactActionTypes.CONTACT_US_ACTION:
            
            return {
                ...state,
                contactus: {
                    name: "",
                    email: "",
                    message: "",
                    invitation_key: "",
                    finished: false
                }
            };
    case contactActionTypes.CONTACT_US_NAME_ACTION:
                return {
                    ...state,
                    contactus: {
                        ...state.contactus,
                        name: action.data.name
                    }
                };
    case contactActionTypes.CONTACT_US_EMAIL_ACTION:
                return {
                    ...state,
                    contactus: {
                        ...state.contactus,
                        email: action.data.email
                    }
                };
    case contactActionTypes.CONTACT_US_MESSAGE_ACTION:
                return {
                    ...state,
                    contactus: {
                        ...state.contactus,
                        message: action.data.message
                    }
                };
    case contactActionTypes.CONTACT_US_ACTION_SAVE:
                return {
                    ...state,
                    contactus: {
                        ...state.contactus,
                        finished: true
                    }
                };
        default:
            return state;
    }
}