import { memo, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllAdmins,
  //   getModeratorListByAdminId,
} from "../../actions/moderators";

import { Button, Dropdown, Grid, Image, Input, Popup } from "semantic-ui-react";
import downArrow from "../../assets/images/downArrow.png";
import { debounce } from "lodash";

const CreatedByFilter = ({ onFilter, onClearFilter }) => {
  const [filterMenu, setFilterMenu] = useState(false);
  const [filter, setFilter] = useState({});
  const [checkAdminName, setCheckAdminName] = useState("");

  const selectAdmins = useSelector((state) => state.moderators.alladmins);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllAdmins());
  }, []);

  const adminsOptions = useMemo(
    () =>
      selectAdmins?.map(({ id, firstName, username }, index) => ({
        key: index,
        value: id,
        text: firstName || username,
      })),
    [selectAdmins?.length, selectAdmins[0]]
  );

  const handleChangeName = debounce((e, data) => {
    const username = data.value;
    const lowerSelectedName = username?.toLowerCase();

    const filteredAdmin = selectAdmins?.find(
      (user) =>
        user?.username?.toLowerCase().includes(lowerSelectedName) ||
        user?.fullName?.toLowerCase().includes(lowerSelectedName) ||
        user?.firstName?.toLowerCase().includes(lowerSelectedName)
    );

    if (filteredAdmin) {
      setFilter((prev) => ({
        ...prev,
        name: filteredAdmin?.firstName || filteredAdmin?.username,
      }));
      setCheckAdminName(
        filteredAdmin?.username ||
          filteredAdmin?.fullName ||
          filteredAdmin?.firstName
      );
    }
  }, 200);

  const onFilterHandler = async (e) => {
    e.preventDefault();
    const lowerCheckAdminName = checkAdminName?.toLowerCase();
    const filteredAdmin = selectAdmins?.find(
      (user) =>
        user?.username?.toLowerCase().includes(lowerCheckAdminName) ||
        user?.fullName?.toLowerCase().includes(lowerCheckAdminName) ||
        user?.firstName?.toLowerCase().includes(lowerCheckAdminName)
    );

    if (filteredAdmin?.id) {
      onFilter({
        filteredByName:
          filteredAdmin?.username ||
          filteredAdmin?.fullName ||
          filteredAdmin?.firstName,
        filteredById: filteredAdmin.id,
      });
    }

    setFilterMenu(false);
  };

  const onClearFilterHandler = (e) => {
    e.preventDefault();
    onClearFilter();
    setFilterMenu(false);
  };

  return (
    <Popup
      on={"click"}
      position="bottom right"
      open={filterMenu}
      onOpen={() => setFilterMenu(true)}
      onClose={() => setFilterMenu(false)}
      style={{ zIndex: "20000" }}
      trigger={
        <Dropdown
          text="Created by"
          className="icon sortBox"
          floating
          labeled
          button
          icon={
            <div className="iconBox">
              <Image src={downArrow} width="10px" height="5px" />
            </div>
          }
        />
      }
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "1rem",
        }}
        className="dropContainer"
      >
        <p>Admins's Name:</p>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
          className="dropdownView country-dropdown"
        >
          <Dropdown
            selection
            options={adminsOptions}
            onChange={(e, data) => {
              const selectedAdmin = adminsOptions.find(
                (item) => item.value === data?.value
              );
              setFilter((prev) => ({
                ...prev,
                name: selectedAdmin?.text,
              }));
              setCheckAdminName(selectedAdmin?.text);
            }}
            text={`${
              adminsOptions?.find((item) => item?.text === filter?.name)
                ?.text || "Select here"
            }`}
            value={filter?.name}
            className="icon filterBox"
            floating
            labeled
            button
            icon={
              <div className="iconBox">
                <Image src={downArrow} width="10px" height="5px" />
              </div>
            }
          />
          <p style={{ margin: "0 12px" }}>Or</p>
          <Input
            fluid
            placeholder="Search Name"
            className="filterInput searchBox"
            onChange={handleChangeName}
          />
        </div>
      </div>
      <Grid.Row>
        <Grid.Column width={7}>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              marginTop: "1.5rem",
            }}
          >
            <Button className="ui blue" onClick={onClearFilterHandler}>
              Clear Filter
            </Button>
            <Button className="filterButton" onClick={onFilterHandler}>
              Filter
            </Button>
          </div>
        </Grid.Column>
      </Grid.Row>
    </Popup>
  );
};

export default memo(CreatedByFilter);
