import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import MainMenu from "../../components/mainMenu";
import Users from "../../components/users/list";

import {
  getUsersList,
  removeUser,
  hideError,
  clearUser,
  sortUsersList,
  onSearchValueChange,
  searchUsersList,
} from "../../actions/users";
import {
  addFakeUsers,
  selectChatFakeUser,
  startNewChat,
} from "../../actions/chat";
import { setActiveMenuPosition } from "../../actions/menu";
import { logoutAction } from "../../actions/session";
import { clearModerator, getModeratorsList } from "../../actions/moderators";
import { useEffect } from "react";

const UsersView = (props) => {
  useEffect(() => {
    props.clearUser();
    if (props.users.users && props.users.users?.length === 0) {
      props.getUsersList(
        props.users.offset,
        props.users.ordered,
        props.users.orderedField,
        props.users.direction
      );
    }
    if (props.menu.activeMenu !== "users") {
      props.setActiveMenuPosition("users");
    }
  }, []);

  if (!props.session.isAuthenticated) {
    return (
      <Redirect to={{ pathname: "/signIn", state: { from: props.location } }} />
    );
  }
  return (
    <div>
      <MainMenu
        needShowPrivateItems={props.session.isAuthenticated}
        menu={props.menu}
        onLogout={props.onLogout}
        setActiveMenuPosition={props.setActiveMenuPosition}
        userDetails={props.session.userDetails}
      />
      <Users
        users={props.users}
        onRemove={props.removeUser}
        getUsersList={props.getUsersList}
        hideError={props.hideError}
        chat={props.chat}
        setActiveMenuPosition={props.setActiveMenuPosition}
        startNewChat={props.startNewChat}
        sortUsersList={props.sortUsersList}
        onSearchValueChange={props.onSearchValueChange}
        searchUsersList={props.searchUsersList}
        userDetails={props.session.userDetails}
        moderators={props.moderators}
        getModeratorsList={props.getModeratorsList}
        addFakeUsers={props.addFakeUsers}
        selectChatFakeUser={props.selectChatFakeUser}
        presets={props.presets}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    menu: state.menu,
    users: state.users,
    session: state.session,
    moderators: state.moderators,
    chat: { ...state.chat },
    presets: state.presets,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    clearUser: () => {
      clearUser()(dispatch);
    },
    getUsersList: (offset, ordered, orderedField) => {
      getUsersList(offset, ordered, orderedField)(dispatch);
    },
    startNewChat: (fakeUserId, realUser) => {
      startNewChat(fakeUserId, realUser)(dispatch);
    },
    onSearchValueChange: (e, data) => {
      onSearchValueChange(data?.value || "")(dispatch);
    },
    searchUsersList: (value) => {
      searchUsersList(value)(dispatch);
    },
    sortUsersList: (offset, prevColumn, column, prevDirection) => {
      sortUsersList(offset, prevColumn, column, prevDirection)(dispatch);
    },
    removeUser: (e, data) => {
      removeUser(data.userId)(dispatch);
    },
    hideError: () => {
      hideError()(dispatch);
    },
    setActiveMenuPosition(activeMenu) {
      setActiveMenuPosition(activeMenu)(dispatch);
    },
    onLogout() {
      logoutAction()(dispatch);
    },
    clearModerator: () => {
      clearModerator()(dispatch);
    },
    getModeratorsList: (offset, ordered, orderedField, fields, search) => {
      getModeratorsList(
        offset,
        ordered,
        orderedField,
        fields,
        search
      )(dispatch);
    },
    addFakeUsers: (data) => {
      addFakeUsers(data)(dispatch);
    },
    selectChatFakeUser: (data) => {
      selectChatFakeUser(data)(dispatch);
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersView);
