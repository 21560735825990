
import React, { Component, useEffect, useState } from "react";
import "./slider.css"
import LeftVetor from "../../../assets/images/leftSideVector.png"
import RightVetor from "../../../assets/images/rightSideVector.png"

export default class Slider extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            prevDisable: true,
            nextDisable:
                true
        };
    }

    componentDidMount() {
        // this.checkButtons(this.refs.offsetWidth, this.refs.scrollWidth);
    }
    checkButtons = (offsetWidthValue, scrollWidthValue) => {
        this.setState({
            prevDisable: this.refs.scrollLeft <= 0 ? true : false,
            nextDisable:
                this.refs.scrollLeft + offsetWidthValue >= scrollWidthValue ? true : false
        });
    };


    renderImage = (src) => {
        return <img
            width={"13px"}
            height={"18px"}
            src={src}
            alt=""
        />
    }

    render() {
        const offsetWidthValue = this.refs.offsetWidth,
            scrollWidthValue = this.refs.scrollWidth;
        return (
            <div
                className="slider-container"
                ref={el => {
                    this.refs = el;
                }}
            >
                <div className="slider-wrapper">
                <div
                    // className={`btn prev ${this.state.prevDisable ? "disable" : ""}`}
                    className={`btn prev `}
                    // disabled={this.state.prevDisable}
                    onClick={() => {
                        this.refs.scrollLeft -= offsetWidthValue / 2;
                        this.checkButtons(offsetWidthValue, scrollWidthValue);
                    }}
                >
                    {this.renderImage(LeftVetor)}

                </div>
                    
                    {this.props.children}
                    
                <div
                    // className={`btn next ${this.state.nextDisable ? "disable" : ""}`}
                    className={`btn next `}
                    // disabled={this.state.nextDisable}
                    onClick={() => {
                        this.refs.scrollLeft += offsetWidthValue / 2;
                        this.checkButtons(offsetWidthValue, scrollWidthValue);
                    }}
                >
                    {this.renderImage(RightVetor)}


                </div>
                    </div>

            </div>
        );
    }
}