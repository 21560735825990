import { chatActionTypes } from "../../constants/actions/chat";
import { pendingChatActionTypes } from "../../constants/actions/pendingChat";
import { Api, baseUrl } from "../../utils/api";
import { ChatApi } from "../../utils/chat_api";
import { fakeUsersActionTypes } from "../../constants/actions/fakeUsers";
import { sessionActionTypes } from "../../constants/actions/session";
import { usersActionTypes } from "../../constants/actions/users";
import AddNewPhoto from "../../components/imageUpload/addNewPhoto";
import { getToken } from "../../utils/auth";
import audioSrc from "../../assets/media/Ring.wav";
import { workersActionTypes } from "../../constants/actions/workers";
import store from "../../store";
import { reFetchWorkerFakeUsers } from "../fakeUsers";
import UserApi from "../../utils/users";
import { sameDayMessagesStats } from "../workers";
const messageHeader = "API request failed";
export function updateChatFakeUsers() {
  return function (dispatch) {
    dispatch({
      type: chatActionTypes.CHAT_NEED_UPDATE_FAKE_USERS,
    });
  };
}

export function selectChatFakeUser(id) {
  return function (dispatch) {
    dispatch({
      type: chatActionTypes.CHAT_SELECT_FAKE_USER,
      data: id,
    });
  };
}

export function addFakeUsers(fakeUser) {
  return function (dispatch) {
    dispatch({
      type: chatActionTypes.ADD_FAKE_USERS,
      data: fakeUser,
    });
  };
}

export function selectChat(chatId, opponent_id, dialogues) {
  return function (dispatch) {
    dispatch({
      type: chatActionTypes.SELECT_CHAT,
      data: { chatId, opponent_id, dialogues },
    });
  };
}

export function setErrorChat(header, errors) {
  return function (dispatch) {
    dispatch({
      type: chatActionTypes.SEND_GIFT_ERROR,
      data: { header, errors },
    });
  };
}

export function getDynamicChatLength() {
  return function (dispatch) {
    return ChatApi.chatMessageLengthSetting()
      .then((res) => {
        dispatch({
          type: chatActionTypes.GET_DYNAMIC_CHAT_LENGTH_SUCCESS,
          data: res.data.chatMessageLengthSetting[0],
        });
      })
      .catch((err) => {
        console.error(
          "getDynamicChatLength chatMessageLengthSetting ~ err:",
          err
        );
        dispatch({
          type: chatActionTypes.GET_DYNAMIC_CHAT_LENGTH_FAILURE,
          data: err,
        });
      });
  };
}

export function checkWorkerCanChat() {
  return function (dispatch) {
    return ChatApi.canWorkerChat()
      .then((res) => {
        dispatch({
          type: chatActionTypes.CHECK_WORKER_CAN_CHAT_SUCCESS,
          data: res.data.chatQueueSettings,
        });
      })
      .catch((err) => {
        console.error("checkWorkerCanChat canWorkerChat ~ err:", err);
        dispatch({
          type: chatActionTypes.CHECK_WORKER_CAN_CHAT_FAILURE,
          data: err,
        });
      });
  };
}

export function checkWorkerCanPaste() {
  return function (dispatch) {
    return ChatApi.canWorkerPaste()
      .then((res) => {
        dispatch({
          type: chatActionTypes.CHECK_WORKER_CAN_PASTE_SUCCESS,
          data: res.data.chatPasteSettings,
        });
      })
      .catch((err) => {
        console.error("checkWorkerCanPaste canWorkerPaste ~ err:", err);
        dispatch({
          type: chatActionTypes.CHECK_WORKER_CAN_PASTE_FAILURE,
          data: err,
        });
      });
  };
}

export function getChatMinCharLen() {
  return function (dispatch) {
    return ChatApi.canWorkerPaste()
      .then((res) => {
        dispatch({
          type: chatActionTypes.CHECK_WORKER_CAN_PASTE_SUCCESS,
          data: res.data.chatPasteSettings,
        });
      })
      .catch((err) => {
        console.error("checkWorkerCanPaste canWorkerChat ~ err:", err);
        dispatch({
          type: chatActionTypes.CHECK_WORKER_CAN_PASTE_FAILURE,
          data: err,
        });
      });
  };
}

export function changeFakeUserOnlineStatus(uid, online) {
  return function (dispatch) {
    dispatch({
      type: chatActionTypes.CHAT_CHANGE_FAKE_USER_STATUS_REQUEST,
      data: { uid: uid, online: online },
    });
    const payload = { isOnline: online };
    return Api.updateUser(uid, payload)
      .then((response) => {
        if (response.status === 401) {
          dispatch({
            type: chatActionTypes.CHAT_CHANGE_FAKE_USER_STATUS_FAILURE,
            data: { uid: uid, online: online },
          });
          dispatch({
            type: sessionActionTypes.LOGOUT_REQUEST,
          });
          return;
        }

        if (response.status === 404) {
          dispatch({
            type: chatActionTypes.CHAT_CHANGE_FAKE_USER_STATUS_FAILURE,
            data: { uid: uid, online: online },
          });
          return;
        }

        // if (response.status !== 200) {
        //   dispatch({
        //     type: chatActionTypes.CHAT_CHANGE_FAKE_USER_STATUS_FAILURE,
        //     data: { uid: uid, online: online },
        //   });
        //   return;
        // }

        dispatch({
          type: chatActionTypes.CHAT_CHANGE_FAKE_USER_STATUS_SUCCESS,
          data: { uid: uid, online: online },
        });
      })
      .catch((error) => {
        console.log("fail", error);
        dispatch({
          type: usersActionTypes.EDIT_USER_DETAILS_FAILURE,
          data: { uid: uid, online: online },
        });
      });
  };
}

export function changeChatMessage(chatMessage) {
  return function (dispatch) {
    dispatch({
      type: chatActionTypes.CHANGE_CHAT_MESSAGE,
      data: chatMessage,
    });
  };
}

export function sendChatMessage(
  chatId,
  fromName,
  fromUserId,
  recipientToken,
  type,
  message
) {
  return function (dispatch) {
    dispatch({
      type: chatActionTypes.SEND_CHAT_MESSAGE_REQUEST,
    });
    return Api.sendChatMessage(
      chatId,
      fromName,
      fromUserId,
      recipientToken,
      type,
      message
    )
      .then((response) => {
        const errorMessage =
          "Unknown error. HTTP Status code: " + response.status;
        if (response.status === 401) {
          dispatch({
            type: chatActionTypes.SEND_CHAT_MESSAGE_FAILURE,
            data: { header: messageHeader },
          });
          dispatch({
            type: sessionActionTypes.LOGOUT_REQUEST,
          });
          return;
        }

        if (response.status !== 200) {
          dispatch({
            type: chatActionTypes.SEND_CHAT_MESSAGE_FAILURE,
            data: { errors: [errorMessage], header: messageHeader },
          });
          return;
        }

        dispatch({
          type: chatActionTypes.SEND_CHAT_MESSAGE_SUCCESS,
        });
      })
      .catch((error) => {
        console.log("fail", error);
        dispatch({
          type: chatActionTypes.SEND_CHAT_MESSAGE_FAILURE,
          data: { header: messageHeader },
        });
      });
  };
}

export function readChatMessages(uid, chatId, messages) {
  return function (dispatch) {
    dispatch({
      type: chatActionTypes.READ_CHAT_MESSAGES_REQUEST,
      data: { messages, chatId },
    });
    return Api.readChatMessages(uid, chatId, messages)
      .then((response) => {
        const errorMessage =
          "Unknown error. HTTP Status code: " + response.status;
        if (response.status === 401) {
          dispatch({
            type: chatActionTypes.READ_CHAT_MESSAGES_FAILURE,
            data: { header: messageHeader },
          });
          dispatch({
            type: sessionActionTypes.LOGOUT_REQUEST,
          });
          return;
        }

        if (response.status !== 200) {
          dispatch({
            type: chatActionTypes.READ_CHAT_MESSAGES_FAILURE,
            data: { errors: [errorMessage], header: messageHeader },
          });
          return;
        }

        dispatch({
          type: chatActionTypes.READ_CHAT_MESSAGES_SUCCESS,
        });
      })
      .catch((error) => {
        console.log("fail", error);
        dispatch({
          type: chatActionTypes.READ_CHAT_MESSAGES_FAILURE,
          data: { header: messageHeader },
        });
      });
  };
}

export function getStockImages(moderatorId) {
  return function (dispatch) {
    return UserApi.getStockImages(moderatorId)
      .then((response) => {
        dispatch({
          type: chatActionTypes.SET_STOCK_IMAGES,
          data: { images: response?.data?.stockImages },
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function saveNotes(chatId, notes) {
  return function (dispatch) {
    return Api.saveChatNotes(chatId, notes)
      .then((response) => {
        const errorMessage =
          "Unknown error. HTTP Status code: " + response.status;
        if (response.status === 401) {
          dispatch({
            type: sessionActionTypes.LOGOUT_REQUEST,
          });
          return;
        }

        if (response.status !== 200) {
          dispatch({
            type: chatActionTypes.SAVE_CHAT_NOTES_FAILURE,
            data: { errors: [errorMessage], header: messageHeader },
          });
        }
      })
      .catch((error) => {
        console.log("fail", error);
        dispatch({
          type: chatActionTypes.SAVE_CHAT_NOTES_FAILURE,
          data: { header: messageHeader },
        });
      });
  };
}

// export function startNewChat(fakeUserId, realUserId) {
//     return function(dispatch) {
//         return Api.startNewChat(fakeUserId, realUserId).then(response => {
//             const errorMessage = "Unknown error. HTTP Status code: " + response.status;
//             if (response.status === 401) {
//                 dispatch({
//                     type: sessionActionTypes.LOGOUT_REQUEST,
//                 });
//                 return;
//             }

//             if (response.status !== 200) {
//                 dispatch({
//                     type: chatActionTypes.START_NEW_CHAT_FAILURE,
//                     data: { errors: [errorMessage], header: messageHeader }
//                 });
//             }

//         }).catch(error => {
//             console.log('fail', error);
//             dispatch({
//                 type: chatActionTypes.START_NEW_CHAT_FAILURE,
//                 data: { header: messageHeader }
//             });
//         });
//     }
// }

export function startNewChat(fakeUserId, realUser) {
  return function (dispatch) {
    Api.likeUsers(fakeUserId, realUser.id)
      .then((res) => {
        ChatApi.createDialogue(
          fakeUserId,
          realUser.username,
          function (error, result) {
            if (error) {
              dispatch({
                type: chatActionTypes.START_NEW_CHAT_FAILURE,
                data: error.message.message,
              });
            } else {
              dispatch({
                type: chatActionTypes.START_NEW_CHAT_SUCCESS,
                data: { fakeUserId, dialog: result, realUser: realUser.id },
              });
              dispatch(cacheUser(result.friendId));
            }
          }
        );
      })
      .catch((err) =>
        dispatch({
          type: chatActionTypes.START_NEW_CHAT_FAILURE,
          message: `${err}`,
        })
      );
  };
}

export function informManualChat(roomId, moderatorId) {
  return function (dispatch) {
    Api.informChatStart(roomId, moderatorId)
      .then((res) => {
        dispatch({
          type: chatActionTypes.INFORM_MANUAL_CHAT_SUCCESS,
        });
      })
      .catch((err) =>
        dispatch({
          type: chatActionTypes.INFORM_MANUAL_CHAT_FAILURE,
          message: `${err}`,
        })
      );
    const workerId = store.getState()?.session?.userDetails?.id;
    const roles = store.getState()?.session.userDetails.roles;
    if (roles.includes("CHATTER")) {
      console.log("Fetch the Worker Stats", { workerId, roles });
      sameDayMessagesStats(workerId)(dispatch);
    }
  };
}

export function sendProfileVisit(realUserId, moderatorId) {
  return function (dispatch) {
    ChatApi.sendProfileVisit(realUserId, moderatorId)
      .then((res) => {
        dispatch({
          type: chatActionTypes.SEND_PROFILE_VISIT_SUCCESS,
        });
      })
      .catch((err) =>
        dispatch({
          type: chatActionTypes.SEND_PROFILE_VISIT_FAILURE,
          message: `${err}`,
        })
      );
  };
}

export function cacheUser(id) {
  return function (dispatch) {
    Api.getUser(id)
      .then((res) => {
        res.json().then((user) =>
          dispatch({
            type: chatActionTypes.CACHE_USER_INFO,
            data: { id, user },
          })
        );
      })
      .catch(console.warn);
  };
}

export function getAllDialogue(fakeUserId) {
  return function (dispatch) {
    // const external_id = loginResults.find(x => x.login === fakeUserIds).user_id
    const external_id = [];
    ChatApi.listChatDialogue(fakeUserId, external_id, (error, result) => {
      if (error) {
        console.warn(error);
      } else {
        dispatch({
          type: chatActionTypes.UPDATE_CHAT_DIALOGUE_LIST,
          data: { fakeUserId: fakeUserId, dialogues: result.items },
        });
        result.items.map((item) => cacheUser(item.friendId)(dispatch));
      }
    });
  };
}

export function getChatHistory(id, dialogueId, isChatter) {
  return function (dispatch) {
    ChatApi.getHistoryWithDialogue(id, dialogueId, isChatter)
      .then((messages) => {
        dispatch({
          type: chatActionTypes.UPDATE_CHAT_HISTORY,
          data: { id: dialogueId, messages },
        });
      })
      .catch(console.warn);

    ChatApi.privatePhotoRequestStatus(dialogueId)
      .then((res) => {
        dispatch({
          type: chatActionTypes.CHAT_PPR_STATUS,
          data: {
            id: dialogueId,
            details:
              res?.data?.privatePhotoRequestStatus === null
                ? { status: null }
                : {
                    ...res?.data?.privatePhotoRequestStatus,
                  },
          },
        });
      })
      .catch((err) => {
        console.error("Error Raised While ::privatePhotoRequestStatus:: ", err);
      });
  };
}

export function appendIncomingMessage(dialogueId, message) {
  return function (dispatch) {
    dispatch({
      type: chatActionTypes.APPEND_INCOMING_CHAT_HISTORY,
      data: { id: dialogueId, message },
    });
  };
}

export function sendAttachmentMessage(currentChat, userId, msg, params) {
  let messageType = msg ? params.type || "G" : "";
  return function (dispatch) {
    Api.uploadStockImageWithId(params.file)
      .then((message) => {
        if (message.success) {
          dispatch(
            sendTextMessage(
              currentChat,
              `${message.img} ${msg || ""}`,
              userId,
              messageType
            )
          );
        } else {
          dispatch({
            type: chatActionTypes.SEND_CHAT_MESSAGE_FAILURE,
            data: message.message,
          });
        }
      })
      .catch((err) =>
        dispatch({ type: chatActionTypes.SEND_CHAT_MESSAGE_FAILURE, data: err })
      );
  };
}

export function sendTextMessage(dialogueId, text, userId, msgType) {
  return function (dispatch, workers) {
    ChatApi.sendTextMessage(dialogueId, text, userId, msgType)
      .then((message) => {
        dispatch({
          type: chatActionTypes.APPEND_CHAT_HISTORY,
          data: { dialogueId, message },
        });

        dispatch({
          type: chatActionTypes.REMOVE_CURRENT_CHATS,
          fakeUserId: dialogueId,
        });

        if (workers) {
          reFetchWorkerFakeUsers()(dispatch, workers);
          window.location.reload();
        }
        sendNotification(message?.recipient_id);
      })
      .catch((err) => {
        dispatch({
          type: chatActionTypes.REMOVE_CURRENT_CHATS,
          fakeUserId: dialogueId,
        });
        console.warn(err);
      });
  };
}

export function removeCurrentChats(dialogueId) {
  return function (dispatch) {
    dispatch({
      type: chatActionTypes.REMOVE_CURRENT_CHATS,
      fakeUserId: dialogueId,
    });
  };
}

export function sendNotification(userId) {
  ChatApi.sendNotification(userId).catch(console.warn);
}

export function messageReceiveListener(userId, roomId) {
  return async function (dispatch) {
    const observerRoom = await ChatApi.addMessageReceiveListener(
      getToken(),
      userId,
      roomId
    );

    const unSubscribingRoom = observerRoom.subscribe(async (result) => {
      if (userId !== result.data.onNewMessage.message.userId.id) {
        const messageData = result.data.onNewMessage.message;
        const sound = new Audio(audioSrc);
        await sound.play();
        if (!store.getState().chat.fakeUserChatDialogues[userId]) {
          dispatch(getAllDialogue(userId));
        } else {
          const message = {
            id: messageData.id,
            created_at: messageData.timestamp,
            recipient_id: messageData.userId.id,
            message:
              messageData.userId.id !== userId ? messageData.content : "",
            url: "",
            ir: messageData.read,
            recieved_message:
              messageData.userId.id === userId ? messageData.content : "",
          };

          dispatch({
            type: chatActionTypes.APPEND_RECIEVED_MESSAGE_CHAT_HISTORY,
            data: {
              dialogueId: result.data.onNewMessage.message.roomId.id,
              message,
              userId,
            },
          });
        }

        dispatch({
          type: fakeUsersActionTypes.UPDATE_LATEST_TIME_FAKE_USERS,
          data: { userId },
        });
        dispatch({
          type: chatActionTypes.UPDATE_CHAT_DIALOGUE_LATEST_TIME,
          data: {
            dialogueId: result.data.onNewMessage.message.roomId.id,
            userId,
          },
        });

        dispatch({
          type: workersActionTypes.ADD_WORKER_MESSAGES_STATISTICS,
          data: { sentCount: 0, receivedCount: 1 },
        });
      } else {
        dispatch({
          type: workersActionTypes.ADD_WORKER_MESSAGES_STATISTICS,
          data: { sentCount: 1, receivedCount: 0 },
        });
      }
    });

    return unSubscribingRoom;
  };
}

export function createNotes(roomId, content, forRealUser, callback) {
  return async function (dispatch) {
    await ChatApi.createNotes(roomId, content, forRealUser).catch(console.warn);
    callback();
  };
}

export const getAllChatsInQueue = () => {
  return async (dispatch) => {
    try {
      const resp = await ChatApi.chatsInQueue();
      const chatsInQueue = resp.data.chatsInQueue;

      const userDetailPromises = [];

      chatsInQueue.forEach((item, index) => {
        userDetailPromises.push(
          UserApi.getUsersInRoom(item.roomId).then((response) => {
            if (response.data.room) {
              return UserApi.getUser(response.data.room.target.id).then(
                (resTarget) => {
                  if (!resTarget.data.user.isModerator) {
                    const newItem = {
                      ...item,
                      userDetails: resTarget.data.user,
                    };
                    chatsInQueue[index] = newItem;
                  } else {
                    return UserApi.getUser(response.data.room.userId.id).then(
                      (resUser) => {
                        const newItem = {
                          ...item,
                          userDetails: resUser.data.user,
                        };
                        chatsInQueue[index] = newItem;
                      }
                    );
                  }
                }
              );
            }
          })
        );
      });

      await Promise.all(userDetailPromises);

      dispatch({
        type: chatActionTypes.GET_CHATS_IN_QUEUE,
        data: chatsInQueue,
      });
    } catch (error) {
      console.error("Error fetching chats in queue:", error);
      throw new Error("Failed to fetch chats in queue");
    }
  };
};
