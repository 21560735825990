import React, { useEffect } from "react";
import { Grid, Image, Loader, Modal, Popup } from "semantic-ui-react";
import UserApi from "../../utils/users";
import styles from "./selectImageCont.module.css";
import { useState } from "react";
import videoIcon from "../../assets/images/videoIcon.png";
import cancelVector from "../../assets/images/closeBlackVector.png";
import { getBaseImageUrl, getImageUrl, isVideo } from "../../utils/helpers";
import { baseUrl } from "../../utils/api";

const SelectImageCont = ({
  isOpen,
  moderatorId,
  onSelect,
  setIsOpen,
  getStockImages,
  stockImages = [],
  children,
  position,
  modal,
}) => {
  // {console.log('moderatorIdmoderatorIdmoderatorId', children)}
  const [play, setPlay] = useState(false);

  const handleImageSelect = (image, dataId) => {
    // var xhr = new XMLHttpRequest({ withCredentials: true });
    // xhr.open("GET", image.getAttribute("src"));
    // xhr.responseType = "blob";
    // xhr.onerror = function () {
    //   console.log("Network error.");
    // };
    // xhr.onload = function () {
    //   if (xhr.status === 200) {
    //     onSelect(xhr.response, dataId);
    //   } else {
    //     console.log("Loading error:" + xhr.statusText);
    //   }
    // };
    // xhr.send();
    // console.log(image);

    onSelect(image, dataId);
  };

  useEffect(() => {
    getStockImages(moderatorId);
  }, [moderatorId]);

  const isImage = (url) => {
    const imgTypes = [
      ".png",
      ".jpg",
      ".jpeg",
      ".apng",
      ".avif",
      ".gif",
      ".jfif",
      ".pjpeg",
      ".pjp",
      ".svg",
      ".webp",
    ];
    return imgTypes.some((imgType) => String(url).includes(imgType));
  };

  const contentView = () => {
    return (
      <>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <p
            style={{
              fontSize: "20px",
              fontWeight: "700",
              color: "#333333",
              marginTop: "10px",
            }}
          >
            Images
          </p>
          <Image
            src={cancelVector}
            height={"25px"}
            width={"25px"}
            onClick={() => setIsOpen(false)}
          />
        </div>
        <Grid
          columns={3}
          style={{ width: "750px", height: window.innerHeight / 1.4 }}
        >
          <Grid.Row
            style={{
              height: window.innerHeight / 1.5,
              overflowY: "scroll",
              paddingTop: 0,
              paddingRight: "10px",
            }}
          >
            {stockImages?.map((image, indx) => (
              <Grid.Column>
                <div
                  key={`stockImage-${indx}`}
                  style={{
                    width: "190px",
                    height: "190px",
                    overflow: "none",
                    cursor: "pointer",
                    marginTop: "20px",
                  }}
                  // onClick={() => {
                  //   setOpenGift()
                  //   setGift(gift)
                  // }}
                  // onClick={() => handleGiftSend(gift)}
                >
                  {/* {isImage(image.file) ? <img
                    src={image.file}
                    alt={image?.id}
                    key={image?.id}
                    data-id={image?.id}
                    // alt={gift.giftName}
                    onClick={(e) => handleImageSelect(e.currentTarget)}
                    style={{
                      width: "230px", height: "230px", objectFit: "cover", borderRadius: "15px", boxShadow: "rgba(0, 0, 0, 0.15) 0px 4px 8px"
                    }}
                  /> : <div className={styles.vedio_cont} style={{ position: "relative" }}>
                    {!play ? <div style={{
                      position: "absolute",
                      height: "230px",
                      width: "230px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center"
                    }}>
                      <Image src={videoIcon} style={{
                        height: "25px",
                        width: "25px",
                        marginTop: "5px",
                        border: "none"
                      }} // onClick={this.props.onClick}
                      // onClick={() => showVideo(url)}
                      />
                    </div> : null}
                    <video src={image?.file}
                      height="230px"
                      width="230px"
                      alt={image?.id}
                      key={image?.id}
                      data-id={image?.id}
                      onClick={(e) => handleImageSelect(e.currentTarget)} />
                  </div>} */}
                  {isVideo(image?.file) ? (
                    <video
                      style={{ width: "100%", height: "290px" }}
                      autoPlay
                      muted
                      loop
                      alt={image?.id}
                      key={image?.id}
                      data-id={image?.id}
                      src={getBaseImageUrl(baseUrl, image?.file)}
                      onClick={(e) =>
                        handleImageSelect(e.currentTarget, image?.id)
                      }
                    >
                      <source
                        src={getBaseImageUrl(baseUrl, image?.file)}
                        type="video/mp4"
                      />
                    </video>
                  ) : (
                    <img
                      src={getBaseImageUrl(baseUrl, image?.file)}
                      alt={image?.id}
                      key={image?.id}
                      data-id={image?.id}
                      // alt={gift.giftName}
                      onClick={(e) =>
                        handleImageSelect(e.currentTarget, image?.id)
                      }
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        borderRadius: "15px",
                        boxShadow: "rgba(0, 0, 0, 0.15) 0px 4px 8px",
                      }}
                    />
                  )}
                </div>
              </Grid.Column>
            ))}
          </Grid.Row>
        </Grid>
      </>
    );
  };

  return (
    <>
      {!modal ? (
        <Popup
          pinned
          open={isOpen}
          style={{ borderRadius: "20px" }}
          position={position ? position : "top center"}
          trigger={children}
        >
          {contentView()}
        </Popup>
      ) : (
        <Modal
          onClose={() => setIsOpen(false)}
          onOpen={() => setIsOpen(true)}
          open={isOpen}
          size={"small"}
        >
          {/* <Modal.Header>

          </Modal.Header> */}
          <Modal.Content>{contentView()}</Modal.Content>
        </Modal>
      )}
    </>

    // <div className={`${styles.main__container} ${isOpen ? styles.open : ""}`} style={{ zIndex: 99999 }}>
    //   {/* <Loader active={isLoading} /> */}
    //   <div
    //     className={styles.close__icon}
    //     onClick={() => setIsOpen(false)}
    //   >X</div>
    //   <div className={styles.image__cont}>
    //     {stockImages?.map(image => {
    //       return isImage(image.file) ? (
    //         <img
    //           src={image?.file}
    //           alt={image?.id}
    //           key={image?.id}
    //           data-id={image?.id}
    //           onClick={(e) => handleImageSelect(e.currentTarget)}
    //         />
    //       ) : (
    //         <div className={styles.vedio_cont} style={{ position: "relative" }}>
    //           {!play ? <div style={{
    //             position: "absolute",
    //             height: "250px",
    //             width: "300px",
    //             display: "flex",
    //             justifyContent: "center",
    //             alignItems: "center"
    //           }}>
    //             <Image src={videoIcon} style={{
    //               height: "25px",
    //               width: "25px",
    //               marginTop: "5px",
    //               border: "none"
    //             }} // onClick={this.props.onClick}
    //             // onClick={() => showVideo(url)}
    //             />
    //           </div> : null}
    //           <video src={image?.file}
    //             width={"300px"}
    //             alt={image?.id}
    //             key={image?.id}
    //             data-id={image?.id}
    //             onClick={(e) => handleImageSelect(e.currentTarget)} />
    //         </div>
    //       )
    //     })}
    //   </div>
    //   <div></div>
    // </div>
  );
};

export default SelectImageCont;
