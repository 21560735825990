import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  Dimmer,
  Grid,
  Icon,
  Image,
  Input,
  Loader,
  Segment,
  Table,
} from "semantic-ui-react";
import { connect } from "react-redux";
import onlineIcon from "../../assets/images/onlineIcon.png";
import offlineIcon from "../../assets/images/offlineIcon.png";

import ConfirmationPopup from "../confirmationPopup";
import ErrorMessage from "../errorMessage";
import { isAllowedRole } from "../../utils/auth";
import { convertArrayToHashMap, isVideo } from "../../utils/helpers";
import { commonConstants } from "../../constants/common";
import {
  getWorkersList,
  hideError,
  sortByWorkersList,
  getWorkerListByAdminId,
  updateWorkersField,
} from "../../actions/workers";
import moment from "moment";
import SortByFilter from "../common/SortByFilter";
import CreatedByFilter from "../common/CreatedByFilter";
import noImagePlaceholder from "../../assets/images/no-image-placeholder.webp";
import { isEmpty } from "lodash";

const intrestedInArr = commonConstants.INTERESTED_IN_OPTIONS;

const WorkersList = (props) => {
  const [state, setState] = useState({ pageNumber: 0 });
  const [selectAdminName, setSelectAdminName] = useState("");

  const {
    session,
    workers,
    hideError,
    getWorkersList,
    sortByWorkersList,
    getWorkerListByAdminId,
    updateWorkersField,
  } = props;

  const columns = useMemo(
    () => Math.round(window.innerWidth / 300),
    [window.innerWidth]
  );

  useEffect(() => {
    if (!props.presets?.loading) {
      setState((state) => ({
        ...state,
        languagePresets: convertArrayToHashMap(
          props?.presets?.value?.languages ?? []
        ),
        heightPresets: convertArrayToHashMap(
          props?.presets?.value?.heights ?? []
        ),
        subscriptionPresets: convertArrayToHashMap(
          props?.presets?.value?.subscriptions ?? []
        ),
        ethnicityPresets: convertArrayToHashMap(
          props?.presets?.value?.ethnicity ?? []
        ),
        familyPresets: convertArrayToHashMap(
          props?.presets?.value?.family ?? []
        ),
      }));
    }
  }, [props.presets?.loading]);

  const handlePrevious = () => {
    setState({ pageNumber: state.pageNumber - 1 });
    getWorkersList(workers?.limit, workers?.limit * (state.pageNumber - 1));
  };

  const handleNext = () => {
    setState({ pageNumber: state.pageNumber + 1 });
    getWorkersList(workers?.limit, workers?.limit * (state.pageNumber + 1));
  };

  const onKeyPress = (e) => {
    if (e.key !== "Enter") {
      return;
    }
    if (workers.search.length >= 3) {
      getWorkersList(workers?.limit, 0, workers.search);
    }
    if (workers.search.length === 0) {
      getWorkersList(workers?.limit, 0);
    }
  };

  const onSearchChangeHandler = (e) => {
    if (e.target.value === "") {
      getWorkersList(workers?.limit, 0);
    }
    updateWorkersField({
      field: "search",
      value: e.target.value,
    });
  };

  return (
    <div>
      <Dimmer.Dimmable as={Segment} dimmed={workers.loading}>
        <Dimmer active={workers.loading} inverted>
          <Loader>Loading</Loader>
        </Dimmer>
        {workers.error && workers.error.visible && (
          <ErrorMessage error={workers.error} hideError={hideError} />
        )}
        <Grid
          as={Segment}
          columns={3}
          verticalAlign="middle"
          textAlign="center"
        >
          <Grid.Row>
            <Grid.Column>
              {selectAdminName && (
                <p className="userText">Created By: {selectAdminName}</p>
              )}
            </Grid.Column>
            <Grid.Column>
              <Button.Group>
                <Button
                  onClick={handlePrevious}
                  size="large"
                  color="green"
                  content="<  Previous page"
                  disabled={!workers?.pevWorkersUrl}
                />
                <Button.Or text={state.pageNumber + 1} />
                <Button
                  onClick={handleNext}
                  size="large"
                  color="green"
                  content="Next page  >"
                  disabled={!workers?.nextWorkersUrl}
                />
              </Button.Group>
            </Grid.Column>
            <Grid.Column
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                gap: "10px",
                whiteSpace: "nowrap",
              }}
            >
              <CreatedByFilter
                onFilter={({ filteredByName, filteredById }) => {
                  setSelectAdminName(filteredByName);
                  getWorkerListByAdminId(filteredById);
                }}
                onClearFilter={() => {
                  console.log("CreatedByFilter:: onClearFilter Called");
                  setSelectAdminName("");
                  sortByWorkersList(
                    workers?.limit,
                    workers?.limit * state.pageNumber,
                    []
                  );
                }}
              />
              <SortByFilter
                onFilter={(filterString) => {
                  sortByWorkersList(
                    workers?.limit,
                    workers?.limit * state.pageNumber,
                    filterString
                  );
                }}
                onClearFilter={() => {
                  console.log("SortByFilter:: onClearFilter Called");
                  sortByWorkersList(
                    workers?.limit,
                    workers?.limit * state.pageNumber,
                    []
                  );
                }}
              />
              <Link to={"/workers/newInvitation/"}>
                <Button circular icon="add" positive floated="right" />
              </Link>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Grid as={Segment} className="userContainer">
          <Grid.Row>
            <Grid.Column>
              <Input
                fluid
                icon={
                  <Icon
                    name="search"
                    circular
                    inverted={workers.search.length >= 3}
                    link={workers.search.length >= 3}
                    onClick={() => {
                      getWorkersList(workers?.limit, 0, workers.search);
                    }}
                  />
                }
                onKeyPress={onKeyPress}
                placeholder="Search..."
                value={workers.search}
                onChange={onSearchChangeHandler}
              />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row columns={columns || 5}>
            {workers?.workers?.length > 0 &&
              workers?.workers?.map((worker, indx) => {
                const editPath = "/workers/edit/" + worker?.id;
                const viewPath = "/workers/view/" + worker?.id;
                let avatar = worker?.avatar_photos?.length
                  ? worker?.avatar_photos[0]?.file
                  : "";
                const primaryImage =
                  worker?.avatar_photos?.[worker?.avatar_index || 0]?.file;

                const displayMedia = (
                  avatar
                    ? primaryImage
                      ? primaryImage
                      : avatar
                    : noImagePlaceholder
                ).trim();

                const is_video = isVideo(displayMedia);

                const getReports = () => {
                  const reports = [];
                  worker?.reports?.map((report) =>
                    reports.push(report?.reason ?? "")
                  );
                  return reports?.join(", ") ?? "";
                };

                return (
                  <Grid.Column key={`workers-${indx}`} className="userInfo">
                    <Card className="userCard">
                      <div style={{ position: "relative" }}>
                        {is_video ? (
                          <video
                            key={`workers-video-${indx}`}
                            style={{
                              width: "100%",
                              height: "220px",
                              borderRadius: "8px",
                              padding: "15px",
                            }}
                            autoPlay
                            muted
                            loop
                          >
                            <source src={displayMedia} type="video/mp4" />
                          </video>
                        ) : (
                          <Image
                            src={displayMedia}
                            width={"100%"}
                            height={"220px"}
                            wrapped
                            ui={false}
                            className={"userImage"}
                            style={{ borderRadius: "8px" }}
                          />
                        )}
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "8px",
                          }}
                          className="actionView"
                        >
                          <Link to={editPath}>
                            <Icon
                              link
                              name="pencil"
                              size="large"
                              color="blue"
                            />
                          </Link>
                          <Link to={viewPath}>
                            <Icon link name="eye" size="large" color="blue" />
                          </Link>
                          {isAllowedRole(
                            [commonConstants.SUPER_ADMIN_ROLE],
                            session?.userDetails
                          ) && (
                            <ConfirmationPopup
                              icon={
                                <Icon
                                  link
                                  name="trash"
                                  size="large"
                                  color="black"
                                />
                              }
                              onSuccessConfirm={() => {}}
                              entityData={{ workerId: worker.uid }}
                              confirmationText="Remove current user?"
                            />
                          )}
                        </div>
                      </div>
                      <Card.Content
                        style={{ display: "block" }}
                        className="userContent"
                      >
                        <Card.Header className="cardHeader">
                          <p
                            style={{
                              maxWidth: "60%",
                              minWidth: "60%",
                              wordWrap: "break-word",
                            }}
                          >
                            {worker?.display_name === ""
                              ? "-"
                              : worker?.display_name}
                          </p>
                          {worker?.isOnline ? (
                            <div>
                              <img
                                src={onlineIcon}
                                alt="Online Icon"
                                width="15px"
                                height="15px"
                              />{" "}
                              Online
                            </div>
                          ) : (
                            <div>
                              <img
                                src={offlineIcon}
                                alt="Online Icon"
                                width="15px"
                                height="15px"
                              />{" "}
                              Offline
                            </div>
                          )}
                        </Card.Header>
                        <Card.Description className="description">
                          <p>
                            Email:&nbsp;
                            {worker?.email === ""
                              ? "-"
                              : `${worker?.email
                                  ?.slice(0, 3)
                                  ?.padEnd(
                                    worker?.email?.slice(3)?.length,
                                    "."
                                  )}`}
                          </p>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <p>Age:&nbsp;{worker?.age + 17}</p>&nbsp;/&nbsp;{" "}
                            <p>
                              Height: &nbsp;
                              {worker?.height
                                ? state?.heightPresets?.[worker?.height]
                                    ?.height ?? ""
                                : "Nil"}
                            </p>
                          </div>
                          <p>City: {worker?.city}</p>
                          <p>
                            Language: &nbsp;
                            {isEmpty(worker?.language)
                              ? "Nil"
                              : state?.languagePresets?.[worker?.language?.[0]]
                                  ?.language ?? ""}
                          </p>
                          <p>
                            Subscription: &nbsp;{" "}
                            {worker?.admin_subscription
                              ? state?.subscriptionPresets[
                                  worker?.admin_subscription?.package
                                ]?.name
                              : "NO SUBSCRIPTION"}
                          </p>
                          <p>
                            IntrestedIn : &nbsp;
                            {worker.interested_in
                              ? worker?.interested_in.split(",").map((item) => {
                                  const intrests = intrestedInArr.find(
                                    (element) => {
                                      // eslint-disable-next-line
                                      return element.value == item;
                                    }
                                  );
                                  return (
                                    <span>{intrests && intrests.text}, </span>
                                  );
                                })
                              : "Nil"}
                          </p>
                          <p>
                            Ethnicity: &nbsp;
                            {worker?.ethnicity
                              ? state?.ethnicityPresets[worker?.ethnicity]
                                  ?.ethnicity
                              : "Nil"}
                          </p>
                          <p>
                            Family Plan: &nbsp;
                            {worker?.familyPlans
                              ? state?.familyPresets[worker?.familyPlans]
                                  ?.familyPlans
                              : "Nil"}
                          </p>
                          <p>
                            Work: &nbsp;
                            {worker?.work ? worker?.work : "Nil"}
                          </p>
                          <p>
                            Joined:&nbsp;
                            {worker?.sign_up &&
                              moment(worker?.sign_up).format(
                                "DD/MM/YYYY, hh:mm:ss a"
                              )}
                          </p>
                          <p>
                            Last Login:&nbsp;
                            {worker?.user_last_seen &&
                              moment(worker?.user_last_seen).format(
                                "DD/MM/YYYY, hh:mm:ss a"
                              )}
                          </p>
                        </Card.Description>
                      </Card.Content>
                      <Card.Content extra className="footer">
                        <p>Disabled: {worker?.disabled ? "True" : "False"}</p>
                        <p>Reports: {getReports() ? getReports() : "None"}</p>
                      </Card.Content>
                    </Card>
                  </Grid.Column>
                );
              })}
          </Grid.Row>
        </Grid>

        <Segment textAlign="right">
          <Button.Group>
            <Button
              onClick={handlePrevious}
              size="large"
              color="green"
              content="<  Previous page"
              disabled={!workers?.pevWorkersUrl}
            />
            <Button.Or text={state.pageNumber + 1} />
            <Button
              onClick={handleNext}
              size="large"
              color="green"
              content="Next page  >"
              disabled={!workers?.nextWorkersUrl}
            />
          </Button.Group>
          {/* <Link to={"/workers/newInvitation/"}>
            <Button circular icon="add" positive floated="right" />
          </Link> */}
        </Segment>
      </Dimmer.Dimmable>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    menu: state.menu,
    users: state.users,
    session: state.session,
    workers: state.workers,
    userDetails: state?.session?.userDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getWorkersList: (limit, offset, search) => {
      getWorkersList(limit, offset, search)(dispatch);
    },
    sortByWorkersList: (limit, offset, filterString) => {
      sortByWorkersList(limit, offset, filterString)(dispatch);
    },
    getWorkerListByAdminId: (id) => {
      getWorkerListByAdminId(id)(dispatch);
    },
    hideError: () => {
      hideError()(dispatch);
    },
    updateWorkersField: (payload) => {
      updateWorkersField(payload)(dispatch);
    },
  };
};

const createConnect = connect(mapStateToProps, mapDispatchToProps);

export default createConnect(WorkersList);
