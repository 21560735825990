import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { isEmpty } from "lodash";
import {
  Button,
  Dimmer,
  Form,
  Loader,
  Message,
  Segment,
  Card,
} from "semantic-ui-react";
import { connect } from "react-redux";
import { logoutAction } from "../../actions/session";
import MainMenu from "../../components/mainMenu";
import { setActiveMenuPosition } from "../../actions/menu";
import MapContainer from "../../components/mapContainer";
import ErrorMessage from "../../components/errorMessage";
import { validateEmail } from "../../utils/helpers";
import { gql, useMutation } from "@apollo/client";
import { client } from "../../utils/client";
import UserApi from "../../utils/users";
import onlineIcon from "../../assets/images/onlineIcon.png";
import offlineIcon from "../../assets/images/offlineIcon.png";

const EditWoker = (props) => {
  const { session, menu, presets, onLogout, setActiveMenuPosition } = props;
  const [worker, setWoker] = useState({});
  const [loading, setLoading] = useState(true);
  const [submitErrors, setSubmitErrors] = useState([]);
  const [error, setError] = useState({
    visible: false,
    messages: [],
    header: "",
  });

  const { id, pageName } = useParams();

  const isViewPage = pageName === "view";

  useEffect(() => {
    const getWorker = async (id) => {
      setLoading(true);
      try {
        const tempData = await UserApi.getUser(id);
        const data = tempData.data.user;
        setWoker({
          firstName: data?.firstName,
          lastName: data?.lastName,
          isOnline: data?.isOnline,
          email: data?.email,
          languageIdCode: data?.languageIdCode,
          language: data?.language,
          about: data?.about,
          address: data?.address,
          location: data?.location,
          pasteAccess: data?.pasteAccess ?? false,
          city: data?.city,
          allow_manual_chat: worker.allow_manual_chat ?? false,
        });
      } catch (error) {
        setError(error.response);
      } finally {
        setLoading(false);
      }
    };
    if (loading) {
      getWorker(id);
    }
  }, []);

  const updateFieldValue = (fieldName, filedValue) => {
    setSubmitErrors([]);
    setWoker((state) => ({ ...state, [fieldName]: filedValue }));
  };
  const updateWorker = gql`
    mutation UpdateProfile(
      $id: String
      $firstName: String
      $lastName: String
      $email: String
      $language: [Int]
      $about: String
      $pasteAccess: Boolean
      $address: String
      $location: [Float]
      $city: String
    ) {
      updateProfile(
        id: $id
        firstName: $firstName
        lastName: $lastName
        email: $email
        language: $language
        about: $about
        pasteAccess: $pasteAccess
        address: $address
        location: $location
        city: $city
      ) {
        id
      }
    }
  `;
  const [updateProfile] = useMutation(updateWorker, { client: client });

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = [];
    if (!worker.firstName) {
      errors.push("Please enter first name !");
    }
    if (!worker.lastName) {
      errors.push("Please enter last name !");
    }
    if (!validateEmail(worker.email)) {
      errors.push("Please enter valid email !");
    }
    if (isEmpty(worker.language)) {
      errors.push("Please select langauge !");
    }
    if (!worker.address || isEmpty(worker.location)) {
      errors.push("Please enter address !");
    }
    if (!isEmpty(errors)) {
      setSubmitErrors(errors);
      return;
    }
    try {
      updateProfile({
        variables: {
          id: id,
          firstName: worker.firstName,
          lastName: worker.lastName,
          email: worker.email,
          language: worker.language,
          about: worker.about,
          pasteAccess: worker.pasteAccess,
          address: worker.address,
          location: worker.location,
          city: worker.city,
        },
      }).then(() => (window.location.href = "/#/workers"));
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Segment.Group>
      <MainMenu
        needShowPrivateItems={session.isAuthenticated}
        menu={menu}
        onLogout={onLogout}
        setActiveMenuPosition={setActiveMenuPosition}
        userDetails={session.userDetails}
      />
      <Dimmer.Dimmable as={Segment} dimmed={loading || presets.loading}>
        <Dimmer active={loading || presets.loading} inverted>
          <Loader>Loading</Loader>
        </Dimmer>
        <Segment padded>
          {error.visible && (
            <ErrorMessage
              error={error}
              hideError={() => {
                setError((state) => ({
                  ...state,
                  visible: false,
                  header: "",
                  messages: [],
                }));
              }}
            />
          )}
          <Card.Header className="cardHeader">
            <p
              style={{
                maxWidth: "60%",
                minWidth: "60%",
                wordWrap: "break-word",
              }}
            ></p>
            {worker?.isOnline ? (
              <div>
                <img
                  src={onlineIcon}
                  alt="Online Icon"
                  width="15px"
                  height="15px"
                />{" "}
                Online
              </div>
            ) : (
              <div>
                <img
                  src={offlineIcon}
                  alt="Online Icon"
                  width="15px"
                  height="15px"
                />{" "}
                Offline
              </div>
            )}
          </Card.Header>
          <Form onSubmit={handleSubmit}>
            {!loading && (
              <Segment secondary>
                <Form.Group widths="equal">
                  <Form.Input
                    readOnly={isViewPage}
                    fluid
                    label={"First Name (*)"}
                    placeholder="First Name"
                    value={worker?.firstName}
                    onChange={(e) => {
                      updateFieldValue("firstName", e.target.value);
                    }}
                    error={!isEmpty(submitErrors) && !worker.firstName}
                  />
                  <Form.Input
                    readOnly={isViewPage}
                    fluid
                    label={"Last Name (*)"}
                    placeholder="Last Name"
                    value={worker?.lastName}
                    onChange={(e) => {
                      updateFieldValue("lastName", e.target.value);
                    }}
                    error={!isEmpty(submitErrors) && !worker.lastName}
                  />
                  <Form.Input
                    readOnly={isViewPage}
                    fluid
                    label={"Email (*)"}
                    placeholder="Email"
                    value={
                      `${worker.email
                        ?.slice(0, 3)
                        ?.padEnd(worker?.email.slice(3)?.length, "X")}` || ""
                    }
                    error={
                      !isEmpty(submitErrors) &&
                      !worker.email &&
                      validateEmail(worker.email)
                    }
                  />
                  <Form.Input
                    readOnly={isViewPage}
                    fluid
                    label={"Language_id_code"}
                    placeholder="Language_id_code"
                    value={worker?.languageIdCode}
                  />
                  <Form.Select
                    readOnly={isViewPage}
                    fluid
                    label={"language (*)"}
                    placeholder="language"
                    multiple
                    options={
                      !isEmpty(presets?.value?.languages)
                        ? presets?.value?.languages.map((lang) => {
                            return {
                              ...lang,
                              key: lang.id,
                              text: lang.language,
                              value: lang.id,
                            };
                          })
                        : []
                    }
                    value={!isEmpty(worker?.language) ? worker?.language : []}
                    onChange={(e, { value }) => {
                      !isViewPage && updateFieldValue("language", value);
                    }}
                    error={!isEmpty(submitErrors) && isEmpty(worker.language)}
                  />
                </Form.Group>
                <Form.TextArea
                  readOnly={isViewPage}
                  label={"Bio "}
                  placeholder="Bio"
                  value={worker.about ?? ""}
                  onChange={(e) => {
                    !isViewPage && updateFieldValue("about", e.target.value);
                  }}
                />
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    columnGap: 10,
                  }}
                >
                  <Form.Checkbox
                    disabled={isViewPage}
                    checked={worker?.pasteAccess}
                    onChange={(e) => {
                      updateFieldValue("pasteAccess", e.target.checked);
                    }}
                    id={"allow_paste"}
                  />
                  <label style={{ paddingBottom: 18 }} htmlFor="allow_paste">
                    Allow Pasting
                  </label>
                </div>
                <div
                  style={{
                    display: "none",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    columnGap: 10,
                  }}
                >
                  <Form.Checkbox
                    disabled={isViewPage}
                    checked={worker.allow_manual_chat}
                    onChange={(e) => {
                      updateFieldValue("allow_manual_chat", e.target.checked);
                    }}
                    id={"allow_manual_chat"}
                  />
                  <label
                    style={{ paddingBottom: 18 }}
                    htmlFor="allow_manual_chat"
                  >
                    Allow Manual Chat
                  </label>
                </div>
                <div
                  style={{
                    width: "100%",
                    position: "relative",
                  }}
                >
                  <MapContainer
                    latLng={{
                      lat:
                        !isEmpty(worker.location) &&
                        Array.isArray(worker.location)
                          ? worker.location[0]
                          : 0,
                      lng:
                        !isEmpty(worker.location) &&
                        Array.isArray(worker.location)
                          ? worker.location[1]
                          : 0,
                    }}
                    disabled={isViewPage}
                    handelChange={(e) => {
                      if (!isViewPage)
                        setWoker((state) => ({
                          ...state,
                          address: e.formattedAddress,
                          location: [e.latLng.lat, e.latLng.lng],
                          city: e.addressParts.city,
                          zip_code: e.addressParts.zip_code,
                          country_code: e.addressParts.country_code,
                          country: e.addressParts.country,
                        }));
                      setSubmitErrors([]);
                    }}
                    mapStyle={{
                      height: "600px",
                      marginBottom: "30px",
                    }}
                    formattedAddress={worker.address}
                    addressParts={{
                      city: worker?.city,
                      country: worker?.country,
                      zip_code: worker?.zip_code,
                    }}
                    city={worker.city}
                  />
                </div>
                <Form.Input
                  readOnly={isViewPage}
                  fluid
                  label={"Worker_id"}
                  placeholder="Worker_id"
                  value={id}
                />
              </Segment>
            )}
            {!isEmpty(submitErrors) &&
              submitErrors.map((error) => (
                <Message key={error} style={{ display: "block" }} error={true}>
                  {error}
                </Message>
              ))}
            {!isViewPage && (
              <Segment secondary textAlign="center">
                <Link to={"/workers"}>
                  <Button size="large" content="Cancel" />
                </Link>
                <Button
                  size="large"
                  color="blue"
                  content="Save"
                  type="submit"
                />
              </Segment>
            )}
          </Form>
        </Segment>
      </Dimmer.Dimmable>
    </Segment.Group>
  );
};

const mapStateToProps = (state) => {
  return {
    menu: state.menu,
    users: state.users,
    session: state.session,
    presets: state.presets,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLogout() {
      logoutAction()(dispatch);
    },
    setActiveMenuPosition(activeMenu) {
      setActiveMenuPosition(activeMenu)(dispatch);
    },
  };
};

const createConnect = connect(mapStateToProps, mapDispatchToProps);

export default createConnect(EditWoker);
