import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { Message } from "semantic-ui-react";

import {
  signUpAction,
  setSignUpInvitationKey,
  changeSignUpPassword,
  changeSignUpRepeatPassword,
  changeSignUpFirstName,
  changeSignUpLastName,
  logoutAction,
  signupChnageLanguages,
} from "../../actions/session";

import MainMenu from "../../components/mainMenu";
import SignUp from "../../components/auth/signUp";
import { getPresets } from "../../actions/fakeUsers";

const SignUpView = (props) => {
  const submit = () => {
    var params = {
      invitation_key: props.session.signUp.invitation_key,
      password: props.session.signUp.password,
      last_name: props.session.signUp.last_name,
      first_name: props.session.signUp.first_name,
      language: props.session.signUp.language,
      paste_access: false,
    };
    props.signUp(params);
  };

  useEffect(() => {
    props.getPresets();
    var params = props.location.search.split("=");
    if (params.length !== 2) {
      return;
    }
    props.setInvitationKey(params[1]);
  }, []);

  if (props.session.signUp.finished) {
    return <Redirect to={{ pathname: "/signIn" }} />;
  }

  if (
    props.session.signUp.invitation_key.length === 0 &&
    props.session.signUp.failed
  ) {
    return (
      <div>
        <MainMenu
          needShowPrivateItems={props.session.isAuthenticated}
          menu={props.menu}
          onLogout={() => {}}
          setActiveMenuPosition={() => {}}
        />
        <Message warning>
          <Message.Header>Invalid invitation key</Message.Header>
          <p>You key is invalid or expired</p>
        </Message>
      </div>
    );
  }

  if (props.session.isAuthenticated) {
    return (
      <div>
        <MainMenu
          needShowPrivateItems={props.session.isAuthenticated}
          menu={props.menu}
          onLogout={props.logout}
          setActiveMenuPosition={() => {}}
        />
        <Message warning>
          <Message.Header>Cannot apply invitation link</Message.Header>
          <p>You should logout to use sign up link</p>
        </Message>
      </div>
    );
  }

  return (
    <div>
      <MainMenu
        needShowPrivateItems={props.session.isAuthenticated}
        menu={props.menu}
        onLogout={() => {}}
        setActiveMenuPosition={() => {}}
      />
      <SignUp
        onSubmit={submit}
        session={props.session}
        changePassword={props.changePassword}
        changeRepeatPassword={props.changeRepeatPassword}
        changeFirstName={props.changeFirstName}
        changeLastName={props.changeLastName}
        fakeUsers={props.fakeUsers}
        changeLanguages={props.changeLanguages}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    session: state.session,
    menu: state.menu,
    fakeUsers: state.fakeUsers,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    signUp: (params) => {
      signUpAction(params)(dispatch);
    },
    logout: () => {
      logoutAction()(dispatch);
    },
    changePassword: (event, data) => {
      dispatch(changeSignUpPassword(data.value));
    },
    changeRepeatPassword: (event, data) => {
      dispatch(changeSignUpRepeatPassword(data.value));
    },
    changeLastName: (event, data) => {
      dispatch(changeSignUpLastName(data.value));
    },
    changeFirstName: (event, data) => {
      dispatch(changeSignUpFirstName(data.value));
    },
    setInvitationKey: (value) => {
      dispatch(setSignUpInvitationKey(value));
    },
    getPresets() {
      getPresets()(dispatch);
    },
    changeLanguages: (event, data) => {
      dispatch(signupChnageLanguages(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUpView);
