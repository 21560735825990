import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Grid, Message, Icon, Form, Button, Segment, Input, TextArea, Card } from 'semantic-ui-react';
import './contactUs.css'
import PropTypes from "prop-types";


export default class contactUs extends Component {

  state = { isDirty: false, errors: [] };

  submitForm = () => {

    const { onSubmit, formData } = this.props;
    this.setState({ isDirty: true });

    if (!this.validate(formData.contactus)) {
      return;
    }

    onSubmit();
  };

  validate = (formData) => {
    if (formData.name.length < 1) {
      return false;
    }
    if (formData.email.length < 1) {
      return false;
    }
    if (formData.message.length < 1) {
      return false;
    }
    return true;
  };


  render() {

    return (
      <div>
        <Grid centered columns={3}>

          <Grid.Column width={4} />

          <Grid.Column>
            <h1>CONTACT  US</h1>
            <Form size="large" onSubmit={e => { e.preventDefault(); this.submitForm(); }}>
              <Segment>

                <Form.Input
                  fluid
                  icon="user"
                  iconPosition="left"
                  placeholder="Name"
                  type="text"
                  value={this.props.formData.contactus.name}
                  onChange={this.props.name}
                  error={
                    this.state.isDirty &&
                    this.props.formData.contactus.name < 3
                  }

                />
                <Form.Input
                  fluid
                  icon="envelope"
                  iconPosition="left"
                  placeholder="Email"
                  value={this.props.formData.contactus.email}
                  onChange={this.props.email}
                  error={this.state.isDirty && this.props.formData.contactus.email < 5}
                />
                <Form.TextArea
                  onChange={this.props.message}
                  label="Message"
                  value={this.props.formData.contactus.message}
                  placeholder="Enter your request message"
                  rows={4}
                />
                <Form.Button className='sendMessage' color="teal" fluid size="large">Send Message</Form.Button>
              </Segment>
            </Form>

          </Grid.Column>
          <Grid.Column width={4} />
        </Grid>
      </div>
    )
  }
}
contactUs.propTypes = {
  isAuthFailed: PropTypes.bool,
  apiError: PropTypes.string,
  contactus: PropTypes.shape({
    name: PropTypes.func.isRequired,
    email: PropTypes.func.isRequired,
    message: PropTypes.func.isRequired
  }),
};