import { useState, useRef } from "react";
import React from "react";
import Photo from "../imageUpload/photo";
import { Button, Loader, Icon, Form, Card, Image } from "semantic-ui-react";

import "./AddMoment.css";
import SelectImageCont from "../chatImageUpload/selectImageCont";
import videoIcon from "../../assets/images/videoIcon.png";
import { isImage, isVideo } from "../../utils/helpers";

function generatePreviewImgUrl(file, callback) {
  const reader = new FileReader();
  // const url = reader.readAsDataURL(file);
  reader.onloadend = (e) => callback(reader.result);
}

const generateString = (length) => {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

const AddMoment = ({
  folder,
  height,
  disabled,
  header,
  onUpload,
  moderatorId,
  stockImages,
}) => {
  let inputRef = useRef();
  const [state, setState] = useState({
    image: null,
    src: "",
    progress: 0,
    isValid: false,
    loading: false,
    description: "",
    errors: [],
    imageContOpen: false,
  });
  const [play, setPlay] = useState(false);
  const setUrl = (url) => {
    setState({ ...state, image: null, src: url });
  };

  const selectImage = (e) => {
    inputRef.click();
  };

  const handleChange = (e) => {
    if (e.target.files[0]) {
      const image = e.target.files[0];
      generatePreviewImgUrl(image, (previewImgUrl) => {
        // (assuming we use React)
        setState({ ...state, src: previewImgUrl, image: image });
      });
    }
  };
  const handleChangeSelect = (image) => {
    if (image && !image?.type) {
      // console.log('imageimageimageimageimageimage', image)
      // this.props.onUpload(image.getAttribute("data-id"))
      setState({
        ...state,
        image: image,
        src: image.getAttribute("src"),
        imageContOpen: false,
      });

      // generatePreviewImgUrl(image, previewImgUrl => {
      //   // (assuming we use React)
      //   this.setState({ src: previewImgUrl })
      // })
    }
  };
  const setImageContOpen = (value) => {
    setState({
      ...state,
      imageContOpen: value,
    });
  };
  const handleUpload = () => {
    // setState({
    //   ...state,
    //   loading: true,
    // });
    const { image } = state;
    const imageName = generateString(29) + ".jpg";
    const fileParams = {
      name: imageName,
      file: image,
      type: image?.type ?? "",
      size: image?.size ?? "",
      public: false,
      description: state.description,
      id: image.getAttribute("data-id"),
    };

    onUpload(fileParams);
    setState({
      ...state,
      image: null,
      src: "",
      progress: 0,
      isValid: false,
      loading: false,
      description: "",
    });
  };

  const onLoad = () => {
    setState({
      ...state,
      isValid: true,
      loading: false,
    });
  };

  const onError = () => {
    setState({
      ...state,
      isValid: false,
      loading: false,
    });
  };

  const reset = () => {
    setState({
      ...state,
      image: null,
      src: "",
      progress: 0,
      isValid: false,
      loading: false,
    });
  };

  const handleInputChange = (e) => {
    const { value, name } = e.target;

    setState({
      ...state,
      [name]: value,
    });
  };

  const isButtonDisabled = () => {
    return !state.description && !state.isValid;
  };

  return (
    <Card className="add-moment-card-container">
      <Loader active={state.loading} />
      <input
        type="file"
        onChange={handleChange}
        style={{ display: "none" }}
        ref={(input) => (inputRef = input)}
      />
      {state.src ? (
        isImage(state.src) ? (
          <Photo
            height={height}
            style={{
              objectFit: "cover",
            }}
            src={state.src !== "" ? state.src : ""}
            onLoad={onLoad}
            onError={onError}
            onClick={disabled ? () => {} : () => setImageContOpen(true)}
            disabled={disabled}
          />
        ) : (
          <div style={{ position: "relative" }}>
            {!play && (
              <div
                style={{
                  position: "absolute",
                  height: height,
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Image
                  src={videoIcon}
                  style={{
                    height: "25px",
                    width: "25px",
                    marginTop: "5px",
                  }}
                />
              </div>
            )}
            <video
              src={state.src}
              height={height}
              onClick={(e) => {
                setPlay(!play);
                if (e.target.paused) {
                  e.target.play();
                } else {
                  e.target.pause();
                }
              }}
              width={"95%"}
            >
              <source src={state.src} type="video/mp4" />
            </video>
          </div>
        )
      ) : (
        <Photo
          height={height}
          style={{
            objectFit: "cover",
          }}
          src={state.src !== "" ? state.src : ""}
          onLoad={onLoad}
          onError={onError}
          onClick={disabled ? () => {} : () => setImageContOpen(true)}
          disabled={disabled}
        />
      )}

      {state.src ? (
        <button
          style={{
            width: 30,
            height: 30,
            background: "#ffffff",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            borderRadius: "10px",
            top: 5,
            right: 5,
            border: "none",
          }}
        >
          <Icon
            className="create-fake-moderator-remove-moment-image-icon"
            link
            corner
            name={"close"}
            onClick={reset}
          />
        </button>
      ) : null}
      <Form style={{ padding: 15 }}>
        {/* <Segment> */}
        <Form.Group widths="equal">
          <Form.TextArea
            fluid={true}
            name="description"
            label={"Description"}
            placeholder="Add description here"
            value={state.description}
            onChange={handleInputChange}
          />
        </Form.Group>
        <Button
          fluid={true}
          disabled={(!state.description && !state.image) || disabled}
          color="blue"
          content={"Add moment"}
          onClick={(e, data) => {
            e.preventDefault();
            handleUpload();
          }}
        />
        <SelectImageCont
          isOpen={state.imageContOpen}
          setIsOpen={setImageContOpen}
          moderatorId={moderatorId}
          // getStockImages={this.getStockImages}
          getStockImages={() => {}}
          stockImages={stockImages}
          onSelect={(image) => handleChangeSelect(image)}
          modal={true}
        />
        {/* </Segment> */}
      </Form>
    </Card>
  );
};

export default AddMoment;
