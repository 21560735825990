import React, { useEffect } from "react";
import { connect } from "react-redux";
import MainMenu from "../../components/mainMenu";
import { setActiveMenuPosition } from "../../actions/menu";
import { logoutAction } from "../../actions/session";
import PendindChats from "../../components/pendingChats/index";
import {
  messageReceiveListener,
  updateChatFakeUsers,
  changeFakeUserOnlineStatus,
  selectChat,
  changeChatMessage,
  sendChatMessage,
  readChatMessages,
  saveNotes,
  startNewChat,
  selectChatFakeUser,
  getAllDialogue,
  getChatHistory,
  sendTextMessage,
  sendAttachmentMessage,
  createNotes,
  appendIncomingMessage,
  getStockImages,
  removeCurrentChats,
  getAllChatsInQueue,
  addFakeUsers,
} from "../../actions/chat";
import {
  getPresets,
  getWorkerFakeUsers,
  reFetchWorkerFakeUsers,
} from "../../actions/fakeUsers";
import {
  getWorkersList,
  selectWorker,
  deleteWorker,
  moderatorsInQueue,
} from "../../actions/workers";
import {
  getUsersList,
  hideError,
  onSearchValueChange,
  searchUsersList,
  sortUsersList,
} from "../../actions/users";

import { getUsersInRoom } from "../../actions/pendingChats";

const PendingChatView = (props) => {
  useEffect(() => {
    if (props.menu.activeMenu !== "pendingChats") {
      props.setActiveMenuPosition("pendingChats");
    }
  }, []);

  useEffect(() => {
    props.getAllChatsInQueue();
  }, []);

  return (
    <div>
      <MainMenu
        session={props.session}
        needShowPrivateItems={!props.session.isAuthenticated}
        menu={props.menu}
        onLogout={props.onLogout}
        setActiveMenuPosition={props.setActiveMenuPosition}
        userDetails={props.session.userDetails}
      />
      <PendindChats
        users={props.chatsInQueue}
        session={props.session.isAuthenticated}
        userDetails={props.session.userDetails}
        addFakeUsers={props.addFakeUsers}
        selectChatFakeUser={props.selectChatFakeUser}
        startNewChat={props.startNewChat}
        usersInRooom={props.usersInRooom}
        presets={props.presets}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  const allFakeUsers = [...state.chat.currentFakeUsers] || [];
  const allDialogues = state.chat.fakeUserChatDialogues || {};
  for (const user of allFakeUsers) {
    const dialogues = allDialogues[user.id] || [];
    user.unread_messages_count = dialogues
      .map((x) => parseInt(x.unread_messages_count))
      .reduce((x, y) => x + y, 0);
  }
  return {
    users: state.users,
    menu: state.menu,
    session: state.session,
    workers: state.workers,
    stockImages: state.chat.stockImages,
    chat: { ...state.chat, currentFakeUsers: allFakeUsers },
    iscurrentFakeuserAdd: state.chat.iscurrentFakeuserAdd,
    currentDialogueMessages: state.chat.currentDialogueMessages,
    isMessageSend: state.chat.isMessageSend,
    chatsInQueue: state.chat.chatsInQueue,
    usersInRooom: state.pendingChats.usersInRooom,
    presets: state.presets,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    async messageReceiveListener(moderatorId) {
      const fn = messageReceiveListener(moderatorId);
      const t = await fn(dispatch);
      return t;
    },
    removeCurrentChats(dialogue_id) {
      removeCurrentChats(dialogue_id)(dispatch);
    },
    onSendAttachment(dialogId, imageId, userId, msg, type) {
      sendAttachmentMessage(dialogId, imageId, userId, msg, type)(dispatch);
    },
    getChatHistory(id, dialogue_id) {
      getChatHistory(id, dialogue_id)(dispatch);
    },
    appendChatHistory(dialogue_id, message) {
      appendIncomingMessage(dialogue_id, message)(dispatch);
    },
    getAllDialogue(id) {
      getAllDialogue(id)(dispatch);
    },
    setActiveMenuPosition(activeMenu) {
      setActiveMenuPosition(activeMenu)(dispatch);
    },
    onLogout() {
      logoutAction()(dispatch);
    },
    updateFakeUsers() {
      updateChatFakeUsers()(dispatch);
    },
    allChatIn() {
      getAllChatsInQueue()(dispatch);
    },
    addFakeUsers: (data) => {
      addFakeUsers(data)(dispatch);
    },
    getWorkerFakeUsers(id) {
      getWorkerFakeUsers(id)(dispatch);
    },
    reFetchWorkerFakeUsers(id, workersData) {
      reFetchWorkerFakeUsers(id)(dispatch, workersData);
    },
    selectChatFakeUser(id) {
      selectChatFakeUser(id)(dispatch);
    },
    changeFakeUserOnlineStatus(uid, online) {
      changeFakeUserOnlineStatus(uid, online)(dispatch);
    },
    sendTextMessage(dialogueId, text, userId, workers, messageType) {
      sendTextMessage(dialogueId, text, userId, messageType)(dispatch, workers);
    },
    getAllChatsInQueue() {
      getAllChatsInQueue()(dispatch);
    },
    selectChat(chatId, opponent_id, dialogues) {
      selectChat(chatId, opponent_id, dialogues)(dispatch);
    },
    changeChatMessage(e, data) {
      changeChatMessage(e?.target?.value)(dispatch);
    },
    sendChatMessage(
      chatId,
      fromName,
      fromUserId,
      recipientToken,
      type,
      message
    ) {
      sendChatMessage(
        chatId,
        fromName,
        fromUserId,
        recipientToken,
        type,
        message
      )(dispatch);
    },
    readChatMessages(uid, chatId, messages) {
      readChatMessages(uid, chatId, messages)(dispatch);
    },
    getStockImages(moderatorId) {
      getStockImages(moderatorId)(dispatch);
    },
    getWorkersList() {
      getWorkersList()(dispatch);
    },
    selectWorker(id) {
      selectWorker(id)(dispatch);
    },
    deleteWorker(id) {
      deleteWorker(id)(dispatch);
    },
    saveNotes(chatId, value) {
      saveNotes(chatId, value)(dispatch);
    },
    getUsersList: (offset, ordered, orderedField) => {
      getUsersList(offset, ordered, orderedField)(dispatch);
    },
    onSearchValueChange: (e, data) => {
      onSearchValueChange(data.value)(dispatch);
    },
    searchUsersList: (value) => {
      searchUsersList(value)(dispatch);
    },
    sortUsersList: (offset, prevColumn, column, prevDirection) => {
      sortUsersList(offset, prevColumn, column, prevDirection)(dispatch);
    },
    startNewChat: (fakeUserId, realUser) => {
      startNewChat(fakeUserId, realUser)(dispatch);
    },
    hideError: () => {
      hideError()(dispatch);
    },
    createNotes(roomId, content, forRealUser, callback) {
      createNotes(roomId, content, forRealUser, callback)(dispatch);
    },
    moderatorsInQueue(moderatorId) {
      moderatorsInQueue(moderatorId)(dispatch);
    },
    getPresets() {
      getPresets()(dispatch);
    },
    getUsersInRoom(roomId) {
      getUsersInRoom(roomId)(dispatch);
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PendingChatView);
