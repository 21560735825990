import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Card,
  Header,
  Icon,
  Image,
  Message,
  Modal,
  Segment,
} from "semantic-ui-react";
import { getStories, uploadStory } from "../../actions/fakeUsers";
import { selectFakeUsersState } from "../../store/selectors/fakeUsersSelectors";
import UserApi from "../../utils/users";
import AddNewPhoto from "../imageUpload/addNewPhoto";

const FakeUserStories = () => {
  const dispatch = useDispatch();
  const fakeUsersState = useSelector(selectFakeUsersState);
  const userId = fakeUsersState?.currentUser?.id;
  const stories = fakeUsersState.stories;
  const [state, setState] = useState({
    isDirty: false,
    storyId: "",
    isDeleteModalOpen: false,
    errors: [],
    isLoading: false,
  });

  useEffect(() => {
    if (userId) dispatch(getStories(userId));
  }, []);

  const removeUserStory = async (storyID) => {
    setState({
      ...state,
      isDeleteModalOpen: true,
      momentId: storyID,
      errors: [],
    });
  };

  const handleDeleteStory = async () => {
    setState({
      ...state,
      isLoading: true,
    });
    const res = await UserApi.removeUserStory({ momentId: state.momentId });
    if (!res) {
      setState({
        ...state,
        isLoading: false,
        errors: [
          res?.message ?? "Something went wrong while removing user story!",
        ],
      });
      return;
    }
    if (res?.errors) {
      setState({
        ...state,
        isLoading: false,
        errors: res?.errors ?? [],
      });
      return;
    }
    setState({
      ...state,
      isLoading: false,
      isDeleteModalOpen: false,
      errors: [],
    });
    dispatch(getStories(userId));
  };

  const onUploadStory = (params) => {
    dispatch((userId, params));
  };

  return (
    <>
      <Modal open={state.isDeleteModalOpen}>
        <Header icon="archive" content="Are you sure want to delete ?" />
        <Modal.Content>
          <p>The file should be not recovered!</p>
        </Modal.Content>
        <Modal.Actions>
          {state?.errors?.length
            ? state.errors.map((error) => (
                <Message
                  style={{ display: "block", textAlign: "start" }}
                  error={true}
                >
                  {error}{" "}
                </Message>
              ))
            : null}
          <Button
            color="red"
            onClick={() => {
              setState({
                ...state,
                errors: [],
                isDeleteModalOpen: false,
                isLoading: false,
              });
            }}
          >
            <Icon name="remove" /> Cancel
          </Button>
          <Button color="green" onClick={handleDeleteStory}>
            <Icon name="checkmark" /> Delete
          </Button>
        </Modal.Actions>
      </Modal>
      <Segment
        inverted={state.isDirty && fakeUsersState.avatar_photos.length === 0}
        tertiary={state.isDirty && fakeUsersState.avatar_photos.length === 0}
        secondary={state.isDirty && fakeUsersState.avatar_photos.length > 0}
        color={
          state.isDirty && fakeUsersState.avatar_photos.length === 0
            ? "red"
            : "black"
        }
      >
        <Header
          size="large"
          color={
            state.isDirty && fakeUsersState.photos.length === 0
              ? "red"
              : "black"
          }
        >
          Story
        </Header>
        {stories.length === 0 && <Header>No stories selected</Header>}
        <Card.Group itemsPerRow={4}>
          {stories.map((image, index) => {
            return (
              <Card key={index} className="user-card-main-container">
                <div className="user-moment-image">
                  <Image src={image.file} centered />
                  <div>
                    <button
                      style={{
                        width: 30,
                        height: 30,
                        background: "#ffffff",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        position: "absolute",
                        borderRadius: "10px",
                        top: 5,
                        right: 5,
                        border: "none",
                      }}
                      type="button"
                      onClick={() => removeUserStory(image?.id)}
                    >
                      <div>
                        <Icon
                          className="create-fake-moderator-remove-moment-image-icon"
                          link
                          corner
                          name={"trash"}
                        />
                      </div>
                    </button>
                  </div>
                </div>
              </Card>
            );
          })}
          <Card>
            <AddNewPhoto
              onUpload={onUploadStory}
              height={"200px"}
              header={"Add new image"}
              folder={userId || "images"}
            />
          </Card>
        </Card.Group>
      </Segment>
    </>
  );
};

export default FakeUserStories;
