import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getAllChatsInQueue } from "../../actions/chat";

const DisplayQueueCounts = (props) => {
  useEffect(() => {
    props.getAllChatsInQueue();
    const updateQueueCountInterval = setInterval(() => {
      props.getAllChatsInQueue();
    }, 15000);

    return () => {
      clearInterval(updateQueueCountInterval);
    };
  }, []);

  const getQueueCount = () => {
    const chatsInQueue = props?.chatsInQueueData ?? [];
    const notAssigned = chatsInQueue; //.filter((item) => !item.isAssigned);
    return notAssigned?.length ?? 0;
  };

  return <>{`QUEUE: ${getQueueCount()}`}</>;
};

const mapStateToProps = (state) => {
  const chatsInQueueData = [...state.chat.chatsInQueue];
  return {
    chatsInQueueData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllChatsInQueue() {
      getAllChatsInQueue()(dispatch);
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DisplayQueueCounts);
