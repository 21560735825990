import _ from "lodash";
import { getToken } from "./auth";
import { client } from "./client";
import { gql } from "@apollo/client";

export const baseUrl =
  process.env.REACT_APP_NODE_ENV === "development"
    ? "https://api.chatadmin-mod.click"
    : "https://api.i69app.com";

// export const baseUrl =
//   process.env.NODE_ENV === "development"
//     ? "https://api.i69app.com"
//     : "https://api.i69app.com";

export const googleMapsApi = "https://maps.googleapis.com/maps/api";

// Google apikey need to use
export const googleMapsApiKey = "AIzaSyBNDQFHOXjOH-AJH_tvgd7FM_IxLNClDRk";

// Google maps api key
// export const googleMapsApiKey = "AIzaSyCOyZT-UiLijJ36jDPUfUIG3x4y1tBASRI";

const apiHost = baseUrl + "/api";
export class Api {
  static addAuthorizationHeader(headers) {
    var newHeaders = Object.assign({}, headers);
    const token = getToken();
    if (token) {
      newHeaders["Authorization"] = `Token ${token}`;
    }
    return newHeaders;
  }

  static login(credentials) {
    var request = new Request(apiHost + "/auth/token-login/", {
      method: "POST",
      headers: new Headers(Api.defaultHeaders),
      body: JSON.stringify(credentials),
    });

    return fetch(request)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  }

  static signUp(params) {
    var request = new Request(apiHost + "/worker/signup/", {
      method: "POST",
      headers: new Headers(Api.defaultHeaders),
      body: JSON.stringify(params),
    });

    return fetch(request)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  }

  static contactUs(params) {
    var request = new Request(apiHost + "/contact-us/", {
      method: "POST",
      headers: new Headers(Api.defaultHeaders),
      body: JSON.stringify(params),
    });

    return fetch(request)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  }

  static applyInvitationKey(key) {
    var request = new Request(apiHost + `/worker/invitation/${key}/`, {
      method: "GET",
      headers: new Headers(Api.defaultHeaders),
      // headers: Api.addAuthorizationHeader(Api.defaultHeaders),
    });

    return fetch(request)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  }

  static createUser(user) {
    var request = new Request(apiHost + "/user/", {
      method: "POST",
      headers: Api.addAuthorizationHeader(Api.defaultHeaders),
      body: JSON.stringify(user),
    });

    return fetch(request)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  }

  static blockUser(uid) {
    var request = new Request(apiHost + "/user/" + uid + "/", {
      method: "PATCH",
      headers: Api.addAuthorizationHeader(Api.defaultHeaders),
      body: JSON.stringify({ is_blocked: true }),
    });

    return fetch(request)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  }

  static unblockUser(uid) {
    var request = new Request(apiHost + "/user/" + uid + "/", {
      method: "PATCH",
      headers: Api.addAuthorizationHeader(Api.defaultHeaders),
      body: JSON.stringify({ is_blocked: false }),
    });

    return fetch(request)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  }

  static async updateUser(uid, user) {
    return await client.mutate({
      mutation: gql`
      mutation {
        updateProfile(
          about: "${String(user?.about)}",
          email: "${String(user?.email || "")}",
           address: "${String(user?.address || "")}",
                fullName: "${String(
                  user?.fullName || user?.display_name || ""
                )}",
                id: "${user?.id || uid}",
                gender:${parseInt(user?.gender || 0)},
                age:${parseInt(user?.age || 0)},
                height:${parseInt(user?.height || 1)},
                ethinicity:${parseInt(user?.ethnicity || 0)},
                location: [${(user?.location && user?.location[0]) || 0}, ${
        (user?.location && user?.location[1]) || 0
      }],
                familyPlans:${parseInt(user?.familyPlans || 0)},
                politics:${parseInt(user?.politics || 0)},
                religion:${parseInt(user?.religion || 0)},
                zodiacSign:${parseInt(user?.ethniczodiacSignity || 0)},
                work: "${String(user?.work || "")}",
                education: "${String(user?.education || "")}"
                subscriptionId: "${user?.subscription || 1}"
                city: "${user?.city || ""}"
                country : "${user?.country || ""}"
                zipCode : "${user?.zip_code || ""}"
                countryCode : "${user?.country_code || ""}"
              ) {
                id
                username
              }
            }
        `,
    });
  }

  static async updateRealUser(payload) {
    return await client.mutate({
      mutation: gql`
        mutation updateProfile(
          $id: String!
          $fullName: String!
          $age: Int!
          $gender: Int!
          $language: [Int]
          $about: String
          $country: String
          $address: String
          $city: String
          $zipCode: String
          $location: [Float]
          $countryCode: String
          $interestedIn: [Int]
        ) {
          updateProfile(
            id: $id
            about: $about
            address: $address
            fullName: $fullName
            gender: $gender
            age: $age
            location: $location
            city: $city
            country: $country
            zipCode: $zipCode
            countryCode: $countryCode
            language: $language
            interestedIn: $interestedIn
          ) {
            id
            username
          }
        }
      `,
      variables: {
        ...payload,
      },
    });
  }

  static removeUser(uid) {
    var request = new Request(apiHost + "/user/" + uid, {
      method: "DELETE",
      headers: Api.addAuthorizationHeader(Api.defaultHeaders),
    });

    return fetch(request)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  }

  static removeUserReports(uid) {
    var request = new Request(apiHost + "/user/" + uid + "/reports/", {
      method: "DELETE",
      headers: Api.addAuthorizationHeader(Api.defaultHeaders),
    });

    return fetch(request)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  }

  static getUser(uid) {
    // var request = new Request(apiHost +"/user/" + uid, {
    var request = new Request(`${apiHost}/user/${uid}/`, {
      method: "GET",
      headers: Api.addAuthorizationHeader(Api.defaultHeaders),
    });

    return fetch(request)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  }

  static getUserDetails = async (uid) => {
    // var request = new Request(apiHost + `/user/${uid}/`, {
    //   method: "GET",
    //   headers: Api.addAuthorizationHeader(Api.defaultHeaders),
    // });

    // return fetch(request)
    //   .then((response) => {
    //     return response;
    //   })
    //   .catch((error) => {
    //     return error;
    //   });
    try {
      const response = await fetch(`${apiHost}/user/${uid}/`, {
        method: "GET",
        headers: Api.addAuthorizationHeader(Api.defaultHeaders),
      });
      return response.json();
    } catch (error) {
      console.log(error);
    }
  };

  // apiHost /user/user-list/?user_role=[REGULAR | ADMIN | CHATTER]
  static sortUsersByRoles(
    limit,
    offset,
    ordered,
    orderedField = undefined,
    userRole = "regular",
    fields = []
  ) {
    if (userRole !== "admin" && userRole !== "chatter") userRole = "regular";

    let sortby_url = apiHost + `/user/user-list/?user_role=` + userRole;

    if (limit && limit !== 0) {
      fields.push({ key: "limit", value: limit });
    }
    if (offset && offset !== 0) {
      fields.push({ key: "offset", value: offset });
    }
    if (ordered && ordered !== 0 && orderedField) {
      fields.push({ key: "field", value: orderedField });
      fields.push({ key: "ordered", value: ordered });
    }
    if (fields.length !== 0) {
      var query = fields.map((i) => i.key + "=" + i.value).join("&");
      sortby_url += "&" + query;
    }
    const request = new Request(sortby_url, {
      method: "GET",
      headers: Api.addAuthorizationHeader(Api.defaultHeaders),
    });
    return fetch(request)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  }

  static getUsers(
    limit,
    offset,
    ordered,
    orderedField,
    userType = "/user/",
    fields = []
  ) {
    let path = apiHost + userType;

    if (limit && limit !== 0) {
      fields.push({ key: "limit", value: limit });
    }
    if (offset && offset !== 0) {
      fields.push({ key: "offset", value: offset });
    }
    if (ordered && ordered !== 0) {
      fields.push({ key: "field", value: orderedField });
      fields.push({ key: "ordered", value: ordered });
    }
    if (fields.length !== 0) {
      var query = fields.map((i) => i.key + "=" + i.value).join("&");
      path += "?" + query;
    }
    const request = new Request(path, {
      method: "GET",
      headers: Api.addAuthorizationHeader(Api.defaultHeaders),
    });
    return fetch(request)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  }

  static getAdmins(limit, offset, ordered, orderedField, fields, search) {
    let path = apiHost + "/admin/";

    if (limit && limit !== 0) {
      fields.push({ key: "limit", value: limit });
    }
    if (offset && offset !== 0) {
      fields.push({ key: "offset", value: offset });
    }
    if (ordered && ordered !== 0 && orderedField) {
      fields.push({ key: "field", value: orderedField });
      fields.push({ key: "ordered", value: ordered });
    }
    if (search) {
      fields.push({ key: "search", value: search });
    }
    if (fields.length !== 0) {
      var query = fields.map((i) => i.key + "=" + i.value).join("&");
      path += "?" + query;
    }
    const request = new Request(path, {
      method: "GET",
      headers: Api.addAuthorizationHeader(Api.defaultHeaders),
    });
    return fetch(request)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  }

  static getModerators(
    limit,
    offset,
    ordered,
    orderedField,
    userType = "/moderator/",
    fields = [],
    search
  ) {
    // fields = [];
    let path = apiHost + userType;
    if (typeof limit !== "undefined" && limit !== 0) {
      fields.push({ key: "limit", value: limit });
    }
    if (typeof offset !== "undefined" && offset !== 0) {
      fields.push({ key: "offset", value: offset });
    }
    if (typeof ordered !== "undefined" && ordered !== 0) {
      fields.push({ key: "field", value: orderedField });
      fields.push({ key: "ordered", value: ordered });
    }
    if (search) {
      fields.push({ key: "search", value: search });
    }
    if (fields.length !== 0) {
      var query = fields.map((i) => i.key + "=" + i.value).join("&");
      path += "?" + query;
    }

    const request = new Request(path, {
      method: "GET",
      headers: Api.addAuthorizationHeader(Api.defaultHeaders),
    });

    return fetch(request)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  }

  static searchUsers(value) {
    var path = apiHost + "/user/";
    if (value && value.length > 0) {
      path += "?search=" + value;
    } else {
      return new Error("filter not defined");
    }
    var request = new Request(path, {
      method: "GET",
      headers: Api.addAuthorizationHeader(Api.defaultHeaders),
    });

    return fetch(request)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  }

  static searchModerators(value) {
    var path = apiHost + "/moderator/";
    if (value && value.length > 0) {
      path += "?search=" + value;
    } else {
      return new Error("filter not defined");
    }
    var request = new Request(path, {
      method: "GET",
      headers: Api.addAuthorizationHeader(Api.defaultHeaders),
    });

    return fetch(request)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  }

  static generateInvitation(params) {
    var request = new Request(apiHost + "/worker/invitation/", {
      method: "POST",
      headers: Api.addAuthorizationHeader(Api.defaultHeaders),
      body: JSON.stringify(params),
    });
    return fetch(request)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  }

  static createFakeUser(user) {
    var request = new Request(apiHost + "/user/", {
      method: "POST",
      headers: Api.addAuthorizationHeader(Api.defaultHeaders),
      body: JSON.stringify(user),
    });
    return fetch(request)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  }

  static async updateFakeUser(uid, user) {
    // return await client.mutate({
    //   mutation: gql`
    //   mutation {
    //     updateProfile(
    //       about: "${String(user?.about)}",
    //       email: "${String(user?.email || "")}",
    //        address: "${String(user?.address || "")}",
    //             fullName: "${String(
    //               user?.fullName || user?.display_name || ""
    //             )}",
    //             id: "${user?.id || uid}",
    //             gender:${parseInt(user?.gender || 0)},
    //             age:${parseInt(user?.age || 0)},
    //             height:${parseInt(user?.height || 1)},
    //             ethinicity:${parseInt(user?.ethnicity || 0)},
    //             location: [${(user?.location && user?.location[0]) || 0}, ${
    //     (user?.location && user?.location[1]) || 0
    //   }],
    //             familyPlans:${parseInt(user?.familyPlans || 0)},
    //             politics:${parseInt(user?.politics || 0)},
    //             religion:${parseInt(user?.religion || 0)},
    //             zodiacSign:${parseInt(user?.ethniczodiacSignity || 0)},
    //             work: "${String(user?.work || "")}",
    //             education: "${String(user?.education || "")}"
    //             subscriptionId: "${user?.subscription || 1}"
    //             city: "${user?.city || ""}"
    //             country : "${user?.country || ""}"
    //             zipCode : "${user?.zip_code || ""}"
    //             countryCode : "${user?.country_code || ""}"
    //           ) {
    //             id
    //             username
    //           }
    //         }
    //     `,
    // });

    const request = new Request(apiHost + "/user/" + uid + "/", {
      method: "PUT",
      headers: Api.addAuthorizationHeader(Api.defaultHeaders),
      body: JSON.stringify(user),
    });

    await client.mutate({
      mutation: gql`
      mutation {
        updateProfile(
                subscriptionId: "${user?.subscription || 1}",
                id: "${uid}"
              ) {
                id
              }
            }
        `,
    });

    return fetch(request)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  }

  static getFakeUser(uid) {
    return this.getUser(uid);
    // var request = new Request(apiHost + '/internal/fakeUsers/' + uid + '/details', {
    //     method: 'GET',
    //     headers: Api.addAuthorizationHeader(Api.defaultHeaders)
    // });

    // return fetch(request).then(response => {
    //     return response;
    // }).catch(error => {
    //     return error;
    // });
  }

  static getFakeUsersPresets() {
    var request = new Request(apiHost + "/", {
      method: "GET",
      headers: Api.addAuthorizationHeader(Api.defaultHeaders),
    });

    return fetch(request)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  }

  static getWorkerFakeUsers(id) {
    var request = new Request(apiHost + "/worker/" + id + "/", {
      method: "GET",
      headers: Api.addAuthorizationHeader(Api.defaultHeaders),
    });

    return fetch(request)
      .then(async (response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  }

  static changeFakeUserOnlineStatus(uid, online) {
    var request = new Request(
      apiHost + "/internal/fakeUsers" + uid + "/online",
      {
        method: "PUT",
        headers: Api.addAuthorizationHeader(Api.defaultHeaders),
        body: JSON.stringify({ online: online }),
      }
    );

    return fetch(request)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  }

  static sendChatMessage(
    chatId,
    fromName,
    fromUserId,
    recipientToken,
    type,
    message
  ) {
    const chatMessage = {
      from_name: fromName,
      from_user_id: fromUserId,
      recipient_token: recipientToken,
      type: type,
      message: message,
    };
    const request = new Request(
      apiHost + "/internal/chats/" + chatId + "/send",
      {
        method: "PUT",
        headers: Api.addAuthorizationHeader(Api.defaultHeaders),
        body: JSON.stringify(chatMessage),
      }
    );

    return fetch(request)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  }

  static saveChatNotes(chatId, notes) {
    const message = {
      notes: notes,
    };
    const request = new Request(
      apiHost + "/internal/chats/" + chatId + "/notes",
      {
        method: "PUT",
        headers: Api.addAuthorizationHeader(Api.defaultHeaders),
        body: JSON.stringify(message),
      }
    );

    return fetch(request)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  }

  static informChatStart = async (room_id, moderator_id, isAssigned = true) => {
    const request = new Request(baseUrl + "/chat/assign-chat/", {
      method: "POST",
      headers: Api.addAuthorizationHeader({
        Accept: "application/json",
        "Content-Type": "application/json",
      }),
      body: JSON.stringify({ room_id, isAssigned, moderator_id }),
    });

    const response = await fetch(request);
    return response.json();
  };

  // static startNewChat(fakeUserId, realUserId) {
  //     const message = {
  //         fake_user_id: fakeUserId,
  //         real_user_id: realUserId,
  //     };
  //     const request = new Request(apiHost + "/internal/chats/new", {
  //         method: "POST",
  //         headers: Api.addAuthorizationHeader(Api.defaultHeaders),
  //         body: JSON.stringify(message),
  //     });
  //
  //     return fetch(request)
  //         .then((response) => {
  //             return response;
  //         })
  //         .catch((error) => {
  //             return error;
  //         });
  // }

  static readChatMessages(uid, chatId, messages) {
    const chatMessage = {
      user_id: uid,
      messages: messages,
    };
    const request = new Request(
      apiHost + "/internal/chats/" + chatId + "/read",
      {
        method: "PUT",
        headers: Api.addAuthorizationHeader(Api.defaultHeaders),
        body: JSON.stringify(chatMessage),
      }
    );

    return fetch(request)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  }

  static getWorkers(limit, offset, search) {
    const url = search
      ? apiHost + `/worker/?limit=${limit}&offset=${offset}&search=${search}`
      : apiHost + `/worker/?limit=${limit}&offset=${offset}`;
    var request = new Request(url, {
      method: "GET",
      headers: Api.addAuthorizationHeader(Api.defaultHeaders),
    });
    return fetch(request)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  }

  static deleteWorker(id) {
    var request = new Request(apiHost + "/internal/workers/" + id, {
      method: "DELETE",
      headers: Api.addAuthorizationHeader(Api.defaultHeaders),
    });

    return fetch(request)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  }

  static async likeUsers(user_id, friend_id) {
    var request = new Request(apiHost + `/user/${user_id}/like/${friend_id}/`, {
      method: "POST",
      headers: Api.addAuthorizationHeader(Api.defaultHeaders),
    });
    try {
      const response = await fetch(request);
      return response;
    } catch (err) {
      return err;
    }
  }

  static uploadAvatarPhoto(user_id, params, type = null) {
    let formData = new FormData();
    if (params?.id) {
      formData.append("stock_image_id", params.id);
    } else {
      formData.append("photo", params.file);
    }
    if (type) {
      formData.append("type", type);
    }
    var request = new Request(apiHost + `/user/${user_id}/photo/`, {
      method: "POST",
      headers: Api.addAuthorizationHeader(),
      body: formData,
    });
    return fetch(request)
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        return json;
      })
      .catch((err) => err);
  }

  static removeAvatarPhoto(userId, image) {
    var request = new Request(apiHost + `/user/${userId}/photo/`, {
      headers: Api.addAuthorizationHeader(Api.defaultHeaders),
      method: "DELETE",
      body: JSON.stringify({ id: image.id }),
    });
    return fetch(request)
      .then((response) => {
        return response;
      })
      .catch((err) => err);
  }

  static getCurrentUserInfo(token) {
    const request = new Request(apiHost + `/user/${token}`, {
      method: "GET",
      headers: Api.addAuthorizationHeader(Api.defaultHeaders),
    });

    return fetch(request)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  }

  static uploadImage = async (image) => {
    const formData = new FormData();
    const token = parseInt((new Date().getDate() * 33333333) / 222);
    formData.append("token", "" + token);
    formData.append("image", image.file);
    formData.append("upload_type", image.type);
    const request = new Request(baseUrl + "/chat/image_upload", {
      method: "POST",
      headers: Api.addAuthorizationHeader({}),
      body: formData,
    });

    const response = await fetch(request);

    return response.json();
  };

  static uploadStockImageWithId = async (payload) => {
    const formData = new FormData();
    const token = parseInt((new Date().getDate() * 33333333) / 222);
    formData.append("token", "" + token);
    if (typeof imageId === "object") {
      formData.append("imageId", payload.imageId);
    } else {
      formData.append("imageId", payload);
    }
    formData.append("upload_type", "image");
    const request = new Request(baseUrl + "/chat/image_upload", {
      method: "POST",
      headers: Api.addAuthorizationHeader({}),
      body: formData,
    });

    const response = await fetch(request);
    return response.json();
  };

  static getAddressDataFromLatLng = _.memoize(
    async ({ lat, lng }) => {
      const response = await fetch(
        `${googleMapsApi}/geocode/json?latlng=${lat ?? 0},${
          lng ?? 0
        }&key=${googleMapsApiKey}`,
        {}
      );

      return response.json();
    },
    () => "getAddressDataFromLatLng"
  );

  static getLatLngFromAddress = async ({ addressString }) => {
    try {
      const response = await fetch(
        `${googleMapsApi}/geocode/json?address=${addressString}&key=${googleMapsApiKey}`
      );

      return response.json();
    } catch (err) {
      console.log(
        "Something went wrong while getting lat/lng for given address!"
      );
      console.log(err);
    }
  };

  static getPresets = async () => {
    try {
      const response = await fetch(apiHost + "/", {
        method: "GET",
        headers: Api.addAuthorizationHeader(Api.defaultHeaders),
      });

      return response.json();
    } catch (err) {
      console.log(err);
    }
  };

  static fetchWorkerById = async (id) => {
    try {
      const response = await fetch(`${apiHost}/worker/${id}/`, {
        method: "GET",
        headers: Api.addAuthorizationHeader(Api.defaultHeaders),
      });

      return response.json();
    } catch (err) {
      console.log(err);
    }
  };

  static getAllAdmins = async () => {
    const res = await client.query({
      query: gql`
        query {
          usersAdmins {
            id
            username
            firstName
            lastName
            isOnline
            email
          }
        }
      `,
    });
    return res.data.usersAdmins;
  };
  static getUserByAdmin = async (id) => {
    const res = await client.query({
      query: gql`
        query {
            usersCreatedByAdmin(id: "${id}") {
              id
              email
              username
              firstName
              lastName
              isOnline
            }
        }
      `,
    });
    return res.data.usersCreatedByAdmin;
  };

  static getModeratorByAdminId = async (id) => {
    try {
      const response = await fetch(`${apiHost}/moderator/user/${id}/`, {
        method: "GET",
        headers: Api.addAuthorizationHeader(Api.defaultHeaders),
      });

      return response.json();
    } catch (error) {
      console.log(error);
    }
  };

  static getCreatedByListByAdminId = async (id, role = "moderator") => {
    try {
      const response = await fetch(
        `${apiHost}/moderator/owner/${id}/?role=${role}`,
        {
          method: "GET",
          headers: Api.addAuthorizationHeader(Api.defaultHeaders),
        }
      );

      return response.json();
    } catch (error) {
      console.log(error);
    }
  };
}

Api.defaultHeaders = {
  "Content-Type": "application/json",
};
