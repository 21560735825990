import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  Dimmer,
  Form,
  Header,
  Icon,
  Image,
  Loader,
  Message,
  Modal,
  Segment,
  List,
} from "semantic-ui-react";

import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { getMoments, getStories } from "../../actions/fakeUsers";
import UserApi from "../../utils/users";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";
import { isAllowedRole } from "../../utils/auth";
import { ADMIN, SUPER_ADMIN } from "../../utils/localization";
import ErrorMessage from "../../components/errorMessage";
import MapContainer from "../../components/mapContainer";
import "./Users.css";
import { Api, baseUrl } from "../../utils/api";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import { commonConstants } from "../../constants/common";
import onlineIcon from "../../assets/images/onlineIcon.png";
import offlineIcon from "../../assets/images/offlineIcon.png";
import { isVideo } from "../../utils/helpers";

const User = (props) => {
  const [state, setState] = useState({
    isDirty: false,
    address: props?.users?.currentUser?.main?.address ?? "",
    geometry: {
      lat:
        Array.isArray(props?.users?.currentUser?.main?.location) &&
        !isEmpty(props?.users?.currentUser?.main?.location)
          ? props?.users?.currentUser?.main?.location[0]
          : 0,
      lng:
        Array.isArray(props?.users?.currentUser?.main?.location) &&
        !isEmpty(props?.users?.currentUser?.main?.location)
          ? props?.users?.currentUser?.main?.location[1]
          : 0,
    },
    addressParts: {
      city: props.users?.currentUser?.main?.city ?? "",
      country: props.users?.currentUser?.main?.country ?? "",
      zip_code: props.users?.currentUser?.main?.zip_code ?? "",
      country_code: props.users?.currentUser?.main?.country_code ?? "",
    },
    errors: [],
  });

  const [genderOptions, setGenderOptions] = useState([]);
  const [ageOptions, setAgeOptions] = useState([]);
  const [languageOptions, setLanguageOptions] = useState([]);
  const [ethnicityOptions, setEthnicityOptions] = useState([]);
  const [zodiacSignsOptions, setZodiacSignsOptions] = useState([]);
  const [familyOptions, setFamilyOptions] = useState([]);
  const [politicsOptions, setPoliticsOptions] = useState([]);
  const [religiousOptions, setReligiousOptions] = useState([]);
  const [heightOptions, setHeightOptions] = useState([]);
  const [tagsOptions, setTagsOptions] = useState([]);
  const [subscriptionOptions, setSubscriptionOptions] = useState([]);

  useEffect(() => {
    if (!props?.presets?.loading) {
      const presetsValue = props?.presets?.value;
      setAgeOptions(
        presetsValue?.ages?.map((item, index) => ({
          key: index,
          value: item?.id,
          text: item?.age,
        }))
      );
      setGenderOptions(
        presetsValue?.genders
          ?.map((item, index) => ({
            key: index,
            value: item?.id,
            text: item?.gender,
          }))
          ?.sort((a, b) => a?.value - b?.value)
      );
      setLanguageOptions(
        presetsValue?.languages
          ?.map((item, index) => ({
            key: index,
            value: item?.id,
            text: item?.language,
          }))
          ?.sort((a, b) => a?.value - b?.value)
      );
      setSubscriptionOptions(
        presetsValue?.subscriptions?.map((item, index) => ({
          key: index,
          value: item?.id,
          text: item?.name,
        }))
      );
      setEthnicityOptions(
        presetsValue?.ethnicity?.map((item, index) => ({
          key: index,
          value: item?.id,
          text: item?.ethnicity,
        }))
      );
      setHeightOptions(
        presetsValue?.heights?.map((item, index) => ({
          key: index,
          value: item?.id,
          text: item?.height,
        }))
      );
      setZodiacSignsOptions(
        presetsValue?.zodiacSigns?.map((item, index) => ({
          key: index,
          value: item?.id,
          text: item?.zodiacSign,
        }))
      );
      setFamilyOptions(
        presetsValue?.family?.map((item, index) => {
          return { key: index, value: item?.id, text: item?.familyPlans };
        })
      );
      setPoliticsOptions(
        presetsValue?.politics?.map((item, index) => {
          return { key: index, value: item?.id, text: item?.politics };
        })
      );
      setReligiousOptions(
        presetsValue?.religious?.map((item, index) => {
          return { key: index, value: item?.id, text: item?.religious };
        })
      );
      setTagsOptions(
        presetsValue?.tags?.map((item, index) => {
          return { key: index, value: item?.id, text: item?.tag };
        })
      );
    }
  }, [props?.presets?.value]);

  useEffect(() => {
    setState((state) => ({
      ...state,
      address: props.users?.currentUser?.main?.address ?? "",
      geometry: {
        lat:
          Array.isArray(props.users?.currentUser?.main?.location) &&
          !isEmpty(props.users?.currentUser?.main?.location)
            ? props.users?.currentUser?.main?.location[0]
            : 0,
        lng:
          Array.isArray(props.users?.currentUser?.main?.location) &&
          !isEmpty(props.users?.currentUser?.main?.location)
            ? props.users?.currentUser?.main?.location[1]
            : 0,
      },
      addressParts: {
        city: props.users?.currentUser?.main?.city ?? "",
        country: props.users?.currentUser?.main?.country ?? "",
        zip_code: props.users?.currentUser?.main?.zip_code ?? "",
        country_code: props.users?.currentUser?.main?.country_code ?? "",
      },
      interestedIn: props?.users?.currentUser?.main?.interested_in,
    }));
  }, [props?.users?.currentUser?.loading]);

  const handleSubmit = async () => {
    const user = props.users?.currentUser?.main;
    try {
      await Api.updateRealUser({
        id: user.id,
        about: user.about,
        fullName: user.display_name,
        gender: user.gender,
        age: user.age ? user.age : "35",
        language: user.language ? user.language : [],
        address: state.address,
        location: [state.geometry.lat, state.geometry.lng],
        city: state.addressParts.city,
        country: state.addressParts.country,
        zipCode: state.addressParts.zip_code,
        countryCode: state.addressParts.country_code,
        interestedIn: user.interestedIn ? user.interestedIn : [],
      });
      props.getUsersList(0);
      return (window.location.href = "#/users");
    } catch (error) {
      setState((state) => ({ ...state, errors: [error.response] }));
    }
  };

  const arr = String(state.interestedIn);
  const intrestedInArr = arr?.split(",")?.map(function (element) {
    return parseInt(element, 10);
  });

  return (
    <Segment.Group>
      <Dimmer.Dimmable
        as={Segment}
        dimmed={props?.users?.currentUser?.loading || props?.presets?.loading}
      >
        <Dimmer
          active={props?.users?.currentUser?.loading || props?.presets?.loading}
          inverted
        >
          <Loader> Loading </Loader>
        </Dimmer>
        {props?.users?.error && props?.users?.error?.visible && (
          <ErrorMessage
            error={props?.users?.error}
            hideError={props?.hideError}
          />
        )}
        <Card.Header className="cardHeader">
          <p
            style={{
              maxWidth: "60%",
              minWidth: "60%",
              wordWrap: "break-word",
            }}
          ></p>
          {props?.users?.currentUser?.main?.isOnline ? (
            <div>
              <img
                src={onlineIcon}
                alt="Online Icon"
                width="15px"
                height="15px"
              />{" "}
              Online
            </div>
          ) : (
            <div>
              <img
                src={offlineIcon}
                alt="Online Icon"
                width="15px"
                height="15px"
              />{" "}
              Offline
            </div>
          )}
        </Card.Header>

        <Segment secondary textAlign="center">
          <Button
            onClick={(e, data) =>
              props.unblockUser(props?.users?.currentUser?.main?.id)
            }
            size="large"
            icon="unlock"
            color="green"
            disabled={
              !!props?.users?.currentUser?.main &&
              !props?.users?.currentUser?.main?.is_blocked
            }
            content="Unblock user"
          />
          <Button
            onClick={(e, data) =>
              props.blockUser(props?.users?.currentUser?.main?.id)
            }
            size="large"
            icon="lock"
            color="yellow"
            disabled={
              !!props?.users?.currentUser?.main &&
              props?.users?.currentUser?.main?.is_blocked
            }
            content="Block user"
          />
          <Button
            onClick={(e, data) =>
              props.removeUserReports(props?.users?.currentUser?.main.id)
            }
            size="large"
            icon="trash"
            color="green"
            disabled={!!props?.users?.currentUser?.main?.reports}
            content="Remove reports"
          />
        </Segment>
        {!props?.users?.currentUser?.loading && !props?.presets?.loading && (
          <Segment padded>
            <Form>
              <Segment secondary>
                <Form.Input
                  fluid
                  label="Name"
                  placeholder="Name"
                  value={
                    props?.users?.currentUser?.main &&
                    props?.users?.currentUser?.main?.display_name
                      ? props?.users?.currentUser?.main?.display_name
                      : ""
                  }
                  // onChange={(e, { value }) => {
                  //   setState((state) => ({ ...state, errors: [] }));
                  //   props.updateEditUser({ display_name: value });
                  // }}
                />
                <Form.Input
                  fluid
                  label="Email"
                  placeholder="Email"
                  value={
                    `${props?.users?.currentUser?.main?.email
                      ?.slice(0, 3)
                      ?.padEnd(
                        props?.users?.currentUser?.main?.email?.slice(3)
                          ?.length,
                        "X"
                      )}` || ""
                  }
                  // onChange={props.changeEmail}
                  disabled={true}
                />
                <Form.Input
                  fluid
                  label="Phone"
                  placeholder="Phone"
                  value={
                    props?.users?.currentUser?.main &&
                    props?.users?.currentUser?.main?.phone
                      ? props?.users?.currentUser?.main.phone
                      : ""
                  }
                  disabled={true}
                  // onChange={props?.changePhone}
                />
                <Form.Input
                  fluid
                  label="Disabled"
                  placeholder="Disabled"
                  disabled={true}
                  readOnly={true}
                  value={
                    props?.users?.currentUser?.main &&
                    props?.users?.currentUser?.main?.disabled
                      ? "true"
                      : "false"
                  }
                />
                <Form.Input
                  fluid
                  label="SignUp date"
                  placeholder="SignUp date"
                  readOnly={true}
                  value={
                    props?.users?.currentUser?.main &&
                    props?.users?.currentUser?.main?.sign_up
                      ? new Date(
                          props?.users?.currentUser?.main.sign_up
                        ).toLocaleString()
                      : "-"
                  }
                />
                <Form.Group widths="equal">
                  <Form.Select
                    fluid
                    search
                    selection
                    label="Subscription (*)"
                    placeholder="Subscription"
                    options={subscriptionOptions}
                    value={
                      props?.users?.currentUser?.main?.user_subscription
                        ? props?.users?.currentUser?.main?.user_subscription
                            .package
                        : 0
                    }
                  />
                  <Form.Select
                    fluid
                    label="Age"
                    placeholder="Age"
                    type="number"
                    options={ageOptions}
                    value={
                      props?.users?.currentUser?.main &&
                      props?.users?.currentUser?.main?.age
                        ? props?.users?.currentUser?.main?.age
                        : 18
                    }
                    // onChange={(e, { value }) => {
                    //   setState((state) => ({ ...state, errors: [] }));
                    //   props.updateEditUser({ age: value });
                    // }}
                  />
                  <Form.Select
                    fluid
                    search
                    label="Height"
                    options={heightOptions}
                    placeholder="Height"
                    value={
                      props?.users?.currentUser?.main &&
                      props?.users?.currentUser?.main?.height
                        ? props?.users?.currentUser?.main?.height
                        : 140
                    }
                  />
                  <Form.Select
                    fluid
                    search
                    selection
                    label={"Ethnicity (*)"}
                    options={ethnicityOptions}
                    placeholder="Ethnicity"
                    value={
                      props?.users?.currentUser?.main &&
                      props?.users?.currentUser?.main?.ethnicity
                        ? props?.users?.currentUser?.main?.ethnicity
                        : ""
                    }
                  />
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Select
                    fluid
                    search
                    selection
                    label={"Zodiac Sign "}
                    options={zodiacSignsOptions}
                    placeholder="Zodiac Sign"
                    value={
                      props?.users?.currentUser?.main
                        ? props?.users?.currentUser?.main?.zodiacSign
                        : ""
                    }
                  />
                  <Form.Select
                    fluid
                    search
                    selection
                    label={"Family plans "}
                    options={familyOptions}
                    placeholder="Family plans"
                    value={
                      props?.users?.currentUser?.main &&
                      props?.users?.currentUser?.main?.familyPlans
                        ? props?.users?.currentUser?.main?.familyPlans
                        : ""
                    }
                  />
                  <Form.Select
                    fluid
                    search
                    selection
                    label={"Politics "}
                    options={politicsOptions}
                    placeholder="Politics"
                    value={
                      props?.users?.currentUser?.main &&
                      props?.users?.currentUser?.main?.politics
                        ? props?.users?.currentUser?.main?.politics
                        : ""
                    }
                  />
                  <Form.Select
                    search
                    label={"Religious Beliefs "}
                    options={religiousOptions}
                    placeholder="Religious Beliefs"
                    value={
                      props?.users?.currentUser?.main &&
                      props?.users?.currentUser?.main?.religion
                        ? props?.users?.currentUser?.main?.religion
                        : ""
                    }
                  />
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Select
                    fluid
                    label="Gender"
                    options={genderOptions}
                    placeholder="Gender"
                    // onChange={(e, { value }) => {
                    //   setState((state) => ({ ...state, errors: [] }));
                    //   props.updateEditUser({ gender: value });
                    // }}
                    value={props?.users?.currentUser?.main?.gender}
                  />
                  <Form.Select
                    fluid
                    label={"Selected Languages*"}
                    search
                    selection
                    options={languageOptions}
                    placeholder="Languages"
                    // onChange={(e, { value }) => {
                    //   setState((state) => ({ ...state, errors: [] }));
                    //   props.updateEditUser({ language: [value] });
                    // }}
                    value={
                      !isEmpty(props?.users?.currentUser?.main?.language)
                        ? props?.users?.currentUser?.main?.language[0]
                        : []
                    }
                  />
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Select
                    fluid
                    label={"Interested In (*)"}
                    multiple
                    search
                    selection
                    options={commonConstants.INTERESTED_IN_OPTIONS}
                    placeholder="Interested In"
                    value={
                      props?.users?.currentUser?.main?.interested_in
                        ? intrestedInArr
                        : []
                    }
                  />
                  <Form.Select
                    fluid
                    label={"Tags (*)"}
                    multiple
                    search
                    selection
                    options={tagsOptions}
                    placeholder="Tags"
                    value={
                      props?.users?.currentUser?.main &&
                      props?.users?.currentUser?.main?.tags
                        ? props?.users?.currentUser?.main?.tags
                        : ""
                    }
                  />
                </Form.Group>

                <Form.TextArea
                  label="About"
                  placeholder="About"
                  value={props?.users?.currentUser?.main?.about}
                  // onChange={props.changeAbout}
                />
                <Form.Group widths="equal">
                  <Form.Input
                    fluid
                    id="users-country"
                    label={"Country"}
                    placeholder="Country"
                    value={state.addressParts.country || ""}
                  />
                </Form.Group>
              </Segment>
              <Segment>
                <div
                  style={{
                    width: "100%",
                    position: "relative",
                  }}
                >
                  <MapContainer
                    latLng={{
                      lat:
                        Array.isArray(
                          props?.users?.currentUser?.main?.location
                        ) && !isEmpty(props?.users?.currentUser?.main?.location)
                          ? props?.users?.currentUser?.main?.location[0]
                          : 0,
                      lng:
                        Array.isArray(
                          props?.users?.currentUser?.main?.location
                        ) && !isEmpty(props?.users?.currentUser?.main?.location)
                          ? props?.users?.currentUser?.main?.location[1]
                          : 0,
                    }}
                    formattedAddress={
                      props?.users?.currentUser?.main?.address ?? ""
                    }
                    handelChange={(e) => {
                      setState((state) => ({
                        ...state,
                        address: e.formattedAddress,
                        geometry: {
                          lat: e.latLng.lat,
                          lng: e.latLng.lng,
                        },
                        addressParts: e.addressParts,
                        errors: [],
                      }));
                    }}
                    mapStyle={{
                      height: "600px",
                    }}
                    addressParts={{
                      city: props.users?.currentUser?.main?.city ?? "",
                      country: props.users?.currentUser?.main?.country ?? "",
                      zip_code: props.users?.currentUser?.main?.zip_code ?? "",
                    }}
                  />
                </div>
              </Segment>
              <Segment secondary>
                <Header size="large"> Reports </Header>
                {!props?.users?.currentUser?.main?.reports && (
                  <span>No reports</span>
                )}
                <ReportsList
                  reports={props?.users?.currentUser?.main?.reports || []}
                />
              </Segment>
              <Segment secondary>
                <Header size="large"> Photos </Header>
                <Card.Group>
                  <PhotosList
                    photos={
                      props?.users?.currentUser?.main?.avatar_photos || []
                    }
                  />
                </Card.Group>
              </Segment>
              <UserStories />
              <UserMoment />
              <Segment secondary textAlign="center">
                {state.errors.length ? (
                  <Message style={{ display: "block" }} error={true}>
                    <Message.Header>
                      There are some errors while updating user details!
                    </Message.Header>
                    <Message.List>
                      {state.errors.map((error, idx) => (
                        <Message.Item key={idx}>{error}</Message.Item>
                      ))}
                    </Message.List>
                  </Message>
                ) : null}
              </Segment>
              {isAllowedRole(
                [ADMIN, SUPER_ADMIN],
                props.session.userDetails
              ) && (
                <Segment secondary textAlign="center">
                  <Link to={"/users/"}>
                    <Button size="large" content="Cancel" />
                  </Link>
                  <Button
                    size="large"
                    color="blue"
                    content="Save"
                    onClick={(e) => {
                      e.preventDefault();
                      handleSubmit();
                    }}
                  />
                </Segment>
              )}
            </Form>
          </Segment>
        )}
      </Dimmer.Dimmable>
    </Segment.Group>
  );
};

export default withRouter(User);

const UsersPhoto = ({
  photoData,
  photoIndex,
  updatePhotoIndexSuccessCallback,
  handleDeleteModal,
}) => {
  const userDetails = useSelector((state) => state.users);
  const primaryImage = userDetails?.currentUser?.main?.avatar_index;
  const userId = userDetails?.currentUser?.main?.id;
  const [state, setState] = useState({
    imageId: "",
    errors: [],
    isUpdatingImageIndex: false,
  });

  const updateImageIndex = async () => {
    setState({
      ...state,
      isUpdatingImageIndex: true,
    });

    const res = await UserApi.updateProfileImageIndex({
      avatarIndex: photoIndex,
      userId,
    });

    if (!res) {
      setState({
        ...state,
        isLoading: false,
        isUpdatingImageIndex: false,
        errors: ["Something went wrong while updating profile image!"],
      });
      updatePhotoIndexSuccessCallback([
        {
          type: "error",
          text: "Something went wrong while updating profile image!",
        },
      ]);
      return;
    }

    if (res?.errors) {
      setState({
        ...state,
        isUpdatingImageIndex: false,
        errors: res?.errors ?? [],
      });
      const errors = res?.errors.map((error) => {
        return { type: "error", text: error };
      });
      updatePhotoIndexSuccessCallback(errors ?? []);
      return;
    }

    setState({
      ...state,
      isUpdatingImageIndex: false,
      errors: [],
    });
    updatePhotoIndexSuccessCallback();
  };

  const is_video = isVideo(photoData?.file || photoData?.file_url);

  return (
    <Card className="user-details-form_images-list">
      <div className="image-container">
        {is_video ? (
          <video style={{ width: "100%", height: "290px" }} autoPlay muted loop>
            <source
              src={photoData?.file || photoData?.file_url}
              type="video/mp4"
            />
          </video>
        ) : (
          <img src={photoData?.file || photoData?.file_url} alt="" />
        )}
        <button
          style={{
            width: 30,
            height: 30,
            background: "#ffffff",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            borderRadius: "10px",
            top: 5,
            right: 5,
            border: "none",
          }}
          type="button"
          onClick={() =>
            handleDeleteModal({
              imageId: photoData?.id,
              isDeleteModalOpen: true,
            })
          }
        >
          <div>
            <Icon
              className="create-fake-moderator-remove-moment-image-icon"
              link
              corner
              name={"trash"}
            />
          </div>
        </button>
      </div>

      <Card.Content>
        <div className="ui two buttons">
          {photoIndex === primaryImage ? (
            <Button type="button" color="grey" disabled>
              Primary image
            </Button>
          ) : (
            <Button
              loading={state.isUpdatingImageIndex}
              type="button"
              color="blue"
              onClick={() => updateImageIndex()}
            >
              Make primary
            </Button>
          )}
        </div>
      </Card.Content>
    </Card>
  );
};

const PhotosList = ({ photos }) => {
  const [state, setState] = useState({
    isDeleteModalOpen: false,
    imageId: "",
    isLoading: false,
    errors: [],
    isUpdatingImageIndex: false,
    messages: [],
  });

  const removeUserProfileImage = async () => {
    setState({
      ...state,
      isLoading: true,
    });
    const res = await UserApi.removeUserProfileImage({
      imageId: state.imageId,
    });
    if (!res?.success) {
      setState({
        ...state,
        isLoading: false,
        errors: [
          res?.message ?? "Something went wrong while removing profile image!",
        ],
      });
      return;
    }
    setState({
      ...state,
      isLoading: false,
      isDeleteModalOpen: false,
      errors: [],
    });
    window.location.reload();
  };

  const handleDeleteModal = ({ imageId, isDeleteModalOpen }) => {
    setState({
      ...state,
      imageId,
      isDeleteModalOpen,
      errors: [],
    });
  };

  const handleUpdatePhotoIndexSuccess = (
    messages = [
      {
        text: "Profile photo updated successfully 123",
        type: "success",
      },
    ]
  ) => {
    setState({
      ...state,
      errors: [],
      messages,
    });
    window.location.reload();
  };

  const list = photos?.map((ph, photoIndex) => (
    <UsersPhoto
      key={ph?.id}
      photoData={ph}
      photoIndex={photoIndex}
      updatePhotoIndexSuccessCallback={handleUpdatePhotoIndexSuccess}
      handleDeleteModal={handleDeleteModal}
    />
  ));

  const handleRemoveMessage = (messageIndex) => {
    let updatedMessages = state.messages?.filter(
      (msg, idx) => idx !== messageIndex
    );

    setState({
      ...state,
      messages: updatedMessages,
    });
  };

  return (
    <div style={{ width: "100%" }}>
      <Modal open={state.isDeleteModalOpen}>
        <Header icon="archive" content="Are you sure want to delete ?" />
        <Modal.Content>
          <p>The file should be not recovered!</p>
        </Modal.Content>
        <Modal.Actions>
          {state?.errors?.length
            ? state.errors?.map((error) => (
                <Message
                  style={{ display: "block", textAlign: "start" }}
                  error={true}
                >
                  {error}{" "}
                </Message>
              ))
            : null}
          <Button
            color="red"
            onClick={() =>
              handleDeleteModal({ imageId: "", isDeleteModalOpen: false })
            }
          >
            <Icon name="remove" /> No
          </Button>
          <Button
            loading={state?.loading}
            color="green"
            onClick={() => removeUserProfileImage()}
          >
            <Icon name="checkmark" /> Yes
          </Button>
        </Modal.Actions>
      </Modal>
      <div>
        {state?.messages?.length
          ? state.messages?.map((message, idx) => (
              <Message
                style={{ display: "block", textAlign: "start" }}
                error={message.type === "error"}
                success={message.type === "success"}
                onDismiss={() => handleRemoveMessage(idx)}
              >
                {message.text}
              </Message>
            ))
          : null}
      </div>
      <div
        style={{
          marginTop: 10,
          marginBottom: 10,
          display: "flex",
        }}
      >
        <Card.Group itemsPerRow={1}>{list}</Card.Group>
      </div>
    </div>
  );
};

const ReportsList = ({ reports }) => {
  const list = reports?.map((r) => <div>{r.reason}</div>);
  return <>{list}</>;
};

const StoryAddCommentModal = ({
  isModalOpen = false,
  handleClose,
  handleSuccess,
  storyId,
  comments,
}) => {
  const [state, setState] = useState({
    errors: [],
    isSubmitting: false,
  });

  const initialValues = {
    comment: "",
  };

  const handleFormSubmit = async (values) => {
    setState({
      ...state,
      errors: [],
      isSubmitting: true,
    });
    const res = await UserApi.addCommnetOnStory(storyId, values.comment);

    if (!res) {
      setState({
        ...state,
        isSubmitting: false,
        errors: [res?.message ?? "Something went wrong while posting comment!"],
      });
      return;
    }
    setState({
      ...state,
      isSubmitting: false,
      errors: [],
    });
    formik.resetForm();
    handleSuccess();
  };

  const formik = useFormik({
    initialValues,
    validationSchema: AddCommentModalFormState,
    validateOnChange: true,
    validateOnBlur: true,
    validateOnMount: false,
    onSubmit: handleFormSubmit,
  });

  const handleModalClose = () => {
    formik.resetForm();
    handleClose();
  };

  return (
    <Modal open={isModalOpen} onClose={handleModalClose}>
      <Header icon="comments" content="Add comment" />
      <Modal.Content>
        <List>
          {comments?.length === 0 && (
            <Header>No Comments for this Story</Header>
          )}
          {comments?.map((comment) => (
            <List.Item>
              <Image
                avatar
                src={comment?.node?.user?.avatarPhotos[0]?.url || ""}
              />
              <List.Content>
                <List.Header as="a">
                  {comment?.node?.user?.username}
                </List.Header>
                <List.Description>
                  {comment?.node?.commentDescription}
                </List.Description>
              </List.Content>
            </List.Item>
          ))}
        </List>
        {/* <Form style={{ padding: 0 }}>
          <Form.Group widths="equal">
            <Form.TextArea
              label="Comment"
              name="comment"
              placeholder="Write your comment here"
              value={formik.values.comment}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.comment && formik.errors.comment}
            />
          </Form.Group>
        </Form> */}
      </Modal.Content>
      <Modal.Actions>
        {state?.errors?.length
          ? state.errors?.map((error) => (
              <Message
                style={{ display: "block", textAlign: "start" }}
                error={true}
              >
                {error}{" "}
              </Message>
            ))
          : null}
        <Button color="red" onClick={() => handleModalClose()}>
          <Icon name="remove" /> Cancel
        </Button>
        {/* <Button
          loading={state.isSubmitting}
          type="submit"
          color="green"
          onClick={formik.handleSubmit}
        >
          <Icon name="checkmark" /> Submit
        </Button> */}
      </Modal.Actions>
    </Modal>
  );
};

const StoryLikeModal = ({
  isModalOpen = false,
  handleClose,
  handleSuccess,
  storyId,
  likeModalStatus,
  likes,
}) => {
  const [state, setState] = useState({
    errors: [],
    isSubmitting: false,
  });

  const handleLikeMoment = async (values) => {
    setState({
      ...state,
      errors: [],
      isSubmitting: true,
    });
    const res = await UserApi.addLikeOnStory(storyId);

    if (!res) {
      setState({
        ...state,
        isSubmitting: false,
        errors: [res?.message ?? "Something went wrong while posting like!"],
      });
      return;
    }
    setState({
      ...state,
      isSubmitting: false,
      errors: [],
    });
    handleSuccess();
  };

  const handleModalClose = () => {
    handleClose();
  };

  return (
    <Modal open={isModalOpen} onClose={handleModalClose}>
      <Header icon="like" content="Likes" />
      <Modal.Content>
        <List>
          {likes.length === 0 && <Header>No Likes for this Story</Header>}
          {likes?.map((like) => (
            <List.Item>
              <Image avatar src={like.node.user.avatarPhotos[0]?.url || ""} />
              <List.Content>
                <List.Header as="a">{like.node.user.username}</List.Header>
                <List.Description>
                  Last seen at:{" "}
                  {new Date(like.node.user.lastSeen).toLocaleString()}
                </List.Description>
              </List.Content>
            </List.Item>
          ))}
        </List>
      </Modal.Content>
      <Modal.Actions>
        {state?.errors?.length
          ? state.errors.map((error) => (
              <Message
                style={{ display: "block", textAlign: "start" }}
                error={true}
              >
                {error}{" "}
              </Message>
            ))
          : null}
        <Button color="red" onClick={() => handleModalClose()}>
          <Icon name="remove" /> Cancel
        </Button>
        {/* <Button
          loading={state.isSubmitting}
          type="submit"
          color="green"
          onClick={handleLikeMoment}
        >
          <Icon name="like" /> Like
        </Button> */}
      </Modal.Actions>
    </Modal>
  );
};

const UserStories = () => {
  const dispatch = useDispatch();
  const fakeUsersState = useSelector((state) => state.fakeUsers);
  const userDetails = useSelector((state) => state.users);
  const stories = fakeUsersState.stories;
  const [likes, setLikes] = useState([]);
  const [comments, setComments] = useState([]);
  const [likeModalOpen, setLikeModalOpen] = useState(false);
  const [likeModalStatus, setLikeModalStatus] = useState({
    text: "",
    type: "",
  });
  const [state, setState] = useState({
    isDirty: false,
    storyId: "",
    isDeleteModalOpen: false,
    errors: [],
    isLoading: false,
  });
  const userId = userDetails?.currentUser?.main?.id;

  useEffect(() => {
    let interval;
    if (userId) {
      dispatch(getStories(userId));

      interval = setInterval(() => {
        dispatch(getStories(userId));
      }, 5000);
    }
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [userDetails]);

  const removeUserStory = async (storyID) => {
    setState({
      ...state,
      isDeleteModalOpen: true,
      storyId: storyID,
      errors: [],
    });
  };
  const handleDeleteStory = async () => {
    setState({
      ...state,
      isLoading: true,
    });
    const res = await UserApi.removeUserStory({ storyId: state.storyId });
    if (!res?.success) {
      setState({
        ...state,
        isLoading: false,
        errors: [
          res?.message ?? "Something went wrong while removing user story!",
        ],
      });
      return;
    }
    dispatch(getStories(userId));
    setState({
      ...state,
      isLoading: false,
      isDeleteModalOpen: false,
      errors: [],
    });
  };

  const handleCommentModalClose = () => {
    setState({
      ...state,
      commentModalState: false,
      errors: [],
      storyId: "",
    });
  };

  const hanldeAddCommentSuccess = () => {
    setState({
      ...state,
      commentModalState: false,
      errors: [],
      storyId: "",
      messages: [
        {
          text: "Comment posted successfully",
          type: "success",
        },
      ],
    });
    dispatch(getStories(userId));
  };

  const handleLikeModalClose = () => {
    setLikeModalOpen(false);
  };

  const handleLikeSuccess = () => {
    setLikeModalOpen(false);
    setLikeModalStatus({
      text: "Liked successfully",
      type: "success",
    });
    dispatch(getStories(userId));
  };

  const handleLikeModal = (isOpen, story) => {
    setLikeModalOpen(isOpen);
    setState({
      ...state,
      storyId: story.pk,
    });
    setLikes(story.likes);
  };

  const handleCommentModal = (isOpen, story) => {
    setState({
      ...state,
      storyId: story.pk,
      commentModalState: isOpen,
    });
    setComments(story.comments);
  };

  return (
    <>
      <Modal open={state.isDeleteModalOpen}>
        <Header icon="archive" content="Are you sure want to delete ?" />
        <Modal.Content>
          <p>The file should be not recovered!</p>
        </Modal.Content>
        <Modal.Actions>
          {state?.errors?.length
            ? state.errors.map((error) => (
                <Message
                  style={{ display: "block", textAlign: "start" }}
                  error={true}
                >
                  {error}{" "}
                </Message>
              ))
            : null}
          <Button
            color="red"
            onClick={() => {
              setState({
                ...state,
                errors: [],
                isDeleteModalOpen: false,
                isLoading: false,
              });
            }}
          >
            <Icon name="remove" /> No
          </Button>
          <Button color="green" onClick={handleDeleteStory}>
            <Icon name="checkmark" /> Yes
          </Button>
        </Modal.Actions>
      </Modal>

      <StoryAddCommentModal
        isModalOpen={state.commentModalState}
        storyId={state.storyId}
        comments={comments}
        handleClose={handleCommentModalClose}
        handleSuccess={hanldeAddCommentSuccess}
      />

      <StoryLikeModal
        isModalOpen={likeModalOpen}
        storyId={state.storyId}
        likes={likes}
        handleClose={handleLikeModalClose}
        handleSuccess={handleLikeSuccess}
        likeModalStatus={likeModalStatus}
      />

      <Segment
        inverted={state.isDirty && fakeUsersState.avatar_photos.length === 0}
        tertiary={state.isDirty && fakeUsersState.avatar_photos.length === 0}
        secondary={state.isDirty && fakeUsersState.avatar_photos.length > 0}
        color={
          state.isDirty && fakeUsersState.avatar_photos.length === 0
            ? "red"
            : "black"
        }
      >
        <Header
          size="large"
          color={
            state.isDirty && fakeUsersState.photos.length === 0
              ? "red"
              : "black"
          }
        >
          Story
        </Header>
        {stories?.length === 0 && <Header>No stories selected</Header>}
        <Card.Group itemsPerRow={4}>
          {stories?.map((image, index) => {
            const imageReplace = image?.file?.replaceAll(
              `${baseUrl}/media/`,
              ``
            );
            return (
              <Card key={index} className="user-card-main-container">
                <div className="user-moment-image">
                  <Image src={imageReplace} centered />
                  <div>
                    <button
                      style={{
                        width: 30,
                        height: 30,
                        background: "#ffffff",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        position: "absolute",
                        borderRadius: "10px",
                        top: 5,
                        right: 79,
                        border: "none",
                      }}
                      type="button"
                      onClick={() => handleLikeModal(true, image)}
                    >
                      <div
                        style={{
                          position: "absolute",
                          top: "-3px",
                          right: "-3px",
                          width: "17px",
                          height: "17px",
                          borderRadius: "50%",
                          backgroundColor: "gold",
                          fontSize: "10px",
                          display: "grid",
                          placeItems: "center",
                        }}
                      >
                        {image?.likesCount || 0}
                      </div>
                      <div>
                        <Icon
                          className="create-fake-moderator-remove-moment-image-icon"
                          link
                          corner
                          name={"like"}
                        />
                      </div>
                    </button>
                    <button
                      style={{
                        width: 30,
                        height: 30,
                        background: "#ffffff",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        position: "absolute",
                        borderRadius: "10px",
                        top: 5,
                        right: 42,
                        border: "none",
                      }}
                      type="button"
                      onClick={() => handleCommentModal(true, image)}
                    >
                      <div
                        style={{
                          position: "absolute",
                          top: "-3px",
                          right: "-3px",
                          width: "17px",
                          height: "17px",
                          borderRadius: "50%",
                          backgroundColor: "gold",
                          fontSize: "10px",
                          display: "grid",
                          placeItems: "center",
                        }}
                      >
                        {image?.commentsCount || 0}
                      </div>
                      <div>
                        <Icon
                          className="create-fake-moderator-remove-moment-image-icon"
                          link
                          corner
                          name={"comments"}
                        />
                      </div>
                    </button>
                    <button
                      style={{
                        width: 30,
                        height: 30,
                        background: "#ffffff",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        position: "absolute",
                        borderRadius: "10px",
                        top: 5,
                        right: 5,
                        border: "none",
                      }}
                      type="button"
                      onClick={() => removeUserStory(image?.id)}
                    >
                      <div>
                        <Icon
                          className="create-fake-moderator-remove-moment-image-icon"
                          link
                          corner
                          name={"trash"}
                        />
                      </div>
                    </button>
                  </div>
                </div>
              </Card>
            );
          })}
        </Card.Group>
      </Segment>
    </>
  );
};

const AddCommentModalFormState = yup.object().shape({
  comment: yup.string().required("Comment text is requiered!"),
});

const AddCommentModal = ({
  isModalOpen = false,
  handleClose,
  handleSuccess,
  momentId,
}) => {
  const [comments, setComments] = useState([]);

  const [state, setState] = useState({
    errors: [],
    isSubmitting: false,
  });

  const initialValues = {
    comment: "",
  };

  const handleFormSubmit = async (values) => {
    setState({
      ...state,
      errors: [],
      isSubmitting: true,
    });
    const res = await UserApi.addCommnetOnMoment({
      momentId,
      comment: values.comment,
    });

    if (!res) {
      setState({
        ...state,
        isSubmitting: false,
        errors: [res?.message ?? "Something went wrong while posting comment!"],
      });
      return;
    }
    setState({
      ...state,
      isSubmitting: false,
      errors: [],
    });
    formik.resetForm();
    handleSuccess();
  };

  const formik = useFormik({
    initialValues,
    validationSchema: AddCommentModalFormState,
    validateOnChange: true,
    validateOnBlur: true,
    validateOnMount: false,
    onSubmit: handleFormSubmit,
  });

  const handleModalClose = () => {
    formik.resetForm();
    handleClose();
  };

  const getMomentComments = async () => {
    const res = await UserApi.getMomentComments(momentId);

    if (res) {
      setComments(res);
    }
  };

  useEffect(() => {
    if (momentId) {
      getMomentComments(momentId);
    }
  }, [momentId, isModalOpen]);

  return (
    <Modal open={isModalOpen} onClose={handleModalClose}>
      <Header icon="comments" content="Add comment" />
      <Modal.Content>
        <List>
          {comments.length === 0 && (
            <Header>No Comments for this Moment</Header>
          )}
          {comments.map((comment) => (
            <List.Item
              style={{
                padding: "5px 0px",
              }}
            >
              <Image avatar src={comment.user.avatarPhotos[0]?.url || ""} />
              <List.Content>
                <List.Header as="a">{comment.user.username}</List.Header>
                <List.Description>
                  {comment.commentDescription}
                </List.Description>
              </List.Content>
            </List.Item>
          ))}
        </List>
        {/* <Form style={{ padding: 0 }}>
          <Form.Group widths="equal">
            <Form.TextArea
              label="Comment"
              name="comment"
              placeholder="Write your comment here"
              value={formik.values.comment}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.comment && formik.errors.comment}
            />
          </Form.Group>
        </Form> */}
      </Modal.Content>
      <Modal.Actions>
        {state?.errors?.length
          ? state.errors.map((error) => (
              <Message
                style={{ display: "block", textAlign: "start" }}
                error={true}
              >
                {error}
              </Message>
            ))
          : null}
        <Button color="red" onClick={() => handleModalClose()}>
          <Icon name="remove" /> Cancel
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

const LikeModal = ({
  isModalOpen = false,
  handleClose,
  handleSuccess,
  momentId,
}) => {
  const [likes, setLikes] = useState([]);

  const [state, setState] = useState({
    errors: [],
    isSubmitting: false,
  });

  const handleLikeMoment = async (values) => {
    setState({
      ...state,
      errors: [],
      isSubmitting: true,
    });
    const res = await UserApi.addLikeOnMoment(momentId);

    if (!res) {
      setState({
        ...state,
        isSubmitting: false,
        errors: [res?.message ?? "Something went wrong while posting like!"],
      });
      return;
    }
    setState({
      ...state,
      isSubmitting: false,
      errors: [],
    });
    handleSuccess();
  };

  const handleModalClose = () => {
    handleClose();
  };

  const getMomentLikes = async () => {
    const res = await UserApi.getMomentLikes(momentId);
    if (res) {
      setLikes(res);
    }
  };

  useEffect(() => {
    if (momentId) {
      getMomentLikes(momentId);
    }
  }, [momentId, isModalOpen]);

  // console.log("likes", likes);

  return (
    <Modal open={isModalOpen} onClose={handleModalClose}>
      <Header icon="like" content="Likes" />
      <Modal.Content>
        <List>
          {likes.length === 0 && <Header>No Likes for this Moment</Header>}
          {likes?.map((like) => (
            <List.Item style={{ marginBottom: "10px" }}>
              <Image avatar src={like.user.avatarPhotos[0]?.url || ""} />
              <List.Content>
                <List.Header as="a">{like.user.username}</List.Header>
                <List.Description>
                  Last seen at: {new Date(like.user.lastSeen).toLocaleString()}
                </List.Description>
              </List.Content>
            </List.Item>
          ))}
        </List>
      </Modal.Content>
      <Modal.Actions>
        {state?.errors?.length
          ? state.errors.map((error) => (
              <Message
                style={{ display: "block", textAlign: "start" }}
                error={true}
              >
                {error}{" "}
              </Message>
            ))
          : null}
        <Button color="red" onClick={() => handleModalClose()}>
          <Icon name="remove" /> Cancel
        </Button>
        {/* <Button
          loading={state.isSubmitting}
          type="submit"
          color="green"
          onClick={handleLikeMoment}
        >
          <Icon name="like" /> Like
        </Button> */}
      </Modal.Actions>
    </Modal>
  );
};

const UserMoment = () => {
  const dispatch = useDispatch();
  const fakeMomentState = useSelector((state) => state.fakeUsers);
  const stories = fakeMomentState.moments;
  const userDetails = useSelector((state) => state.users);
  const userId = userDetails?.currentUser?.main?.id;
  const [likeModalOpen, setLikeModalOpen] = useState(false);
  const [likeModalStatus, setLikeModalStatus] = useState({
    text: "",
    type: "",
  });
  const [state, setState] = useState({
    isDirty: false,
    momentId: "",
    isDeleteModalOpen: false,
    errors: [],
    isLoading: true,
    commentModalState: false,
    messages: [],
  });

  useEffect(() => {
    let interval;
    if (userId) {
      dispatch(getMoments(userId));

      interval = setInterval(() => {
        dispatch(getMoments(userId));
      }, 5000);
    }
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [userDetails]);

  const removeUserMoment = async (momentID) => {
    setState({
      ...state,
      isDeleteModalOpen: true,
      momentId: momentID,
      errors: [],
    });
  };

  const handleDeleteMoment = async () => {
    setState({
      ...state,
      isLoading: true,
      errors: [],
    });
    const res = await UserApi.removeUserMoment({ momentId: state.momentId });

    if (!res) {
      setState({
        ...state,
        isLoading: false,
        errors: [res?.message ?? "Something went wrong while removing moment!"],
      });
      return;
    }
    dispatch(getMoments(userId));
    setState({
      ...state,
      isLoading: false,
      errors: [],
      isDeleteModalOpen: false,
    });
  };

  const handleCommentModalClose = () => {
    setState({
      ...state,
      commentModalState: false,
      errors: [],
      momentId: "",
    });
  };

  const hanldeAddCommentSuccess = () => {
    setState({
      ...state,
      commentModalState: false,
      errors: [],
      momentId: "",
      messages: [
        {
          text: "Comment posted successfully",
          type: "success",
        },
      ],
    });
    dispatch(getMoments(userId));
  };

  const handleLikeModalClose = () => {
    setLikeModalOpen(false);
  };

  const handleLikeSuccess = () => {
    setLikeModalOpen(false);
    setLikeModalStatus({
      text: "Liked successfully",
      type: "success",
    });
    dispatch(getMoments(userId));
  };

  const handleRemoveMessage = (messageIndex) => {
    let updatedMessages = state.messages.filter(
      (msg, idx) => idx !== messageIndex
    );

    setState({
      ...state,
      messages: updatedMessages,
    });
  };

  const handleLikeModal = (isOpen, moment) => {
    // console.log(isOpen, moment);
    setLikeModalOpen(isOpen);
    setState({
      ...state,
      momentId: moment.id,
    });
  };

  return (
    <>
      <Modal open={state.isDeleteModalOpen}>
        <Header icon="archive" content="Are you sure want to delete ?" />
        <Modal.Content>
          <p>The file should be not recovered!</p>
        </Modal.Content>
        <Modal.Actions>
          {state?.errors?.length
            ? state.errors.map((error) => (
                <Message
                  style={{ display: "block", textAlign: "start" }}
                  error={true}
                >
                  {error}{" "}
                </Message>
              ))
            : null}
          <Button
            color="red"
            onClick={() =>
              setState({
                ...state,
                isDeleteModalOpen: false,
              })
            }
          >
            <Icon name="remove" /> No
          </Button>
          <Button color="green" onClick={handleDeleteMoment}>
            <Icon name="checkmark" /> Yes
          </Button>
        </Modal.Actions>
      </Modal>

      <AddCommentModal
        isModalOpen={state.commentModalState}
        momentId={state.momentId}
        handleClose={handleCommentModalClose}
        handleSuccess={hanldeAddCommentSuccess}
      />

      <LikeModal
        isModalOpen={likeModalOpen}
        momentId={state.momentId}
        handleClose={handleLikeModalClose}
        handleSuccess={handleLikeSuccess}
        likeModalStatus={likeModalStatus}
      />

      <Segment
        inverted={state.isDirty && fakeMomentState.avatar_photos.length === 0}
        tertiary={state.isDirty && fakeMomentState.avatar_photos.length === 0}
        secondary={state.isDirty && fakeMomentState.avatar_photos.length > 0}
        color={
          state.isDirty && fakeMomentState.avatar_photos.length === 0
            ? "red"
            : "black"
        }
      >
        <Header
          size="large"
          color={
            state.isDirty && fakeMomentState.photos.length === 0
              ? "red"
              : "black"
          }
        >
          Moment
        </Header>
        {stories?.length === 0 && <Header>No moments selected</Header>}
        <Card.Group itemsPerRow={4}>
          <div>
            {state?.messages?.length
              ? state.messages?.map((message, idx) => (
                  <Message
                    style={{ display: "block", textAlign: "start" }}
                    error={message.type === "error"}
                    success={message.type === "success"}
                    onDismiss={() => handleRemoveMessage(idx)}
                  >
                    {message.text}
                  </Message>
                ))
              : null}
          </div>
          {stories?.map((image, index) => {
            const imageReplace = image?.file?.replaceAll(
              `${baseUrl}/media/`,
              ``
            );
            return (
              <Card key={index}>
                <Image src={imageReplace} centered />
                <div>
                  <button
                    style={{
                      width: 30,
                      height: 30,
                      background: "#ffffff",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      position: "absolute",
                      borderRadius: "10px",
                      top: 5,
                      right: 79,
                      border: "none",
                    }}
                    type="button"
                    onClick={() => handleLikeModal(true, image)}
                  >
                    <div
                      style={{
                        position: "absolute",
                        top: "-3px",
                        right: "-3px",
                        width: "17px",
                        height: "17px",
                        borderRadius: "50%",
                        backgroundColor: "gold",
                        fontSize: "10px",
                        display: "grid",
                        placeItems: "center",
                      }}
                    >
                      {image?.likes || 0}
                    </div>
                    <div>
                      <Icon
                        className="create-fake-moderator-remove-moment-image-icon"
                        link
                        corner
                        name={"like"}
                      />
                    </div>
                  </button>
                  <button
                    style={{
                      width: 30,
                      height: 30,
                      background: "#ffffff",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      position: "absolute",
                      borderRadius: "10px",
                      top: 5,
                      right: 42,
                      border: "none",
                    }}
                    type="button"
                    onClick={() =>
                      setState({
                        ...state,
                        commentModalState: true,
                        errors: [],
                        momentId: image?.id ?? "",
                      })
                    }
                  >
                    <div
                      style={{
                        position: "absolute",
                        top: "-3px",
                        right: "-3px",
                        width: "17px",
                        height: "17px",
                        borderRadius: "50%",
                        backgroundColor: "gold",
                        fontSize: "10px",
                        display: "grid",
                        placeItems: "center",
                      }}
                    >
                      {image?.comments || 0}
                    </div>
                    <div>
                      <Icon
                        className="create-fake-moderator-remove-moment-image-icon"
                        link
                        corner
                        name={"comments"}
                      />
                    </div>
                  </button>
                  <button
                    style={{
                      width: 30,
                      height: 30,
                      background: "#ffffff",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      position: "absolute",
                      borderRadius: "10px",
                      top: 5,
                      right: 5,
                      border: "none",
                    }}
                    type="button"
                    onClick={() => removeUserMoment(image?.id)}
                  >
                    <div>
                      <Icon
                        className="create-fake-moderator-remove-moment-image-icon"
                        link
                        corner
                        name={"trash"}
                      />
                    </div>
                  </button>
                </div>
                <Card.Content className="moments-list-card-bottom-content">
                  <p style={{ marginBottom: 20, wordBreak: "break-all" }}>
                    {image?.momentDescription}
                  </p>
                </Card.Content>
              </Card>
            );
          })}
        </Card.Group>
      </Segment>
    </>
  );
};

User.propTypes = {
  changeAddress: PropTypes.func.isRequired,
};
