import { contactActionTypes } from "../../constants/actions/contactUs";
import { Api } from "../../utils/api";


export function contactUsAction() {
    return function (dispatch) {
      dispatch({
        type: contactActionTypes.CONTACT_US_ACTION,
      });
    };
  }

  export function contactUsSaveAction(params) {    
    return function (dispatch) {
      dispatch({
        type: contactActionTypes.CONTACT_US_ACTION_SAVE,
      });
      return Api.contactUs(params)
        .then((response) => {
          if (response.status === 400) {
            errorMessage = "Invalid params";
            dispatch(contactUsFailure(errorMessage));
            return;
          }
          if (response.status !== 201) {
            var errorMessage =
              "Unknown error. HTTP Status code: " + response.status;
            dispatch(contactUsFailure(errorMessage));
            return;
          }
          dispatch(contactUsSuccess());
        })
        .catch((error) => {
          console.log("fail", error);
          dispatch(contactUsFailure(error));
        });
    };
  }
  

  export function enterContactEmailAction(value) {
    return {
      type: contactActionTypes.CONTACT_US_EMAIL_ACTION,
      data: {
        email: value,
      },
    };
  }

  export function enterContactNameAction(value) {
    return {
      type: contactActionTypes.CONTACT_US_NAME_ACTION,
      data: {
        name: value,
      },
    };
  }

  export function enterContactMessageAction(value) {
    return {
      type: contactActionTypes.CONTACT_US_MESSAGE_ACTION,
      data: {
        message: value,
      },
    };
  }

  function contactUsFailure(error) {
    return {
      type: contactActionTypes.CONTACT_US_FAILURE,
      data: { error: error },
    };
  }

  function contactUsSuccess() {
    return {
      type: contactActionTypes.CONTACT_US_SUCCESS,
    };
  }