import _ from "lodash";
import { moderatorsActionTypes } from "../constants/actions/moderators";
import { sessionActionTypes } from "../constants/actions/session";

const initialState = {
  moderators: [],
  alladmins: [],
  adminusers: [],
  moderatoradmin: [],
  specificmoderatorlist: [],
  search: "",
  currentPageSize: 0,
  sort_column: "",
  direction: 0,
  next: "",
  previous: "",
  currentUser: {
    main: {},
    details: {},
  },
  newModerator: {},
  loading: true,
  error: {
    visible: false,
    header: "",
    messages: [],
  },
};

export default function moderators(state = initialState, action) {
  switch (action.type) {
    case moderatorsActionTypes.GET_ALL_ADMINS:
      return {
        ...state,
        alladmins: action.data,
      };
    case moderatorsActionTypes.GET_USER_ADMINS:
      return {
        ...state,
        adminusers: action.data,
      };
    case moderatorsActionTypes.GET_MODERATOR_ADMINS_ID:
      return {
        ...state,
        moderatoradmin: action.data,
      };
    case moderatorsActionTypes.GET_MODERATOR_LIST_ADMINS_ID:
      return {
        ...state,
        specificmoderatorlist: action.data,
      };
    case sessionActionTypes.LOGIN_SUCCESS:
      return {
        ...state,
        error: {
          visible: false,
          header: "",
          messages: [],
        },
      };
    case moderatorsActionTypes.CLEAR_MODERATORS:
      return {
        ...state,
        newModerator: {
          ...state.newModerator,
          _created: false,
        },
        currentUser: {
          ...state.currentUser,
          _updated: false,
        },
        loading: false,
      };
    case moderatorsActionTypes.GET_MODERATORS_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case moderatorsActionTypes.GET_MODERATORS_LIST_FAILURE:
      return {
        ...state,
        error: {
          ...state.error,
          header:
            action.data.header && action.data.header !== ""
              ? action.data.header
              : "",
          messages:
            action.data.errors && action.data.errors.length > 0
              ? action.data.errors
              : [],
          visible: true,
        },
        loading: false,
      };
    case moderatorsActionTypes.GET_MODERATORS_LIST_SUCCESS:
      if (!action.data instanceof Array) {
        return state;
      }

      if (action.data.length === 0) {
        return state;
      }
      return {
        ...state,
        moderators: action.data.moderators,
        currentPageSize: action.data.currentPageSize,
        offset: action.data.offset,
        size: action.data.size,
        next: action.data.next,
        filter: action.data.filter,
        previous: action.data.previous,
        error: {
          ...state.error,
          visible: false,
        },
        loading: false,
      };

    case moderatorsActionTypes.SEARCH_VALUE_CHANGE:
      return {
        ...state,
        search: action.data,
      };

    case moderatorsActionTypes.SEARCH_MODERATORS_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case moderatorsActionTypes.SEARCH_MODERATORS_LIST_FAILURE:
      return {
        ...state,
        error: {
          ...state.error,
          header:
            action.data.header && action.data.header !== ""
              ? action.data.header
              : "",
          messages:
            action.data.errors && action.data.errors.length > 0
              ? action.data.errors
              : [],
          visible: true,
        },
        loading: false,
      };
    case moderatorsActionTypes.SEARCH_MODERATORS_LIST_SUCCESS:
      if (!action.data.results instanceof Array) {
        return { ...state, loading: false };
      }
      if (action.data.results.length === 0) {
        return { ...state, loading: false };
      }

      var moderators = action.data.results;
      if (state.direction && state.direction === "ascending") {
        moderators = _.sortBy(moderators, "disabled");
      } else if (state.direction && state.direction === "descending") {
        moderators = _.sortBy(moderators, "disabled");
        moderators = moderators.reverse();
      }
      return {
        ...state,
        moderators,
        currentPage: "",
        nextPage: "",
        error: {
          ...state.error,
          visible: false,
        },
        newModerator: {
          _created: false,
        },
        currentUser: {
          _updated: false,
        },
        loading: false,
      };
    case moderatorsActionTypes.HIDE_ERROR:
      return {
        ...state,
        error: {
          visible: false,
          header: "",
          messages: [],
        },
      };
    default:
      return state;
  }
}
