import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import {
  Menu,
  Icon,
  Dropdown,
  Modal,
  Table,
  TableHeader,
  TableBody,
  TableRow,
  TableHeaderCell,
  TableCell,
  TableFooter,
} from "semantic-ui-react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect, useSelector } from "react-redux";
import moment from "moment";

import { ADMIN, CHATTER } from "../../utils/localization";
import { fetchPresets } from "../../actions/presets";
import DisplayQueueCounts from "./DisplayQueueCounts";
import "./menu.css";
import { checkWorkerCanChat } from "../../actions/chat";
import {
  sameDayMessagesStats,
  sameMonthMessagesStats,
  sameWeekMessagesStats,
} from "../../actions/workers";
import { canVisitAdminListing, canVisitUserListing } from "../../actions/users";
const statsOptions = [
  {
    key: 0,
    text: "Day",
    value: 1,
  },
  {
    key: 1,
    text: "Week",
    value: 2,
  },
  {
    key: 2,
    text: "Month",
    value: 3,
  },
  {
    key: 3,
    text: "Prev. Month",
    value: 4,
  },
];

const WorkerStatsModal = ({ open, onClose, selectedBy }) => {
  const topNavWorkerStats = useSelector(
    (state) => state.workers.topNavWorkerStats
  );
  return (
    <Modal open={open} onClose={onClose}>
      <Modal.Header>
        Worker Messages Statistics by "{statsOptions[selectedBy].text}"
      </Modal.Header>
      <Modal.Content>
        <div style={{ overflow: "auto", maxHeight: "67dvh" }}>
          <Table
            celled
            selectable
            loading={topNavWorkerStats?.isLoading?.toString()}
            key={topNavWorkerStats?.statsArr?.length}
          >
            <TableHeader>
              <TableRow>
                <TableHeaderCell>
                  {selectedBy === 1 ? "Day" : "Date"}
                </TableHeaderCell>
                <TableHeaderCell>Received Messages</TableHeaderCell>
                <TableHeaderCell>Sent Messages</TableHeaderCell>
              </TableRow>
            </TableHeader>

            {topNavWorkerStats?.statsArr?.length && (
              <TableBody>
                {topNavWorkerStats?.statsArr?.map((stat, indx) => {
                  const workDay = stat?.date || stat?.day;
                  const normalizedWorkDay = moment
                    .utc(workDay)
                    .format(selectedBy === 1 ? "dddd" : "DD-MMMM-YYYY");
                  return (
                    <TableRow
                      key={`${statsOptions[selectedBy].text}-${
                        workDay || indx
                      }`}
                    >
                      <TableCell>{normalizedWorkDay}</TableCell>
                      <TableCell>{stat?.receivedCount}</TableCell>
                      <TableCell>{stat?.sentCount}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            )}

            {!topNavWorkerStats?.isLoading && (
              <TableFooter>
                <TableRow>
                  <TableHeaderCell>
                    {statsOptions[selectedBy].text} Total
                  </TableHeaderCell>
                  <TableHeaderCell>
                    {topNavWorkerStats?.receivedCount}
                  </TableHeaderCell>
                  <TableHeaderCell>
                    {topNavWorkerStats?.sentCount}
                  </TableHeaderCell>
                </TableRow>
              </TableFooter>
            )}
          </Table>
        </div>
      </Modal.Content>
    </Modal>
  );
};

const MainMenu = memo((props) => {
  const logout = useCallback(
    (e) => {
      e.preventDefault();
      if (typeof props.onLogout === "function") {
        props.onLogout();
      }
    },
    [props]
  );

  const setMenu = useCallback(
    (name) => props.setActiveMenuPosition(name),
    [props]
  );
  const isAllowed_ChatInQueue = props.chat?.chatQueueSettings?.[0]?.isAllowed;
  const workerId = props.session?.userDetails?.id;
  const [openModal, setOpenModal] = useState(false);
  const [selectedBy, setSelectedBy] = useState(0);

  useEffect(() => {
    if (workerId) {
      props.checkWorkerCanChat();
      props.canVisitUserListing();
      props.canVisitAdminListing();
      props.sameDayMessagesStats(workerId);

      /* let stats_interval;

      if (props.session.isAuthenticated && !props.topNavWorkerStats.isLoading) {
        dropDownChangeHandler(selectedBy + 1);

        stats_interval = setInterval(() => {
          dropDownChangeHandler(selectedBy + 1);
        }, 5000);
      }

      return () => {
        if (stats_interval) {
          clearInterval(stats_interval);
        }
      }; */
    }
  }, []);

  const dropDownChangeHandler = (val) => {
    setSelectedBy(val - 1);
    switch (val) {
      case 4:
        props.sameMonthMessagesStats(workerId, moment.utc().month() || 12);
        setOpenModal(true);
        break;
      case 3:
        props.sameMonthMessagesStats(workerId, moment.utc().month() + 1);
        setOpenModal(true);
        break;
      case 2:
        props.sameWeekMessagesStats(workerId);
        setOpenModal(true);
        break;
      case 1:
      default:
        props.sameDayMessagesStats(workerId);
        setOpenModal(false);
        break;
    }
  };

  const userPrivileges = useMemo(() => {
    return props?.session?.userDetails?.privileges;
  }, [props?.session?.userDetails?.privileges]);

  const getNavbar = (isAuthenticated, userPrivileges = []) => {
    if (isAuthenticated) {
      if (userPrivileges.includes(ADMIN)) {
        return (
          <>
            <Menu.Item header style={{ paddingBottom: "1px" }}>
              <div className="logo" />
            </Menu.Item>
            <Menu.Menu>
              <Menu.Item
                name="dashboard"
                active={props.menu.activeMenu === "dashboard"}
              >
                <Icon name="dashboard" />
                <Link to="/dashboard" onClick={() => setMenu("dashboard")}>
                  Dashboard
                </Link>
              </Menu.Item>
              {props.userListing.isLoading === false
                ? props.userListing?.isAllowed && (
                    <Menu.Item
                      name="users"
                      active={props.menu.activeMenu === "users"}
                    >
                      <Icon name="users" />
                      <Link to="/users" onClick={() => setMenu("users")}>
                        Users
                      </Link>
                    </Menu.Item>
                  )
                : null}
              {props.adminListing.isLoading === false
                ? props.adminListing?.isAllowed && (
                    <Menu.Item
                      name="admins"
                      active={props.menu.activeMenu === "admins"}
                    >
                      <Icon name="user secret" />
                      <Link to="/admins" onClick={() => setMenu("admins")}>
                        Admins
                      </Link>
                    </Menu.Item>
                  )
                : null}
              <Menu.Item
                name="workers"
                active={props.menu.activeMenu === "workers"}
              >
                <Icon name="users" />
                <Link to="/workers" onClick={() => setMenu("workers")}>
                  Workers
                </Link>
              </Menu.Item>
              <Menu.Item
                name="moderators"
                active={props.menu.activeMenu === "moderators"}
              >
                <Icon name="users" />
                <Link to="/moderators" onClick={() => setMenu("moderators")}>
                  Moderators
                </Link>
              </Menu.Item>
              <Menu.Item name="users">
                <Link to="/" style={{ pointerEvents: "none" }}>
                  <DisplayQueueCounts />
                </Link>
              </Menu.Item>
            </Menu.Menu>
            <Menu.Menu position="right">
              <Menu.Item
                name={
                  props?.userDetails?.fullName ||
                  props?.userDetails?.username ||
                  ""
                }
                style={{ padding: "0" }}
              >
                <span
                  style={{
                    fontWeight: "bold",
                    color: "#fff",
                    textTransform: "uppercase",
                  }}
                >
                  {props?.userDetails?.fullName ||
                    props?.userDetails?.username ||
                    ""}{" "}
                  .{" "}
                </span>
              </Menu.Item>
              {props?.needShowPrivateItems && (
                <Menu.Item
                  name="signout"
                  onClick={logout}
                  style={{ padding: "1rem 1.5rem 1rem 0.5rem" }}
                >
                  <Icon inverted color="grey" name="power" />
                  Logout
                </Menu.Item>
              )}
            </Menu.Menu>
          </>
        );
      } else if (userPrivileges.includes(CHATTER)) {
        return (
          <>
            <Menu.Menu>
              <Menu.Item
                name="dashboard"
                active={props.menu.activeMenu === "dashboard"}
              >
                <Icon name="dashboard" />
                <Link to="/dashboard" onClick={() => setMenu("dashboard")}>
                  Dashboard
                </Link>
              </Menu.Item>
              {props.userListing.isLoading === false
                ? props.userListing?.isAllowed && (
                    <Menu.Item
                      name="users"
                      active={props.menu.activeMenu === "users"}
                    >
                      <Icon name="users" />
                      <Link to="/users" onClick={() => setMenu("users")}>
                        Users
                      </Link>
                    </Menu.Item>
                  )
                : null}
              {!props.topNavWorkerStats.receivedCount >= 0 && (
                <Menu.Item name="users" style={{ gap: "5px" }}>
                  {props.topNavWorkerStats.isLoading
                    ? 0
                    : props?.topNavWorkerStats?.receivedCount || 0}
                  &nbsp; RECEIVED MESSAGE /&nbsp;{" "}
                  {props.topNavWorkerStats.isLoading
                    ? 0
                    : props?.topNavWorkerStats?.sentCount}
                  &nbsp; SENT MESSAGE
                  <Dropdown
                    options={statsOptions}
                    defaultValue={statsOptions[selectedBy].value}
                    onChange={(_, data) => dropDownChangeHandler(data.value)}
                  />
                </Menu.Item>
              )}
              {isAllowed_ChatInQueue && (
                <Menu.Item
                  name="pendingChats"
                  active={props.menu.activeMenu === "pendingChats"}
                >
                  <Icon name="chat" />
                  <Link
                    to="/pendingChats"
                    onClick={() => setMenu("pendingChats")}
                  >
                    CHAT IN QUEUE
                  </Link>
                </Menu.Item>
              )}
              <Menu.Item name="users">
                <Link to="/" style={{ pointerEvents: "none" }}>
                  <DisplayQueueCounts />
                </Link>
              </Menu.Item>
            </Menu.Menu>
            <Menu.Menu position="right">
              <Menu.Item
                name={
                  props?.userDetails?.fullName ||
                  props?.userDetails?.username ||
                  ""
                }
                style={{ padding: "0" }}
              >
                <span
                  style={{
                    fontWeight: "bold",
                    color: "#fff",
                    textTransform: "uppercase",
                  }}
                >
                  {props?.userDetails?.fullName ||
                    props?.userDetails?.username ||
                    ""}{" "}
                  .{" "}
                </span>
              </Menu.Item>
              {props.needShowPrivateItems && (
                <Menu.Item
                  name="signout"
                  onClick={logout}
                  style={{ padding: "1rem 1.5rem 1rem 0.5rem" }}
                >
                  <Icon inverted color="grey" name="power" />
                  Logout
                </Menu.Item>
              )}
            </Menu.Menu>
          </>
        );
      } else {
        return (
          <>
            <Menu.Item header style={{ paddingBottom: "1px" }}>
              <div className="logo" />
            </Menu.Item>
            <Menu.Item name="contact-us" position="right">
              <Icon name="phone" />
              <Link to="/contactUs">Contact Us</Link>
            </Menu.Item>
          </>
        );
      }
    } else {
      return (
        <>
          <Menu.Item header style={{ paddingBottom: "1px" }}>
            <div className="logo" />
          </Menu.Item>
          <Menu.Item name="contact-us" position="right">
            <Icon name="phone" />
            <Link to="/contactUs">Contact Us</Link>
          </Menu.Item>
        </>
      );
    }
  };

  useEffect(() => {
    if (props.presets.loading) {
      props.fetchPresets();
    }
  }, []);

  return (
    <Menu
      tabular
      inverted
      size="tiny"
      style={{
        position: "sticky",
        top: "0",
        left: "0",
        right: "0",
        zIndex: "999999999",
      }}
    >
      {getNavbar(props?.session?.isAuthenticated, userPrivileges)}

      <WorkerStatsModal
        open={openModal}
        onClose={() => setOpenModal(false)}
        selectedBy={selectedBy}
      />
    </Menu>
  );
});

MainMenu.propTypes = {
  userDetails: PropTypes.shape({
    privileges: PropTypes.array.isRequired,
    id: PropTypes.string.isRequired,
  }),
  needShowPrivateItems: PropTypes.bool.isRequired,
  menu: PropTypes.shape({
    activeMenu: PropTypes.string.isRequired,
  }),
  setActiveMenuPosition: PropTypes.func.isRequired,
  onLogout: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    session: state.session,
    chat: state.chat,
    presets: state.presets,
    topNavWorkerStats: state.workers.topNavWorkerStats,
    userListing: state.users.userListing,
    adminListing: state.users.adminListing,
  };
};

const matchDispatchToProps = (dispatch) => {
  return {
    fetchPresets() {
      fetchPresets()(dispatch);
    },
    checkWorkerCanChat() {
      checkWorkerCanChat()(dispatch);
    },
    canVisitUserListing() {
      canVisitUserListing()(dispatch);
    },
    canVisitAdminListing() {
      canVisitAdminListing()(dispatch);
    },
    sameDayMessagesStats(workerId) {
      sameDayMessagesStats(workerId)(dispatch);
    },
    sameWeekMessagesStats(workerId) {
      sameWeekMessagesStats(workerId)(dispatch);
    },
    sameMonthMessagesStats(workerId, month) {
      sameMonthMessagesStats(workerId, month)(dispatch);
    },
  };
};

export default connect(mapStateToProps, matchDispatchToProps)(MainMenu);
