import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  Dimmer,
  Grid,
  Icon,
  Image,
  Input,
  Loader,
  Segment,
} from "semantic-ui-react";
import { connect } from "react-redux";
import moment from "moment";
import onlineIcon from "../../assets/images/onlineIcon.png";
import offlineIcon from "../../assets/images/offlineIcon.png";

import ConfirmationPopup from "../confirmationPopup";
import ErrorMessage from "../errorMessage";
import { getToken, isAllowedRole } from "../../utils/auth";
import { commonConstants } from "../../constants/common";
import {
  getAdminListByAdminId,
  getAdminsList,
  sortByAdminsList,
  updateAdminsField,
} from "../../actions/admins";
import CreatedByFilter from "../common/CreatedByFilter";
import SortByFilter from "../common/SortByFilter";
import { convertArrayToHashMap, isVideo } from "../../utils/helpers";
import { isEmpty } from "lodash";
import noImagePlaceholder from "../../assets/images/no-image-placeholder.webp";

const intrestedInArr = commonConstants.INTERESTED_IN_OPTIONS;

const AdminsList = (props) => {
  const {
    admins,
    getAdminsList,
    userDetails,
    hideError,
    onRemove,
    updateAdminsField,
    sortByAdminsList,
    getAdminListByAdminId,
  } = props;

  const [state, setState] = useState({ pageNumber: 0 });
  const [selectAdminName, setSelectAdminName] = useState("");

  const columns = useMemo(
    () => Math.round(window.innerWidth / 300),
    [window.innerWidth]
  );

  useEffect(() => {
    if (!props.presets?.loading) {
      setState((state) => ({
        ...state,
        languagePresets: convertArrayToHashMap(
          props?.presets?.value?.languages ?? []
        ),
        heightPresets: convertArrayToHashMap(
          props?.presets?.value?.heights ?? []
        ),
        subscriptionPresets: convertArrayToHashMap(
          props?.presets?.value?.subscriptions ?? []
        ),
        ethnicityPresets: convertArrayToHashMap(
          props?.presets?.value?.ethnicity ?? []
        ),
        familyPresets: convertArrayToHashMap(
          props?.presets?.value?.family ?? []
        ),
      }));
    }
  }, [props.presets?.loading]);

  const onKeyPress = (e) => {
    if (e.key !== "Enter") {
      return;
    }
    if (admins.search?.length >= 3) {
      getAdminsList(
        admins.limit,
        0,
        admins?.direction,
        admins?.orderedField,
        [],
        admins.search
      );
    }
    if (admins.search?.length === 0) {
      getAdminsList(admins.limit, 0, admins?.direction, admins?.orderedField);
    }
  };

  const handlePrevious = () => {
    setState({ pageNumber: state.pageNumber - 1 });
    getAdminsList(
      admins.limit,
      (state.pageNumber - 1) * admins.limit,
      admins?.direction,
      admins?.sort_column
    );
  };

  const handleNext = () => {
    setState({ pageNumber: state.pageNumber + 1 });
    getAdminsList(
      admins.limit,
      (state.pageNumber + 1) * admins.limit,
      admins?.direction,
      admins?.sort_column
    );
  };

  const onSearchValueChange = (e) => {
    if (e.target.value === "") {
      getAdminsList(admins.limit, 0, admins?.direction, admins?.orderedField);
    }
    updateAdminsField({ field: "search", value: e.target.value });
  };

  return (
    <div>
      <Dimmer.Dimmable as={Segment} dimmed={admins.loading}>
        <Dimmer active={admins.loading} inverted>
          <Loader>Loading</Loader>
        </Dimmer>
        {isAllowedRole([commonConstants.SUPER_ADMIN_ROLE], userDetails) && (
          <Segment textAlign="center" basic>
            <Link to={"/users/new/"}>
              <Button size="large" color="blue" content="New user" />
            </Link>
            <a
              href={
                window.location.origin +
                "/internal/users.csv?token=" +
                getToken()
              }
            >
              <Button size="large" color="orange" content="Export users" />
            </a>
          </Segment>
        )}
        {admins.error && admins.error.visible && (
          <ErrorMessage error={admins.error} hideError={hideError} />
        )}
        <Grid
          as={Segment}
          columns={3}
          verticalAlign="middle"
          textAlign="center"
        >
          <Grid.Row>
            <Grid.Column>
              {selectAdminName && (
                <p className="userText">Created By: {selectAdminName}</p>
              )}
            </Grid.Column>
            <Grid.Column>
              <Button.Group>
                <Button
                  onClick={handlePrevious}
                  size="large"
                  color="green"
                  disabled={!admins.prevAdminsUrl}
                  content="<  Previous page"
                />
                <Button.Or text={state.pageNumber + 1} />
                <Button
                  onClick={handleNext}
                  size="large"
                  color="green"
                  disabled={!admins.nextAdminsUrl}
                  content="Next page  >"
                />
              </Button.Group>
            </Grid.Column>
            <Grid.Column
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                gap: "10px",
                whiteSpace: "nowrap",
              }}
            >
              <CreatedByFilter
                onFilter={({ filteredByName, filteredById }) => {
                  setSelectAdminName(filteredByName);
                  getAdminListByAdminId(filteredById);
                }}
                onClearFilter={() => {
                  console.log("onClearFilter Called");
                  setSelectAdminName("");
                  sortByAdminsList(
                    admins.limit,
                    0,
                    admins?.direction,
                    admins?.orderedField,
                    []
                  );
                }}
              />
              <SortByFilter
                onFilter={(filterString) => {
                  sortByAdminsList(
                    admins.limit,
                    0,
                    admins?.direction,
                    admins?.orderedField,
                    filterString
                  );
                }}
                onClearFilter={() => {
                  console.log("SortByFilter:: onClearFilter Called");
                  sortByAdminsList(
                    admins.limit,
                    0,
                    admins?.direction,
                    admins?.orderedField,
                    []
                  );
                }}
              />
              <Link to={"/workers/newInvitation/"}>
                <Button circular icon="add" positive floated="right" />
              </Link>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Grid as={Segment} className="userContainer">
          <Grid.Row>
            <Grid.Column>
              <Input
                fluid
                icon={
                  <Icon
                    name="search"
                    inverted={admins?.search?.length >= 3}
                    circular
                    link={admins?.search?.length >= 3}
                    onClick={() => {
                      getAdminsList(
                        admins?.limit,
                        0,
                        admins?.direction,
                        admins?.orderedField,
                        [],
                        admins?.search
                      );
                    }}
                  />
                }
                value={admins.search}
                onChange={onSearchValueChange}
                onKeyPress={onKeyPress}
                placeholder="Search..."
              />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row columns={columns || 5}>
            {admins?.admins?.length > 0 &&
              admins?.admins?.map((admin, indx) => {
                const editPath = "/admins/edit/" + admin?.id;
                const viewPath = "/admins/view/" + admin?.id;
                let avatar = admin?.avatar_photos?.length
                  ? admin?.avatar_photos[0]?.file
                  : "";
                const primaryImage =
                  admin?.avatar_photos?.[admin?.avatar_index || 0]?.file;

                const displayMedia = (
                  avatar
                    ? primaryImage
                      ? primaryImage
                      : avatar
                    : noImagePlaceholder
                ).trim();

                const is_video = isVideo(displayMedia);

                const getReports = () => {
                  const reports = [];
                  admin?.reports?.map((report) =>
                    reports.push(report?.reason ?? "")
                  );
                  return reports?.join(", ") ?? "";
                };

                return (
                  <Grid.Column key={`admin-${indx}`} className="userInfo">
                    <Card className="userCard">
                      <div style={{ position: "relative" }}>
                        {is_video ? (
                          <video
                            key={`admin-video-${indx}`}
                            style={{
                              width: "100%",
                              height: "220px",
                              borderRadius: "8px",
                              padding: "15px",
                            }}
                            autoPlay
                            muted
                            loop
                          >
                            <source src={displayMedia} type="video/mp4" />
                          </video>
                        ) : (
                          <Image
                            src={displayMedia}
                            width={"100%"}
                            height={"220px"}
                            wrapped
                            ui={false}
                            className={"userImage"}
                            style={{ borderRadius: "8px" }}
                          />
                        )}
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "8px",
                          }}
                          className="actionView"
                        >
                          <Link to={editPath}>
                            <Icon
                              link
                              name="pencil"
                              size="large"
                              color="blue"
                            />
                          </Link>
                          <Link to={viewPath}>
                            <Icon link name="eye" size="large" color="blue" />
                          </Link>
                          {isAllowedRole(
                            [commonConstants.SUPER_ADMIN_ROLE],
                            userDetails
                          ) && (
                            <ConfirmationPopup
                              icon={
                                <Icon
                                  link
                                  name="trash"
                                  size="large"
                                  color="black"
                                />
                              }
                              onSuccessConfirm={onRemove}
                              entityData={{ adminId: admin.uid }}
                              confirmationText="Remove current user?"
                            />
                          )}
                        </div>
                      </div>
                      <Card.Content
                        style={{ display: "block" }}
                        className="userContent"
                      >
                        <Card.Header className="cardHeader">
                          <p
                            style={{
                              maxWidth: "60%",
                              minWidth: "60%",
                              wordWrap: "break-word",
                            }}
                          >
                            {admin?.display_name === ""
                              ? "-"
                              : admin?.display_name}
                          </p>
                          {admin?.isOnline ? (
                            <div>
                              <img
                                src={onlineIcon}
                                alt="Online Icon"
                                width="15px"
                                height="15px"
                              />{" "}
                              Online
                            </div>
                          ) : (
                            <div>
                              <img
                                src={offlineIcon}
                                alt="Online Icon"
                                width="15px"
                                height="15px"
                              />{" "}
                              Offline
                            </div>
                          )}
                        </Card.Header>
                        <Card.Description className="description">
                          <p>
                            Email:&nbsp;
                            {admin?.email === ""
                              ? "-"
                              : `${admin?.email
                                  ?.slice(0, 3)
                                  ?.padEnd(
                                    admin?.email?.slice(3)?.length,
                                    "."
                                  )}`}
                          </p>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <p>Age:&nbsp;{admin?.age + 17}</p>&nbsp;/&nbsp;{" "}
                            <p>
                              Height: &nbsp;
                              {admin?.height
                                ? state?.heightPresets[admin?.height]?.height ??
                                  ""
                                : "Nil"}
                            </p>
                          </div>
                          <p>City: {admin?.city}</p>
                          <p>
                            Language: &nbsp;
                            {isEmpty(admin?.language)
                              ? "Nil"
                              : state?.languagePresets?.[admin?.language?.[0]]
                                  ?.language ?? ""}
                          </p>
                          <p>
                            Subscription: &nbsp;{" "}
                            {admin?.admin_subscription
                              ? state?.subscriptionPresets[
                                  admin?.admin_subscription?.package
                                ]?.name
                              : "NO SUBSCRIPTION"}
                          </p>
                          <p>
                            IntrestedIn : &nbsp;
                            {admin.interested_in
                              ? admin?.interested_in.split(",").map((item) => {
                                  const intrests = intrestedInArr.find(
                                    (element) => {
                                      // eslint-disable-next-line
                                      return element.value == item;
                                    }
                                  );
                                  return (
                                    <span>{intrests && intrests.text}, </span>
                                  );
                                })
                              : "Nil"}
                          </p>
                          <p>
                            Ethnicity: &nbsp;
                            {admin?.ethnicity
                              ? state?.ethnicityPresets[admin?.ethnicity]
                                  ?.ethnicity
                              : "Nil"}
                          </p>
                          <p>
                            Family Plan: &nbsp;
                            {admin?.familyPlans
                              ? state?.familyPresets[admin?.familyPlans]
                                  ?.familyPlans
                              : "Nil"}
                          </p>
                          <p>
                            Work: &nbsp;
                            {admin?.work ? admin?.work : "Nil"}
                          </p>
                          <p>
                            Joined:&nbsp;
                            {admin?.sign_up &&
                              moment(admin?.sign_up).format(
                                "DD/MM/YYYY, hh:mm:ss a"
                              )}
                          </p>
                          <p>
                            Last Login:&nbsp;
                            {admin?.user_last_seen &&
                              moment(admin?.user_last_seen).format(
                                "DD/MM/YYYY, hh:mm:ss a"
                              )}
                          </p>
                        </Card.Description>
                      </Card.Content>
                      <Card.Content extra className="footer">
                        <p>Disabled: {admin?.disabled ? "True" : "False"}</p>
                        <p>Reports: {getReports() ? getReports() : "None"}</p>
                      </Card.Content>
                    </Card>
                  </Grid.Column>
                );
              })}
          </Grid.Row>
        </Grid>
        <Segment textAlign="right">
          <Button.Group>
            <Button
              onClick={handlePrevious}
              size="large"
              color="green"
              disabled={!admins.prevAdminsUrl}
              content="<  Previous page"
            />
            <Button.Or text={state.pageNumber + 1} />
            <Button
              onClick={handleNext}
              size="large"
              color="green"
              disabled={!admins.nextAdminsUrl}
              content="Next page  >"
            />
          </Button.Group>
          {/* <Link to={"/workers/newInvitation/"}>
            <Button circular icon="add" positive floated="right" />
          </Link> */}
        </Segment>
      </Dimmer.Dimmable>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    admins: state.admins,
    userDetails: state?.session?.userDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAdminsList: (limit, offset, ordered, orderedField, fields, search) => {
      getAdminsList(
        limit,
        offset,
        ordered,
        orderedField,
        fields,
        search
      )(dispatch);
    },
    sortByAdminsList: (limit, offset, ordered, orderedField, fields) => {
      sortByAdminsList(limit, offset, ordered, orderedField, fields)(dispatch);
    },
    getAdminListByAdminId: (id) => {
      getAdminListByAdminId(id)(dispatch);
    },
    updateAdminsField: (payload) => {
      updateAdminsField(payload)(dispatch);
    },
  };
};

const createConnect = connect(mapStateToProps, mapDispatchToProps);

export default createConnect(AdminsList);
