import { Component } from "react";
import React from "react";
import PropTypes from "prop-types";
import { Form } from "semantic-ui-react";
import UploadScheduleImage from "./uploadScheduleImage";

export default class AddNewScheduleStoryPhoto extends Component {
  render() {
    return (
      <UploadScheduleImage
        onUpload={this.props.onUpload}
        onError={this.onError}
        onLoad={this.onLoad}
        height={this.props.height}
        disabled={this.props.disabled}
        header={this.props.header}
        moderatorId={this.props.moderatorId}
        stockImages={this.props.stockImages}
        stockImage={this.props.stockImage}
        folder={this.props.folder}
        position={this.props.position}
        type={this.props.type}
        userId={this.props.userId}
        publishAt={this.props.publishAt}
        handleChangeTime={this.props.handleChangeTime}
      />
    );
  }
}

AddNewScheduleStoryPhoto.propTypes = {};
