import React, { Component } from "react";
import PropTypes from "prop-types";
import { Image } from "semantic-ui-react";
import videoExtensions from "video-extensions";
import UserApi from "../../utils/users";
import { Api, googleMapsApi, googleMapsApiKey } from "../../utils/api";
import videoIcon from "../../assets/images/videoIcon.png";
import { getFullAddressFromLatLng } from "../../utils/helpers";
import { connect } from "react-redux";
import { getChatHistory } from "../../actions/chat";
const isVideo = (url) => {
  // const imgTypes = [".png", ".jpg", ".jpeg", ".apng", ".avif", ".gif", ".jfif", ".pjpeg", ".pjp", ".svg", ".webp"];
  return videoExtensions.some((videoType) => String(url).includes(videoType));
};

class ChatBoxContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isUrl: props?.content?.includes("media/chat_files"),
      // isVideo: videoExtensions?.includes(props?.content?.split('.').pop()),
      isVideo: isVideo(props?.content?.split(" ")),
      content: props?.content,
      privateRequest: !!props?.privatePhotoRequestId,
    };
  }

  reFetchChats = () => {
    if (this.props?.currentChat !== null && this.props?.currentChat !== "") {
      this.props.getChatHistory(
        this.props.chatRoomDetails.moderator_id,
        this.props.currentChat,
        this.props.isChatter
      );
    }
  };

  handleApproveRequest = async (requestId) => {
    const res = await UserApi.privatePhotoDecision("A", requestId);
    if (res?.success) this.reFetchChats();
  };

  handleRejectRequest = async (requestId) => {
    const res = await UserApi.privatePhotoDecision("R", requestId);
    if (res?.success) this.reFetchChats();
  };

  handleRequestCancel = async (requestId) => {
    const res = await UserApi.privatePhotoDecision("C", requestId);
    if (res?.success) this.reFetchChats();
  };

  handleSelfRequestCancel = async () => {
    const res = await UserApi.cancelPrivatePhotoRequest(
      this.props.chatRoomDetails.realuser_id,
      this.props.chatRoomDetails.moderator_id
    );
    if (res?.success) this.reFetchChats();
  };

  componentDidMount = async () => {
    if (this.props.messageType === "GL") {
      const script = document.createElement("script");
      script.src = `${googleMapsApi}/js?key=${googleMapsApiKey}&libraries=places`;
      script.async = true;
      script.id = "google-maps-script";
      document.body.appendChild(script);
      script.addEventListener("load", async () => {
        let lat = this.props.content?.split(",")[0]?.slice(0, 8);
        let lng = this.props.content?.split(",")[1]?.slice(0, 8);

        let coords = { lat: parseFloat(lat), lng: parseFloat(lng) };

        const address = await getFullAddressFromLatLng(lat, lng);

        let map = new window.google.maps.Map(
          document.getElementById(
            `google-map-for-address-preview-${this.props.id}`
          ),
          {
            zoom: 17,
            center: coords,
          }
        );

        let marker = new window.google.maps.Marker({
          position: coords,
          map: map,
        });

        let infowindow = new window.google.maps.InfoWindow();

        infowindow.setContent(
          "<a style=font-weight:600; href=" +
            `http://maps.google.com/?q=${lat},${lng}` +
            " target=_blank>" +
            `${address.formattedAddress}` +
            "</a>"
        );
        infowindow.open(map, marker);
        //api.chatadmin-mod.click
        https: marker.addListener("click", (e) => {
          const geocoder = new window.google.maps.Geocoder();
          geocoder.geocode({ address: address.formattedAddress });
          infowindow.open(map, marker);
        });
      });
    }
  };

  processGiftContent = (string) => {
    const parts = string.split(" ");
    const url = parts[0];
    const content = parts?.slice(1)?.join(" ");
    return { url: url, msg: content };
  };

  render() {
    const {
      user_id,
      recipient_id,
      showVideo,
      privatePhotoRequestId,
      requestStatus,
      messageType,
      id,
    } = this.props;
    const { content, isUrl, isVideo, privateRequest, mapCoords } = this.state;

    if (messageType === "GL") {
      return (
        <>
          <div
            style={{ height: "250px", width: "100%" }}
            id={`google-map-for-address-preview-${id}`}
          ></div>
        </>
      );
    } else if (messageType === "G") {
      const { url, msg } = this.processGiftContent(content);
      return (
        <p
          style={{
            color:
              recipient_id !== user_id
                ? recipient_id === null
                  ? ""
                  : "#fff"
                : "",
            wordBreak: "break-all",
          }}
        >
          {msg}
          {/* <a href={url} alt="gift"> */}
          <Image
            src={url}
            style={{
              height: this.props.height,
              width: this.props.width,
              marginTop: "5px",
            }}
            // onClick={this.props.onClick}
            onClick={() => showVideo(url)}
          />
          {/* </a> */}
        </p>
      );
    } else if (isUrl) {
      if (isVideo) {
        const { url, msg } = this.processGiftContent(content);
        return (
          <>
            <p
              style={{
                color:
                  recipient_id !== user_id
                    ? recipient_id === null
                      ? ""
                      : "#fff"
                    : "",
                wordBreak: "break-all",
              }}
            >
              {msg}
            </p>
            {/* <a target="_blank" href={url}> */}
            <div
              style={{
                position: "absolute",
                height: this.props.height,
                width: this.props.width,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Image
                src={videoIcon}
                style={{ height: "25px", width: "25px", marginTop: "5px" }}
                // onClick={this.props.onClick}
                // onClick={() => showVideo(url)}
              />
            </div>
            <video
              height={this.props.height}
              onClick={() => showVideo(url)}
              style={{ width: this.props.width, height: this.props.height }}
            >
              <source src={url} />
            </video>
            {/* </a> */}
          </>
        );
      } else {
        const { url, msg } = this.processGiftContent(content);
        return (
          <>
            <p
              style={{
                color:
                  recipient_id !== user_id
                    ? recipient_id === null
                      ? ""
                      : "#fff"
                    : "",
                wordBreak: "break-all",
              }}
            >
              {msg}
              {/* <a href={url}> */}
              <Image
                src={url}
                style={{
                  height: this.props.height,
                  width: this.props.width,
                  marginTop: "5px",
                }}
                // onClick={this.props.onClick}
                onClick={() => showVideo(url)}
              />
              {/* </a> */}
            </p>
          </>
        );
      }
    } else if (
      privateRequest &&
      (content?.toLowerCase()?.includes("requested") ||
        content?.toLowerCase()?.includes("approved")) &&
      user_id === recipient_id
    ) {
      return (
        <div>
          <p
            style={{
              color:
                recipient_id !== user_id
                  ? recipient_id === null
                    ? ""
                    : "#fff"
                  : "",
              wordBreak: "break-all",
            }}
          >
            {content}
          </p>
          {content?.toLowerCase()?.includes("requested")
            ? (requestStatus === "PENDING" || requestStatus === "CANCEL") && (
                <button
                  onClick={() => this.handleSelfRequestCancel()}
                  style={{
                    marginRight: "5px",
                  }}
                  disabled={requestStatus === "CANCEL"}
                  title="Cancel Request"
                >
                  Cancel
                  {/* Request */}
                </button>
              )
            : content?.toLowerCase()?.includes("approved")
            ? (requestStatus === "PENDING" ||
                requestStatus === "APPROVE" ||
                requestStatus === "CANCEL") && (
                <button
                  onClick={() =>
                    this.handleRequestCancel(privatePhotoRequestId)
                  }
                  style={{
                    marginRight: "5px",
                  }}
                  disabled={requestStatus === "CANCEL"}
                  title="Cancel Allowed"
                >
                  Cancel
                  {/* Allowed */}
                </button>
              )
            : null}
        </div>
      );
    } else if (
      privateRequest &&
      !content?.toLowerCase()?.includes("cancel") &&
      user_id !== recipient_id
    ) {
      return (
        <div>
          <p
            style={{
              color:
                recipient_id !== user_id
                  ? recipient_id === null
                    ? ""
                    : "#fff"
                  : "",
              wordBreak: "break-all",
            }}
          >
            {content}
          </p>
          {requestStatus === "PENDING" ? (
            <>
              <button
                onClick={() => this.handleApproveRequest(privatePhotoRequestId)}
                style={{
                  marginRight: "5px",
                }}
              >
                Accept
              </button>
              <button
                onClick={() => this.handleRejectRequest(privatePhotoRequestId)}
              >
                Reject
              </button>
            </>
          ) : requestStatus === "REJECT" ? (
            <button disabled>Rejected</button>
          ) : requestStatus === "APPROVE" || requestStatus === "CANCEL" ? (
            <button disabled>Approved</button>
          ) : (
            <></>
          )}
        </div>
      );
    }
    return (
      <>
        <p
          style={{
            color:
              recipient_id !== user_id
                ? recipient_id === null
                  ? ""
                  : "#fff"
                : "",
            wordBreak: "break-all",
          }}
        >
          {content?.substring(0, 150)}
        </p>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const isChatter = state.session?.userDetails?.privileges?.includes("CHATTER");
  const currentChat = Number(state?.chat?.currentChat);

  const current_CIQ = state?.chat?.chatsInQueue?.find(
    (chat) => chat?.roomId === currentChat
  );
  const chatRoomDetails = {
    moderator_id: current_CIQ?.moderator?.id,
    realuser_id: current_CIQ?.userDetails?.id,
    worker_id: current_CIQ?.worker?.id,
  };

  return { chatRoomDetails, isChatter, currentChat };
};

const mapDispatchToProps = (dispatch) => ({
  getChatHistory: (id, dialogueId, isChatter) => {
    getChatHistory(id, dialogueId, isChatter)(dispatch);
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ChatBoxContent);
