import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  Button,
  Segment,
  Dimmer,
  Loader,
  Form,
  Header,
  Image,
  Card,
  Grid,
  Message,
  Icon,
  Modal,
  Popup,
} from "semantic-ui-react";
import ErrorMessage from "../errorMessage";
import { withRouter, Link } from "react-router-dom";
import PropTypes from "prop-types";
import Tags from "./tagsComponent";
import { commonConstants } from "../../constants/common";
import {
  getAllUserMoments,
  getMoments,
  getWorkerFakeUsers,
} from "../../actions/fakeUsers";
import AddMoment from "./AddMoment";
import FakeUserPhotos from "./FakeUserPhotos";
import onlineIcon from "../../assets/images/onlineIcon.png";
import offlineIcon from "../../assets/images/offlineIcon.png";

import "./index.css";
import FakeUserStories from "./FakeUserStories";
import StockImageSection from "./StockImageSection";
import FakeUserPrivatePhotos from "./FakeUserPrivatePhotos";
import { isEmpty } from "lodash";
import MapContainer from "../mapContainer";
import UserApi from "../../utils/users";
import ScheduleStories from "./ScheduleStories";
import AddScheduleMoments from "./AddScheduleMoments";
import { getModeratorByAdminId } from "../../actions/moderators";
import { baseUrl } from "../../utils/api";
import { getImageUrl, isVideo } from "../../utils/helpers";
import momentFn from "moment";

const requiredFields = [
  { field: "fullName", validateFn: (value) => value?.length > 0 },
  { field: "age", validateFn: (value) => !!value },
  { field: "gender", validateFn: (value) => !!value },
];

const LikeModal = ({
  isModalOpen = false,
  handleClose,
  handleSuccess,
  momentId,
  likes,
}) => {
  const [momentLikes, setMomentLikes] = useState([]);
  const [state, setState] = useState({
    errors: [],
    isSubmitting: false,
  });

  // const initialValues = {
  //   comment: "",
  // };

  const handleLikeMoment = async (values) => {
    setState({
      ...state,
      errors: [],
      isSubmitting: true,
    });
    const res = await UserApi.addLikeOnStory(momentId);

    if (!res) {
      setState({
        ...state,
        isSubmitting: false,
        errors: [res?.message ?? "Something went wrong while posting like!"],
      });
      handleSuccess();
      return;
    }
    setState({
      ...state,
      isSubmitting: false,
      errors: [],
    });
    handleSuccess();
  };

  const handleModalClose = () => {
    handleClose();
  };

  const getMomentLikes = async () => {
    const res = await UserApi.getMomentLikes(momentId);
    if (res) {
      setMomentLikes(res);
    }
  };

  useEffect(() => {
    if (momentId) {
      getMomentLikes(momentId);
    }
  }, [momentId, isModalOpen]);

  return (
    <Modal open={isModalOpen} onClose={handleModalClose}>
      <Header icon="like" content="Likes" />
      <Modal.Content>
        <ul style={{ listStyle: "none", padding: "0", margin: "0" }}>
          {momentLikes.length === 0 && (
            <Header>No Likes for this Moment</Header>
          )}
          {momentLikes?.map((like, index) => (
            <li
              key={index}
              style={{
                display: "flex",
                padding: "5px 0px",
              }}
            >
              <Image avatar src={like?.user?.avatarPhotos[0]?.url || ""} />
              <div>
                <Header as="a">{like?.user?.username}</Header>
                <p style={{ color: "gray" }}>
                  <small>
                    Last seen at:{" "}
                    {new Date(like?.user?.lastSeen).toLocaleString()}
                  </small>
                </p>
              </div>
            </li>
          ))}
        </ul>
      </Modal.Content>
      <Modal.Actions>
        {state?.errors?.length
          ? state.errors.map((error) => (
              <Message
                style={{ display: "block", textAlign: "start" }}
                error={true}
              >
                {error}{" "}
              </Message>
            ))
          : null}
        <Button color="red" onClick={() => handleModalClose()}>
          <Icon name="remove" /> Cancel
        </Button>
        {/* <Button
          loading={state.isSubmitting}
          type="submit"
          color="green"
          onClick={handleLikeMoment}
        >
          <Icon name="like" /> Like
        </Button> */}
      </Modal.Actions>
    </Modal>
  );
};

const AddCommentModal = ({
  isModalOpen = false,
  handleClose,
  handleSuccess,
  momentId,
}) => {
  const [momentComments, setMomentComments] = useState([]);
  // const [comments, setComments] = useState([]);

  const [state, setState] = useState({
    errors: [],
    isSubmitting: false,
  });

  const initialValues = {
    comment: "",
  };

  const getMomentComments = async () => {
    const res = await UserApi.getMomentComments(momentId);
    if (res) {
      setMomentComments(res);
    }
  };

  useEffect(() => {
    if (momentId) {
      getMomentComments(momentId);
    }
  }, [momentId, isModalOpen]);

  const handleFormSubmit = async (values) => {
    setState({
      ...state,
      errors: [],
      isSubmitting: true,
    });
    const res = await UserApi.addCommnetOnStory(momentId, values.comment);

    if (!res) {
      setState({
        ...state,
        isSubmitting: false,
        errors: [res?.message ?? "Something went wrong while posting comment!"],
      });
      return;
    }
    setState({
      ...state,
      isSubmitting: false,
      errors: [],
    });
    // formik.resetForm();
    handleSuccess();
  };

  // const formik = useFormik({
  //   initialValues,
  //   validationSchema: AddCommentModalFormState,
  //   validateOnChange: true,
  //   validateOnBlur: true,
  //   validateOnMount: false,
  //   onSubmit: handleFormSubmit,
  // });

  const handleModalClose = () => {
    // formik.resetForm();
    handleClose();
  };

  return (
    <Modal open={isModalOpen} onClose={handleModalClose}>
      <Header icon="comments" content="Comments" />
      <Modal.Content>
        <ul style={{ listStyle: "none", padding: "0", margin: "0" }}>
          {momentComments.length === 0 && (
            <Header>No Comments for this Moment</Header>
          )}
          {momentComments?.map((comment, index) => (
            <li
              key={index}
              style={{
                display: "flex",
                padding: "5px 0px",
              }}
            >
              <Image avatar src={comment?.user?.avatarPhotos[0]?.url || ""} />
              <div>
                <Header as="a">{comment?.user?.username}</Header>
                <p style={{ color: "gray" }}>
                  <small>{comment?.commentDescription}</small>
                  <br />
                  <small>
                    Commented at:{" "}
                    {new Date(comment?.createdDate).toLocaleString()}
                  </small>
                </p>
              </div>
            </li>
          ))}
        </ul>
        {/* <Form style={{ padding: 0 }}>
          <Form.Group widths="equal">
            <Form.TextArea
              label="Comment"
              name="comment"
              placeholder="Write your comment here"
              value={formik.values.comment}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.comment && formik.errors.comment}
            />
          </Form.Group>
        </Form> */}
      </Modal.Content>
      <Modal.Actions>
        {state?.errors?.length
          ? state.errors.map((error) => (
              <Message
                style={{ display: "block", textAlign: "start" }}
                error={true}
              >
                {error}{" "}
              </Message>
            ))
          : null}
        <Button color="red" onClick={() => handleModalClose()}>
          <Icon name="remove" /> Cancel
        </Button>
        {/* <Button
          loading={state.isSubmitting}
          type="submit"
          color="green"
          onClick={formik.handleSubmit}
        >
          <Icon name="checkmark" /> Submit
        </Button> */}
      </Modal.Actions>
    </Modal>
  );
};

const Moderator = (props) => {
  const currentTimestamp = new Date().getTime();
  const dispatch = useDispatch();
  const [state, setState] = useState({
    isDirty: false,
    address: props.fakeUsers?.currentUser?.address ?? "",
    note: props.fakeUsers?.currentUser?.note ?? "",
    geometry: {
      lat:
        Array.isArray(props?.fakeUsers?.currentUser?.location) &&
        !isEmpty(props?.fakeUsers?.currentUser?.location)
          ? props?.fakeUsers?.currentUser?.location[0]
          : 0,
      lng:
        Array.isArray(props?.fakeUsers?.currentUser?.location) &&
        !isEmpty(props?.fakeUsers?.currentUser?.location)
          ? props?.fakeUsers?.currentUser?.location[1]
          : 0,
    },
    addressParts: {
      city: props.fakeUsers?.currentUser?.city ?? "",
      country: props.fakeUsers?.currentUser?.country ?? "",
      zip_code: props.fakeUsers?.currentUser?.zip_code ?? "",
      country_code: props.fakeUsers?.currentUser?.country_code ?? "",
    },
    errors: [],
    isDeleteModalOpen: false,
    momentId: "",
    isLoading: false,
  });

  const [subscriptionOptions, setSubscriptionOptions] = useState([]);
  const [genderOptions, setGenderOptions] = useState([]);
  const [ethnicityOptions, setEthnicityOptions] = useState([]);
  const [zodiacSignsOptions, setZodiacSignsOptions] = useState([]);
  const [familyOptions, setFamilyOptions] = useState([]);
  const [politicsOptions, setPoliticsOptions] = useState([]);
  const [religiousOptions, setReligiousOptions] = useState([]);
  const [tagsOptions, setTagsOptions] = useState([]);
  const [ageOptions, setAgeOptions] = useState([]);
  const [heightOptions, setHeightOptions] = useState([]);
  const [moment, setMoment] = useState({});
  const [likeOpen, setLikeOpen] = useState(false);
  const [commentOpen, setCommentOpen] = useState(false);
  const [momentsData, setMomentsData] = useState(props?.allmoments);
  const [scheduleMomentsData, setScheduleMomentsData] = useState(
    props?.schedulemoments
  );
  const moderatorAdmin = useSelector(
    (state) => state?.moderators?.moderatoradmin
  );

  useEffect(() => {
    setMomentsData(props.allmoments);
  }, [props.allmoments]);

  useEffect(() => {
    setScheduleMomentsData(props.schedulemoments);
  }, [props.schedulemoments]);

  const saveUser = async (workerId, currentUser) => {
    let errors = [];
    if (!currentUser?.fullName) {
      errors.push("Please enter name!");
    }
    if (
      currentUser?.gender !== 1 &&
      currentUser?.gender !== 2 &&
      currentUser?.gender !== 3
    ) {
      errors.push("Please select gender!");
    }
    if (currentUser?.ethnicity === 0 || !currentUser?.ethnicity) {
      errors.push("Please select Ethnicity!");
    }
    if (!currentUser?.interestedIn?.length) {
      errors.push("Please select interested in!");
    }
    if (!currentUser?.tags?.length) {
      errors.push("Please select tags!");
    }
    if (!currentUser?.subscription) {
      errors.push("Please select subscription plan");
    }
    if (!state?.address || !state?.geometry?.lat || !state?.geometry?.lat) {
      errors.push("Please enter addresss!");
    }

    if (errors?.length) {
      setState({ errors });
      return;
    }

    if (currentUser?.id) {
      const subscriptionId = subscriptionOptions?.find(
        (sub) => sub?.text === props?.fakeUsers?.currentUser?.subscription
      )?.value;
      let { age = 18, fullName = "" } = props?.fakeUsers?.currentUser;
      let updatedName = fullName.trim().replace(" ", "_");

      props.updateUser(currentUser.id, {
        ...currentUser,
        subscription: subscriptionId ? subscriptionId : null,
        address: state.address,
        country: state.addressParts?.country ?? "",
        city: state.addressParts?.city,
        country_code: state.addressParts?.country_code ?? "",
        zip_code: state.addressParts?.zip_code,
        location: [state.geometry.lat, state.geometry.lng],
        display_name: `${updatedName}_${age}`,
        username: props?.fakeUsers?.currentUser?.username,
        note: props?.fakeUsers?.currentUser?.note,
      });
      setState((state) => ({ ...state, isDirty: false }));
      return props.getModeratorsList(0);
    }
  };

  useEffect(() => {
    if (!props?.presets?.loading) {
      const presetsValue = props?.presets?.value;
      setSubscriptionOptions(
        presetsValue?.subscriptions?.map((item, index) => ({
          key: index,
          value: item?.id,
          text: item?.name,
        }))
      );
      setAgeOptions(
        presetsValue?.ages?.map((item, index) => ({
          key: index,
          value: item?.id,
          text: item?.age,
        }))
      );
      setHeightOptions(
        presetsValue?.heights?.map((item, index) => ({
          key: index,
          value: item?.id,
          text: item?.height,
        }))
      );
      setGenderOptions(
        presetsValue?.genders
          ?.map((item, index) => ({
            key: index,
            value: item?.id,
            text: item?.gender,
          }))
          ?.sort((a, b) => a?.value - b?.value)
      );
      setEthnicityOptions(
        presetsValue?.ethnicity?.map((item, index) => ({
          key: index,
          value: item?.id,
          text: item?.ethnicity,
        }))
      );
      setZodiacSignsOptions(
        presetsValue?.zodiacSigns?.map((item, index) => ({
          key: index,
          value: item?.id,
          text: item?.zodiacSign,
        }))
      );
      setFamilyOptions(
        presetsValue?.family?.map((item, index) => {
          return { key: index, value: item?.id, text: item?.familyPlans };
        })
      );
      setPoliticsOptions(
        presetsValue?.politics?.map((item, index) => {
          return { key: index, value: item?.id, text: item?.politics };
        })
      );
      setReligiousOptions(
        presetsValue?.religious?.map((item, index) => {
          return { key: index, value: item?.id, text: item?.religious };
        })
      );
      setTagsOptions(
        presetsValue?.tags?.map((item, index) => {
          return { key: index, value: item?.id, text: item?.tag };
        })
      );
    }
  }, [props.presets.value]);

  useEffect(() => {
    setState((state) => ({
      ...state,
      address: props?.fakeUsers?.currentUser?.address ?? "",
      geometry: {
        lat:
          Array.isArray(props?.fakeUsers?.currentUser?.location) &&
          !isEmpty(props?.fakeUsers?.currentUser?.location)
            ? props?.fakeUsers?.currentUser?.location[0]
            : 0,
        lng:
          Array.isArray(props?.fakeUsers?.currentUser?.location) &&
          !isEmpty(props?.fakeUsers?.currentUser?.location)
            ? props?.fakeUsers?.currentUser?.location[1]
            : 0,
      },

      addressParts: {
        city: props?.fakeUsers?.currentUser?.city ?? "",
        country: props.fakeUsers?.currentUser?.country ?? "",
        zip_code: props.fakeUsers?.currentUser?.zip_code ?? "",
        country_code: props.fakeUsers?.currentUser?.country_code ?? "",
      },
    }));
  }, [props?.fakeUsers?.currentUserLoading]);

  const removeUserMoment = (momentID) => {
    setState({
      ...state,
      isDeleteModalOpen: true,
      momentId: momentID,
      errors: [],
    });
    dispatch(getAllUserMoments());
  };

  const handleDeleteMoment = async () => {
    setState({
      ...state,
      isLoading: true,
    });
    await props.onRemoveUserAllMoments(state.momentId);
    setState({
      ...state,
      isDeleteModalOpen: false,
      errors: [],
    });
    dispatch(getAllUserMoments());
  };

  const handleLikeModal = (state) => {
    setLikeOpen(state);
  };

  const handleLikeModalClose = () => {
    setLikeOpen(false);
  };

  const handleCommentModalClose = () => {
    setCommentOpen(false);
  };

  const fetchModeratorByAdminsId = async () => {
    if (props?.fakeUsers?.currentUser?.id)
      await dispatch(getModeratorByAdminId(props?.fakeUsers?.currentUser?.id));
  };

  useEffect(() => {
    fetchModeratorByAdminsId();
  }, [props?.fakeUsers?.currentUser?.id]);

  return (
    <>
      <Modal open={state.isDeleteModalOpen}>
        <Header icon="archive" content="Are you sure want to delete ?" />
        <Modal.Content>
          <p>The file should be not recovered!</p>
        </Modal.Content>
        <Modal.Actions>
          {state?.errors?.length
            ? state.errors.map((error) => (
                <Message
                  style={{ display: "block", textAlign: "start" }}
                  error={true}
                >
                  {error}{" "}
                </Message>
              ))
            : null}
          <Button
            color="red"
            onClick={() => {
              setState({
                ...state,
                errors: [],
                isDeleteModalOpen: false,
                isLoading: false,
              });
            }}
          >
            <Icon name="remove" /> Cancel
          </Button>
          <Button onClick={handleDeleteMoment} color="green">
            <Icon name="checkmark" /> Delete
          </Button>
        </Modal.Actions>
      </Modal>
      <Segment.Group>
        <Dimmer.Dimmable as={Segment} dimmed={props?.fakeUsers?.loading}>
          <Dimmer active={props?.fakeUsers?.currentUserLoading} inverted>
            <Loader>Loading</Loader>
          </Dimmer>
          {props?.fakeUsers?.error && props?.fakeUsers?.error.visible && (
            <ErrorMessage
              error={props?.fakeUsers?.error}
              hideError={props.hideError}
            />
          )}
          <Segment
            inverted={
              state.isDirty &&
              props?.fakeUsers?.currentUser?.avatar_photos?.length === 0
            }
            tertiary={
              state.isDirty &&
              props?.fakeUsers?.currentUser?.avatar_photos?.length === 0
            }
            secondary={
              state.isDirty &&
              props?.fakeUsers?.currentUser?.avatar_photos?.length > 0
            }
            color={
              state.isDirty &&
              props?.fakeUsers?.currentUser?.avatar_photos?.length === 0
                ? "red"
                : "black"
            }
          >
            <Header
              size="large"
              color={
                state.isDirty &&
                props?.fakeUsers?.currentUser?.photos?.length === 0
                  ? "red"
                  : "black"
              }
            >
              <Card.Header className="cardHeader">
                Photos (*)
                <div
                  style={{
                    textTransform: "capitalize",
                  }}
                >
                  By:{" "}
                  {moderatorAdmin?.results?.map((v, i) => (
                    <span key={i}>{v?.fullName || v?.username}</span>
                  ))}
                </div>
                {props?.fakeUsers?.currentUser?.isOnline ? (
                  <div>
                    <img
                      src={onlineIcon}
                      alt="Online Icon"
                      width="15px"
                      height="15px"
                    />{" "}
                    Online
                  </div>
                ) : (
                  <div>
                    <img
                      src={offlineIcon}
                      alt="Online Icon"
                      width="15px"
                      height="15px"
                    />{" "}
                    Offline
                  </div>
                )}
              </Card.Header>
            </Header>
            {props?.fakeUsers?.currentUser?.avatar_photos?.length === 0 && (
              <Header>No photos selected</Header>
            )}
            <FakeUserPhotos />
          </Segment>
          <Segment
            inverted={
              state.isDirty &&
              props?.fakeUsers?.currentUser?.avatar_photos?.length === 0
            }
            tertiary={
              state.isDirty &&
              props?.fakeUsers?.currentUser?.avatar_photos?.length === 0
            }
            secondary={
              state.isDirty &&
              props?.fakeUsers?.currentUser?.avatar_photos?.length > 0
            }
            color={
              state.isDirty &&
              props?.fakeUsers?.currentUser?.avatar_photos?.length === 0
                ? "red"
                : "black"
            }
          >
            <Header
              size="large"
              color={
                state.isDirty &&
                props?.fakeUsers?.currentUser?.photos?.length === 0
                  ? "red"
                  : "black"
              }
            >
              Private Photos (*)
            </Header>
            {props?.fakeUsers.currentUser?.avatar_photos?.length === 0 && (
              <Header>No photos selected</Header>
            )}
            <FakeUserPrivatePhotos userId={props?.fakeUsers?.currentUser?.id} />
          </Segment>

          <FakeUserStories />
          <ScheduleStories />
          <Segment
            inverted={
              state.isDirty &&
              props?.fakeUsers?.currentUser?.avatar_photos?.length === 0
            }
            tertiary={
              state.isDirty &&
              props?.fakeUsers?.currentUser?.avatar_photos?.length === 0
            }
            secondary={
              state.isDirty &&
              props?.fakeUsers?.currentUser?.avatar_photos?.length > 0
            }
            color={
              state.isDirty &&
              props?.fakeUsers?.currentUser?.avatar_photos?.length === 0
                ? "red"
                : "black"
            }
          >
            <Header
              size="large"
              color={
                state.isDirty && props.fakeUsers.currentUser.photos.length === 0
                  ? "red"
                  : "black"
              }
            >
              Moment
            </Header>
            {momentsData?.length === 0 && <Header>No moments selected</Header>}
            <Card.Group itemsPerRow={4}>
              {momentsData
                ?.filter(
                  (x) =>
                    (!x.node?.publishAt ||
                      new Date(x.node?.publishAt).getTime() <=
                        currentTimestamp) &&
                    x?.node?.user?.id === props.fakeUsers?.currentUser?.id
                )
                ?.map((image, index) => {
                  return (
                    <Card key={index}>
                      <div className="moments-list-moment-card-image-container">
                        {isVideo(getImageUrl(image?.node?.file)) ? (
                          <video
                            style={{ width: "100%", height: "290px" }}
                            autoPlay
                            muted
                            loop
                          >
                            <source
                              src={getImageUrl(image?.node?.file)}
                              type="video/mp4"
                            />
                          </video>
                        ) : (
                          <Image
                            src={getImageUrl(image?.node?.file)}
                            centered
                          />
                        )}
                        {image?.node?.createdBy !== null && (
                          <span style={{ textAlign: "center" }}>
                            <p>
                              <b style={{ textTransform: "uppercase" }}>
                                Created By:
                              </b>{" "}
                              &nbsp;
                              {image?.node?.createdBy?.fullName}
                              &nbsp;
                              <Popup
                                hideOnScroll
                                on="click"
                                content="ID Copied!"
                                position="top center"
                                trigger={
                                  <Icon
                                    name="id badge"
                                    title="Click to copy ID"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      navigator.clipboard.writeText(
                                        image?.node?.createdBy?.id
                                      );
                                    }}
                                  />
                                }
                              />
                            </p>
                          </span>
                        )}
                      </div>
                      <Card.Content className="moments-list-card-bottom-content">
                        <p style={{ marginBottom: 20, wordBreak: "break-all" }}>
                          {image?.node?.momentDescription}
                        </p>
                        <div className="ui two buttons add-moment-button">
                          <Button
                            basic
                            color="red"
                            onClick={(e, data) => {
                              e.preventDefault();
                              props.onRemoveUserAllMoments(image?.node?.pk);
                            }}
                          >
                            Remove
                          </Button>
                        </div>
                      </Card.Content>
                      <div>
                        <button
                          style={{
                            width: 30,
                            height: 30,
                            background: "#ffffff",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            position: "absolute",
                            borderRadius: "10px",
                            top: 4,
                            right: 79,
                            border: "none",
                            zIndex: 10,
                          }}
                          type="button"
                          onClick={() => {
                            setMoment(image);
                            handleLikeModal(true);
                          }}
                        >
                          <div
                            style={{
                              position: "absolute",
                              top: "-5px",
                              right: "-3px",
                              width: "17px",
                              height: "17px",
                              borderRadius: "50%",
                              backgroundColor: "gold",
                              fontSize: "10px",
                              display: "grid",
                              placeItems: "center",
                              zIndex: 10,
                            }}
                          >
                            {image?.node?.like || 0}
                          </div>
                          <div>
                            <Icon
                              className="create-fake-moderator-remove-moment-image-icon"
                              link
                              corner
                              name={"like"}
                            />
                          </div>
                        </button>
                        <button
                          style={{
                            width: 30,
                            height: 30,
                            background: "#ffffff",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            position: "absolute",
                            borderRadius: "10px",
                            top: 5,
                            right: 42,
                            border: "none",
                            zIndex: 10,
                          }}
                          type="button"
                          onClick={() => {
                            setMoment(image);
                            setCommentOpen(!commentOpen);
                          }}
                        >
                          <div
                            style={{
                              position: "absolute",
                              top: "-5px",
                              right: "-3px",
                              width: "17px",
                              height: "17px",
                              borderRadius: "50%",
                              backgroundColor: "gold",
                              fontSize: "10px",
                              display: "grid",
                              placeItems: "center",
                            }}
                          >
                            {image?.node?.comment || 0}
                          </div>
                          <div>
                            <Icon
                              className="create-fake-moderator-remove-moment-image-icon"
                              link
                              corner
                              name={"comments"}
                            />
                          </div>
                        </button>
                        <button
                          style={{
                            width: 30,
                            height: 30,
                            background: "#ffffff",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            position: "absolute",
                            borderRadius: "10px",
                            top: 5,
                            right: 5,
                            border: "none",
                          }}
                          type="button"
                          onClick={() => removeUserMoment(image?.node?.pk)}
                        >
                          <div>
                            <Icon
                              className="create-fake-moderator-remove-moment-image-icon"
                              link
                              corner
                              name={"trash"}
                            />
                          </div>
                        </button>
                      </div>
                    </Card>
                  );
                })}
              <AddMoment
                onUpload={props.onUploadAllUserMoments}
                height={"200px"}
                header={"Add new image"}
                folder={props?.fakeUsers?.currentUser?.id || "images"}
                stockImages={props?.fakeUsers?.stockImages?.map((image) => {
                  const imageReplace = image?.file?.startsWith(
                    "https://djbbbm5n90p1g.cloudfront.net"
                  )
                    ? image?.file
                    : image?.file?.replace(`${baseUrl}/media/`, "");

                  return {
                    ...image,
                    file: image?.file?.startsWith(
                      "https://djbbbm5n90p1g.cloudfront.net"
                    )
                      ? imageReplace
                      : getImageUrl(image?.file),
                  };
                })}
                moderatorId={props?.fakeUsers?.currentUser?.id}
              />
            </Card.Group>
          </Segment>
          <Segment
            inverted={
              state.isDirty &&
              props.fakeUsers?.currentUser?.avatar_photos.length === 0
            }
            tertiary={
              state.isDirty &&
              props.fakeUsers?.currentUser?.avatar_photos.length === 0
            }
            secondary={
              state.isDirty &&
              props.fakeUsers?.currentUser?.avatar_photos.length > 0
            }
            color={
              state.isDirty &&
              props.fakeUsers?.currentUser?.avatar_photos.length === 0
                ? "red"
                : "black"
            }
          >
            <Header
              size="large"
              color={
                state.isDirty &&
                props.fakeUsers?.currentUser?.photos.length === 0
                  ? "red"
                  : "black"
              }
            >
              Schedule Moments
            </Header>
            {scheduleMomentsData?.length === 0 && (
              <Header>No schedule moments selected</Header>
            )}
            <Card.Group itemsPerRow={4}>
              {scheduleMomentsData
                /* ?.filter(
                  (x) =>
                    (x.node?.publishAt ||
                      momentFn(x.node?.publishAt) > momentFn()) &&
                    x?.node?.user?.id === props.fakeUsers.currentUser?.id
                ) */
                ?.map((image, index) => {
                  return (
                    <Card key={index}>
                      <div className="moments-list-moment-card-image-container">
                        {isVideo(image?.file) ? (
                          <video
                            style={{ width: "100%", height: "290px" }}
                            autoPlay
                            muted
                            loop
                          >
                            <source src={image?.file} type="video/mp4" />
                          </video>
                        ) : (
                          <Image src={image?.file} centered />
                        )}
                        {image?.createdBy !== null && (
                          <span style={{ textAlign: "center" }}>
                            <p>
                              <b style={{ textTransform: "uppercase" }}>
                                Created By:
                              </b>{" "}
                              &nbsp;
                              {image?.createdBy?.fullName}
                              &nbsp;
                              <Popup
                                hideOnScroll
                                on="click"
                                content="ID Copied!"
                                position="top center"
                                trigger={
                                  <Icon
                                    name="id badge"
                                    title="Click to copy ID"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      navigator.clipboard.writeText(
                                        image?.createdBy?.id
                                      );
                                    }}
                                  />
                                }
                              />
                            </p>
                          </span>
                        )}
                      </div>
                      <Card.Content className="moments-list-card-bottom-content">
                        <p style={{ margin: "20px 0", wordBreak: "break-all" }}>
                          {image?.momentDescription}
                        </p>
                        <p style={{ marginBottom: 20, wordBreak: "break-all" }}>
                          <span style={{ fontWeight: 600, fontSize: "0.9rem" }}>
                            Publish At:
                          </span>{" "}
                          {image?.publishAt}
                        </p>
                        <div className="ui two buttons add-moment-button">
                          <Button
                            basic
                            color="red"
                            onClick={(e, data) => {
                              e.preventDefault();
                              props.onRemoveScheduleMoment(image?.pk);
                            }}
                          >
                            Remove
                          </Button>
                        </div>
                      </Card.Content>
                      <div>
                        <button
                          style={{
                            width: 30,
                            height: 30,
                            background: "#ffffff",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            position: "absolute",
                            borderRadius: "10px",
                            top: 4,
                            right: 79,
                            border: "none",
                            zIndex: 10,
                          }}
                          type="button"
                          onClick={() => {
                            setMoment(image);
                            handleLikeModal(true);
                          }}
                        >
                          <div
                            style={{
                              position: "absolute",
                              top: "-5px",
                              right: "-3px",
                              width: "17px",
                              height: "17px",
                              borderRadius: "50%",
                              backgroundColor: "gold",
                              fontSize: "10px",
                              display: "grid",
                              placeItems: "center",
                              zIndex: 10,
                            }}
                          >
                            {image?.like || 0}
                          </div>
                          <div>
                            <Icon
                              className="create-fake-moderator-remove-moment-image-icon"
                              link
                              corner
                              name={"like"}
                            />
                          </div>
                        </button>
                        <button
                          style={{
                            width: 30,
                            height: 30,
                            background: "#ffffff",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            position: "absolute",
                            borderRadius: "10px",
                            top: 5,
                            right: 42,
                            border: "none",
                            zIndex: 10,
                          }}
                          type="button"
                          onClick={() => {
                            setMoment(image);
                            setCommentOpen(!commentOpen);
                          }}
                        >
                          <div
                            style={{
                              position: "absolute",
                              top: "-5px",
                              right: "-3px",
                              width: "17px",
                              height: "17px",
                              borderRadius: "50%",
                              backgroundColor: "gold",
                              fontSize: "10px",
                              display: "grid",
                              placeItems: "center",
                            }}
                          >
                            {image?.comment || 0}
                          </div>
                          <div>
                            <Icon
                              className="create-fake-moderator-remove-moment-image-icon"
                              link
                              corner
                              name={"comments"}
                            />
                          </div>
                        </button>
                        <button
                          style={{
                            width: 30,
                            height: 30,
                            background: "#ffffff",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            position: "absolute",
                            borderRadius: "10px",
                            top: 5,
                            right: 5,
                            border: "none",
                          }}
                          type="button"
                          onClick={() => removeUserMoment(image?.pk)}
                        >
                          <div>
                            <Icon
                              className="create-fake-moderator-remove-moment-image-icon"
                              link
                              corner
                              name={"trash"}
                            />
                          </div>
                        </button>
                      </div>
                    </Card>
                  );
                })}
              <AddScheduleMoments
                onUpload={props.onUploadScheduleMoment}
                height={"200px"}
                header={"Add new image"}
                folder={props.fakeUsers?.currentUser?.id || "images"}
                stockImages={props?.fakeUsers?.stockImages?.map((image) => {
                  const imageReplace = image?.file?.startsWith(
                    "https://djbbbm5n90p1g.cloudfront.net"
                  )
                    ? image?.file
                    : image?.file?.replace(`${baseUrl}/media/`, "");

                  return {
                    ...image,
                    file: image?.file?.startsWith(
                      "https://djbbbm5n90p1g.cloudfront.net"
                    )
                      ? imageReplace
                      : getImageUrl(image?.file),
                  };
                })}
                moderatorId={props.fakeUsers?.currentUser?.id}
              />
            </Card.Group>
          </Segment>
          {/* Schedule Moments */}
          <StockImageSection />
          {!props?.fakeUsers?.currentUserLoading && (
            <Segment padded>
              <Form>
                <Segment secondary>
                  <Form.Group widths="equal">
                    <Form.Input
                      fluid="true"
                      label={
                        "Name " +
                        (requiredFields?.some(
                          (item, i) => item.field === "fullName"
                        )
                          ? "(*)"
                          : "")
                      }
                      placeholder="Name"
                      value={props?.fakeUsers?.currentUser?.fullName || ""}
                      onChange={props.changeName}
                      error={
                        state?.errors?.length !== 0 &&
                        props?.fakeUsers?.currentUser?.fullName?.length === 0
                      }
                    />
                    <Form.Input
                      fluid="true"
                      label={
                        "Work " +
                        (requiredFields?.some(
                          (item, i) => item.field === "work"
                        )
                          ? "(*)"
                          : "")
                      }
                      placeholder="Work"
                      value={props?.fakeUsers?.currentUser?.work || ""}
                      onChange={props.changeWork}
                    />
                    <Form.Input
                      fluid="true"
                      label={
                        "Education " +
                        (requiredFields?.some(
                          (item, i) => item.field === "education"
                        )
                          ? "(*)"
                          : "")
                      }
                      placeholder="Education"
                      value={props?.fakeUsers?.currentUser?.education || ""}
                      onChange={props.changeEducation}
                    />
                  </Form.Group>
                  <Form.Group widths="equal">
                    <Form.Select
                      fluid="true"
                      search
                      selection
                      label="Subscription (*)"
                      placeholder="Subscription"
                      options={subscriptionOptions}
                      value={
                        subscriptionOptions?.find(
                          (sub) =>
                            sub?.text ===
                            props?.fakeUsers?.currentUser?.subscription
                        )?.value
                      }
                      onChange={(e, { value }) => {
                        const name = subscriptionOptions?.find(
                          (sub) => sub.value === value
                        )?.text;
                        console.log(name);
                        if (name) {
                          props.changeSubscription(name);
                        }
                      }}
                    />
                    <Form.Select
                      fluid="true"
                      search
                      selection
                      label="Age"
                      placeholder="Age"
                      options={ageOptions}
                      value={
                        props?.fakeUsers?.currentUser &&
                        props?.fakeUsers?.currentUser?.age
                          ? props?.fakeUsers?.currentUser?.age
                          : 18
                      }
                      onChange={props.changeAge}
                      error={
                        state.isDirty &&
                        props?.fakeUsers?.currentUser?.age === 0
                      }
                    />
                    <Form.Select
                      fluid="true"
                      search
                      selection
                      label={"Gender "}
                      options={genderOptions}
                      placeholder="Gender"
                      onChange={props.changeGender}
                      value={
                        props?.fakeUsers?.currentUser &&
                        props?.fakeUsers?.currentUser?.gender
                          ? props?.fakeUsers?.currentUser?.gender
                          : ""
                      }
                      error={
                        props?.fakeUsers?.currentUser?.gender !== 1 &&
                        props?.fakeUsers?.currentUser?.gender !== 2 &&
                        props?.fakeUsers?.currentUser?.gender !== 3
                      }
                    />
                    <Form.Select
                      fluid="true"
                      search
                      label="Height"
                      options={heightOptions}
                      placeholder="Height"
                      onChange={props.changeHeight}
                      value={
                        props?.fakeUsers?.currentUser &&
                        props?.fakeUsers?.currentUser?.height
                          ? props?.fakeUsers?.currentUser?.height
                          : 140
                      }
                    />
                    <Form.Select
                      fluid="true"
                      search
                      selection
                      label={"Ethnicity (*)"}
                      options={ethnicityOptions}
                      placeholder="Ethnicity"
                      onChange={props.changeEthnicity}
                      value={
                        props?.fakeUsers?.currentUser &&
                        props?.fakeUsers?.currentUser?.ethnicity
                          ? props?.fakeUsers?.currentUser?.ethnicity
                          : ""
                      }
                      error={
                        state?.errors?.length !== 0 &&
                        !props?.fakeUsers?.currentUser?.ethnicity
                      }
                    />
                  </Form.Group>
                  <Form.Group widths="equal">
                    <Form.Select
                      fluid="true"
                      search
                      selection
                      label={
                        "Zodiac Sign " +
                        (requiredFields?.some(
                          (item, i) => item.field === "zodiacSign"
                        )
                          ? "(*)"
                          : "")
                      }
                      options={zodiacSignsOptions}
                      placeholder="Zodiac Sign"
                      onChange={props.changeZodiacSign}
                      value={
                        isNaN(props?.fakeUsers?.currentUser?.zodiacSign)
                          ? zodiacSignsOptions?.find(
                              (i) =>
                                i.text ===
                                props?.fakeUsers?.currentUser?.zodiacSign
                            )?.value
                          : props?.fakeUsers?.currentUser?.zodiacSign
                          ? props?.fakeUsers?.currentUser?.zodiacSign
                          : 0
                      }
                    />
                    <Form.Select
                      fluid="true"
                      search
                      selection
                      label={
                        "Family plans " +
                        (requiredFields?.some(
                          (item, i) => item.field === "familyPlans"
                        )
                          ? "(*)"
                          : "")
                      }
                      options={familyOptions}
                      placeholder="Family plans"
                      onChange={props.changeFamilyPlans}
                      value={
                        props?.fakeUsers?.currentUser &&
                        props?.fakeUsers?.currentUser?.familyPlans
                          ? props?.fakeUsers?.currentUser?.familyPlans
                          : ""
                      }
                    />
                    <Form.Select
                      fluid="true"
                      search
                      selection
                      label={
                        "Politics " +
                        (requiredFields?.some(
                          (item, i) => item.field === "politics"
                        )
                          ? "(*)"
                          : "")
                      }
                      options={politicsOptions}
                      placeholder="Politics"
                      onChange={props.changePolitics}
                      value={
                        props?.fakeUsers?.currentUser &&
                        props?.fakeUsers?.currentUser?.politics
                          ? props?.fakeUsers?.currentUser?.politics
                          : ""
                      }
                    />
                    <Form.Select
                      fluid="true"
                      search
                      selection
                      label={
                        "Religious Beliefs " +
                        (requiredFields?.some(
                          (item, i) => item.field === "religion"
                        )
                          ? "(*)"
                          : "")
                      }
                      options={religiousOptions}
                      placeholder="Religious Beliefs"
                      onChange={props.changeReligious}
                      value={
                        props?.fakeUsers?.currentUser &&
                        props?.fakeUsers?.currentUser?.religion
                          ? props?.fakeUsers?.currentUser?.religion
                          : ""
                      }
                    />
                  </Form.Group>
                  <Form.Group widths="equal">
                    <Form.Select
                      fluid="true"
                      label={"Interested In (*)"}
                      multiple
                      search
                      selection
                      options={commonConstants.INTERESTED_IN_OPTIONS}
                      placeholder="Interested In"
                      onChange={props.changeInterestedIn}
                      value={props?.fakeUsers?.currentUser?.interestedIn}
                      error={
                        state?.errors?.length !== 0 &&
                        !props?.fakeUsers?.currentUser?.interestedIn?.length
                      }
                    />

                    <Form.Select
                      fluid="true"
                      label={"Tags (*)"}
                      multiple
                      search
                      selection
                      options={tagsOptions}
                      placeholder="Tags"
                      onChange={props.changeTags}
                      value={
                        props?.fakeUsers?.currentUser &&
                        props?.fakeUsers?.currentUser?.tags
                          ? props?.fakeUsers?.currentUser?.tags
                          : ""
                      }
                      error={
                        state?.errors?.length !== 0 &&
                        !props?.fakeUsers?.currentUser?.tags?.length
                      }
                    />
                  </Form.Group>
                  <Form.TextArea
                    label={
                      "About " +
                      (requiredFields?.some((item, i) => item.field === "about")
                        ? "(*)"
                        : "")
                    }
                    placeholder="About"
                    value={
                      props?.fakeUsers?.currentUser &&
                      props?.fakeUsers?.currentUser?.about
                        ? props?.fakeUsers?.currentUser?.about
                        : ""
                    }
                    onChange={props.changeAbout}
                  />

                  <Segment>
                    <div
                      style={{
                        width: "100%",
                        position: "relative",
                      }}
                    >
                      <MapContainer
                        latLng={{
                          lat:
                            Array.isArray(
                              props?.fakeUsers?.currentUser?.location
                            ) &&
                            !isEmpty(props?.fakeUsers?.currentUser?.location)
                              ? props?.fakeUsers?.currentUser?.location[0]
                              : 0,
                          lng:
                            Array.isArray(
                              props?.fakeUsers?.currentUser?.location
                            ) &&
                            !isEmpty(props?.fakeUsers?.currentUser?.location)
                              ? props?.fakeUsers?.currentUser?.location[1]
                              : 0,
                        }}
                        formattedAddress={
                          props?.fakeUsers?.currentUser?.address ?? ""
                        }
                        handelChange={(e) => {
                          setState((state) => ({
                            ...state,
                            address: e.formattedAddress,
                            geometry: {
                              lat: e.latLng.lat,
                              lng: e.latLng.lng,
                            },
                            addressParts: e.addressParts,
                          }));
                        }}
                        mapStyle={{
                          height: "600px",
                        }}
                        addressParts={{
                          city: props?.fakeUsers?.currentUser?.city ?? "",
                          country: props?.fakeUsers?.currentUser?.country ?? "",
                          zip_code:
                            props?.fakeUsers?.currentUser?.zip_code ?? "",
                        }}
                      />
                    </div>
                  </Segment>
                  <Header size="large">Additional fields </Header>
                  <Grid columns="equal">
                    <Grid.Row>
                      <Grid.Column>
                        <Tags
                          isRequired={requiredFields?.some(
                            (item, i) => item.field === "books"
                          )}
                          tags={props?.fakeUsers?.currentUser?.books}
                          item="book"
                          type={commonConstants.BOOK_ITEM}
                          header="Books"
                          onChangeTag={props.onChangeItem}
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <Tags
                          tags={props?.fakeUsers?.currentUser?.music}
                          item="music"
                          type={commonConstants.MUSIC_ITEM}
                          header="Music"
                          onChangeTag={props.onChangeItem}
                        />
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column>
                        <Tags
                          tags={props?.fakeUsers?.currentUser?.movies}
                          item="movie"
                          type={commonConstants.MOVIE_ITEM}
                          header="Movies"
                          onChangeTag={props.onChangeItem}
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <Tags
                          tags={props?.fakeUsers?.currentUser?.tvShows}
                          item="TV show"
                          type={commonConstants.TV_SHOW_ITEM}
                          header="TV Shows"
                          onChangeTag={props.onChangeItem}
                        />
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column>
                        <Tags
                          tags={props?.fakeUsers?.currentUser?.sportsTeams}
                          item="team"
                          type={commonConstants.SPORT_TEAM_ITEM}
                          header="Sports Teams"
                          onChangeTag={props.onChangeItem}
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <Form.TextArea
                          label={
                            "Note " +
                            (requiredFields?.some(
                              (item) => item.field === "note"
                            )
                              ? "(*)"
                              : "")
                          }
                          placeholder="Note"
                          value={
                            props?.fakeUsers?.currentUser &&
                            props?.fakeUsers?.currentUser?.note
                              ? props?.fakeUsers?.currentUser?.note
                              : ""
                          }
                          onChange={(e, { value }) => {
                            if (localStorage.getItem("Newmoderaotr")) {
                              props.changeNote(e, { value });
                            }
                          }}
                          onKeyPress={(e) => {
                            if (
                              e.key === "Enter" &&
                              localStorage.getItem("Newmoderaotr")
                            ) {
                              localStorage.removeItem("Newmoderaotr");
                              e.preventDefault();
                            }
                          }}
                          disabled={!localStorage.getItem("Newmoderaotr")}
                        />
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Segment>
                {state?.errors?.length
                  ? state?.errors?.map((error) => (
                      <Message style={{ display: "block" }} error={true}>
                        {error}{" "}
                      </Message>
                    ))
                  : null}
                <Segment secondary textAlign="center">
                  <Link to={"/moderators"}>
                    <Button size="large" content="Cancel" />
                  </Link>
                  <Button
                    size="large"
                    color="blue"
                    content="Save"
                    onClick={(e, data) => {
                      e.preventDefault();
                      saveUser(
                        props?.fakeUsers?.workerId,
                        props?.fakeUsers?.currentUser
                      );
                    }}
                  />
                </Segment>
              </Form>
            </Segment>
          )}
        </Dimmer.Dimmable>
      </Segment.Group>
      <LikeModal
        isModalOpen={likeOpen}
        momentId={moment?.node?.pk}
        handleClose={handleLikeModalClose}
        // handleSuccess={handleLikeSuccess}
      />
      <AddCommentModal
        isModalOpen={commentOpen}
        momentId={moment?.node?.pk}
        handleClose={handleCommentModalClose}
        // handleSuccess={hanldeAddCommentSuccess}
      />
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    getWorkerFakeUsers(id) {
      getWorkerFakeUsers(id)(dispatch);
    },
  };
};

export default connect(null, mapDispatchToProps)(withRouter(Moderator));

Moderator.propTypes = {
  fakeUsers: PropTypes.shape({
    loading: PropTypes.bool,
    error: PropTypes.shape({
      visible: PropTypes.bool,
    }),
    currentUser: PropTypes.shape({
      fullName: PropTypes.string.isRequired,
      lastName: PropTypes.string,
      work: PropTypes.string,
      education: PropTypes.string,
      age: PropTypes.number.isRequired,
      gender: PropTypes.number.isRequired,
      subscription: PropTypes.string,
      height: PropTypes.number,
      ethnicity: PropTypes.string,
      zodiacSign: PropTypes.string,
      familyPlans: PropTypes.string,
      politics: PropTypes.string,
      religion: PropTypes.string,
      tags: PropTypes.array,
      interestedIn: PropTypes.array,
      books: PropTypes.array,
      movies: PropTypes.array,
      music: PropTypes.array,
      tvShows: PropTypes.array,
      sportsTeams: PropTypes.array,
    }).isRequired,
  }).isRequired,
  onChangeItem: PropTypes.func.isRequired,
  onAddNewPhoto: PropTypes.func.isRequired,
  onRemovePhoto: PropTypes.func.isRequired,
  changeName: PropTypes.func.isRequired,
  changeLastName: PropTypes.func.isRequired,
  changeGender: PropTypes.func.isRequired,
  changeAbout: PropTypes.func.isRequired,
  changeNote: PropTypes.func.isRequired,
  changeAge: PropTypes.func.isRequired,
  changeTags: PropTypes.func.isRequired,
  changeInterestedIn: PropTypes.func.isRequired,
  changePolitics: PropTypes.func.isRequired,
  changeSubscription: PropTypes.func.isRequired,
  changeReligious: PropTypes.func.isRequired,
  changeFamilyPlans: PropTypes.func.isRequired,
  changeZodiacSign: PropTypes.func.isRequired,
  changeHeight: PropTypes.func.isRequired,
  changeEducation: PropTypes.func.isRequired,
  changeEthnicity: PropTypes.func.isRequired,
  changeWork: PropTypes.func.isRequired,
  updateUser: PropTypes.func.isRequired,
};
