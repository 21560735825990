import React, { Component } from "react";
import FileField from "./FileField";
import { Button, Grid, Loader, Icon } from "semantic-ui-react";
import { useRef } from "react";
import { useState } from "react";
import AvatarEditor from "react-avatar-editor";

export const cropImageStyle = {
  position: "fixed",
  zIndex: 999,
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  background: "#fff",
  border: "1px solid #ddd",
  padding: "10px 16px",
};

function MediaUpload({ onUpload, height, header }) {
  const inputRef = useRef();
  const [media, setMedia] = useState(null);
  const [mediaType, setMediaType] = useState("image");
  const [url, setUrl] = useState("");
  const [isValid, setIsValid] = useState(true);
  const [loading, setLoading] = useState(false);
  const [dimentions, setDimentions] = useState({ width: 320, height: 640 });

  // START
  const editorRef = useRef(null);
  const [image, setImage] = useState(null);
  const [scale, setScale] = useState(1);
  const [preview, setPreview] = useState(null);

  const handleScaleChange = (e) => {
    const newScale = parseFloat(e.target.value);
    setScale(newScale);
  };

  const dataURItoBlob = (dataURI) => {
    const byteString = atob(dataURI.split(",")[1]);
    const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);

    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ab], { type: mimeString });
  };

  const handleCrop = async () => {
    if (editorRef.current) {
      const canvas = editorRef.current.getImageScaledToCanvas();

      const croppedImage = canvas.toDataURL("image/jpeg", 1);

      const file = dataURItoBlob(croppedImage);
      setPreview(file);
      setUrl(croppedImage);
      setMedia(file);
      setImage(null);
    }
  };

  const skipCrop = () => {
    setImage(null);
  };
  // END

  const selectImage = (e) => {
    inputRef.current.click();
  };

  const handleChange = (e) => {
    if (e.target.files[0]) {
      const file = e.target.files[0];
      if (file?.type?.includes("video")) {
        setMediaType("video");
      } else {
        setMediaType("image");
      }
      setMedia(file);
      setImage(file);
      setUrl(URL.createObjectURL(file));
    }
  };

  const reset = () => {
    setMedia(null);
    setUrl("");
    setIsValid(true);
    setLoading(false);
  };

  const handleUpload = async () => {
    setLoading(true);
    // console.log(typeof media);
    try {
      media.name = media.type.split("/")[1];
    } catch {}
    const mediaName = generateName(media);
    const fileParams = {
      name: mediaName,
      file: media,
      type: media.type,
      size: media.size,
      public: false,
    };
    onUpload(fileParams);
    reset();
    setLoading(false);
    setImage(null);
  };
  return (
    <div>
      <input
        type="file"
        onChange={(e) => handleChange(e)}
        style={{ display: "none" }}
        ref={inputRef}
      />
      <Grid textAlign="center">
        <Grid.Row>
          <FileField
            height={height}
            src={url}
            type={mediaType}
            onClick={() => selectImage()}
          />
          {url !== "" && <Icon link corner name={"close"} onClick={reset} />}
        </Grid.Row>
        <Grid.Row>
          <Button
            disabled={!isValid}
            color="blue"
            content={header}
            onClick={(e, data) => {
              e.preventDefault();
              handleUpload();
            }}
          />
        </Grid.Row>
      </Grid>
      {image && mediaType === "image" && (
        <div style={cropImageStyle}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <h2>Crop Image</h2>
            <Button onClick={() => skipCrop()}>X</Button>
          </div>
          <hr />
          <AvatarEditor
            ref={editorRef}
            image={image}
            width={dimentions?.width}
            height={dimentions?.height}
            border={50}
            color={[0, 0, 0, 0.6]}
            scale={scale}
            rotate={0}
          />
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                columnGap: "20px",
              }}
            >
              <label htmlFor="scale">Scale:</label>
              <input
                type="range"
                id="scale"
                min="0.1"
                max="3"
                step="0.01"
                value={scale}
                onChange={handleScaleChange}
              />
            </div>
            {/* <Button onClick={skipCrop}>Use Full Image</Button> */}
            <Button
              onClick={() =>
                setDimentions({ ...dimentions, width: 320, height: 320 })
              }
            >
              1:1
            </Button>
            <Button
              onClick={() =>
                setDimentions({ ...dimentions, width: 320, height: 640 })
              }
            >
              9:16
            </Button>
            <Button onClick={handleCrop}>Crop</Button>
          </div>
        </div>
      )}
    </div>
  );
}

export default MediaUpload;

function generateName(file) {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  for (var i = 0; i < 29; i++) {
    result += characters.charAt(Math.floor(Math.random() * 29));
  }
  const fileNameParts = file?.name?.split(".");
  return result + "." + fileNameParts[fileNameParts?.length - 1];
}
