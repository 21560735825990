import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Card,
  Header,
  Icon,
  Message,
  Modal,
  Popup,
} from "semantic-ui-react";
import { onAddImage, uploadAvatarPhoto } from "../../actions/fakeUsers";
import { selectFakeUsersState } from "../../store/selectors/fakeUsersSelectors";
import UserApi from "../../utils/users";
import AddNewPhoto from "../imageUpload/addNewPhoto";
import { baseUrl } from "../../utils/api";
import { getImageUrl, isVideo } from "../../utils/helpers";

const FakeUserPhotos = () => {
  const dispatch = useDispatch();
  const fakeUsersState = useSelector(selectFakeUsersState);
  const userId = fakeUsersState?.currentUser?.id;
  // const isfakeUsersStateLoading = fakeUsersState?.loading;
  const avatarPhotos = fakeUsersState?.currentUser?.avatar_photos ?? [];

  const [state, setState] = useState({
    isDeleteModalOpen: false,
    imageId: "",
    isLoading: false,
    errors: [],
    isUpdatingImageIndex: false,
    messages: [],
  });

  const handleDeleteModal = ({ imageId, isDeleteModalOpen }) => {
    setState({
      ...state,
      imageId,
      isDeleteModalOpen,
      errors: [],
    });
  };

  const removeUserProfileImage = async () => {
    setState({
      ...state,
      isLoading: true,
    });
    const res = await UserApi.removeUserProfileImage({
      imageId: state.imageId,
      moderatorId: fakeUsersState?.currentUser?.id,
    });
    if (!res?.success) {
      setState({
        ...state,
        isLoading: false,
        errors: [
          res?.message ?? "Something went wrong while removing profile image!",
        ],
      });
      return;
    }
    setState({
      ...state,
      isLoading: false,
      isDeleteModalOpen: false,
      errors: [],
    });
    window.location.reload();
  };

  const handleUpdatePhotoIndexSuccess = (
    messages = [
      {
        text: "Profile photo updated successfully",
        type: "success",
      },
    ]
  ) => {
    setState({
      ...state,
      errors: [],
      messages,
    });
    window.location.reload();
  };

  const handleRemoveMessage = (messageIndex) => {
    let updatedMessages = state.messages.filter(
      (msg, idx) => idx !== messageIndex
    );

    setState({
      ...state,
      messages: updatedMessages,
    });
  };

  const onAddNewPhoto = (item) => {
    dispatch(onAddImage(item));
  };

  const onuploadAvatarPhoto = async (params) => {
    dispatch(
      uploadAvatarPhoto(userId, params, "", (data) => {
        setState({
          ...state,
          messages: [
            {
              text: data?.reason,
              type: "error",
            },
          ],
        });
      })
    );
    // .then((data) => {
    //     console.log("🚀 ~ file: FakeUserPhotos.js:105 ~ data ~ data:", data)
    //     return data
    //   })
    // this.props?.getFakeUserDetails(userId)
  };

  return (
    <div style={{ width: "100%" }}>
      <div style={{ width: "100%", marginBottom: 15 }}>
        {state?.messages?.length
          ? state.messages.map((message, idx) => (
              <Message
                style={{ display: "block", textAlign: "start" }}
                error={message.type === "error"}
                success={message.type === "success"}
                onDismiss={() => handleRemoveMessage(idx)}
              >
                {message.text}
              </Message>
            ))
          : null}
      </div>
      <Modal open={state.isDeleteModalOpen}>
        <Header icon="archive" content="Are you sure want to delete ?" />
        <Modal.Content>
          <p>The file should be not recovered!</p>
        </Modal.Content>
        <Modal.Actions>
          {state?.errors?.length
            ? state.errors.map((error) => (
                <Message
                  style={{ display: "block", textAlign: "start" }}
                  error={true}
                >
                  {error}{" "}
                </Message>
              ))
            : null}
          <Button
            color="red"
            onClick={() =>
              handleDeleteModal({ imageId: "", isDeleteModalOpen: false })
            }
          >
            <Icon name="remove" /> Cancel
          </Button>
          <Button
            loading={state.loading}
            color="green"
            onClick={() => removeUserProfileImage()}
          >
            <Icon name="checkmark" /> Delete
          </Button>
        </Modal.Actions>
      </Modal>
      <Card.Group itemsPerRow={4}>
        {avatarPhotos?.map((image, avatarIndex) => {
          if (image.type !== "PRIVATE") {
            return (
              <UsersPhoto
                key={image?.id ?? avatarIndex}
                photoData={image}
                photoIndex={avatarIndex}
                handleDeleteModal={handleDeleteModal}
                updatePhotoIndexSuccessCallback={handleUpdatePhotoIndexSuccess}
              />
            );
          }
          return <></>;
        })}
        <Card>
          <AddNewPhoto
            onUpload={onuploadAvatarPhoto}
            onAdd={onAddNewPhoto}
            height={"200px"}
            header={"Add new image"}
            stockImage={true}
            stockImages={fakeUsersState?.stockImages?.map((image) => {
              const imageReplace = image?.file?.startsWith(
                "https://djbbbm5n90p1g.cloudfront.net"
              )
                ? image?.file
                : image?.file?.replace(`${baseUrl}/media/`, "");

              return {
                ...image,
                file: image?.file?.startsWith(
                  "https://djbbbm5n90p1g.cloudfront.net"
                )
                  ? imageReplace
                  : getImageUrl(image?.file),
              };
            })}
            moderatorId={fakeUsersState.currentUser.id}
            getStockImages={(id) => {}}
            position={" bottom left"}
            folder={fakeUsersState.currentUser.id || "images"}
            type={"profile"}
            userId={userId}
          />
        </Card>
      </Card.Group>
    </div>
  );
};

/**
 *
 * UsersPhoto component
 */

const UsersPhoto = ({
  photoData,
  photoIndex,
  updatePhotoIndexSuccessCallback,
  handleDeleteModal,
}) => {
  const fakeUsersState = useSelector(selectFakeUsersState);
  const userId = fakeUsersState?.currentUser?.id;
  const avatarIndex = fakeUsersState?.currentUser?.avatar_index;
  const [currentPhotoIndex, setCurrentPhotoIndex] = useState(photoIndex);

  const [state, setState] = useState({
    imageId: "",
    errors: [],
    isUpdatingImageIndex: false,
  });

  const updateImageIndex = async () => {
    setCurrentPhotoIndex();

    setState({
      ...state,
      isUpdatingImageIndex: true,
    });

    const res = await UserApi.updateProfileImageIndex({
      avatarIndex: photoIndex,
      userId,
    });

    if (!res) {
      setState({
        ...state,
        isLoading: false,
        isUpdatingImageIndex: false,
        errors: ["Something went wrong while updating profile image!"],
      });
      updatePhotoIndexSuccessCallback([
        {
          type: "error",
          text: "Something went wrong while updating profile image!",
        },
      ]);
      return;
    }

    if (res?.errors) {
      setState({
        ...state,
        isUpdatingImageIndex: false,
        errors: res?.errors ?? [],
      });
      const errors = res?.errors.map((error) => {
        return { type: "error", text: error };
      });
      updatePhotoIndexSuccessCallback(errors ?? []);
      return;
    }

    setState({
      ...state,
      isUpdatingImageIndex: false,
      errors: [],
    });
    updatePhotoIndexSuccessCallback();
  };

  return (
    <Card className="user-details-form_images-list">
      <div className="image-container">
        {isVideo(photoData.file || photoData.file_url) ? (
          <video style={{ width: "100%", height: "290px" }} autoPlay muted loop>
            <source
              src={photoData.file || photoData.file_url}
              type="video/mp4"
            />
          </video>
        ) : (
          <img src={photoData.file || photoData.file_url} />
        )}
        <button
          style={{
            width: 30,
            height: 30,
            background: "#ffffff",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            borderRadius: "10px",
            top: 5,
            right: 5,
            border: "none",
          }}
          type="button"
          onClick={() =>
            handleDeleteModal({
              imageId: photoData?.id,
              isDeleteModalOpen: true,
            })
          }
        >
          <div>
            <Icon
              className="create-fake-moderator-remove-moment-image-icon"
              link
              corner
              name={"trash"}
            />
          </div>
        </button>
      </div>
      {Boolean(photoData?.created_by) && (
        <span style={{ textAlign: "center" }}>
          <p>
            <b style={{ textTransform: "uppercase" }}>Created By:</b> &nbsp;
            {photoData?.created_by_full_name}
            &nbsp;
            <Popup
              hideOnScroll
              on="click"
              content="ID Copied!"
              position="top center"
              trigger={
                <Icon
                  name="id badge"
                  title="Click to copy ID"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    navigator.clipboard.writeText(photoData?.created_by);
                  }}
                />
              }
            />
          </p>
        </span>
      )}

      <Card.Content>
        <div className="ui two buttons">
          {currentPhotoIndex === avatarIndex ? (
            <Button type="button" color="grey" disabled>
              Primary image
            </Button>
          ) : (
            <Button
              loading={state.isUpdatingImageIndex}
              type="button"
              color="blue"
              onClick={() => updateImageIndex()}
            >
              Make primary
            </Button>
          )}
        </div>
      </Card.Content>
    </Card>
  );
};

export default FakeUserPhotos;
