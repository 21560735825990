import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Button,
  Segment,
  Dimmer,
  Loader,
  Form,
  Header,
  Image,
  Card,
  Input,
  Grid,
  Message,
} from "semantic-ui-react";
import ErrorMessage from "../errorMessage";
import { withRouter, Link } from "react-router-dom";
import PropTypes from "prop-types";
import Tags from "./tagsComponent";
import { commonConstants } from "../../constants/common";
import AddNewPhoto from "../imageUpload/addNewPhoto";
import { getWorkerFakeUsers } from "../../actions/fakeUsers";
import { Api, googleMapsApi, googleMapsApiKey } from "../../utils/api";
import AddMoment from "./AddMoment";
import FakeUserPhotos from "./FakeUserPhotos";

import "./index.css";
import FakeUserStories from "./FakeUserStories";

const requiredFields = [
  { field: "fullName", validateFn: (value) => value?.length > 0 },
  { field: "age", validateFn: (value) => !!value },
  { field: "gender", validateFn: (value) => !!value },
  // { field: "photos", validateFn: (value) => value.length > 0 }
];

let addressSearchTimeout = null;
let addressAutoComplete = null;
let addressInput = null;
let map = null;
let addressMarker = null;
let geoCodingService = null;
let selectedPlace = null;

class FakeUser extends Component {
  state = {
    isDirty: false,
    address: "",
    geometry: { lat: "0", lng: "0" },
    errors: [],
  };

  saveUser = async (workerId, currentUser) => {
    // if (!this.validate(currentUser)) {
    //   this.setState({ isDirty: true });
    //   return;
    // }
    if (currentUser.id) {
      this.props.updateUser(workerId, currentUser.id, currentUser);
      this.setState({ isDirty: false });
      return;
    }
    let errors = [];
    if (!currentUser.fullName) {
      errors.push("Please enter name!");
    }
    if (currentUser.gender !== 1 && currentUser.gender !== 2) {
      errors.push("Please select gender!");
    }
    if (currentUser?.ethnicity === 0 || !currentUser?.ethnicity) {
      errors.push("Please select Ethnicity!");
    }
    if (!currentUser?.interestedIn?.length) {
      errors.push("Please select interested in!");
    }
    if (!currentUser?.tags?.length) {
      errors.push("Please select tags!");
    }
    if (!currentUser?.music?.length) {
      errors.push("Please add music!");
    }
    if (!currentUser?.movies?.length) {
      errors.push("Please add movies!");
    }
    if (!currentUser?.tvShows?.length) {
      errors.push("Please add tv shows!");
    }
    if (!currentUser?.sportsTeams?.length) {
      errors.push("Please add sports teams!");
    }
    if (
      !this.state.address ||
      !this.state.geometry.lat ||
      !this.state.geometry.lat
    ) {
      let isInvalid = true;
      if (addressInput) {
        const address = addressInput?.value;

        if (address) {
          const latLngRes = await Api.getLatLngFromAddress({
            addressString: address,
          });
          const geometry = latLngRes?.results?.[0]?.geometry?.location;

          this.state.geometry = {
            lat: geometry?.lat ?? 0,
            lng: geometry?.lng ?? 0,
          };
          this.state.address =
            latLngRes?.results?.[0]?.formatted_address ?? address;
          isInvalid = false;
        }
      }
      if (isInvalid) errors.push("Please enter addresss!");
    }

    if (errors.length) {
      this.setState({ errors });
      return;
    }

    let { name, lastName = "", age = 18, fullName = "" } = currentUser;
    name = name.replace(/[^0-9a-z_]/gi, "");
    lastName = lastName.replace(/[^0-9a-z_]/gi, "");
    let updatedName = fullName.trim().replace(" ", "_");
    this.props.createUser({
      ...currentUser,
      email: `${updatedName}${age}@i69app.com`,
      display_name: `${updatedName}_${age}`,
      username: `${updatedName}_${age}@i69app.com`,
      address: this.state.address,
      location: `${this.state.geometry.lat}, ${this.state.geometry.lng}`,
    });
    // this.setState({ isDirty: false });
  };

  validate = (user) => {
    const form_valid = requiredFields.every((item, index) =>
      item.validateFn(user[item.field])
    );
    // console.log({
    //   form_valid,
    //   requiredFields: requiredFields.map((item, index) =>
    //     item.validateFn(user[item.field])
    //   ),
    // });
    return form_valid;
  };

  componentDidMount() {
    this.props.getWorkerFakeUsers(this.props.match.params.workerId);

    const script = document.createElement("script");
    // script.src = `https://maps.googleapis.com/maps/api/js?key=${googleMapsApiKey}&libraries=places&callback=initMap`;
    script.src = `${googleMapsApi}/js?key=${googleMapsApiKey}&libraries=places`;
    script.async = true;
    script.id = "google-maps-script";

    document.body.appendChild(script);

    script.addEventListener("load", (event) => {
      map = new window.google.maps.Map(
        document.getElementById("google-map-for-address-preview"),
        {
          center: { lat: 40.749933, lng: -73.98633 },
          zoom: 13,
          mapTypeControl: false,
        }
      );

      const center = { lat: 50.064192, lng: -130.605469 };
      // Create a bounding box with sides ~10km away from the center point
      const defaultBounds = {
        north: center.lat + 0.1,
        south: center.lat - 0.1,
        east: center.lng + 0.1,
        west: center.lng - 0.1,
      };
      addressInput = document.getElementById("address-input");
      const searchBox = new window.google.maps.places.SearchBox(addressInput);

      const options = {
        // bounds: defaultBounds,
        // componentRestrictions: { country: "us" },
        fields: [
          // "address_components",
          "geometry",
          "icon",
          "name",
          "formatted_address",
        ],
        strictBounds: false,
        types: ["establishment"],
      };

      addressAutoComplete = new window.google.maps.places.Autocomplete(
        addressInput,
        options
      );
      addressAutoComplete.bindTo("bounds", map);
      let markers = [];

      map.addListener("bounds_changed", () => {
        searchBox.setBounds(map.getBounds());
      });
      searchBox.addListener("places_changed", () => {
        const places = searchBox.getPlaces();
        if (places.length == 0) {
          return;
        }
        markers.forEach((marker) => {
          marker.setMap(null);
        });
        markers = [];
        const bounds = new window.google.maps.LatLngBounds();

        places.forEach((place) => {
          if (!place.geometry || !place.geometry.location) {
            console.log("Returned place contains no geometry");
            return;
          }

          const icon = {
            url: place.icon,
            size: new window.google.maps.Size(71, 71),
            origin: new window.google.maps.Point(0, 0),
            anchor: new window.google.maps.Point(17, 34),
            scaledSize: new window.google.maps.Size(25, 25),
          };

          // Create a marker for each place.
          markers.push(
            new window.google.maps.Marker({
              map,
              icon,
              title: place.name,
              position: place.geometry.location,
            })
          );

          if (place.geometry.viewport) {
            // Only geocodes have viewport.
            bounds.union(place.geometry.viewport);
          } else {
            bounds.extend(place.geometry.location);
          }
        });
        map.fitBounds(bounds);
      });

      const infowindow = new window.google.maps.InfoWindow();
      const infowindowContent = document.getElementById("infowindow-content");
      infowindow.setContent(infowindowContent);

      window.google.maps.event.addListener(
        addressAutoComplete,
        "place_changed",
        () => {
          if (addressMarker) {
            addressMarker.setMap(null);
          }
          addressMarker = new window.google.maps.Marker({
            map,
            anchorPoint: new window.google.maps.Point(0, -29),
          });

          addressMarker.setVisible(false);
          const place = addressAutoComplete.getPlace();

          if (!place.geometry || !place.geometry.location) {
            // User entered the name of a Place that was not suggested and
            // pressed the Enter key, or the Place Details request failed.
            return;
          }

          // If the place has a geometry, then present it on a map.
          if (place.geometry.viewport) {
            map.fitBounds(place.geometry.viewport);
          } else {
            map.setCenter(place.geometry.location);
            map.setZoom(17);
          }

          addressMarker.setPosition(place.geometry.location);
          addressMarker.setVisible(true);
          infowindowContent.children["place-name"].textContent = place.name;
          infowindowContent.children["place-address"].textContent =
            place.formatted_address;
          infowindow.open(map, addressMarker);

          this.setState({
            address: place?.formatted_address ?? "",
            geometry: {
              lat: place.geometry.location?.lat() ?? 0,
              lng: place.geometry.location.lng() ?? 0,
            },
          });
          this.props.changeAddress(place?.formatted_address);
          this.props.changeLocation([
            place.geometry.location?.lat() ?? 0,
            place.geometry.location.lng() ?? 0,
          ]);
        }
      );
      window.google.maps.event.addListener(map, "click", (e) => {
        if (addressMarker) {
          addressMarker.setMap(null);
        }
        geoCodingService = new window.google.maps.Geocoder();

        let request = {
          latLng: e.latLng,
        };

        geoCodingService.geocode(request, (results, status) => {
          if ((status = "OK")) {
            selectedPlace = results?.[0];
            const adderes = selectedPlace?.formatted_address;
            let location = selectedPlace.geometry.location;
            addressMarker = new window.google.maps.Marker({
              position: e.latLng,
              map: map,
            });
            infowindowContent.children["place-name"].textContent = adderes;
            // infowindowContent.children["place-address"].textContent = adderes;
            infowindow.open(map, addressMarker);
            addressMarker.setVisible(true);
            addressMarker.setMap(map);
            // map.panTo(e.latLng);
            this.props.changeAddress(adderes);
            this.props.changeLocation([
              location?.lat() ?? 0,
              location.lng() ?? 0,
            ]);

            this.setState({
              address: adderes ?? "",
              geometry: {
                lat: location?.lat() ?? 0,
                lng: location.lng() ?? 0,
              },
            });
            if (addressInput) addressInput.value = adderes;
            return;
          }
          console.log(
            "Something went wrong while getting address deatils of current selected place!"
          );
          console.log(results);
        });
      });
    });
  }

  render() {
    let genderOptions = [];
    let ethnicityOptions = [];
    let zodiacSignsOptions = [];
    let familyOptions = [];
    let politicsOptions = [];
    let religiousOptions = [];
    let tagsOptions = [];
    let ageOptions = [];
    let heightOptions = [];

    if (this.props.fakeUsers.presets) {
      const presets = this.props.fakeUsers.presets;
      ageOptions = presets.ages.map((item, index) => ({
        key: index,
        value: item.id,
        text: item.age,
      }));
      heightOptions = presets.heights.map((item, index) => ({
        key: index,
        value: item.id,
        text: item.height,
      }));
      genderOptions = presets.genders.map((item, index) => ({
        key: index,
        value: item.id,
        text: item.gender,
      }));
      ethnicityOptions = presets.ethnicity.map((item, index) => ({
        key: index,
        value: item.id,
        text: item.ethnicity,
      }));
      zodiacSignsOptions = presets.zodiacSigns.map((item, index) => ({
        key: index,
        value: item.id,
        text: item.zodiacSign,
      }));
      familyOptions = presets.family.map((item, index) => {
        return { key: index, value: item.id, text: item.familyPlans };
      });
      politicsOptions = presets.politics.map((item, index) => {
        return { key: index, value: item.id, text: item.politics };
      });
      religiousOptions = presets.religious.map((item, index) => {
        return { key: index, value: item.id, text: item.religious };
      });
      tagsOptions = presets.tags.map((item, index) => {
        return { key: index, value: item.id, text: item.tag };
      });
    }

    return (
      <Segment.Group>
        <Dimmer.Dimmable as={Segment} dimmed={this.props.fakeUsers.loading}>
          <Dimmer active={this.props.fakeUsers.loading} inverted>
            <Loader>Loading</Loader>
          </Dimmer>
          {this.props.fakeUsers.error && this.props.fakeUsers.error.visible && (
            <ErrorMessage
              error={this.props.fakeUsers.error}
              hideError={this.props.hideError}
            />
          )}
          <Segment
            inverted={
              this.state.isDirty &&
              this.props.fakeUsers.currentUser.avatar_photos.length === 0
            }
            tertiary={
              this.state.isDirty &&
              this.props.fakeUsers.currentUser.avatar_photos.length === 0
            }
            secondary={
              this.state.isDirty &&
              this.props.fakeUsers.currentUser.avatar_photos.length > 0
            }
            color={
              this.state.isDirty &&
              this.props.fakeUsers.currentUser.avatar_photos.length === 0
                ? "red"
                : "black"
            }
          >
            <Header
              size="large"
              color={
                this.state.isDirty &&
                this.props.fakeUsers.currentUser.photos.length === 0
                  ? "red"
                  : "black"
              }
            >
              Photos (*)
            </Header>
            {this.props.fakeUsers.currentUser?.avatar_photos?.length === 0 && (
              <Header>No photos selected</Header>
            )}
            <FakeUserPhotos />
          </Segment>

          <FakeUserStories />

          <Segment
            inverted={
              this.state.isDirty &&
              this.props.fakeUsers.currentUser.avatar_photos.length === 0
            }
            tertiary={
              this.state.isDirty &&
              this.props.fakeUsers.currentUser.avatar_photos.length === 0
            }
            secondary={
              this.state.isDirty &&
              this.props.fakeUsers.currentUser.avatar_photos.length > 0
            }
            color={
              this.state.isDirty &&
              this.props.fakeUsers.currentUser.avatar_photos.length === 0
                ? "red"
                : "black"
            }
          >
            <Header
              size="large"
              color={
                this.state.isDirty &&
                this.props.fakeUsers.currentUser.photos.length === 0
                  ? "red"
                  : "black"
              }
            >
              Moment
            </Header>
            {this.props.moments.length === 0 && (
              <Header>No moments selected</Header>
            )}
            <Card.Group itemsPerRow={4}>
              {this.props.moments.map((image, index) => {
                return (
                  <Card key={index}>
                    <div className="moments-list-moment-card-image-container">
                      <Image src={image.file || image.file_url} centered />
                    </div>
                    <Card.Content className="moments-list-card-bottom-content">
                      <p style={{ marginBottom: 20, wordBreak: "break-all" }}>
                        {image?.momentDescription}
                      </p>
                      <div className="ui two buttons add-moment-button">
                        <Button
                          basic
                          color="red"
                          onClick={(e, data) => {
                            e.preventDefault();
                            this.props.onRemoveMoment(image.id);
                          }}
                        >
                          Remove
                        </Button>
                      </div>
                    </Card.Content>
                  </Card>
                );
              })}
              {/* <Card>
                <Form>
                  <Segment>
                    <Form.Group widths="equal">
                      <Form.TextArea
                        fluid
                        label={"Description"}
                        placeholder="Add description here"
                        value=""
                        // onChange={this.props.changeAbout}
                      />
                    </Form.Group>
                  </Segment>
                </Form>
                <AddNewPhoto
                  onUpload={this.props.onUploadMoment}
                  height={"200px"}
                  header={"Add new image"}
                  folder={this.props.fakeUsers.currentUser.id || "images"}
                />
              </Card> */}
              {/* <AddNewPhoto
                  onUpload={this.props.onUploadMoment}
                  height={"200px"}
                  header={"Add new image"}
                  folder={this.props.fakeUsers.currentUser.id || "images"}
                /> */}
              <AddMoment
                onUpload={this.props.onUploadMoment}
                height={"200px"}
                header={"Add new image"}
                folder={this.props.fakeUsers.currentUser.id || "images"}
              />
              {/* <Form>
                <Segment>
                  <Form.Group widths="equal">
                    <Form.TextArea
                      fluid
                      label={"Description"}
                      placeholder="Add description here"
                      value=""
                      // onChange={this.props.changeAbout}
                    />
                  </Form.Group>
                </Segment>
              </Form> */}
            </Card.Group>
          </Segment>

          <Segment padded>
            <Form>
              <Segment secondary>
                <Form.Group widths="equal">
                  <Form.Input
                    fluid
                    label={
                      "Name " +
                      (requiredFields.some(
                        (item, i) => item.field === "fullName"
                      )
                        ? "(*)"
                        : "")
                    }
                    placeholder="Name"
                    value={this.props.fakeUsers.currentUser?.fullName || ""}
                    onChange={this.props.changeName}
                    error={
                      this.state.errors.length !== 0 &&
                      this.props.fakeUsers?.currentUser?.name.length === 0
                    }
                  />
                  {/* <Form.Input
                                        fluid
                                        label={'Last Name ' + (requiredFields.some((item, i) => item.field === 'lastName') ? '(*)' : '')}
                                        placeholder='Last Name'
                                        value={this.props.fakeUsers.currentUser?.last_name || ''}
                                        onChange={this.props.changeLastName}
                                    /> */}
                  <Form.Input
                    fluid
                    label={
                      "Work " +
                      (requiredFields.some((item, i) => item.field === "work")
                        ? "(*)"
                        : "")
                    }
                    placeholder="Work"
                    value={this.props.fakeUsers.currentUser?.work || ""}
                    onChange={this.props.changeWork}
                  />
                  <Form.Input
                    fluid
                    label={
                      "Education " +
                      (requiredFields.some(
                        (item, i) => item.field === "education"
                      )
                        ? "(*)"
                        : "")
                    }
                    placeholder="Education"
                    value={this.props.fakeUsers.currentUser?.education || ""}
                    onChange={this.props.changeEducation}
                  />
                </Form.Group>
                <Form.Group widths="equal">
                  {/* <Form.Input
                                        fluid
                                        label={'Age ' + (requiredFields.some((item, i) => item.field === 'age') ? '(*)' : '')}
                                        placeholder='Age'
                                        type='number'
                                        min={18}
                                        max={59}
                                        value={this.props.fakeUsers.currentUser && this.props.fakeUsers.currentUser.age ? this.props.fakeUsers.currentUser.age : 18}
                                        onChange={this.props.changeAge}
                                        error={this.state.isDirty && this.props.fakeUsers.currentUser.age === 0}
                                    /> */}
                  <Form.Select
                    fluid
                    search
                    selection
                    label="Age"
                    placeholder="Age"
                    options={ageOptions}
                    value={
                      this.props.fakeUsers.currentUser &&
                      this.props.fakeUsers.currentUser.age
                        ? this.props.fakeUsers.currentUser.age
                        : 18
                    }
                    onChange={this.props.changeAge}
                    error={
                      this.state.isDirty &&
                      this.props.fakeUsers.currentUser.age === 0
                    }
                  />

                  <Form.Select
                    fluid
                    search
                    selection
                    label={
                      "Gender " +
                      (requiredFields.some((item, i) => item.field === "gender")
                        ? "(*)"
                        : "")
                    }
                    options={genderOptions}
                    placeholder="Gender"
                    onChange={this.props.changeGender}
                    value={
                      this.props.fakeUsers.currentUser &&
                      this.props.fakeUsers.currentUser.gender
                        ? this.props.fakeUsers.currentUser.gender
                        : ""
                    }
                    error={
                      this.state.errors.length !== 0 &&
                      this.props.fakeUsers.currentUser.gender !== 0 &&
                      this.props.fakeUsers.currentUser.gender !== 1
                    }
                  />

                  {/* <Form.Input
                                        fluid
                                        label={'Height ' + (requiredFields.some((item, i) => item.field === 'height') ? '(*)' : '')}
                                        placeholder='Height'
                                        type='number'
                                        min={140}
                                        max={247}
                                        onChange={this.props.changeHeight}
                                        value={this.props.fakeUsers.currentUser && this.props.fakeUsers.currentUser.height ? this.props.fakeUsers.currentUser.height : 140} /> */}

                  <Form.Select
                    fluid
                    search
                    label="Height"
                    options={heightOptions}
                    placeholder="Height"
                    onChange={this.props.changeHeight}
                    value={
                      this.props.fakeUsers.currentUser &&
                      this.props.fakeUsers.currentUser.height
                        ? this.props.fakeUsers.currentUser.height
                        : 140
                    }
                  />

                  <Form.Select
                    fluid
                    search
                    selection
                    label={
                      "Ethnicity (*)"
                      // (requiredFields.some(
                      //   (item, i) => item.field === "ethnicity"
                      // )
                      //   ? "(*)"
                      //   : "")
                    }
                    options={ethnicityOptions}
                    placeholder="Ethnicity"
                    onChange={this.props.changeEthnicity}
                    value={
                      this.props.fakeUsers.currentUser &&
                      this.props.fakeUsers.currentUser.ethnicity
                        ? this.props.fakeUsers.currentUser.ethnicity
                        : ""
                    }
                    error={
                      this.state.errors.length !== 0 &&
                      !this.props?.fakeUsers?.currentUser?.ethnicity
                    }
                  />
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Select
                    fluid
                    search
                    selection
                    label={
                      "Zodiac Sign " +
                      (requiredFields.some(
                        (item, i) => item.field === "zodiacSign"
                      )
                        ? "(*)"
                        : "")
                    }
                    options={zodiacSignsOptions}
                    placeholder="Zodiac Sign"
                    onChange={this.props.changeZodiacSign}
                    value={
                      this.props.fakeUsers.currentUser &&
                      this.props.fakeUsers.currentUser.zodiacSign
                        ? this.props.fakeUsers.currentUser.zodiacSign
                        : ""
                    }
                  />
                  <Form.Select
                    fluid
                    search
                    selection
                    label={
                      "Family plans " +
                      (requiredFields.some(
                        (item, i) => item.field === "familyPlans"
                      )
                        ? "(*)"
                        : "")
                    }
                    options={familyOptions}
                    placeholder="Family plans"
                    onChange={this.props.changeFamilyPlans}
                    value={
                      this.props.fakeUsers.currentUser &&
                      this.props.fakeUsers.currentUser.familyPlans
                        ? this.props.fakeUsers.currentUser.familyPlans
                        : ""
                    }
                  />

                  <Form.Select
                    fluid
                    search
                    selection
                    label={
                      "Politics " +
                      (requiredFields.some(
                        (item, i) => item.field === "politics"
                      )
                        ? "(*)"
                        : "")
                    }
                    options={politicsOptions}
                    placeholder="Politics"
                    onChange={this.props.changePolitics}
                    value={
                      this.props.fakeUsers.currentUser &&
                      this.props.fakeUsers.currentUser.politics
                        ? this.props.fakeUsers.currentUser.politics
                        : ""
                    }
                  />

                  <Form.Select
                    fluid
                    search
                    selection
                    label={
                      "Religious Beliefs " +
                      (requiredFields.some(
                        (item, i) => item.field === "religion"
                      )
                        ? "(*)"
                        : "")
                    }
                    options={religiousOptions}
                    placeholder="Religious Beliefs"
                    onChange={this.props.changeReligious}
                    value={
                      this.props.fakeUsers.currentUser &&
                      this.props.fakeUsers.currentUser.religion
                        ? this.props.fakeUsers.currentUser.religion
                        : ""
                    }
                  />
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Select
                    fluid
                    label={"Interested In (*)"}
                    multiple
                    search
                    selection
                    options={commonConstants.INTERESTED_IN_OPTIONS}
                    placeholder="Interested In"
                    onChange={this.props.changeInterestedIn}
                    value={this.props.fakeUsers.currentUser.interestedIn}
                    error={
                      this.state.errors.length !== 0 &&
                      !this.props.fakeUsers.currentUser?.interestedIn?.length
                    }
                  />

                  <Form.Select
                    fluid
                    label={"Tags (*)"}
                    multiple
                    search
                    selection
                    options={tagsOptions}
                    placeholder="Tags"
                    onChange={this.props.changeTags}
                    value={
                      this.props.fakeUsers.currentUser &&
                      this.props.fakeUsers.currentUser.tags
                        ? this.props.fakeUsers.currentUser.tags
                        : ""
                    }
                    error={
                      this.state.errors.length !== 0 &&
                      !this.props.fakeUsers.currentUser?.tags?.length
                    }
                  />
                </Form.Group>
                <Form.Input
                  fluid
                  id="address-input"
                  label={"Address (*)"}
                  // placeholder="Enter your address here"
                  placeholder="Street, City, District, State, Country."
                  error={
                    this.state.errors.length !== 0 &&
                    this.state.address?.length === 0
                  }
                  // value={this.state.address || ""}
                  // onChange={this.handleAddressInput}
                />

                <div
                  style={{ height: 600, width: "100%", marginBottom: "14px" }}
                  id="google-map-for-address-preview"
                ></div>
                <div id="infowindow-content">
                  <span id="place-name" className="title"></span>
                  <br />
                  <span id="place-address"></span>
                </div>
                <Form.TextArea
                  label={
                    "About " +
                    (requiredFields.some((item, i) => item.field === "about")
                      ? "(*)"
                      : "")
                  }
                  placeholder="About"
                  value={
                    this.props.fakeUsers.currentUser &&
                    this.props.fakeUsers.currentUser.about
                      ? this.props.fakeUsers.currentUser.about
                      : ""
                  }
                  onChange={this.props.changeAbout}
                />

                <Header size="large">Additional fields</Header>

                <Grid columns="equal">
                  <Grid.Row>
                    <Grid.Column>
                      <Tags
                        isRequired={requiredFields.some(
                          (item, i) => item.field === "books"
                        )}
                        tags={this.props.fakeUsers.currentUser.books}
                        item="book"
                        type={commonConstants.BOOK_ITEM}
                        header="Books"
                        onChangeTag={this.props.onChangeItem}
                      />
                    </Grid.Column>
                    <Grid.Column>
                      <Tags
                        isRequired={requiredFields.some(
                          (item, i) => item.field === "music"
                        )}
                        tags={this.props.fakeUsers.currentUser.music}
                        item="music"
                        type={commonConstants.MUSIC_ITEM}
                        header="Music *"
                        onChangeTag={this.props.onChangeItem}
                        error={
                          this.state.errors.length !== 0 &&
                          !this.props.fakeUsers.currentUser?.music?.length
                        }
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column>
                      <Tags
                        isRequired={requiredFields.some(
                          (item, i) => item.field === "movies"
                        )}
                        tags={this.props.fakeUsers.currentUser.movies}
                        item="movie"
                        type={commonConstants.MOVIE_ITEM}
                        header="Movies *"
                        onChangeTag={this.props.onChangeItem}
                        error={
                          this.state.errors.length !== 0 &&
                          !this.props.fakeUsers.currentUser?.movies?.length
                        }
                      />
                    </Grid.Column>
                    <Grid.Column>
                      <Tags
                        isRequired={requiredFields.some(
                          (item, i) => item.field === "tvShows"
                        )}
                        tags={this.props.fakeUsers.currentUser.tvShows}
                        item="TV show"
                        type={commonConstants.TV_SHOW_ITEM}
                        header="TV Shows *"
                        onChangeTag={this.props.onChangeItem}
                        error={
                          this.state.errors.length !== 0 &&
                          !this.props.fakeUsers.currentUser?.tvShows?.length
                        }
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column>
                      <Tags
                        isRequired={requiredFields.some(
                          (item, i) => item.field === "sportsTeams"
                        )}
                        tags={this.props.fakeUsers.currentUser.sportsTeams}
                        item="team"
                        type={commonConstants.SPORT_TEAM_ITEM}
                        header="Sports Teams *"
                        onChangeTag={this.props.onChangeItem}
                        error={
                          this.state.errors.length !== 0 &&
                          !this.props.fakeUsers.currentUser?.sportsTeams?.length
                        }
                      />
                    </Grid.Column>
                    <Grid.Column></Grid.Column>
                  </Grid.Row>
                </Grid>
              </Segment>
              {this.state?.errors?.length
                ? this.state.errors.map((error) => (
                    <Message style={{ display: "block" }} error={true}>
                      {error}{" "}
                    </Message>
                  ))
                : null}
              <Segment secondary textAlign="center">
                <Link to={"/dashboard"}>
                  <Button size="large" content="Cancel" />
                </Link>
                <Button
                  size="large"
                  color="blue"
                  content="Save"
                  onClick={(e, data) => {
                    e.preventDefault();
                    this.saveUser(
                      this.props.fakeUsers.workerId,
                      this.props.fakeUsers.currentUser
                    );
                  }}
                />
              </Segment>
            </Form>
          </Segment>
        </Dimmer.Dimmable>
      </Segment.Group>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getWorkerFakeUsers(id) {
      getWorkerFakeUsers(id)(dispatch);
    },
  };
};

export default connect(null, mapDispatchToProps)(withRouter(FakeUser));

FakeUser.propTypes = {
  fakeUsers: PropTypes.shape({
    loading: PropTypes.bool,
    error: PropTypes.shape({
      visible: PropTypes.bool,
    }),
    currentUser: PropTypes.shape({
      name: PropTypes.string.isRequired,
      lastName: PropTypes.string,
      work: PropTypes.string,
      education: PropTypes.string,
      age: PropTypes.number.isRequired,
      gender: PropTypes.number.isRequired,
      height: PropTypes.number,
      ethnicity: PropTypes.number,
      zodiacSign: PropTypes.number,
      familyPlans: PropTypes.number,
      politics: PropTypes.number,
      religion: PropTypes.number,
      tags: PropTypes.array,
      interestedIn: PropTypes.array,
      books: PropTypes.array,
      movies: PropTypes.array,
      music: PropTypes.array,
      tvShows: PropTypes.array,
      sportsTeams: PropTypes.array,
    }).isRequired,
  }).isRequired,
  onChangeItem: PropTypes.func.isRequired,
  onAddNewPhoto: PropTypes.func.isRequired,
  onRemovePhoto: PropTypes.func.isRequired,
  changeName: PropTypes.func.isRequired,
  changeLastName: PropTypes.func.isRequired,
  changeGender: PropTypes.func.isRequired,
  changeAbout: PropTypes.func.isRequired,
  changeAge: PropTypes.func.isRequired,
  changeTags: PropTypes.func.isRequired,
  changeInterestedIn: PropTypes.func.isRequired,
  changePolitics: PropTypes.func.isRequired,
  changeReligious: PropTypes.func.isRequired,
  changeFamilyPlans: PropTypes.func.isRequired,
  changeZodiacSign: PropTypes.func.isRequired,
  changeHeight: PropTypes.func.isRequired,
  changeEducation: PropTypes.func.isRequired,
  changeEthnicity: PropTypes.func.isRequired,
  changeWork: PropTypes.func.isRequired,
  updateUser: PropTypes.func.isRequired,
  createUser: PropTypes.func.isRequired,
};
