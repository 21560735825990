import { useState, useEffect } from "react";
import { Form, Grid } from "semantic-ui-react";
import PropTypes from "prop-types";

import { googleMapsApi, googleMapsApiKey } from "../../utils/api";
import {
  addressDataExtracter,
  getFullAddressFromLatLng,
} from "../../utils/helpers";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

const addressComponentsOrder = [
  "sublocality",
  "sublocality_level_2",
  "sublocality_level_1",
  "locality",
  "administrative_area_level_2",
  "administrative_area_level_1",
];

const getCity = (addressParts) => {
  let city = "";
  const zipCode = addressParts.postal_code;
  addressComponentsOrder.every((key) => {
    if (addressParts[key]) {
      city = addressParts[key];
      return false;
    }
    return true;
  });

  if (zipCode) {
    city = `${city} - ${zipCode}`;
  }

  return city;
};

const MapContainer = ({
  latLng,
  formattedAddress,
  addressParts,
  handelChange,
  mapStyle,
  city,
  showCity,
  showSearch,
}) => {
  const { pageName } = useParams();

  const isViewPage = pageName === "view";

  const [state, setState] = useState({
    latLng: {
      lat: latLng?.lat ?? 0,
      lng: latLng?.lng ?? 0,
    },
    formattedAddress: formattedAddress,
    addressParts: addressParts,
  });

  useEffect(() => {
    let map = null;
    let marker = null;
    let infowindow = null;
    let mapLoadListner = null;
    let clickListner = null;
    let searchPlacesListner = null;
    const script = document.createElement("script");
    script.src = `${googleMapsApi}/js?key=${googleMapsApiKey}&libraries=places`;
    script.async = true;
    script.id = "google-maps-script";
    document.body.appendChild(script);

    mapLoadListner = script.addEventListener("load", () => {
      const mapContent = document.getElementById("map-content");
      map = new window.google.maps.Map(mapContent, {
        zoom: 12,
        center: { lat: state.latLng.lat, lng: state.latLng.lng },
      });

      // Adding intial marker
      marker = new window.google.maps.Marker({
        position: new window.google.maps.LatLng(
          state.latLng.lat,
          state.latLng.lng
        ),
        map: map,
      });

      // Adding intial infowindow content
      infowindow = new window.google.maps.InfoWindow({
        content: `<p style="padding:0 10px 0 0;font-weight:600;max-width:250px">${formattedAddress}</p>`,
      });

      if (formattedAddress) {
        infowindow.open(map, marker);
      }

      // Marker click event listner
      marker.addListener("click", (e) => {
        infowindow.open(map, marker);
      });

      // onClick event listner
      clickListner = window.google.maps.event.addListener(
        map,
        "click",
        async (e) => {
          // Closing marker, removing listner and infowindow
          marker.setMap(null);
          infowindow?.close();
          // Centering map, adding marker and infowindow
          map.setCenter({ lat: e.latLng.lat(), lng: e.latLng.lng() });
          marker = new window.google.maps.Marker({
            position: new window.google.maps.LatLng(
              e.latLng.lat(),
              e.latLng.lng()
            ),
            map: map,
          });
          const getAddress = await getFullAddressFromLatLng(
            e.latLng.lat(),
            e.latLng.lng()
          );

          infowindow.setContent(
            `<p style="padding:0 10px 0 0;font-weight:600">${getAddress.formattedAddress}</p>`
          );
          infowindow.open(map, marker);
          marker.addListener("click", (e) => {
            infowindow.open(map, marker);
          });

          // Updating local state
          setState((state) => ({
            ...state,
            latLng: {
              lat: getAddress.geometry.lat,
              lng: getAddress.geometry.lng,
            },
            formattedAddress: getAddress.formattedAddress,
            addressParts: {
              ...getAddress.addressParts,
              city: getCity(getAddress.addressParts),
              zip_code: getAddress?.addressParts?.postal_code ?? "",
            },
          }));

          handelChange({
            latLng: {
              lat: getAddress.geometry.lat,
              lng: getAddress.geometry.lng,
            },
            formattedAddress: getAddress.formattedAddress,
            addressParts: {
              ...getAddress.addressParts,
              city: getCity(getAddress.addressParts),
              zip_code: getAddress?.addressParts?.postal_code ?? "",
              country_code: getAddress?.addressParts?.country_code ?? "",
            },
          });
        }
      );

      // onSearch event listner
      const input = document.getElementById("address-input");
      const options = {
        fields: [
          "formatted_address",
          "geometry",
          "name",
          "address_components",
          "icon",
        ],
        strictBounds: false,
      };
      const autocomplete = new window.google.maps.places.Autocomplete(
        input,
        options
      );

      searchPlacesListner = autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();
        if (place) {
          // Closing marker, removing listner and infowindow
          marker.setMap(null);
          infowindow?.close();
          // Centering map, adding marker and infowindow
          map.setCenter({
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng(),
          });
          marker = new window.google.maps.Marker({
            position: new window.google.maps.LatLng(
              place.geometry.location.lat(),
              place.geometry.location.lng()
            ),
            map: map,
          });
          infowindow.setContent(
            `<p style="padding:0 10px 0 0;font-weight:600">${place.formatted_address}</p>`
          );
          infowindow.open(map, marker);
          marker.addListener("click", () => {
            infowindow.open(map, marker);
          });

          // Updating local state
          const addressParts = addressDataExtracter(place.address_components);
          setState((state) => ({
            ...state,
            latLng: {
              lat: place.geometry.location.lat(),
              lng: place.geometry.location.lng(),
            },
            formattedAddress: place.formatted_address,
            addressParts: {
              ...addressParts,
              city: getCity(addressParts),
              zip_code: addressParts?.postal_code ?? "",
            },
          }));

          handelChange({
            latLng: {
              lat: place.geometry.location.lat(),
              lng: place.geometry.location.lng(),
            },
            formattedAddress: place.formatted_address,
            addressParts: {
              ...addressParts,
              city: getCity(addressParts),
              zip_code: addressParts?.postal_code ?? "",
            },
          });
        }
      });
    });

    return () => {
      window.google.maps.event.clearInstanceListeners(marker);
      window.google.maps.event.removeListener(mapLoadListner);
      window.google.maps.event.removeListener(clickListner);
      window.google.maps.event.removeListener(searchPlacesListner);
      const scriptElement = document.getElementById("google-maps-script");
      scriptElement.remove();
    };
  }, []);

  return (
    <>
      <Form>
        <Grid>
          <Grid.Row>
            {showSearch && (
              <Grid.Column width={showCity ? 12 : 16}>
                <Form.Input
                  readOnly={isViewPage}
                  fluid
                  id="address-input"
                  label={"Address (*)"}
                  placeholder="Search For Street, City, District, State, Country."
                  value={state.formattedAddress}
                  onChange={(e) => {
                    setState((state) => ({
                      ...state,
                      formattedAddress: e.target.value,
                    }));
                  }}
                  style={{
                    marginBottom: "20px",
                  }}
                />
              </Grid.Column>
            )}
            {showCity && (
              <Grid.Column width={4}>
                <Form.Input
                  readOnly={isViewPage}
                  fluid
                  label={"City"}
                  placeholder="City"
                  value={
                    state?.addressParts?.city ? state?.addressParts?.city : city
                  }
                  style={{
                    marginBottom: "20px",
                  }}
                />
              </Grid.Column>
            )}
          </Grid.Row>
        </Grid>
      </Form>
      <div
        style={{ width: "100%", marginBottom: "14px", ...mapStyle }}
        id="map-content"
      />
    </>
  );
};

MapContainer.propTypes = {
  latLng: PropTypes.object,
  handelChange: PropTypes.func,
  formattedAddress: PropTypes.string,
  mapStyle: PropTypes.object,
  addressParts: PropTypes.object,
  city: PropTypes.string,
  showCity: PropTypes.bool,
  showSearch: PropTypes.bool,
};

MapContainer.defaultProps = {
  latLng: {
    lat: 0,
    lng: 0,
  },
  handelChange: () => {},
  formattedAddress: "",
  mapStyle: {},
  addressParts: {
    city: "",
    country: "",
    country_code: "",
    zip_code: "",
  },
  showCity: true,
  showSearch: true,
};

export default MapContainer;
