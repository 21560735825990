import React, { memo, useEffect, useState } from "react";
import moment from "moment";

const calculateTimeLeft = (timestamp) => {
  const deadline = moment(timestamp).add(3, "seconds");
  const now = moment();

  const difference = deadline.diff(now);

  if (difference <= 0) {
    return { minutes: 0, seconds: 0, millisec: 0 };
  }

  const minutes = Math.floor((difference / 1000 / 60) % 60);
  const seconds = Math.floor((difference / 1000) % 60);
  const millisec = Math.floor(difference % 1000);

  return { minutes, seconds, millisec };
};

const formatTime = (time, pad = 2) => {
  return time?.toString()?.padStart(pad, "0");
};

const Timer = ({ deadline, className, prefixText = "" }) => {
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(deadline));

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeLeft((prevTimeLeft) => {
        const newTimeLeft = calculateTimeLeft(deadline);
        if (moment(deadline) < moment()) {
          console.log(newTimeLeft);
          if (window !== undefined) window.location.reload();
          clearInterval(intervalId);
        }
        return newTimeLeft;
      });
    }, 451);

    return () => clearInterval(intervalId);
  }, [deadline]);

  return (
    <div className={`timer-container ${className}`}>
      {timeLeft?.minutes === 0 &&
      timeLeft?.seconds === 0 &&
      timeLeft?.millisec === 0 ? (
        <p>Time is over</p>
      ) : (
        <p>
          {prefixText}
          {`${formatTime(timeLeft.minutes)}:${formatTime(
            timeLeft.seconds
          )}:${formatTime(timeLeft.millisec, 3)}`}
        </p>
      )}
    </div>
  );
};

export default memo(Timer);
