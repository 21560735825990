import React, { Component } from "react";
import PropTypes from "prop-types";
import image from "../../assets/images/image.png";

export default class FileField extends Component {
  render() {
    const { src, type } = this.props;
    if (src === "") {
      return (
        <img
          style={{
            ...this.props.style,
            height: this.props.height,
            maxHeight: this.props.height,
          }}
          src={image}
          height={this.props.height}
          onClick={this.props.onClick}
          alt=""
        />
      );
    }
    return (
      <>
        {type === "video" ? (
          <video
            src={src}
            style={{ ...this.props.style, height: this.props.height }}
            height={this.props.height}
            onLoad={this.onLoad}
            onClick={this.props.onClick}
            alt=""
          ></video>
        ) : (
          <img
            src={src}
            style={{ ...this.props.style, height: this.props.height }}
            height={this.props.height}
            onLoad={this.onLoad}
            onClick={this.props.onClick}
            alt=""
          />
        )}
      </>
    );
  }
}

FileField.defaultProps = {
  height: "100px",
  style: {},
};

FileField.propTypes = {
  src: PropTypes.string.isRequired,
  height: PropTypes.string,
  style: PropTypes.any,
};
