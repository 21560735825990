import videoExtensions from "video-extensions";
import Geocode from "react-geocode";

export const CloudfrontUrl = "https://djbbbm5n90p1g.cloudfront.net";
export const StagingImgUrl = "https://api.chatadmin-mod.click/media/";
export const ProductionImgUrl = "https://api.i69app.com/media/";

let stateGraph = {
  0: 1,
  1: -1,
  "-1": 0,
};

export function computeDirection(oldColumn, newColumn, prevDirection) {
  if (oldColumn !== newColumn) {
    return 1;
  }

  if (!stateGraph.hasOwnProperty(prevDirection)) {
    return 0;
  }

  return stateGraph[prevDirection];
}

export function directionToString(direction) {
  switch (direction) {
    case 1:
      return "ascending";
    case -1:
      return "descending";
    default:
      return null;
  }
}

export function prepareToSave(user) {
  let newUser = { ...user };
  Object.keys(newUser).forEach((value, index) => {
    if (value === "zip_code") {
      newUser[value] = user[value];
      return;
    }

    if (!newUser[value]) {
      delete newUser[value];
      return;
    }

    if (value === "interestedIn") {
      newUser["interestedIn"] = user["interestedIn"].join(",");
      return (newUser["interested_in"] = user["interestedIn"].join(","));
    }

    if (newUser[value] instanceof Array) {
      if (newUser[value].length === 0) {
        delete newUser[value];
        return;
      }
      if (
        value !== "tags" &&
        value !== "books" &&
        value !== "movies" &&
        value !== "music" &&
        value !== "sportsTeams" &&
        value !== "tvShows" &&
        value !== "location"
      ) {
        newUser[value] = { ...newUser[value] };
      }
    }
  });

  return newUser;
}

export function prepareFromAPI(user) {
  Object.keys(user).forEach((value, index) => {
    if (
      user[value] instanceof Object &&
      !user[value].hasOwnProperty("length")
    ) {
      let arr = [];

      Object.keys(user[value])
        .sort()
        .forEach(function (key) {
          arr.push(user[value][key]);
        });

      user[value] = arr;
    }
  });

  return user;
}

export const isImage = (url) => {
  const imgTypes = [
    "png",
    "jpg",
    "jpeg",
    "apng",
    "avif",
    "gif",
    "jfif",
    "pjpeg",
    "pjp",
    "svg",
    "webp",
  ];
  for (let i = 0; i < imgTypes.length; i++) {
    const imgType = imgTypes[i];
    if (String(url).toLowerCase().includes(`.${imgType}`)) {
      return true;
    }
  }
  return false;
};

export const isVideo = (url) => {
  // const imgTypes = [".png", ".jpg", ".jpeg", ".apng", ".avif", ".gif", ".jfif", ".pjpeg", ".pjp", ".svg", ".webp"];
  return videoExtensions.some((videoType) => String(url).includes(videoType));
};

export const convertArrayToHashMap = (array, by = "id") => {
  return array.reduce((accumulator, current) => {
    accumulator[current[by]] = { ...current };
    return accumulator;
  }, {});
};

export const covertHashmapToArray = (object) => {
  return Object.values(object);
};

export const addressDataExtracter = (address) => {
  const addressBreakDown = {
    plus_code: null,
    street_number: null,
    route: null,
    neighborhood: null,
    sublocality_level_1: null,
    sublocality_level_2: null,
    sublocality: null,
    locality: null,
    administrative_area_level_2: null,
    administrative_area_level_1: null,
    country: null,
    postal_code: null,
    country_code: null,
  };

  for (let i = 0; i <= address.length - 1; i++) {
    for (let j = 0; j <= address[i].types.length - 1; j++) {
      switch (address[i].types[j]) {
        case "plus_code":
          addressBreakDown.plus_code = address[i].long_name;
          break;
        case "street_number":
          addressBreakDown.street_number = address[i].long_name;
          break;
        case "route":
          addressBreakDown.route = address[i].long_name;
          break;
        case "neighborhood":
          addressBreakDown.neighborhood = address[i].long_name;
          break;
        case "sublocality_level_1":
          addressBreakDown.sublocality_level_1 = address[i].long_name;
          break;
        case "sublocality_level_2":
          addressBreakDown.sublocality_level_2 = address[i].long_name;
          break;
        case "sublocality":
          addressBreakDown.sublocality = address[i].long_name;
          break;
        case "locality":
          addressBreakDown.locality = address[i].long_name;
          break;
        case "administrative_area_level_2":
          addressBreakDown.administrative_area_level_2 = address[i].long_name;
          break;
        case "administrative_area_level_1":
          addressBreakDown.administrative_area_level_1 = address[i].long_name;
          break;
        case "country":
          addressBreakDown.country = address[i].long_name;
          addressBreakDown.country_code = address[i].short_name;
          break;
        case "postal_code":
          addressBreakDown.postal_code = address[i].long_name;
          break;
        default:
          break;
      }
    }
  }
  return addressBreakDown;
};

export const getFullAddressFromLatLng = (lat, lng) =>
  new Promise((resolve, reject) => {
    Geocode.setApiKey("AIzaSyBNDQFHOXjOH-AJH_tvgd7FM_IxLNClDRk");
    Geocode.setLanguage("en");
    Geocode.fromLatLng(lat, lng).then(
      (response) => {
        const addressObject = response.results[0];
        const addressCompenents = addressObject.address_components;
        const finalData = {
          addressParts: addressDataExtracter(addressCompenents),
          geometry: addressObject.geometry.location,
          formattedAddress: addressObject.formatted_address,
        };
        resolve(finalData);
      },
      (err) => {
        reject(err);
      }
    );
  });

export const validateEmail = (email = "") => {
  const pattern =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return pattern.test(email);
};

export const getImageUrl = (fileUrl) => {
  if (fileUrl?.startsWith(CloudfrontUrl)) {
    return fileUrl;
  } else {
    return fileUrl;
  }
};

export const getBaseImageUrl = (baseUrl, fileUrl) => {
  if (fileUrl?.startsWith(CloudfrontUrl)) {
    return fileUrl;
  } else {
    return baseUrl + fileUrl;
  }
};

export const getChatImageUrl = (fileUrl) => {
  if (fileUrl && fileUrl.startsWith(StagingImgUrl)) {
    return fileUrl.replace(StagingImgUrl, "");
  } else if (fileUrl && fileUrl.startsWith(ProductionImgUrl)) {
    return fileUrl.replace(ProductionImgUrl, "");
  } else {
    return fileUrl;
  }
};
