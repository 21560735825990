import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { Dimmer, Image, Loader, Table, Segment } from "semantic-ui-react";
import chatIcon from "../../assets/images/chatIcon.png";

const Pendingchats = (props) => {
  const { users } = props;
  const [pendingChats, setPendingChats] = useState([]);

  const isUserAuthenticatedToShowPrivateItems = () => {
    let isAuthenticated = false;
    const privileges = props?.userDetails?.privileges;
    if (privileges?.includes("ADMIN")) isAuthenticated = true;
    return isAuthenticated;
  };
  console.log({ pendingChats });
  const onChat = (user, userDetails) => {
    console.log(":::: onChat :::: ", { user, userDetails });
    if (!props?.userDetails?.privileges?.includes("ADMIN")) {
      if (user && user?.id && userDetails && userDetails?.id) {
        props.addFakeUsers(user);
        setTimeout(() => {
          props.selectChatFakeUser(user?.id);

          props.startNewChat(user?.id, userDetails);
          props.history.push("/dashboard");
        }, 1000);
      } else {
        if (!user?.id) {
          console.log("No moderator present");
        }
        if (userDetails?.id) {
          console.log("No real user present");
        }
      }
    }
  };

  useEffect(() => {
    const filteredChats = users.filter((chat) => !chat.isAssigned);
    setPendingChats(filteredChats);
  }, [users]);

  return (
    <div>
      {!isUserAuthenticatedToShowPrivateItems() && pendingChats.length > 0 ? (
        <Dimmer.Dimmable dimmed={props?.users?.loading}>
          <Dimmer active={props?.users?.loading} inverted>
            <Loader>Loading</Loader>
          </Dimmer>
          <Table className="center" style={{ width: "100%" }}>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Name</Table.HeaderCell>
                <Table.HeaderCell>User Type</Table.HeaderCell>
                <Table.HeaderCell>Action</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {pendingChats?.map((user) => {
                if (!user?.isAssigned) {
                  return (
                    <Table.Row key={user?.id}>
                      <Table.Cell>
                        {user?.moderator?.username.split("@")[0]}@XXXXXXX
                      </Table.Cell>
                      <Table.Cell>{user?.moderator?.__typename}</Table.Cell>
                      <Table.Cell>
                        <div
                          style={{
                            width: "fit-content",
                            cursor: "pointer",
                            overflow: "hidden",
                          }}
                          onClick={() => {
                            sessionStorage.setItem(
                              "clickedRoomId",
                              user.roomId
                            );
                            onChat(user?.moderator, user.userDetails);
                          }}
                        >
                          <img src={chatIcon} width={"26px"} height={"26px"} />
                        </div>
                      </Table.Cell>
                    </Table.Row>
                  );
                }
                return null;
              })}
            </Table.Body>
          </Table>
        </Dimmer.Dimmable>
      ) : (
        <Segment>
          <h3>No Chats In Queue</h3>
        </Segment>
      )}
    </div>
  );
};

export default withRouter(Pendingchats);
