import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import MainMenu from "../../components/mainMenu";
import Moderators from "../../components/moderators/list";
import {
  getModeratorsList,
  clearModerator,
  onSearchValueChange,
  hideError,
} from "../../actions/moderators";
import { setActiveMenuPosition } from "../../actions/menu";
import { logoutAction } from "../../actions/session";
import {
  addFakeUsers,
  selectChatFakeUser,
  startNewChat,
} from "../../actions/chat";
import { resetCurrentFakeUser } from "../../actions/fakeUsers";

const ModeratorView = (props) => {
  useEffect(() => {
    props.clearModerator();
    if (
      props.moderators.moderators &&
      props.moderators.moderators.length === 0
    ) {
      props.getModeratorsList(
        props.moderators.offset,
        props.moderators.direction,
        props.moderators.orderedField
      );
    }
    if (props.menu.activeMenu !== "moderators") {
      props.setActiveMenuPosition("moderators");
    }
  }, []);

  if (!props.session.isAuthenticated) {
    return (
      <Redirect to={{ pathname: "/signIn", state: { from: props.location } }} />
    );
  }

  return (
    <div>
      <MainMenu
        needShowPrivateItems={props.session.isAuthenticated}
        menu={props.menu}
        onLogout={props.onLogout}
        setActiveMenuPosition={props.setActiveMenuPosition}
        userDetails={props.session.userDetails}
      />
      <Moderators
        moderators={props.moderators}
        getModeratorsList={props.getModeratorsList}
        chat={props.chat}
        onSearchValueChange={props.onSearchValueChange}
        userDetails={props.session.userDetails}
        addFakeUsers={props.addFakeUsers}
        selectChatFakeUser={props.selectChatFakeUser}
        startNewChat={props.startNewChat}
        resetCurrentFakeUser={props.resetCurrentFakeUser}
        hideError={props.hideError}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    menu: state.menu,
    moderators: state.moderators,
    session: state.session,
    chat: state.chat,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    clearModerator: () => {
      clearModerator()(dispatch);
    },
    getModeratorsList: (offset, ordered, orderedField, fields, search) => {
      getModeratorsList(
        offset,
        ordered,
        orderedField,
        fields,
        search
      )(dispatch);
    },
    onSearchValueChange: (e, data) => {
      onSearchValueChange(data.value)(dispatch);
    },
    addFakeUsers: (data) => {
      addFakeUsers(data)(dispatch);
    },
    selectChatFakeUser: (data) => {
      selectChatFakeUser(data)(dispatch);
    },
    startNewChat: (fakeUserId, realUser) => {
      startNewChat(fakeUserId, realUser)(dispatch);
    },
    setActiveMenuPosition(activeMenu) {
      setActiveMenuPosition(activeMenu)(dispatch);
    },
    onLogout() {
      logoutAction()(dispatch);
    },
    resetCurrentFakeUser: () => {
      resetCurrentFakeUser()(dispatch);
    },
    hideError() {
      hideError()(dispatch);
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModeratorView);
