import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import {
  Button,
  Dimmer,
  Dropdown,
  Icon,
  Image,
  Input,
  Loader,
  Popup,
  Grid,
  Checkbox,
  Segment,
} from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import ModeratorsList from "../common/moderaterList";

import collection from "../../assets/images/collection.png";
import downArrow from "../../assets/images/downArrow.png";
import ReactPaginate from "react-paginate";
import { getPresets } from "../../actions/fakeUsers";
import {
  getAllAdmins,
  getModeratorListByAdminId,
  getModeratorsList,
  getUserByAdmin,
} from "../../actions/moderators";
import UserApi from "../../utils/users";
import ErrorMessage from "../errorMessage";
import "./Users.css";
import "./index.css";

const SortComponent = ({ offset, moderators }) => {
  const [filterMenu, setFilterMenu] = useState(false);
  const [filter, setFilter] = useState({});
  const [countries, setCountries] = useState([]);
  const [state, setState] = useState({
    currentPage: 0,
  });
  const dispatch = useDispatch();
  const {
    fakeUsers: { presets },
  } = useSelector((state) => state);

  const getAllCountries = async () => {
    const allCountries = await UserApi.allCountries();
    setCountries(allCountries?.data?.allCountries);
  };

  useEffect(() => {
    dispatch(getPresets());
    getAllCountries();
  }, []);

  const ageOptions = presets?.ages.map(({ id, age }, index) => ({
    key: index,
    value: id,
    text: age,
  }));
  const countryOptions = countries.map(({ id, fullName }, index) => ({
    key: index,
    value: id,
    text: fullName,
  }));

  const onFilter = () => {
    let filterString = [];
    for (const key in filter) {
      filterString.push({ key, value: filter[key] });
    }

    dispatch(
      getModeratorsList(
        0,
        moderators?.direction,
        moderators?.sort_column,
        filterString,
        moderators?.search,
        state.currentPage
      )
    );

    setFilterMenu(false);
  };

  const clearFilter = () => {
    window.location.reload();
  };

  return (
    <Popup
      on={"click"}
      position="bottom right"
      open={filterMenu}
      onOpen={() => setFilterMenu(true)}
      onClose={() => setFilterMenu(false)}
      style={{ zIndex: "20000" }}
      trigger={
        <Dropdown
          text="Sort by"
          className="icon sortBox"
          style={{ marginRight: "20px" }}
          floating
          labeled
          button
          icon={
            <div className="iconBox">
              <Image src={downArrow} width="10px" height="5px" />
            </div>
          }
        />
      }
    >
      <div style={{ width: "fit-content" }}>
        <Grid className="filterMenu">
          <Grid.Row style={{ alignItems: "center" }}>
            <Grid.Column width={9}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
                className="dropContainer"
              >
                <p style={{ margin: "0px 20px 0px 0px" }}>Moderator's Name:</p>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <Input
                    fluid
                    placeholder="Name.."
                    className="filterInput searchBox"
                    onChange={(e, data) => {
                      setFilter({ ...filter, name: data?.value });
                    }}
                  />
                </div>
                <p style={{ margin: "0px 20px 0px 25px" }}>By Country:</p>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                  }}
                  className="dropdownView country-dropdown"
                >
                  <Dropdown
                    selection
                    options={countryOptions}
                    onChange={(e, data) => {
                      setFilter({
                        ...filter,
                        country: countryOptions.find(
                          (item) => item.value === data?.value
                        )?.text,
                      });
                    }}
                    text={`${
                      countryOptions?.find(
                        (item) => item?.text === filter?.country
                      )?.text || "Select here"
                    }`}
                    value={filter?.country}
                    className="icon filterBox"
                    floating
                    labeled
                    button
                    icon={
                      <div className="iconBox">
                        <Image src={downArrow} width="10px" height="5px" />
                      </div>
                    }
                  ></Dropdown>
                  <p style={{ margin: "0 12px" }}>Or</p>
                  <Input
                    fluid
                    placeholder="Search Country"
                    className="filterInput searchBox"
                    onChange={(e, data) => {
                      setFilter({ ...filter, countryOr: data?.value });
                    }}
                    value={filter?.countryOr}
                  />
                </div>
              </div>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: "25px",
                }}
                className="dropContainer"
              >
                <p>By Age:</p>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    gap: "25px",
                  }}
                  className="dropdownView"
                >
                  <Dropdown
                    selection
                    options={ageOptions}
                    onChange={(e, data) => {
                      setFilter({ ...filter, age_from: data?.value });
                    }}
                    text={`${
                      ageOptions?.find(
                        (item) => item?.value === filter?.age_from
                      )?.text || "From"
                    }`}
                    value={filter?.age_from}
                    className="icon filterBox"
                    floating
                    labeled
                    button
                    icon={
                      <div className="iconBox">
                        <Image src={downArrow} width="10px" height="5px" />
                      </div>
                    }
                  />
                  <Dropdown
                    selection
                    onChange={(e, data) => {
                      setFilter({ ...filter, age_to: data?.value });
                    }}
                    options={ageOptions}
                    text={`${
                      ageOptions?.find((item) => item?.value === filter?.age_to)
                        ?.text || "To"
                    }`}
                    value={filter?.age_to}
                    className="icon filterBox"
                    floating
                    labeled
                    button
                    icon={
                      <div className="iconBox">
                        <Image src={downArrow} width="10px" height="5px" />
                      </div>
                    }
                  />
                </div>
              </div>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: "25px",
                }}
                className="dropContainer"
              >
                <p>Gender</p>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    gap: "25px",
                  }}
                >
                  <Checkbox
                    className="radioBox"
                    label="Male"
                    checked={filter?.gender === 1}
                    onClick={() => setFilter({ ...filter, gender: 1 })}
                  />
                  <Checkbox
                    className="radioBox"
                    label="Female"
                    checked={filter?.gender === 2}
                    onClick={() => setFilter({ ...filter, gender: 2 })}
                  />
                  <Checkbox
                    className="radioBox"
                    label="Both"
                    checked={filter?.gender === 3}
                    onClick={() => setFilter({ ...filter, gender: 3 })}
                  />
                </div>
              </div>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: "25px",
                }}
                className="dropContainer"
              >
                <p>Status</p>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    gap: "25px",
                  }}
                >
                  <Checkbox
                    className="radioBox"
                    label="Online"
                    checked={filter?.is_online === true}
                    onClick={() => setFilter({ ...filter, is_online: true })}
                  />
                  <Checkbox
                    className="radioBox"
                    label="Offline"
                    checked={filter?.is_online === false}
                    onClick={() => setFilter({ ...filter, is_online: false })}
                  />
                </div>
              </div>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={7}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <Button
                  className="ui blue"
                  onClick={() => {
                    clearFilter();
                  }}
                >
                  Clear Filter
                </Button>
                <Button
                  className="filterButton"
                  onClick={() => {
                    onFilter();
                  }}
                >
                  Filter
                </Button>
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    </Popup>
  );
};

function CreateByComponent({ setSelectAdminName }) {
  const [filterMenu, setFilterMenu] = useState(false);
  const [filter, setFilter] = useState({});
  const [state, setState] = useState({
    currentPage: 0,
  });
  const dispatch = useDispatch();
  const [userId, setUserId] = useState("");
  const selectAdmins = useSelector((state) => state.moderators.alladmins);
  const [checkAdminName, setCheckAdminName] = useState("");

  useEffect(() => {
    dispatch(getAllAdmins());
  }, []);

  const adminsOptions = selectAdmins?.map(
    ({ id, firstName, username }, index) => ({
      key: index,
      value: id,
      text: firstName || username,
    })
  );

  const handleChangeName = (e, data) => {
    const username = data.value;

    const filteredAdmins = selectAdmins?.filter(
      (user) =>
        user &&
        (user.username?.toLowerCase().includes(username?.toLowerCase()) ||
          user.fullName?.toLowerCase().includes(username?.toLowerCase()))
    );

    if (filteredAdmins) {
      setUserId(filteredAdmins[0]?.id);
      setCheckAdminName(
        filteredAdmins[0]?.username || filteredAdmins[0]?.fullName
      );
    }
  };

  const onFilter = async (e) => {
    e.preventDefault();
    const filteredAdmins = selectAdmins?.filter(
      (user) =>
        user &&
        (user.username?.toLowerCase().includes(checkAdminName?.toLowerCase()) ||
          user.fullName?.toLowerCase().includes(checkAdminName?.toLowerCase()))
    );
    setSelectAdminName(
      filteredAdmins[0]?.username || filteredAdmins[0]?.fullName
    );
    await dispatch(getModeratorListByAdminId(userId));
    setFilterMenu(false);
  };

  const clearFilter = () => {
    window.location.reload();
  };

  return (
    <Popup
      on={"click"}
      position="bottom right"
      open={filterMenu}
      onOpen={() => setFilterMenu(true)}
      onClose={() => setFilterMenu(false)}
      style={{ zIndex: "20000" }}
      trigger={
        <Dropdown
          text="Created by"
          className="icon sortBox"
          style={{ marginRight: "20px" }}
          floating
          labeled
          button
          icon={
            <div className="iconBox">
              <Image src={downArrow} width="10px" height="5px" />
            </div>
          }
        />
      }
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "1rem",
        }}
        className="dropContainer"
      >
        <p>Admins's Name:</p>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
          className="dropdownView country-dropdown"
        >
          <Dropdown
            selection
            options={adminsOptions}
            onChange={(e, data) => {
              const selectedAdmin = adminsOptions.find(
                (item) => item.value === data?.value
              );
              setFilter({
                ...filter,
                name: selectedAdmin?.text,
              });
              setUserId(selectedAdmin?.value);
              setCheckAdminName(selectedAdmin?.text);
            }}
            text={`${
              adminsOptions?.find((item) => item?.text === filter?.name)
                ?.text || "Select here"
            }`}
            value={filter?.name}
            className="icon filterBox"
            floating
            labeled
            button
            icon={
              <div className="iconBox">
                <Image src={downArrow} width="10px" height="5px" />
              </div>
            }
          ></Dropdown>
          <p style={{ margin: "0 12px" }}>Or</p>
          <Input
            fluid
            placeholder="Search Name"
            className="filterInput searchBox"
            onChange={handleChangeName}
          />
        </div>
      </div>
      <Grid.Row>
        <Grid.Column width={7}>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              marginTop: "1.5rem",
            }}
          >
            <Button className="ui blue" onClick={clearFilter}>
              Clear Filter
            </Button>
            <Button className="filterButton" onClick={onFilter}>
              Filter
            </Button>
          </div>
        </Grid.Column>
      </Grid.Row>
    </Popup>
  );
}

const Moderators = (props) => {
  const [state, setState] = useState({
    open: false,
    currentPage: props?.moderators?.currentPageSize || 0,
    filterMenu: false,
    gender: -1,
  });
  const [selectAdminName, setSelectAdminName] = useState("");
  const moderatorListAdminId = useSelector(
    (state) => state.moderators.specificmoderatorlist
  );

  useEffect(() => {
    if (props?.moderators?.currentPageSize === 0) {
      setState({ currentPage: 0 });
    }
  }, []);

  const onKeyPress = (e) => {
    if (e.key !== "Enter") {
      return;
    }

    if (props.moderators.search === "") {
      props.getModeratorsList(
        0,
        props.moderators?.direction,
        props.moderators?.sort_column,
        props.moderators?.filter,
        props?.moderators?.search
      );
    }
    if (!props.moderators.search || props.moderators.search.length < 3) {
      return;
    }
    props.getModeratorsList(
      0,
      props.moderators?.direction,
      props.moderators?.sort_column,
      props.moderators?.filter,
      props?.moderators?.search
    );
  };

  const handlePageClick = (data) => {
    setState({ currentPage: data?.selected });
    props.getModeratorsList(
      data?.selected * 28,
      props.moderators.direction,
      props.moderators.sort_column,
      props.moderators.filter,
      props?.moderators?.search,
      data?.selected
    );
  };

  const isAdmin = props?.userDetails?.privileges.includes("ADMIN")
    ? true
    : false;

  return (
    <div>
      <Dimmer.Dimmable dimmed={props.moderators.loading}>
        <Dimmer active={props.moderators.loading} inverted>
          <Loader></Loader>
        </Dimmer>
        <Segment>
          {props?.moderators.error && props?.moderators.error.visible && (
            <ErrorMessage
              error={props?.moderators.error}
              hideError={props.hideError}
            />
          )}
          <div style={{ padding: "0px 30px 0px 23px" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-start",
                padding: "35px 0px",
              }}
            >
              <div style={{ display: "flex", width: "100%" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "35%",
                    position: "relative",
                  }}
                >
                  <Input
                    fluid
                    icon={
                      <Icon
                        name="search"
                        circular
                        inverted={props.moderators.search.length >= 3}
                        link={props.moderators.search.length >= 3}
                        onClick={() => {
                          props.getModeratorsList(
                            0,
                            props.moderators?.direction,
                            props.moderators?.sort_column,
                            props.moderators?.filter,
                            props?.moderators?.search
                          );
                        }}
                      />
                    }
                    value={props.moderators.search}
                    onChange={props.onSearchValueChange}
                    onKeyPress={onKeyPress}
                    placeholder="Search..."
                    className="searchBox"
                    style={{ width: "100%", alignItems: "center" }}
                  />
                </div>
              </div>
              <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={Math.ceil(props.moderators?.size / 28)}
                marginPagesDisplayed={2}
                pageRangeDisplayed={2}
                onPageChange={handlePageClick}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
                disabledLinkClassName="disableLink"
                nextLinkClassName="nextLink"
                previousLinkClassName="previousLink"
                forcePage={state.currentPage}
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div>
                <p className="userText">Moderators Listing</p>
                <p className="pageText">
                  Showing results &nbsp;
                  {moderatorListAdminId?.results?.length > 0
                    ? moderatorListAdminId?.results?.length
                    : (state?.currentPage + 1) * 28 > props.moderators?.size
                    ? props.moderators?.size
                    : (state?.currentPage + 1) * 28}{" "}
                  of {props.moderators?.size}{" "}
                </p>
              </div>
              {selectAdminName && (
                <div>
                  <p className="userText">By: {selectAdminName}</p>
                </div>
              )}
              <div style={{ display: "flex" }}>
                <CreateByComponent
                  moderators={props?.moderators}
                  setSelectAdminName={setSelectAdminName}
                />
                <SortComponent
                  offset={state?.currentPage * 28}
                  moderators={props?.moderators}
                />
                <Dropdown
                  text="Collection Type"
                  className="icon sortBox"
                  floating
                  labeled
                  button
                  icon={
                    <div className="iconBox" style={{ paddingTop: "10px" }}>
                      <Image src={collection} width="12px" height="15px" />
                    </div>
                  }
                >
                  <Dropdown.Menu className="left">
                    <Dropdown.Item>
                      <Icon name="dropdown" />
                      <span className="text">Left</span>
                      <Dropdown.Menu>
                        <Dropdown.Item>
                          <Icon name="dropdown" />
                          <span className="text">Still Left</span>
                          <Dropdown.Menu>
                            <Dropdown.Item>1</Dropdown.Item>
                            <Dropdown.Item>2</Dropdown.Item>
                            <Dropdown.Item>3</Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown.Item>
                        <Dropdown.Item>2</Dropdown.Item>
                        <Dropdown.Item>3</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Icon name="left dropdown" />
                      <span className="text">Left</span>
                      <Dropdown.Menu>
                        <Dropdown.Item>1</Dropdown.Item>
                        <Dropdown.Item>2</Dropdown.Item>
                        <Dropdown.Item>3</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                {props?.userDetails?.privileges?.includes("ADMIN") ? (
                  <Button
                    onClick={() => {
                      props.resetCurrentFakeUser();
                      return (window.location.href = "#/create/moderator/");
                    }}
                    circular
                    icon="add"
                    positive
                    floated="right"
                  />
                ) : null}
              </div>
            </div>
            {moderatorListAdminId?.results?.length > 0 ? (
              <ModeratorsList
                users={moderatorListAdminId?.results}
                isAdmin={isAdmin}
                columns={5}
                editPath={"/moderators/edit/"}
                onChat={(moderator) => {
                  if (!props?.userDetails?.privileges?.includes("ADMIN")) {
                    props.addFakeUsers(moderator);
                    setTimeout(() => {
                      props.selectChatFakeUser(moderator?.id);
                      props.startNewChat(moderator?.id, props.user);
                      props.history.push("/dashboard");
                    }, 1000);
                  }
                }}
              />
            ) : (
              <ModeratorsList
                users={props.moderators.moderators}
                isAdmin={isAdmin}
                columns={5}
                editPath={"/moderators/edit/"}
                onChat={(moderator) => {
                  if (!props?.userDetails?.privileges?.includes("ADMIN")) {
                    props.addFakeUsers(moderator);
                    setTimeout(() => {
                      props.selectChatFakeUser(moderator?.id);
                      props.startNewChat(moderator?.id, props.user);
                      props.history.push("/dashboard");
                    }, 1000);
                  }
                }}
              />
            )}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "35px 0px",
            }}
          >
            <div style={{ display: "flex", width: "100%" }}></div>
            <ReactPaginate
              previousLabel={"<"}
              nextLabel={">"}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={Math.ceil(props.moderators?.size / 28)}
              marginPagesDisplayed={2}
              pageRangeDisplayed={2}
              onPageChange={handlePageClick}
              containerClassName={"pagination"}
              subContainerClassName={"pages pagination"}
              activeClassName={"active"}
              disabledLinkClassName="disableLink"
              nextLinkClassName="nextLink"
              previousLinkClassName="previousLink"
              forcePage={state.currentPage}
            />
          </div>
        </Segment>
      </Dimmer.Dimmable>
    </div>
  );
};

export default withRouter(Moderators);
