import moment from "moment";
import React from "react";
import { Card, Grid, Image } from "semantic-ui-react";
import onlineIcon from "../../assets/images/onlineIcon.png";
import offlineIcon from "../../assets/images/offlineIcon.png";
import editIcon from "../../assets/images/editIcon.png";
import chatIcon from "../../assets/images/chatIcon.png";
import { Link } from "react-router-dom";
import { isEmpty } from "lodash";
import { commonConstants } from "../../constants/common";
import noImagePlaceholder from "../../assets/images/no-image-placeholder.webp";
import { isVideo } from "../../utils/helpers";

const UserList = ({
  users,
  columns,
  isAdmin,
  onChat,
  editPath,
  languagePresets,
  heightPresets,
  subscriptionPresets,
  ethnicityPresets,
  familyPresets,
}) => {
  const intrestedInArr = commonConstants.INTERESTED_IN_OPTIONS;

  return (
    <Grid columns={columns} className="userContainer">
      <Grid.Row>
        {users?.map((user, index) => {
          let avatar = user?.avatar_photos?.length
            ? user?.avatar_photos[0]?.file
            : "";
          const primaryImage = user?.avatar_photos[user?.avatar_index]?.file;

          const displayMedia = (
            avatar ? (primaryImage ? primaryImage : avatar) : noImagePlaceholder
          ).trim();

          const is_video = isVideo(displayMedia);

          const getReports = () => {
            const reports = [];
            user?.reports?.map((report) => reports.push(report?.reason ?? ""));
            return reports?.join(", ") ?? "";
          };

          return (
            <Grid.Column key={`user-${index}`} className="userInfo">
              <Card className="userCard">
                <div style={{ position: "relative" }}>
                  {is_video ? (
                    <video
                      key={`video-${index}`}
                      style={{
                        width: "100%",
                        height: "220px",
                        borderRadius: "8px",
                        padding: "15px",
                      }}
                      autoPlay
                      muted
                      loop
                    >
                      <source src={displayMedia} type="video/mp4" />
                    </video>
                  ) : (
                    <Image
                      src={displayMedia}
                      width={"100%"}
                      height={"220px"}
                      wrapped
                      ui={false}
                      className={"userImage"}
                      style={{ borderRadius: "8px" }}
                    />
                  )}
                  <div className="actionView">
                    <Link to={`${editPath}${user?.id}`}>
                      <Image
                        src={editIcon}
                        width={"26px"}
                        height={"26px"}
                        wrapped
                        ui={false}
                      />
                    </Link>
                    {!isAdmin ? (
                      <Image
                        src={chatIcon}
                        width={"26px"}
                        height={"26px"}
                        wrapped
                        ui={false}
                        className="chatIcon"
                        onClick={() => onChat(user)}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <Card.Content
                  style={{ display: "block" }}
                  className="userContent"
                >
                  <Card.Header className="cardHeader">
                    <p
                      style={{
                        maxWidth: "60%",
                        minWidth: "60%",
                        wordWrap: "break-word",
                      }}
                    >
                      {user?.display_name}
                    </p>
                    {user?.isOnline ? (
                      <div>
                        <img
                          src={onlineIcon}
                          alt="Online Icon"
                          width="15px"
                          height="15px"
                        />{" "}
                        Online
                      </div>
                    ) : (
                      <div>
                        <img
                          src={offlineIcon}
                          alt="Online Icon"
                          width="15px"
                          height="15px"
                        />{" "}
                        Offline
                      </div>
                    )}
                  </Card.Header>
                  <Card.Description className="description">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <p>Age:&nbsp;{user?.age + 17}</p>&nbsp;/&nbsp;{" "}
                      <p>
                        Height: &nbsp;
                        {user?.height
                          ? heightPresets[user?.height]?.height ?? ""
                          : "Nil"}
                      </p>
                    </div>
                    <p>City: {user?.city}</p>
                    <p>
                      Language: &nbsp;
                      {isEmpty(user?.language)
                        ? "Nil"
                        : languagePresets[user?.language[0]]?.language ?? ""}
                    </p>
                    <p>
                      Subscription: &nbsp;{" "}
                      {user?.user_subscription
                        ? subscriptionPresets[user?.user_subscription?.package]
                            ?.name
                        : "NO SUBSCRIPTION"}
                    </p>
                    <p>
                      IntrestedIn : &nbsp;
                      {user.interested_in
                        ? user?.interested_in.split(",").map((item) => {
                            const intrests = intrestedInArr.find((element) => {
                              // eslint-disable-next-line
                              return element.value == item;
                            });
                            return <span>{intrests && intrests.text}, </span>;
                          })
                        : "Nil"}
                    </p>
                    <p>
                      Ethnicity: &nbsp;
                      {user?.ethnicity
                        ? ethnicityPresets[user?.ethnicity]?.ethnicity
                        : "Nil"}
                    </p>
                    <p>
                      Family Plan: &nbsp;
                      {user?.familyPlans
                        ? familyPresets[user?.familyPlans]?.familyPlans
                        : "Nil"}
                    </p>
                    <p>
                      Work: &nbsp;
                      {user?.work ? user?.work : "Nil"}
                    </p>
                    <p>
                      Joined:&nbsp;
                      {moment(user?.sign_up).format("DD/MM/YYYY,hh:mm:ss a")}
                    </p>
                    <p>
                      Last Login:&nbsp;
                      {moment(user?.user_last_seen).format(
                        "DD/MM/YYYY,hh:mm:ss a"
                      )}
                    </p>
                  </Card.Description>
                </Card.Content>
                <Card.Content extra className="footer">
                  <p>Disabled: {user?.is_blocked ? "True" : "False"}</p>
                  <p>Reports: {getReports() ? getReports() : "None"}</p>
                </Card.Content>
              </Card>
            </Grid.Column>
          );
        })}
      </Grid.Row>
    </Grid>
  );
};

export default UserList;
