import { presetsActionTypes } from "../constants/actions/presets";

const presetsInitialState = {
  loading: true,
  value: {},
};

const presetsReducer = (state = presetsInitialState, action) => {
  switch (action.type) {
    case presetsActionTypes.GET_PRESETS:
      return {
        ...state,
        loading: false,
        value: action.payload,
      };
    default:
      return state;
  }
};

export default presetsReducer;
