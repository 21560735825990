import { workersActionTypes } from "../constants/actions/workers";
import { fakeUsersActionTypes } from "../constants/actions/fakeUsers";

const initialState = {
  workers: [],
  workersLastContact: {},
  workersLastMessage: {},
  workersActiveChats: [],
  workersStats: [],
  currentWorker: {
    id: 0,
  },
  fakeUsers: [],
  invitation: {
    email: "",
    is_admin_permission: false,
    is_chat_admin_permission: false,
    generated: false,
    link_value: "",
  },
  loading: true,
  error: {
    visible: false,
    header: "",
    messages: [],
  },
  workerStatisticsMessages: undefined,
  moderatorInQueue: undefined,
  nextWorkersUrl: null,
  pevWorkersUrl: null,
  limit: 25,
  search: "",
  sortColoumn: "",
  direction: 0,
  topNavWorkerStats: { receivedCount: 0, sentCount: 0 },
};

export default function workers(state = initialState, action) {
  switch (action.type) {
    case workersActionTypes.GET_WORKERS_LIST_SUCCESS:
      return {
        ...state,
        workers: action?.data?.results,
        nextWorkersUrl: action?.data?.next,
        pevWorkersUrl: action?.data?.previous,
        size: action?.data?.count,
        error: {
          ...initialState.error,
        },
        loading: false,
      };
    case workersActionTypes.UPDATE_MODERATOR_IN_QUEUE:
      return {
        ...state,
        moderatorInQueue: [],
      };
    case workersActionTypes.CLEAR_INVITATION:
      return {
        ...state,
        invitation: {
          email: "",
          is_admin_permission: false,
          is_chat_admin_permission: false,
          generated: false,
        },
        loading: false,
      };

    case workersActionTypes.GET_WORKERS_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case workersActionTypes.GET_WORKERS_LIST_FAILURE:
      return {
        ...state,
        error: {
          ...state.error,
          header:
            action.data.header && action.data.header !== ""
              ? action.data.header
              : "",
          messages:
            action.data.errors && action.data.errors.length > 0
              ? action.data.errors
              : [],
          visible: true,
        },
        loading: false,
      };
    case workersActionTypes.DELETE_WORKER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case workersActionTypes.UPDATE_WORKER_STATS:
      return {
        ...state,
        workersStats: action.data,
      };
    case workersActionTypes.INIT_WORKER_MESSAGES_STATISTICS:
      return {
        ...state,
        workerStatisticsMessages: action.data,
      };
    case workersActionTypes.ADD_WORKER_MESSAGES_STATISTICS:
      return {
        ...state,
        workerStatisticsMessages: {
          sentCount:
            state.workerStatisticsMessages.sentCount + action.data.sentCount,
          receivedCount:
            state.workerStatisticsMessages.receivedCount +
            action.data.receivedCount,
        },
      };
    case workersActionTypes.DELETE_WORKER_FAILURE:
      return {
        ...state,
        error: {
          ...state.error,
          header:
            action.data.header && action.data.header !== ""
              ? action.data.header
              : "",
          messages:
            action.data.errors && action.data.errors.length > 0
              ? action.data.errors
              : [],
          visible: true,
        },
        loading: false,
      };
    case workersActionTypes.CHANGE_EMAIL:
      return {
        ...state,
        invitation: {
          ...state.invitation,
          email: action.data,
        },
      };

    case workersActionTypes.CHANGE_ADMIN_PERMISSION:
      return {
        ...state,
        invitation: {
          ...state.invitation,
          is_admin_permission: action.data,
          is_chat_admin_permission: action.data
            ? false
            : state.invitation.is_chat_admin_permission,
        },
      };

    case workersActionTypes.CHANGE_CHAT_ADMIN_PERMISSION:
      return {
        ...state,
        invitation: {
          ...state.invitation,
          is_chat_admin_permission: action.data,
          is_admin_permission: action.data
            ? false
            : state.invitation.is_admin_permission,
        },
      };

    case workersActionTypes.CREATE_INVITATION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case workersActionTypes.CREATE_INVITATION_FAILURE:
      return {
        ...state,
        error: {
          ...state.error,
          header:
            action.data.header && action.data.header !== ""
              ? action.data.header
              : "",
          messages:
            action.data.errors && action.data.errors.length > 0
              ? action.data.errors
              : [],
          visible: true,
        },
        loading: false,
      };

    case workersActionTypes.CREATE_INVITATION_SUCCESS:
      return {
        ...state,
        error: {
          ...state.error,
          visible: false,
        },
        invitation: {
          ...state.invitation,
          link_value: action.data,
          generated: true,
        },
        loading: false,
      };
    case workersActionTypes.HIDE_ERROR:
      return {
        ...state,
        error: {
          ...state.error,
          visible: false,
        },
      };

    case workersActionTypes.SELECT_WORKER:
      return {
        ...state,
        currentWorker: {
          ...state.currentWorker,
          id: action.data,
        },
      };

    case fakeUsersActionTypes.GET_WORKER_FAKE_USERS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case fakeUsersActionTypes.GET_WORKER_FAKE_USERS_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case fakeUsersActionTypes.GET_WORKER_FAKE_USERS_SUCCESS: {
      return {
        ...state,
        fakeUsers: action.data.fake_users,
        loading: false,
      };
    }
    case workersActionTypes.UPDATE_WORKERS_STORE_FIELD: {
      return {
        ...state,
        [action.data.field]: action.data.value,
      };
    }
    case workersActionTypes.TOP_NAV_WORKER_STATS_REQUEST: {
      return {
        ...state,
        topNavWorkerStats: { ...state.topNavWorkerStats, isLoading: true },
      };
    }
    case workersActionTypes.TOP_NAV_WORKER_STATS_SUCCESS: {
      const dataObj = action.data.reduce(
        (acc, crr) => ({
          receivedCount: acc.receivedCount + crr.receivedCount,
          sentCount: acc.sentCount + crr.sentCount,
        }),
        { receivedCount: 0, sentCount: 0 }
      );
      return {
        ...state,
        topNavWorkerStats: {
          ...state.topNavWorkerStats,
          ...dataObj,
          statsArr: action.data,
          isLoading: false,
        },
      };
    }
    case workersActionTypes.TOP_NAV_WORKER_STATS_FAILURE: {
      return {
        ...state,
        topNavWorkerStats: { error: action.data, isLoading: false },
      };
    }
    default:
      return state;
  }
}
