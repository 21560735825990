import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import MainMenu from "../../components/mainMenu";
import { setActiveMenuPosition } from "../../actions/menu";
import { logoutAction } from "../../actions/session";
import WorkerDashboard from "../../components/dashboard/worker";
import { isAllowedRole } from "../../utils/auth";
import { commonConstants } from "../../constants/common";
import Workers from "../../components/workers/list";
import {
  messageReceiveListener,
  updateChatFakeUsers,
  changeFakeUserOnlineStatus,
  selectChat,
  changeChatMessage,
  sendChatMessage,
  readChatMessages,
  saveNotes,
  startNewChat,
  selectChatFakeUser,
  getAllDialogue,
  getChatHistory,
  sendTextMessage,
  sendAttachmentMessage,
  createNotes,
  appendIncomingMessage,
  getStockImages,
  removeCurrentChats,
  informManualChat,
  getAllChatsInQueue,
  checkWorkerCanPaste,
  getDynamicChatLength,
  sendProfileVisit,
} from "../../actions/chat";
import {
  getPresets,
  getWorkerFakeUsers,
  reFetchWorkerFakeUsers,
} from "../../actions/fakeUsers";
import {
  getWorkersList,
  selectWorker,
  deleteWorker,
  moderatorsInQueue,
} from "../../actions/workers";
import {
  getUsersList,
  hideError,
  onSearchValueChange,
  searchUsersList,
  sortUsersList,
} from "../../actions/users";
import moment from "moment";

const HOURLY_LABEL = [
  "00:00",
  "01:00",
  "02:00",
  "03:00",
  "04:00",
  "05:00",
  "06:00",
  "07:00",
  "08:00",
  "09:00",
  "10:00",
  "11:00",
  "12:00",
  "13:00",
  "14:00",
  "15:00",
  "16:00",
  "17:00",
  "18:00",
  "19:00",
  "20:00",
  "21:00",
  "22:00",
  "23:00",
];
const MODERATOR_IN_QUEUE_INTERVAL = 30000;

class DashboardView extends Component {
  interval;
  constructor(props) {
    super(props);

    this.state = {
      labels: this.getDaysCurrentMonth(),
      isMonthlyChartType: true,
      isLoadModeratorsInQueue: false,
      isAllowChatter: false,
    };
    this.refreshChat = this.refreshChat.bind(this);
  }

  getDaysCurrentMonth = function () {
    const monthDate = moment();
    let daysInMonth = monthDate.daysInMonth();
    const arrDays = [];

    while (daysInMonth) {
      const current = moment().date(daysInMonth);
      arrDays.push(current.format("MM-DD-YYYY"));
      daysInMonth--;
    }

    return arrDays.reverse();
  };

  componentDidMount() {
    if (this.props.menu.activeMenu === "pendingChats") {
      this.setState({ isAllowChatter: true });
      // this.props.setActiveMenuPosition("dashboard");
    }
    this.props.getAllChatsInQueue();
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.isMonthlyChartType !== this.state.isMonthlyChartType) {
      this.setState({
        labels: this.state.isMonthlyChartType
          ? this.getDaysCurrentMonth()
          : HOURLY_LABEL,
      });
    }

    if (
      this.props.session.isAuthenticated &&
      !this.state.isLoadModeratorsInQueue &&
      (this.props.chat?.loading || this.props.workers.loading)
    ) {
      this.props.moderatorsInQueue();
      this.interval = setInterval(() => {
        this.props.moderatorsInQueue();
      }, MODERATOR_IN_QUEUE_INTERVAL);
      this.setState({ isLoadModeratorsInQueue: true });
    }
  }
  componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  handleChatSelect = (dialogue_id, opponent_id, dialogues) => {
    this.props.selectChat(dialogue_id, opponent_id, dialogues);
    if (!this.props.chat.allChats[dialogue_id]) {
      this.props.getChatHistory(
        this.props.chat.currentFakeUser.id,
        dialogue_id,
        this.isChatter
      );
    }
  };

  refreshChat = () => {
    if (
      this.props.chat?.currentChat !== null &&
      this.props.chat?.currentChat !== ""
    ) {
      this.props.getChatHistory(
        this.props.chat?.currentFakeUser.id,
        this.props.chat?.currentChat,
        this.isChatter
      );
    }
  };

  changeMonthlyChartType = (e) => {
    e.stopPropagation();
    this.setState({ isMonthlyChartType: !this.state.isMonthlyChartType });
  };
  isChatter = false;

  render() {
    if (
      this.props.session?.userDetails?.privileges[0] === "CHATTER" &&
      this.state.isAllowChatter
    ) {
      this.isChatter = true;
    }

    if (!this.props.session.isAuthenticated) {
      return (
        <Redirect
          to={{
            pathname: "/signIn",
            state: { from: this.props.location },
          }}
        />
      );
    }

    return (
      <div>
        <MainMenu
          session={this.props.session}
          needShowPrivateItems={this.props.session.isAuthenticated}
          menu={this.props.menu}
          onLogout={this.props.onLogout}
          setActiveMenuPosition={this.props.setActiveMenuPosition}
          userDetails={this.props.session.userDetails}
          moderatorInQueue={this.props.workers?.moderatorInQueue}
        />
        {(isAllowedRole(
          [commonConstants.ADMIN_ROLE],
          this.props.session.userDetails
        ) &&
          !this.props.match.params?.id && (
            <Workers
              labels={this.state.labels}
              changeMonthlyChartType={this.changeMonthlyChartType}
              isMonthlyChartType={this.state.isMonthlyChartType}
            />
          )) ||
          (this.props.chat?.chatsInQueue.length > 0 && (
            <WorkerDashboard
              removeCurrentChats={this.removeCurrentChats}
              refreshChat={this.refreshChat}
              messageReceiveListener={this.props.messageReceiveListener}
              onSendAttachment={this.props.onSendAttachment}
              session={this.props?.session}
              chat={this.props.chat}
              users={this.props.users}
              presets={this?.props?.presets}
              iscurrentFakeuserAdd={this.props.iscurrentFakeuserAdd}
              currentFakeUserId={this.props?.chat?.currentFakeUser?.id}
              getChatUsers={this.props.getChatUsers}
              getWorkerFakeUsers={this.props.getWorkerFakeUsers}
              appendChatHistory={this.props.appendChatHistory}
              reFetchWorkerFakeUsers={this.props.reFetchWorkerFakeUsers}
              workers={this.props.workers}
              updateFakeUsers={this.props.updateFakeUsers}
              selectChatFakeUser={this.props.selectChatFakeUser}
              changeFakeUserOnlineStatus={this.props.changeFakeUserOnlineStatus}
              changeChatMessage={this.props.changeChatMessage}
              selectChat={this.handleChatSelect}
              getStockImages={this.props.getStockImages}
              stockImages={this.props.stockImages}
              sendChatMessage={this.props.sendChatMessage}
              sendTextMessage={this.props.sendTextMessage}
              readChatMessages={this.props.readChatMessages}
              saveNotes={this.props.saveNotes}
              getUsersList={this.props.getUsersList}
              hideError={this.props.hideError}
              sortUsersList={this.props.sortUsersList}
              onSearchValueChange={this.props.onSearchValueChange}
              searchUsersList={this.props.searchUsersList}
              startNewChat={this.props.startNewChat}
              getAllDialogue={this.props.getAllDialogue}
              currentDialogueMessages={this.props.currentDialogueMessages}
              isMessageSend={this.props.isMessageSend}
              createNotes={this.props.createNotes}
              getPresets={this.props.getPresets}
              sendAssignChat={this.props.sendAssignChat}
              getAllChatsInQueue={this.props.getAllChatsInQueue}
              checkWorkerCanPaste={this.props.checkWorkerCanPaste}
              getDynamicChatLength={this.props.getDynamicChatLength}
              sendProfileVisit={this.props.sendProfileVisit}
            />
          ))}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  // calculate total unread message for each fake user
  const allFakeUsers = [...state.chat.currentFakeUsers] || [];
  const allDialogues = state.chat.fakeUserChatDialogues || {};
  for (const user of allFakeUsers) {
    const dialogues = allDialogues[user.id] || [];
    user.unread_messages_count = dialogues
      .map((x) => parseInt(x.unread_messages_count))
      .reduce((x, y) => x + y, 0);
  }

  const chatClearedAt =
    state.chat?.chatsInQueue?.reduce(
      (acc, crr) => ({ ...acc, [`${crr?.roomId}`]: crr?.clearedAt }),
      {}
    ) || {};
  const clearedAt = chatClearedAt?.[state.chat?.currentChat];

  /* console.log({
    chatClearedAt,
    currentChat: state.chat?.currentChat,
    clearedAt,
  }); */

  return {
    users: state.users,
    menu: state.menu,
    session: state.session,
    workers: state.workers,
    stockImages: state.chat.stockImages,
    chat: {
      ...state.chat,
      currentFakeUsers: allFakeUsers,
      clearedAt,
      customMessageLength: state.chat.chatMessage?.replaceAll(" ", "")?.length,
    },
    iscurrentFakeuserAdd: state.chat.iscurrentFakeuserAdd,
    currentDialogueMessages: state.chat.currentDialogueMessages,
    isMessageSend: state.chat.isMessageSend,
    presets: state?.fakeUsers?.presets,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    async messageReceiveListener(moderatorId) {
      const fn = messageReceiveListener(moderatorId);
      const t = await fn(dispatch);
      return t;
    },
    removeCurrentChats(dialogue_id) {
      removeCurrentChats(dialogue_id)(dispatch);
    },
    onSendAttachment(dialogId, userId, msg, params) {
      sendAttachmentMessage(dialogId, userId, msg, params)(dispatch);
    },
    getChatHistory(id, dialogue_id, isChatter) {
      getChatHistory(id, dialogue_id, isChatter)(dispatch);
    },
    appendChatHistory(dialogue_id, message) {
      appendIncomingMessage(dialogue_id, message)(dispatch);
    },
    getAllDialogue(id) {
      getAllDialogue(id)(dispatch);
    },
    setActiveMenuPosition(activeMenu) {
      setActiveMenuPosition(activeMenu)(dispatch);
    },
    onLogout() {
      logoutAction()(dispatch);
    },
    updateFakeUsers() {
      updateChatFakeUsers()(dispatch);
    },
    getWorkerFakeUsers(id) {
      getWorkerFakeUsers(id)(dispatch);
    },
    reFetchWorkerFakeUsers(id, workersData) {
      reFetchWorkerFakeUsers(id)(dispatch, workersData);
    },
    selectChatFakeUser(id) {
      selectChatFakeUser(id)(dispatch);
    },
    changeFakeUserOnlineStatus(uid, online) {
      changeFakeUserOnlineStatus(uid, online)(dispatch);
    },
    sendTextMessage(dialogueId, text, userId, workers, messageType) {
      sendTextMessage(dialogueId, text, userId, messageType)(dispatch, workers);
    },
    sendAssignChat(roomID, moderatorID) {
      informManualChat(roomID, moderatorID)(dispatch);
    },
    selectChat(chatId, opponent_id, dialogues) {
      selectChat(chatId, opponent_id, dialogues)(dispatch);
    },
    changeChatMessage: (e, data) => {
      const msgStr = e?.target?.value;
      changeChatMessage(msgStr)(dispatch);
    },
    checkWorkerCanPaste() {
      checkWorkerCanPaste()(dispatch);
    },
    getDynamicChatLength() {
      getDynamicChatLength()(dispatch);
    },
    sendChatMessage(
      chatId,
      fromName,
      fromUserId,
      recipientToken,
      type,
      message
    ) {
      sendChatMessage(
        chatId,
        fromName,
        fromUserId,
        recipientToken,
        type,
        message
      )(dispatch);
    },
    sendProfileVisit(realUserId, moderatorId) {
      sendProfileVisit(realUserId, moderatorId)(dispatch);
    },
    readChatMessages(uid, chatId, messages) {
      readChatMessages(uid, chatId, messages)(dispatch);
    },
    getStockImages(moderatorId) {
      getStockImages(moderatorId)(dispatch);
    },
    getWorkersList() {
      getWorkersList()(dispatch);
    },
    getAllChatsInQueue() {
      getAllChatsInQueue()(dispatch);
    },
    selectWorker(id) {
      selectWorker(id)(dispatch);
    },
    deleteWorker(id) {
      deleteWorker(id)(dispatch);
    },
    saveNotes(chatId, value) {
      saveNotes(chatId, value)(dispatch);
    },
    getUsersList: (offset, ordered, orderedField) => {
      getUsersList(offset, ordered, orderedField)(dispatch);
    },
    onSearchValueChange: (e, data) => {
      onSearchValueChange(data.value)(dispatch);
    },
    searchUsersList: (value) => {
      searchUsersList(value)(dispatch);
    },
    sortUsersList: (offset, prevColumn, column, prevDirection) => {
      sortUsersList(offset, prevColumn, column, prevDirection)(dispatch);
    },
    startNewChat: (fakeUserId, realUser) => {
      startNewChat(fakeUserId, realUser)(dispatch);
    },
    hideError: () => {
      hideError()(dispatch);
    },

    createNotes(roomId, content, forRealUser, userId, userDetails, callback) {
      createNotes(roomId, content, forRealUser, callback)(dispatch);
      const innerObject = Object.values(userDetails)[0];

      startNewChat(userId, innerObject)(dispatch);
    },
    moderatorsInQueue(moderatorId) {
      moderatorsInQueue(moderatorId)(dispatch);
    },
    getPresets() {
      getPresets()(dispatch);
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardView);
