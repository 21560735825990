export const chatActionTypes = {
  UPDATE_CHAT_DIALOGUE_LIST: "UPDATE_CHAT_DIALOGUE_LIST",
  GET_CHAT_MESSAGES_LIST_REQUEST: "GET_CHAT_MESSAGES_LIST_REQUEST",
  GET_CHAT_MESSAGES_LIST_FAILURE: "GET_CHAT_MESSAGES_LIST_FAILURE",
  GET_CHAT_MESSAGES_LIST_SUCCESS: "GET_CHAT_MESSAGES_LIST_SUCCESS",
  GET_CHAT_MESSAGES_UPDATE: "GET_CHAT_MESSAGES_UPDATE",
  GET_CHAT_MESSAGES_NEW: "GET_CHAT_MESSAGES_NEW",
  GET_CHAT_USERS_LIST_REQUEST: "GET_CHAT_USERS_LIST_REQUEST",
  GET_CHAT_USERS_LIST_FAILURE: "GET_CHAT_USERS_LIST_FAILURE",
  GET_CHAT_USERS_LIST_SUCCESS: "GET_CHAT_USERS_LIST_SUCCESS",
  GET_CHAT_USERS_UPDATE: "GET_CHAT_USERS_UPDATE",
  CHAT_NEED_UPDATE_FAKE_USERS: "CHAT_NEED_UPDATE_FAKE_USERS",
  CHAT_SELECT_FAKE_USER: "CHAT_SELECT_FAKE_USER",
  CHAT_CHANGE_FAKE_USER_STATUS_REQUEST: "CHAT_CHANGE_FAKE_USER_STATUS_REQUEST",
  CHAT_CHANGE_FAKE_USER_STATUS_FAILURE: "CHAT_CHANGE_FAKE_USER_STATUS_FAILURE",
  CHAT_CHANGE_FAKE_USER_STATUS_SUCCESS: "CHAT_CHANGE_FAKE_USER_STATUS_SUCCESS",
  INFORM_MANUAL_CHAT_REQUEST: "INFORM_MANUAL_CHAT_REQUEST",
  INFORM_MANUAL_CHAT_FAILURE: "INFORM_MANUAL_CHAT_FAILURE",
  INFORM_MANUAL_CHAT_SUCCESS: "INFORM_MANUAL_CHAT_SUCCESS",
  SEND_PROFILE_VISIT_SUCCESS: "SEND_PROFILE_VISIT_SUCCESS",
  SEND_PROFILE_VISIT_FAILURE: "SEND_PROFILE_VISIT_FAILURE",
  SEND_CHAT_MESSAGE_REQUEST: "SEND_CHAT_MESSAGE_REQUEST",
  SEND_CHAT_MESSAGE_FAILURE: "SEND_CHAT_MESSAGE_FAILURE",
  SEND_CHAT_MESSAGE_SUCCESS: "SEND_CHAT_MESSAGE_SUCCESS",
  READ_CHAT_MESSAGES_REQUEST: "READ_CHAT_MESSAGES_REQUEST",
  SET_STOCK_IMAGES: "SET_STOCK_IMAGES",
  READ_CHAT_MESSAGES_FAILURE: "READ_CHAT_MESSAGES_FAILURE",
  READ_CHAT_MESSAGES_SUCCESS: "READ_CHAT_MESSAGES_SUCCESS",
  SAVE_CHAT_NOTES_FAILURE: "SAVE_CHAT_NOTES_FAILURE",
  SELECT_CHAT: "SELECT_CHAT",
  CHANGE_CHAT_MESSAGE: "CHANGE_CHAT_MESSAGE",
  HIDE_ERROR: "HIDE_ERROR",
  CHECK_WORKER_CAN_CHAT_FAILURE: "CHECK_WORKER_CAN_CHAT_FAILURE",
  CHECK_WORKER_CAN_CHAT_SUCCESS: "CHECK_WORKER_CAN_CHAT_SUCCESS",
  CHECK_WORKER_CAN_PASTE_FAILURE: "CHECK_WORKER_CAN_PASTE_FAILURE",
  CHECK_WORKER_CAN_PASTE_SUCCESS: "CHECK_WORKER_CAN_PASTE_SUCCESS",
  GET_DYNAMIC_CHAT_LENGTH_FAILURE: "GET_DYNAMIC_CHAT_LENGTH_FAILURE",
  GET_DYNAMIC_CHAT_LENGTH_SUCCESS: "GET_DYNAMIC_CHAT_LENGTH_SUCCESS",
  // updated actions
  START_NEW_CHAT_FAILURE: "START_NEW_CHAT_FAILURE",
  START_NEW_CHAT_SUCCESS: "START_NEW_CHAT_SUCCESS",
  CLEAR_USER: "CLEAR_USER",
  CACHE_USER_INFO: "CACHE_USER_INFO",
  GET_CHAT_HISTORY: "GET_CHAT_HISTORY",
  UPDATE_CHAT_HISTORY: "UPDATE_CHAT_HISTORY",
  APPEND_CHAT_HISTORY: "APPEND_CHAT_HISTORY",
  APPEND_INCOMING_CHAT_HISTORY: "APPEND_INCOMING_CHAT_HISTORY",
  APPEND_RECIEVED_MESSAGE_CHAT_HISTORY: "APPEND_RECIEVED_MESSAGE_CHAT_HISTORY",
  APPEND_RECIEVED_MESSAGE: "APPEND_RECIEVED_MESSAGE",
  UPDATE_FAKE_USER_DATA: "UPDATE_FAKE_USER_DATA",
  NOTHING_APPEND: "NOTHING_APPEND",
  UPDATE_CHAT_DIALOGUE_LATEST_TIME: "UPDATE_CHAT_DIALOGUE_LATEST_TIME",
  GET_CHATS_IN_QUEUE: "GET_CHATS_IN_QUEUE",
  GET_CHATS_IN_QUEUE_COUNT: "GET_CHATS_IN_QUEUE_COUNT",
  SET_CHATS_IN_QUEUE: "SET_CHATS_IN_QUEUE",
  REMOVE_CURRENT_CHATS: "REMOVE_CURRENT_CHATS",
  RESET_CHATS_STATE: "RESET_CHATS_STATE",
  SEND_GIFT_ERROR: "SEND_GIFT_ERROR",
  ADD_FAKE_USERS: "ADD_FAKE_USERS",
  CHAT_PPR_STATUS: "CHAT_PPR_STATUS",
};
