import React, { useState } from "react";
import {
  Form,
  Segment,
  Button,
  Dimmer,
  Loader,
  Input,
  Grid,
  Message,
} from "semantic-ui-react";
import ErrorMessage from "../errorMessage";
import { CopyToClipboard } from "react-copy-to-clipboard";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const Invitation = (props) => {
  const [state, setState] = useState({ copied: false });

  const {
    workers,
    changeEmail,
    hideError,
    changeAdminPermission,
    changeChatAdminPermission,
    createInvitation,
  } = props;

  const invitationLink = `${window.location.origin}${workers.invitation.link_value}`;

  const clearFilter = () => {
    window.location.href = "/";
  };

  return (
    <Segment.Group>
      <Dimmer.Dimmable dimmed={workers.loading}>
        <Dimmer active={workers.loading} inverted>
          <Loader>Loading</Loader>
        </Dimmer>
        {workers.error && workers.error.visible && (
          <ErrorMessage error={workers.error} hideError={hideError} />
        )}
        <Segment secondary textAlign="center">
          <Form>
            <Form.Input
              disabled={workers.invitation.generated}
              fluid
              label="Email"
              placeholder="Email"
              value={workers.invitation.email ? workers.invitation.email : ""}
              onChange={changeEmail}
            />
            <Form.Checkbox
              label="Grant admin permission"
              disabled={
                workers.invitation.generated ||
                workers.invitation.email.length < 8
              }
              checked={workers.invitation.is_admin_permission}
              onChange={() => {
                hideError();
                changeAdminPermission(!workers.invitation.is_admin_permission);
              }}
            />
            <Form.Checkbox
              label="Grant chat functionality to admin"
              disabled={
                workers.invitation.generated ||
                workers.invitation.email.length < 8
              }
              checked={workers.invitation.is_chat_admin_permission}
              onChange={() => {
                hideError();
                changeChatAdminPermission(
                  !workers.invitation.is_chat_admin_permission
                );
              }}
            />
            {!workers.invitation.generated && (
              <Button
                size="large"
                color="green"
                disabled={workers.invitation.email.length < 8}
                content="Generate invitation link"
                onClick={(e, data) => {
                  e.preventDefault();
                  createInvitation(workers.invitation);
                }}
              />
            )}
          </Form>
          {workers.invitation.generated === true && (
            <div>
              <CopyToClipboard
                text={invitationLink}
                onCopy={() => setState((state) => ({ ...state, copied: true }))}
              >
                <Grid>
                  <Grid.Column>
                    <Input
                      fluid
                      readOnly
                      value={invitationLink}
                      action={{
                        color: "teal",
                        labelPosition: "right",
                        icon: "copy",
                        content: "Copy",
                      }}
                    />
                    {state.copied ? "Copied" : ""}
                  </Grid.Column>
                </Grid>
              </CopyToClipboard>
              <Message positive>
                <Message.Header>
                  Invitation link was successfully generated
                </Message.Header>
                <p>
                  This invitation expires in <b>24</b> hours.
                </p>
              </Message>
              <Button
                onClick={() => {
                  clearFilter();
                }}
                size="large"
                content="Go to Dashboard"
              />
            </div>
          )}
        </Segment>
      </Dimmer.Dimmable>
    </Segment.Group>
  );
};

Invitation.propTypes = {
  workers: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    error: PropTypes.shape({
      visible: PropTypes.bool,
    }).isRequired,
    invitation: PropTypes.shape({
      generated: PropTypes.bool.isRequired,
      email: PropTypes.string.isRequired,
      is_admin_permission: PropTypes.bool.isRequired,
      is_chat_admin_permission: PropTypes.bool.isRequired,
    }).isRequired,
  }).isRequired,
  changeAdminPermission: PropTypes.func.isRequired,
  changeChatAdminPermission: PropTypes.func.isRequired,
  changeEmail: PropTypes.func.isRequired,
  createInvitation: PropTypes.func.isRequired,
};

export default Invitation;
