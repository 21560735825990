import React from "react";
import { useSelector } from "react-redux";
import { Button, Form, Grid } from "semantic-ui-react";
import { presetsState } from "../../store/selectors/fakeUsersSelectors";
import { useEffect } from "react";
import { useState } from "react";
import { commonConstants } from "../../constants/common";

const UserInfo = React.memo(
  ({ user, setActiveIndex, activeIndex, presets }) => {
    const options = {
      genderOptions: [],
      ethnicityOptions: [],
      zodiacSignsOptions: [],
      familyOptions: [],
      politicsOptions: [],
      religiousOptions: [],
      tagsOptions: [],
      ageOptions: [],
      heightOptions: [],
    };

    const {
      ageOptions,
      heightOptions,
      genderOptions,
      ethnicityOptions,
      zodiacSignsOptions,
      familyOptions,
      politicsOptions,
      religiousOptions,
      tagsOptions,
    } = options;

    if (presets) {
      const {
        ages,
        heights,
        genders,
        ethnicity,
        zodiacSigns,
        family,
        politics,
        religious,
        tags,
      } = presets;
      ageOptions.push(
        ...ages.map(({ id, age }, index) => ({
          key: index,
          value: id,
          text: age,
        }))
      );
      heightOptions.push(
        ...heights.map(({ id, height }, index) => ({
          key: index,
          value: id,
          text: height,
        }))
      );

      genderOptions.push(
        ...genders.map(({ id, gender }, index) => ({
          key: index,
          value: id,
          text: gender,
        }))
      );
      ethnicityOptions.push(
        ...ethnicity.map(({ id, ethnicity }, index) => ({
          key: index,
          value: id,
          text: ethnicity,
        }))
      );
      zodiacSignsOptions.push(
        ...zodiacSigns.map(({ id, zodiacSign }, index) => ({
          key: index,
          value: id,
          text: zodiacSign,
        }))
      );
      familyOptions.push(
        ...family.map(({ id, familyPlans }, index) => ({
          key: index,
          value: id,
          text: familyPlans,
        }))
      );
      politicsOptions.push(
        ...politics.map(({ id, politics }, index) => ({
          key: index,
          value: id,
          text: politics,
        }))
      );
      religiousOptions.push(
        ...religious.map(({ id, religious }, index) => ({
          key: index,
          value: id,
          text: religious,
        }))
      );
      tagsOptions.push(
        ...tags.map(({ id, tag }, index) => ({
          key: index,
          value: id,
          text: tag,
        }))
      );
    }

    const languagePresets = useSelector(presetsState);

    const languages = languagePresets?.value?.languages;
    const userLanguageCode = user?.language && user?.language.at(0);

    const userLanguage = languages?.filter(
      (item) => item.id === userLanguageCode
    )[0];

    // console.log(userLanguageCode);

    //console.log(user)

    const arr = String(user?.interestedIn);
    const intrestedInArr = arr?.split(",")?.map(function (element) {
    return parseInt(element, 10);
    });

    return (
      <Form className="showOption" style={{ padding: "3px 10px" }}>
        <div
          className="tab_content User_info_btn"
          style={{ marginBottom: "10px" }}
        >
          <Grid columns={2} className="tabContent">
            <Grid.Column onClick={() => setActiveIndex(0)}>
              {/* <span className={`active_btn ${activeIndex === 0 ? "Personal_active" : ""}`} style={{ borderRadius: '5px 0px 0px 5px', marginRight: "5px" }}>Personal</span> */}
              <Button
                className={`active_btn ${
                  activeIndex === 0 ? "Personal_active" : ""
                }`}
                style={{ borderRadius: "5px 0px 0px 5px", marginRight: "5px" }}
              >
                Personal
              </Button>
            </Grid.Column>
            <Grid.Column onClick={() => setActiveIndex(1)}>
              <Button
                className={`Hobbies_btn ${
                  activeIndex === 1 ? "Personal_active" : ""
                }`}
                style={{ borderRadius: "0px" }}
              >
                Hobbies
              </Button>
            </Grid.Column>
          </Grid>
        </div>

        {activeIndex === 0 ? (
          <div>
            {!user.isModerator && (
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  fontSize: "16px",
                  margin: "16px 0px",
                }}
              >
                <h4>Language :</h4> {userLanguage ? userLanguage?.language : ""}
              </div>
            )}
            <Form.Group
              widths="equal"
              className="fieldsUser"
              style={{ display: "block" }}
            >
              {/* <Form.Input fluid label={"Language"} value={realuserLangauge ? realuserLangauge?.language : ""} /> */}
              <Form.Input
                fluid
                label={"Address"}
                value={user?.address ? user?.address : ""}
              />
              <Form.Input
                fluid
                label={"City"}
                value={user?.address ? user?.city : ""}
              />
            </Form.Group>
            <div className="fieldsUser">
              <label>Desciption</label>
            </div>
            <div className="textbox">
              <Form.TextArea value={user?.about || ""} rows={4} />
            </div>

            <div className="fieldsUser">
              <label>Name : </label> {user?.fullName || ""}
            </div>

            <div className="fieldsUser">
              <label>Age : </label> {user?.age || ""}
            </div>
            <div className="fieldsUser flexView">
              <label>Gender : </label> 
              <Button
                      className={`male_btn gender_active`}
                    >
                      {user?.gender === 1 ? "Male" : user?.gender === 2 ? "Female" : "Other"}
                    </Button> 
            </div>
    
            
          <div >
            <Form.Group
              widths="equal"
              inline
              style={{ display: "flex", justifyContent: "space-between" }}
              className="fieldsUser"
            >
              <div style={{ width: "50%", marginRight: "10px" }}>
                <label>Height</label>
                <Form.Select
                  fluid
                  selection
                  options={heightOptions}
                  placeholder="Height"
                  value={user?.height}
                />
              </div>
              <div style={{ width: "50%" }}>
                <label>Ethnicity</label>
                <Form.Select
                  fluid
                  selection
                  options={ethnicityOptions}
                  placeholder="Ethnicity"
                  value={user?.ethinicity}
                />
              </div>
            </Form.Group>

            <Form.Group
              widths="equal"
              inline
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: 0,
              }}
              className="fieldsUser"
            >
              <div style={{ width: "50%", marginRight: "10px" }}>
                <label>Zodiac Sign</label>
                <Form.Select
                  fluid
                  selection
                  options={zodiacSignsOptions}
                  placeholder="Zodiac Sign"
                  value={user?.zodiacSign}
                />
              </div>
              <div style={{ width: "50%" }}>
                <label>Religious Beliefs</label>
                <Form.Select
                  fluid
                  selection
                  options={religiousOptions}
                  placeholder="Religious Beliefs"
                  value={user?.religion}
                />
              </div>
            </Form.Group>
          </div>
            <Form.Group widths="equal" inline className="fieldsUser">
              <div style={{ width: "50%", marginRight: "10px" }}>
                <Form.Input
                  fluid
                  label={"Education"}
                  // placeholder='Last Name'
                  value={user?.education}
                />
              </div>
              <div style={{ width: "50%" }}>
                <Form.Input
                  fluid
                  label={"Work"}
                  // placeholder='Last Name'
                  value={user?.work}
                />
              </div>
            </Form.Group>

            <Form.Group
              widths="equal"
              inline
              style={{ display: "flex", justifyContent: "space-between" }}
              className="fieldsUser"
            >
              <div style={{ width: "100%", marginRight: "10px" }}>
                <label>Family</label>
                <Form.Select
                  fluid
                  selection
                  options={familyOptions}
                  placeholder="Family Plans"
                  value={user?.familyPlans}
                />
              </div>
            </Form.Group>

            <Form.Group
              widths="equal"
              inline
              style={{ display: "flex", justifyContent: "space-between" }}
              className="fieldsUser"
            >

            <div style={{ width: "100%" }}>
                <label>Interested In</label>
                <Form.Select
                    fluid
                    multiple
                    search
                    selection
                    options={commonConstants.INTERESTED_IN_OPTIONS}
                    placeholder="Interested In"
                    value={
                     user?.interestedIn
                        ? intrestedInArr
                        : []
                    }
                  />
              </div>
              </Form.Group>


          </div>
        ) : activeIndex === 1 ? (
          <div>
            <Form.Group
              widths="equal"
              className="fieldsUser"
              style={{ display: "block" }}
            >
              <label style={{ paddingLeft: "10px" }}>Politics</label>
              <Form.Select
                fluid
                selection
                placeholder="Politics"
                options={politicsOptions}
                value={user?.politics}
              />
            </Form.Group>
            <Form.Group
              widths="equal"
              className="fieldsUser"
              style={{ display: "block" }}
            >
              <Form.Input
                fluid
                label={"Books"}
                // placeholder='Last Name'
                value={user?.books?.toString()}
              />
            </Form.Group>
            <Form.Group
              widths="equal"
              className="fieldsUser"
              style={{ display: "block" }}
            >
              <Form.Input
                fluid
                label={"Music"}
                // placeholder='Last Name'
                value={user?.music?.toString()}
              />
            </Form.Group>
            <Form.Group
              widths="equal"
              className="fieldsUser"
              style={{ display: "block" }}
            >
              <Form.Input
                fluid
                label={"Movies"}
                // placeholder='Last Name'
                value={user?.movies?.toString()}
              />
            </Form.Group>
            <Form.Group
              widths="equal"
              className="fieldsUser"
              style={{ display: "block" }}
            >
              <Form.Input
                fluid
                label={"Tv Shows"}
                // placeholder='Last Name'
                value={user?.tvShows?.toString()}
              />
            </Form.Group>
            <Form.Group
              widths="equal"
              className="fieldsUser"
              style={{ display: "block" }}
            >
              <Form.Input
                fluid
                label={"Sport Teams"}
                // placeholder='Last Name'
                value={user?.sportsTeams?.toString()}
              />
            </Form.Group>
          </div>
        ) : activeIndex === 2 ? (
          <div>
            {/* <Form.Group widths="equal" className="fieldsUser" style={{ display: "block" }}>
          <Form.Input
            fluid
            label={"Address 1"}
            // placeholder='Last Name'
            value={user?.address}
          />
        </Form.Group> */}
            {/* <Form.Group widths="equal" className="fieldsUser" style={{ display: "block" }}>
          <Form.Input
            fluid
            label={"Address 2"}
            // placeholder='Last Name'
            value={user?.address}
          />
        </Form.Group> */}
            {/* <Form.Group widths="equal" className="fieldsUser" style={{ display: "block" }}>
          <Form.Input
            fluid
            label={"Pincode"}
          // placeholder='Last Name'
          // value={this.props.fakeUsers.currentUser?.fullName.split(" ")[1] || ''}
          // onChange={this.props.changeLastName}
          />
        </Form.Group> */}
            <Form.Group
              widths="equal"
              className="fieldsUser"
              style={{ display: "block" }}
            >
              <Form.Input
                fluid
                label={"City"}
                // placeholder='Last Name'
                value={user?.city}
              />
            </Form.Group>
            <Form.Group
              widths="equal"
              className="fieldsUser"
              style={{ display: "block" }}
            >
              <Form.Input
                fluid
                label={"State"}
                // placeholder='Last Name'
                value={user?.state}
              />
            </Form.Group>
            <Form.Group
              widths="equal"
              className="fieldsUser"
              style={{ display: "block" }}
            >
              <Form.Input
                fluid
                label={"Country"}
                // placeholder='Last Name'
                value={user?.country}
              />
            </Form.Group>
          </div>
        ) : null}
      </Form>
    );
  }
);

export default UserInfo;
