export const moderatorsActionTypes = {
  GET_MODERATORS_LIST_REQUEST: "GET_MODERATORS_LIST_REQUEST",
  GET_MODERATORS_LIST_FAILURE: "GET_MODERATORS_LIST_FAILURE",
  GET_MODERATORS_LIST_SUCCESS: "GET_MODERATORS_LIST_SUCCESS",
  SEARCH_VALUE_CHANGE: "SEARCH_VALUE_CHANGE",
  SEARCH_MODERATORS_LIST_REQUEST: "SEARCH_MODERATORS_LIST_REQUEST",
  SEARCH_MODERATORS_LIST_FAILURE: "SEARCH_MODERATORS_LIST_FAILURE",
  SEARCH_MODERATORS_LIST_SUCCESS: "SEARCH_MODERATORS_LIST_SUCCESS",
  SORT_USERS_LIST: "SORT_USERS_LIST",
  REMOVE_USER_REQUEST: "REMOVE_USER_REQUEST",
  REMOVE_USER_SUCCESS: "REMOVE_USER_SUCCESS",
  REMOVE_USER_FAILURE: "REMOVE_USER_FAILURE",
  REMOVE_USER_REPORTS_REQUEST: "REMOVE_USER_REPORTS_REQUEST",
  REMOVE_USER_REPORTS_SUCCESS: "REMOVE_USER_REPORTS_SUCCESS",
  REMOVE_USER_REPORTS_FAILURE: "REMOVE_USER_REPORTS_FAILURE",
  EDIT_USER_MAIN_REQUEST: "EDIT_USER_MAIN_REQUEST",
  EDIT_USER_MAIN_SUCCESS: "EDIT_USER_MAIN_SUCCESS",
  EDIT_USER_MAIN_FAILURE: "EDIT_USER_MAIN_FAILURE",
  EDIT_USER_DETAILS_REQUEST: "EDIT_USER_DETAILS_REQUEST",
  EDIT_USER_DETAILS_SUCCESS: "EDIT_USER_DETAILS_SUCCESS",
  EDIT_USER_DETAILS_FAILURE: "EDIT_USER_DETAILS_FAILURE",
  CHANGE_NAME: "CHANGE_NAME",
  CHANGE_PHONE: "CHANGE_PHONE",
  CHANGE_EMAIL: "CHANGE_EMAIL",
  CHANGE_AGE: "CHANGE_AGE",
  CHANGE_GENDER: "CHANGE_GENDER",
  CHANGE_ABOUT: "CHANGE_ABOUT",
  REMOVE_IMAGE: "REMOVE_IMAGE",
  NEW_USER: "NEW_USER",
  CHANGE_NEW_USER_EMAIL: "CHANGE_NEW_USER_EMAIL",
  CREATE_USER_REQUEST: "CREATE_USER_REQUEST",
  CREATE_USER_SUCCESS: "CREATE_USER_SUCCESS",
  CREATE_USER_FAILURE: "CREATE_USER_FAILURE",
  BLOCK_USER_REQUEST: "BLOCK_USER_REQUEST",
  BLOCK_USER_SUCCESS: "BLOCK_USER_SUCCESS",
  BLOCK_USER_FAILURE: "BLOCK_USER_FAILURE",
  UNBLOCK_USER_REQUEST: "UNBLOCK_USER_REQUEST",
  UNBLOCK_USER_SUCCESS: "UNBLOCK_USER_SUCCESS",
  UNBLOCK_USER_FAILURE: "UNBLOCK_USER_FAILURE",
  SAVE_USER_REQUEST: "SAVE_USER_REQUEST",
  SAVE_USER_SUCCESS: "SAVE_USER_SUCCESS",
  SAVE_USER_FAILURE: "SAVE_USER_FAILURE",
  HIDE_ERROR: "HIDE_ERROR",
  CLEAR_MODERATORS: "CLEAR_MODERATORS",
  HIDE_ERROR: "HIDE_ERROR",

  GET_ALL_ADMINS: "GET_ALL_ADMINS",
  GET_USER_ADMINS: "GET_USER_ADMINS",

  GET_MODERATOR_ADMINS_ID: "GET_MODERATOR_ADMINS_ID",
  GET_MODERATOR_LIST_ADMINS_ID: "GET_MODERATOR_LIST_ADMINS_ID"
};
