import _ from "lodash";
import { adminsActionTypes } from "../constants/actions/admins";
import { sessionActionTypes } from "../constants/actions/session";

const initialState = {
  admins: [],
  next: "",
  previous: "",
  currentUser: {
    main: {},
    details: {},
  },
  newAdmin: {},
  loading: true,
  error: {
    visible: false,
    header: "",
    messages: [],
  },
  nextAdminsUrl: null,
  prevAdminsUrl: null,
  limit: 25,
  search: "",
  sortColoumn: "",
  direction: 0,
};

export default function users(state = initialState, action) {
  switch (action.type) {
    case sessionActionTypes.LOGIN_SUCCESS:
      return {
        ...state,
        error: {
          visible: false,
          header: "",
          messages: [],
        },
      };
    case adminsActionTypes.CLEAR_ADMIN:
      return {
        ...state,
        newAdmin: {
          ...state.newAdmin,
          _created: false,
        },
        currentUser: {
          ...state.currentUser,
          _updated: false,
        },
        loading: false,
      };
    case adminsActionTypes.GET_ADMINS_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case adminsActionTypes.GET_ADMINS_LIST_FAILURE:
      return {
        ...state,
        error: {
          ...state.error,
          header:
            action.data.header && action.data.header !== ""
              ? action.data.header
              : "",
          messages:
            action.data.errors && action.data.errors.length > 0
              ? action.data.errors
              : [],
          visible: true,
        },
        loading: false,
      };
    case adminsActionTypes.GET_ADMINS_LIST_SUCCESS:
      if (!action.data instanceof Array) {
        return state;
      }
      if (action.data.length === 0) {
        return state;
      }
      return {
        ...state,
        admins: action.data.admins,
        currentPageSize: action.data.currentPageSize,
        offset: action.data.offset,
        size: action.data.size,
        nextAdminsUrl: action.data.next,
        prevAdminsUrl: action.data.previous,
        error: {
          ...state.error,
          visible: false,
        },
        loading: false,
      };

    case adminsActionTypes.SEARCH_VALUE_CHANGE:
      return {
        ...state,
        search: action.data,
      };

    case adminsActionTypes.SEARCH_ADMINS_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case adminsActionTypes.SEARCH_ADMINS_LIST_FAILURE:
      return {
        ...state,
        error: {
          ...state.error,
          header:
            action.data.header && action.data.header !== ""
              ? action.data.header
              : "",
          messages:
            action.data.errors && action.data.errors.length > 0
              ? action.data.errors
              : [],
          visible: true,
        },
        loading: false,
      };
    case adminsActionTypes.SEARCH_ADMINS_LIST_SUCCESS:
      if (!action.data.results instanceof Array) {
        return { ...state, loading: false };
      }
      if (action.data.results.length === 0) {
        return { ...state, loading: false };
      }

      var admins = action.data.results;
      if (state.direction && state.direction === "ascending") {
        admins = _.sortBy(admins, "disabled");
      } else if (state.direction && state.direction === "descending") {
        admins = _.sortBy(admins, "disabled");
        admins = admins.reverse();
      }
      return {
        ...state,
        admins: admins,
        nextAdminsUrl: admins?.next,
        prevAdminsUrl: admins?.previous,
        error: {
          ...state.error,
          visible: false,
        },
        loading: false,
      };

    case adminsActionTypes.REMOVE_USER_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case adminsActionTypes.REMOVE_USER_FAILURE:
      return {
        ...state,
        error: {
          ...state.error,
          header:
            action.data.header && action.data.header !== ""
              ? action.data.header
              : "",
          messages:
            action.data.errors && action.data.errors.length > 0
              ? action.data.errors
              : [],
          visible: true,
        },
        loading: false,
      };

    case adminsActionTypes.REMOVE_USER_SUCCESS:
      return {
        ...state,
        users: state.users.filter((user, i) => {
          return user.uid !== action.data.uid;
        }),
        error: {
          ...state.error,
          visible: false,
        },
        loading: false,
      };

    case adminsActionTypes.REMOVE_USER_REPORTS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case adminsActionTypes.REMOVE_USER_REPORTS_FAILURE:
      return {
        ...state,
        error: {
          ...state.error,
          header:
            action.data.header && action.data.header !== ""
              ? action.data.header
              : "",
          messages:
            action.data.errors && action.data.errors.length > 0
              ? action.data.errors
              : [],
          visible: true,
        },
        loading: false,
      };

    case adminsActionTypes.REMOVE_USER_REPORTS_SUCCESS:
      return {
        ...state,
        users: state.users.map((user, i) => {
          if (user.uid === action.data.uid) {
            return { ...user, reports: 0 };
          }
          return user;
        }),
        error: {
          ...state.error,
          visible: false,
        },
        currentUser: {
          ...state.currentUser,
          main: {
            ...state.currentUser.main,
            reports: 0,
          },
        },
        loading: false,
      };

    case adminsActionTypes.NEW_USER:
      return {
        ...state,
        newAdmin: {
          email: "",
          _isValid: false,
        },
        error: {
          ...state.error,
          visible: false,
        },
      };
    case adminsActionTypes.CHANGE_NEW_USER_EMAIL:
      var emailRe = /[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}/gim;
      var isValid = emailRe.test(action.data);
      return {
        ...state,
        newAdmin: {
          ...state.newAdmin,
          email: action.data,
          _isValid: isValid,
        },
      };
    case adminsActionTypes.CREATE_USER_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case adminsActionTypes.CREATE_USER_SUCCESS:
      return {
        ...state,
        newAdmin: {
          ...state.newAdmin,
          _isValid: false,
          _created: true,
        },
        users: [...state.users, action.data.user],
        error: {
          ...state.error,
          visible: false,
        },
        loading: false,
      };
    case adminsActionTypes.CREATE_USER_FAILURE:
      return {
        ...state,
        newAdmin: {
          ...state.newAdmin,
          _created: false,
        },
        error: {
          ...state.error,
          header:
            action.data.header && action.data.header !== ""
              ? action.data.header
              : "",
          messages:
            action.data.errors && action.data.errors.length > 0
              ? action.data.errors
              : [],
          visible: true,
        },
        loading: false,
      };

    case adminsActionTypes.EDIT_USER_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case adminsActionTypes.EDIT_USER_DETAILS_SUCCESS:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          details: action.data.user,
        },
        error: {
          ...state.error,
          visible: false,
        },
        loading: false,
      };
    case adminsActionTypes.EDIT_USER_DETAILS_FAILURE:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          details: {},
        },
        error: {
          ...state.error,
          header:
            action.data.header && action.data.header !== ""
              ? action.data.header
              : "",
          messages:
            action.data.errors && action.data.errors.length > 0
              ? action.data.errors
              : [],
          visible: true,
        },
        loading: false,
      };

    case adminsActionTypes.EDIT_USER_MAIN_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case adminsActionTypes.EDIT_USER_MAIN_SUCCESS:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          main: action.data.user,
        },
        error: {
          ...state.error,
          visible: false,
        },
        loading: false,
      };

    case adminsActionTypes.EDIT_USER_MAIN_FAILURE:
      return {
        ...state,
        error: {
          ...state.error,
          header:
            action.data.header && action.data.header !== ""
              ? action.data.header
              : "",
          messages:
            action.data.errors && action.data.errors.length > 0
              ? action.data.errors
              : [],
          visible: true,
        },
        loading: false,
      };

    case adminsActionTypes.BLOCK_USER_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case adminsActionTypes.BLOCK_USER_FAILURE:
      return {
        ...state,
        error: {
          ...state.error,
          header:
            action.data.header && action.data.header !== ""
              ? action.data.header
              : "",
          messages:
            action.data.errors && action.data.errors.length > 0
              ? action.data.errors
              : [],
          visible: true,
        },
        loading: false,
      };

    case adminsActionTypes.BLOCK_USER_SUCCESS:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          main: {
            ...state.currentUser.main,
            is_blocked: true,
          },
        },
        users: state?.users?.map((user, i) => {
          if (user.uid === state.currentUser.main.uid) {
            return {
              ...user,
              is_blocked: true,
            };
          }
          return user;
        }),
        loading: false,
      };

    case adminsActionTypes.UNBLOCK_USER_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case adminsActionTypes.UNBLOCK_USER_FAILURE:
      return {
        ...state,
        error: {
          ...state.error,
          header:
            action.data.header && action.data.header !== ""
              ? action.data.header
              : "",
          messages:
            action.data.errors && action.data.errors.length > 0
              ? action.data.errors
              : [],
          visible: true,
        },
        loading: false,
      };

    case adminsActionTypes.UNBLOCK_USER_SUCCESS:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          main: {
            ...state.currentUser.main,
            is_blocked: false,
          },
        },
        users: state?.users?.map((user, i) => {
          if (user.uid === state.currentUser.main.uid) {
            return {
              ...user,
              is_blocked: false,
            };
          }
          return user;
        }),
        loading: false,
      };

    case adminsActionTypes.SAVE_USER_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case adminsActionTypes.SAVE_USER_FAILURE:
      return {
        ...state,
        error: {
          ...state.error,
          header:
            action.data.header && action.data.header !== ""
              ? action.data.header
              : "",
          messages:
            action.data.errors && action.data.errors.length > 0
              ? action.data.errors
              : [],
          visible: true,
        },
        loading: false,
      };

    case adminsActionTypes.SAVE_USER_SUCCESS:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          _updated: true,
        },
        users: state.users.map((user, i) => {
          if (user.uid === state.currentUser.main.uid) {
            return state.currentUser.main;
          }
          return user;
        }),
        loading: false,
      };

    case adminsActionTypes.CHANGE_NAME:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          main: {
            ...state.currentUser.main,
            display_name: action.data,
          },
          details: {
            ...state.currentUser.details,
            name: action.data,
          },
        },
      };

    case adminsActionTypes.CHANGE_AGE:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          main: {
            ...state.currentUser.main,
            age: Number(action.data),
          },
        },
      };

    case adminsActionTypes.CHANGE_EMAIL:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          main: {
            ...state.currentUser.main,
            email: action.data,
          },
        },
      };

    case adminsActionTypes.CHANGE_PHONE:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          main: {
            ...state.currentUser.main,
            phone: action.data,
          },
        },
      };

    case adminsActionTypes.CHANGE_ABOUT:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          main: {
            ...state.currentUser.main,
            about: action.data,
          },
        },
      };

    case adminsActionTypes.CHANGE_GENDER:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          main: {
            ...state.currentUser.main,
            gender: action.data,
          },
        },
      };

    case adminsActionTypes.REMOVE_IMAGE:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          details: {
            ...state.currentUser.details,
            photos: state.currentUser.details.photos.filter((photo, i) => {
              return photo !== action.data;
            }),
          },
        },
      };

    case adminsActionTypes.HIDE_ERROR:
      return {
        ...state,
        error: {
          ...state.error,
          visible: false,
        },
      };
    case adminsActionTypes.UPDATE_ADMINS_STORE_FIELD:
      return {
        ...state,
        [action.data.field]: action.data.value,
      };
    default:
      return state;
  }
}
