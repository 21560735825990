import { moderatorsActionTypes } from "../../constants/actions/moderators";
import { sessionActionTypes } from "../../constants/actions/session";
import { Api } from "../../utils/api";

var messageHeader = "API request failed";

export function getModeratorsList(
  offset,
  ordered,
  orderedField,
  fields,
  search,
  currentPage
) {
  return function (dispatch) {
    dispatch({
      type: moderatorsActionTypes.GET_MODERATORS_LIST_REQUEST,
    });
    const limit = 28;
    return Api.getModerators(
      limit,
      offset,
      ordered,
      orderedField,
      "/moderator/",
      fields,
      search
    )
      .then((response) => {
        const errorMessage =
          "Unknown error. HTTP Status code: " + response.status;
        if (response.status === 401) {
          dispatch({
            type: moderatorsActionTypes.GET_MODERATORS_LIST_FAILURE,
            data: { errors: ["Authorization failed"], header: messageHeader },
          });
          dispatch({
            type: sessionActionTypes.LOGOUT_REQUEST,
          });
          return;
        }
        if (response.status !== 200) {
          dispatch({
            type: moderatorsActionTypes.GET_MODERATORS_LIST_FAILURE,
            data: { errors: [errorMessage], header: messageHeader },
          });
          return;
        }
        response
          .json()
          .then((json) => {
            if (!json.results) {
              dispatch({
                type: moderatorsActionTypes.GET_MODERATORS_LIST_FAILURE,
                data: {
                  errors: ["Unexpected API response"],
                  header: messageHeader,
                },
              });
              return;
            }
            dispatch({
              type: moderatorsActionTypes.GET_MODERATORS_LIST_SUCCESS,
              data: {
                moderators: json.results,
                next: json.next,
                previous: json.previous,
                currentPageSize: currentPage,
                offset: "",
                filter: fields,
                size: json.count,
              },
            });
          })
          .catch((error) => {
            dispatch({
              type: moderatorsActionTypes.GET_MODERATORS_LIST_FAILURE,
              data: { errors: [error], header: messageHeader },
            });
            return error;
          });
      })
      .catch((error) => {
        dispatch({
          type: moderatorsActionTypes.GET_MODERATORS_LIST_FAILURE,
          data: { errors: [error], header: messageHeader },
        });
      });
  };
}

export function onSearchValueChange(value) {
  return function (dispatch) {
    dispatch({
      type: moderatorsActionTypes.SEARCH_VALUE_CHANGE,
      data: value,
    });
    // if (value === "") {
    //   getModeratorsList()(dispatch);
    // }
  };
}

export function searchModeratorsList(value) {
  return function (dispatch) {
    dispatch({
      type: moderatorsActionTypes.SEARCH_MODERATORS_LIST_REQUEST,
    });

    return Api.searchModerators(value, "/moderator/")
      .then((response) => {
        var errorMessage =
          "Unknown error. HTTP Status code: " + response.status;
        if (response.status === 401) {
          dispatch({
            type: moderatorsActionTypes.SEARCH_MODERATORS_LIST_FAILURE,
            data: { errors: ["Authorization failed"], header: messageHeader },
          });
          dispatch({
            type: sessionActionTypes.LOGOUT_REQUEST,
          });
          return;
        }
        if (response.status !== 200) {
          dispatch({
            type: moderatorsActionTypes.SEARCH_MODERATORS_LIST_FAILURE,
            data: { errors: [errorMessage], header: messageHeader },
          });
          return;
        }
        response
          .json()
          .then((json) => {
            if (!json) {
              dispatch({
                type: moderatorsActionTypes.SEARCH_MODERATORS_LIST_FAILURE,
                data: {
                  errors: ["Unexpected API response"],
                  header: messageHeader,
                },
              });
              return;
            }
            dispatch({
              type: moderatorsActionTypes.SEARCH_MODERATORS_LIST_SUCCESS,
              data: json,
            });
          })
          .catch((error) => {
            dispatch({
              type: moderatorsActionTypes.SEARCH_MODERATORS_LIST_FAILURE,
              data: { errors: [error], header: messageHeader },
            });
            return error;
          });
      })
      .catch((error) => {
        console.log("fail", error);
        dispatch({
          type: moderatorsActionTypes.SEARCH_MODERATORS_LIST_FAILURE,
          data: { errors: [error], header: messageHeader },
        });
      });
  };
}

export function clearModerator() {
  return function (dispatch) {
    dispatch({
      type: moderatorsActionTypes.CLEAR_MODERATORS,
    });
  };
}

export const hideError = () => {
  return function (dispatch) {
    dispatch({
      type: moderatorsActionTypes.HIDE_ERROR,
    });
  };
};

export const getAllAdmins = () => {
  return function (dispatch) {
    Api.getAllAdmins().then((res) => {
      dispatch({
        type: moderatorsActionTypes.GET_ALL_ADMINS,
        data: res,
      });
    });
  };
};

export const getUserByAdmin = (id) => {
  return function (dispatch) {
    Api.getUserByAdmin(id).then((data) => {
      dispatch({
        type: moderatorsActionTypes.GET_USER_ADMINS,
        data: data,
      });
    });
  };
};

export const getModeratorByAdminId = (id) => {
  return function (dispatch) {
    Api.getModeratorByAdminId(id).then((data) => {
      dispatch({
        type: moderatorsActionTypes.GET_MODERATOR_ADMINS_ID,
        data: data,
      });
    });
  };
};

export const getModeratorListByAdminId = (id) => {
  return function (dispatch) {
    Api.getCreatedByListByAdminId(id, "moderator").then((data) => {
      dispatch({
        type: moderatorsActionTypes.GET_MODERATOR_LIST_ADMINS_ID,
        data: data,
      });
    });
  };
};
