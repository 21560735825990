import React from "react";
import Slider from "./slider";
import "./slider.css";
import Photos_4 from "../../../assets/images/Rectangle 18.png";
import { baseUrl } from "../../../utils/api";
import { getImageUrl } from "../../../utils/helpers";

export default class SliderParent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="parent">
        {this?.props?.data?.length ? (
          <Slider>
            <div className="child slider_content">
              {this?.props?.data?.slice(0, 7).map((item) => {
                let file = item?.file || item?.url;
                // let image = item?.node?.thumbnail
                //   ? `/media/${item?.node?.thumbnail}`
                //   : file?.includes("media")
                //   ? file
                //   : `/media/${file}`;
                // console.log("🚀 ~ file: sliderParent.js:25 ~ {this?.props?.data?.slice ~ image:", image);
                // src={image?.includes(baseUrl) ? image : baseUrl + image}
                // const imageReplace = file?.replaceAll(`${baseUrl}/media/`, "");
                return file || item?.node?.thumbnail ? (
                  <img
                    width={"95px"}
                    height={"95px"}
                    src={getImageUrl(file)}
                    alt=""
                    key={item?.id}
                    onClick={() => this?.props?.onSelect(item)}
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null; // prevents looping
                      currentTarget.src = Photos_4;
                    }}
                  />
                ) : null;
              })}
            </div>
          </Slider>
        ) : null}
      </div>
    );
  }
}
