import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setActiveMenuPosition } from '../../../actions/menu'
import { logoutAction } from '../../../actions/session'
import MainMenu from '../../../components/mainMenu'
import Chat from "../chatUser"
const ChatUser = () => {
    const { menu, users, session, chat } = useSelector(state => state)

    const dispatch = useDispatch()
    return (
        <div>
            <MainMenu
                needShowPrivateItems={session.isAuthenticated}
                menu={menu}
                onLogout={() => dispatch(logoutAction)}
                setActiveMenuPosition={() => dispatch(setActiveMenuPosition)}
                userDetails={session.userDetails}
            />
            <Chat />
            {/* <Users
            users={this.props.users}
            onRemove={this.props.removeUser}
            getUsersList={this.props.getUsersList}
            hideError={this.props.hideError}
            chat={this.props.chat}
            setActiveMenuPosition={this.props.setActiveMenuPosition}
            startNewChat={this.props.startNewChat}
            sortUsersList={this.props.sortUsersList}
            onSearchValueChange={this.props.onSearchValueChange}
            searchUsersList={this.props.searchUsersList}
            userDetails={this.props.session.userDetails}
        /> */}
        </div>
    )
}

export default ChatUser