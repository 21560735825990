import React, { useEffect, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import {
  Button,
  Dimmer,
  Dropdown,
  Icon,
  Image,
  Input,
  Loader,
  Modal,
  Segment,
  Popup,
  Grid,
  Checkbox,
} from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import ErrorMessage from "../errorMessage";
import { getToken, isAllowedRole } from "../../utils/auth";
import { convertArrayToHashMap } from "../../utils/helpers";
import { commonConstants } from "../../constants/common";
import Moderators from "../moderators/list";

import { clearModerator, onSearchValueChange } from "../../actions/moderators";

import searchIcon from "../../assets/images/searchIcon.png";
import collection from "../../assets/images/collection.png";
import downArrow from "../../assets/images/downArrow.png";
import UserList from "../common/userList";
import cancelVector from "../../assets/images/closeBlackVector.png";
import ReactPaginate from "react-paginate";
import { getPresets } from "../../actions/fakeUsers";
import { getUsersList } from "../../actions/users";
import moment from "moment";
import UserApi from "../../utils/users";
import "./Users.css";

const SortComponent = ({ offset, users }) => {
  const [filterMenu, setFilterMenu] = useState(false);
  const [filter, setFilter] = useState({});
  const [countries, setCountries] = useState([]);
  const dispatch = useDispatch();
  const {
    fakeUsers: { presets },
  } = useSelector((state) => state);

  const getAllCountries = async () => {
    const allCountries = await UserApi.allCountries();
    setCountries(allCountries?.data?.allCountries);
  };

  useEffect(() => {
    dispatch(getPresets());
    getAllCountries();
  }, []);

  const ageOptions = presets?.ages?.map(({ id, age }, index) => ({
    key: index,
    value: id,
    text: age,
  }));
  const ethnicityOptions = presets?.ethnicity?.map(
    ({ id, ethnicity }, index) => ({
      key: index,
      value: id,
      text: ethnicity,
    })
  );
  const countryOptions = countries?.map(({ id, fullName }, index) => ({
    key: index,
    value: id,
    text: fullName,
  }));

  const onFilter = () => {
    let filterString = [];
    for (const key in filter) {
      filterString.push({ key, value: filter[key] });
    }

    dispatch(
      getUsersList(offset, users?.direction, users?.sort_column, filterString)
    );
    setFilterMenu(false);
  };

  const hourArray = Array.from({ length: 24 }).map((_, index) => ({
    key: index,
    text: `${index + 1} ${index === 0 ? "hour" : 'hours'} ago`,
    value: moment().subtract(index + 1, "hours").toISOString(),
  }))

  const dayArray = [
    { key: 0, text: "Today", value: moment().toISOString() },
    {
      key: 1,
      text: "Yesterday",
      value: moment().subtract(1, "days").toISOString(),
    },
    {
      key: 2,
      text: "2 day's ago",
      value: moment().subtract(2, "days").toISOString(),
    },
    {
      key: 3,
      text: "3 day's ago",
      value: moment().subtract(3, "days").toISOString(),
    },
    {
      key: 4,
      text: "4 day's ago",
      value: moment().subtract(4, "days").toISOString(),
    },
    {
      key: 5,
      text: "5 day's ago",
      value: moment().subtract(5, "days").toISOString(),
    },
    {
      key: 6,
      text: "6 day's ago",
      value: moment().subtract(6, "days").toISOString(),
    },
    {
      key: 7,
      text: "7 day's ago",
      value: moment().subtract(7, "days").toISOString(),
    },
    {
      key: 8,
      text: "2 weeks ago",
      value: moment().subtract(2, "weeks").toISOString(),
    },
    {
      key: 9,
      text: "3 weeks ago",
      value: moment().subtract(3, "weeks").toISOString(),
    },
    {
      key: 10,
      text: "1 month ago",
      value: moment().subtract(1, "month").toISOString(),
    },
  ];

  const getDayLabel = (value) => {
    switch (value) {
      case 0:
        return "Today";
      case 1:
        return "Yesterday";
      default:
        const filterVal = value <= 7 ? value : value <= 14 ? "2 weeks" : value <= 21 ? "3 weeks" : value < 32 ? "1 month" : undefined;
        return dayArray?.find((item) => item?.text.includes(filterVal))?.text;
    }
  };

  const clearFilter = () => {
    window.location.reload();
  };

  return (
    <Popup
      on={"click"}
      position="bottom right"
      open={filterMenu}
      onOpen={() => setFilterMenu(true)}
      onClose={() => setFilterMenu(false)}
      trigger={
        <Dropdown
          text="Sort by"
          className="icon sortBox"
          style={{ marginRight: "20px" }}
          floating
          labeled
          button
          icon={
            <div className="iconBox">
              <Image src={downArrow} width="10px" height="5px" />
            </div>
          }
        />
      }
    >
      <div style={{ width: "800px" }}>
        <Grid className="filterMenu">
          <Grid.Row>
            <Grid.Column width={7}>
              <div style={{
                display: "flex",
                justifyContent: "space-between",
                width: "70%",
              }}>

                <Checkbox
                  className="radioBox"
                  label="Online Users"
                  checked={filter?.s_online === true}
                  onClick={() => setFilter({ ...filter, s_online: true })}
                />
                <Checkbox
                  className="radioBox"
                  label="Offline Users"
                  checked={filter?.s_online === false}
                  onClick={() => setFilter({ ...filter, s_online: false })}
                />
              </div>
            </Grid.Column>
            <Grid.Column width={9}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
                className="dropContainer"
              >
                <p>Last Login:</p>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                  className="dropdownView"
                >
                  <Dropdown
                    style={{ width: '140px' }}
                    text={`${hourArray.find((item) =>
                      item.text.includes(
                        parseInt(
                          Math.abs(
                            new Date(filter.last_seen_to) -
                            new Date(filter.last_seen_from)
                          ) / 3600000
                        ).toString()
                      )
                    )?.text || "By Hour"
                      }`}
                    selection
                    options={hourArray}
                    onChange={(e, data) =>
                      setFilter({
                        ...filter,
                        last_seen_from: data?.value,
                        last_seen_to: moment().toISOString(),
                      })
                    }
                    className="icon filterBox"
                    floating
                    labeled
                    button
                    icon={
                      <div className="iconBox">
                        <Image src={downArrow} width="10px" height="5px" />
                      </div>
                    }
                  ></Dropdown>
                  <Dropdown
                    style={{ width: '140px' }}
                    text={`${getDayLabel(
                      parseInt(
                        Math.abs(
                          new Date(filter.last_seen_to) -
                          new Date(filter.last_seen_from)
                        ) / 86400000
                      )
                    ) || "By Day"
                      }`}
                    selection
                    options={dayArray}
                    onChange={(e, data) =>
                      setFilter({
                        ...filter,
                        last_seen_from: data?.value,
                        last_seen_to: moment().toISOString(),
                      })
                    }
                    className="icon filterBox"
                    floating
                    labeled
                    button
                    icon={
                      <div className="iconBox">
                        <Image src={downArrow} width="10px" height="5px" />
                      </div>
                    }
                  ></Dropdown>
                </div>
              </div>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={7}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: "25px",
                }}
                className="dropContainer"
              >
                <p>By Age:</p>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                  className="dropdownView"
                >
                  <Dropdown
                    selection
                    options={ageOptions}
                    onChange={(e, data) => {
                      setFilter({ ...filter, age_from: data?.value });
                    }}
                    text={`${ageOptions?.find(
                      (item) => item?.value === filter?.age_from
                    )?.text || "From"
                      }`}
                    value={filter?.age_from}
                    className="icon filterBox"
                    floating
                    labeled
                    button
                    icon={
                      <div className="iconBox">
                        <Image src={downArrow} width="10px" height="5px" />
                      </div>
                    }
                  ></Dropdown>
                  <Dropdown
                    selection
                    onChange={(e, data) => {
                      setFilter({ ...filter, age_to: data?.value });
                    }}
                    options={ageOptions}
                    text={`${ageOptions?.find((item) => item?.value === filter?.age_to)
                      ?.text || "To"
                      }`}
                    value={filter?.age_to}
                    className="icon filterBox"
                    floating
                    labeled
                    button
                    icon={
                      <div className="iconBox">
                        <Image src={downArrow} width="10px" height="5px" />
                      </div>
                    }
                  ></Dropdown>
                </div>
              </div>
            </Grid.Column>
            <Grid.Column width={9}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
                className="dropContainer"
              >
                <p>Joined Date:</p>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                  className="dropdownView"
                >
                  <Dropdown
                    style={{ width: '140px' }}
                    text={`${hourArray.find((item) =>
                      item.text.includes(
                        parseInt(
                          Math.abs(
                            new Date(filter.join_date_to) -
                            new Date(filter.join_date_from)
                          ) / 3600000
                        ).toString()
                      )
                    )?.text || "By Hour"
                      }`}
                    selection
                    options={hourArray}
                    onChange={(e, data) =>
                      setFilter({
                        ...filter,
                        join_date_from: data?.value,
                        join_date_to: moment().toISOString(),
                      })
                    }
                    className="icon filterBox"
                    floating
                    labeled
                    button
                    icon={
                      <div className="iconBox">
                        <Image src={downArrow} width="10px" height="5px" />
                      </div>
                    }
                  ></Dropdown>
                  <Dropdown
                    style={{ width: '140px' }}
                    text={`${getDayLabel(
                      parseInt(
                        Math.abs(
                          new Date(filter.join_date_to) -
                          new Date(filter.join_date_from)
                        ) / 86400000
                      )
                    ) || "By Day"
                      }`}
                    selection
                    options={dayArray}
                    onChange={(e, data) =>
                      setFilter({
                        ...filter,
                        join_date_from: data?.value,
                        join_date_to: moment().toISOString(),
                      })
                    }
                    className="icon filterBox"
                    floating
                    labeled
                    button
                    icon={
                      <div className="iconBox">
                        <Image src={downArrow} width="10px" height="5px" />
                      </div>
                    }
                  ></Dropdown>
                </div>
              </div>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={7}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: "25px",
                }}
                className="dropContainer"
              >
                <p>Gender</p>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "70%",
                  }}
                >
                  <Checkbox
                    className="radioBox"
                    label="Male"
                    checked={filter?.gender === 1}
                    onClick={() => setFilter({ ...filter, gender: 1 })}
                  />
                  <Checkbox
                    className="radioBox"
                    label="Female"
                    checked={filter?.gender === 2}
                    onClick={() => setFilter({ ...filter, gender: 2 })}
                  />
                  <Checkbox
                    className="radioBox"
                    label="Both"
                    checked={filter?.gender === 3}
                    onClick={() => setFilter({ ...filter, gender: 3 })}
                  />
                </div>
              </div>
            </Grid.Column>
            <Grid.Column width={9}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
                className="dropContainer"
              >
                <p>By Country:</p>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "80%",
                  }}
                  className="dropdownView country-dropdown"
                >
                  <Dropdown
                    selection
                    options={countryOptions}
                    onChange={(e, data) => {
                      setFilter({
                        ...filter,
                        country: countryOptions.find(
                          (item) => item.value === data?.value
                        )?.text,
                      });
                    }}
                    text={`${countryOptions?.find(
                      (item) => item?.text === filter?.country
                    )?.text || "Select here"
                      }`}
                    value={filter?.country}
                    className="icon filterBox"
                    floating
                    labeled
                    button
                    icon={
                      <div className="iconBox">
                        <Image src={downArrow} width="10px" height="5px" />
                      </div>
                    }
                  ></Dropdown>
                  <p style={{ margin: "0 12px" }}>Or</p>
                  <Input
                    fluid
                    placeholder="Search Country"
                    className="filterInput searchBox"
                  />
                </div>
              </div>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={7}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-Start",
                  alignItems: "center",
                }}
              >
                <Button
                  className="ui blue"
                  onClick={() => {
                    clearFilter();
                  }}
                >
                  Clear Filter
                </Button>
                <Button
                  className="filterButton"
                  onClick={() => {
                    onFilter();
                  }}
                >
                  Filter
                </Button>
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    </Popup>
  );
};

const ModeratorListModal = ({
  getModeratorsList,
  chat,
  onSearchValue,
  userDetails,
  addFakeUsers,
  selectChatFakeUser,
  startNewChat,
  setOpen,
  open,
  user,
}) => {
  const { moderators } = useSelector((state) => state);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearModerator());
    getModeratorsList(
      [],
      moderators.offset,
      moderators.ordered,
      moderators.orderedField,
      moderators.direction,
      15
    );
    if (moderators.moderators && moderators.moderators.length === 0) {
    }
  }, [open]);

  return (
    <Modal
      onClose={() => {
        onSearchValue("");
        setOpen(false);
      }}
      onOpen={() => setOpen(true)}
      open={open}
      size={"large"}
      style={{ height: window.innerHeight - 60, overflowY: "scroll" }}
    >
      <div style={{ margin: "15px 20px" }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <p
            style={{
              fontSize: "20px",
              fontWeight: "700",
              color: "#333333",
              marginTop: "10px",
            }}
          >
            Select “MODERATOR”
          </p>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Image
              src={cancelVector}
              height={"25px"}
              width={"25px"}
              onClick={() => setOpen(false)}
            />
          </div>
        </div>
        <Moderators
          moderators={moderators}
          getModeratorsList={getModeratorsList}
          chat={chat}
          onSearchValueChange={(e, data) => {
            dispatch(onSearchValueChange(data?.value));
          }}
          modal={true}
          userDetails={userDetails}
          addFakeUsers={addFakeUsers}
          selectChatFakeUser={selectChatFakeUser}
          startNewChat={startNewChat}
          user={user}
          showPopup={false}
        />
      </div>
    </Modal>
  );
};

const Users = (props) => {
  const [state, setState] = useState({
    open: false,
    currentPage: 0,
    filterMenu: false,
    gender: -1,
    languagePresets: {},
    heightPresets: {},
    subscriptionPresets: {},
    ethnicityPresets: {},
    familyPresets: {},
  });

  const onKeyPress = (e) => {
    if (e.key !== "Enter") {
      return;
    }

    if (!props.users.search || props.users.search.length < 3) {
      return;
    }

    props.searchUsersList(props.users.search);
  };

  const handlePageClick = (data) => {
    setState({ ...state, currentPage: data?.selected });
    props.getUsersList(
      data?.selected * 28,
      props.users.direction,
      props.users.sort_column
    );
  };

  useEffect(() => {
    if (!props.presets.loading) {
      setState((state) => ({
        ...state,
        languagePresets: convertArrayToHashMap(
          props?.presets?.value?.languages ?? []
        ),
        heightPresets: convertArrayToHashMap(
          props?.presets?.value?.heights ?? []
        ),
        subscriptionPresets: convertArrayToHashMap(
          props?.presets?.value?.subscriptions ?? []
        ),
        ethnicityPresets: convertArrayToHashMap(
          props?.presets?.value?.ethnicity ?? []
        ),
        familyPresets: convertArrayToHashMap(
          props?.presets?.value?.family ?? []
        ),
      }));
    }
  }, [props.presets.loading]);

  const isAdmin = props?.userDetails?.privileges.includes("ADMIN")
    ? true
    : false;

  return (
    <div>
      {!props.modal ? (
        <Dimmer.Dimmable dimmed={props.users.loading}>
          <Dimmer active={props.users.loading} inverted>
            <Loader></Loader>
          </Dimmer>
          {isAllowedRole(
            [commonConstants.SUPER_ADMIN_ROLE],
            props.userDetails
          ) && (
              <Segment textAlign="center" basic>
                <Link to={"/users/new/"}>
                  <Button size="large" color="blue" content="New user" />
                </Link>
                <a
                  href={
                    window.location.origin +
                    "/internal/users.csv?token=" +
                    getToken()
                  }
                >
                  <Button size="large" color="orange" content="Export users" />
                </a>
              </Segment>
            )}
          {props.users.error && props.users.error.visible && (
            <ErrorMessage
              error={props.users.error}
              hideError={props.hideError}
            />
          )}
          <div style={{ padding: "0px 30px 0px 23px" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-start",
                padding: "35px 0px",
              }}
            >
              <div style={{ display: "flex", width: "100%" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "35%",
                    position: "relative",
                  }}
                >
                  <Input
                    fluid
                    icon={
                      <Icon
                        name="search"
                        circular
                        inverted={props.users.search.length >= 3}
                        link={props.users.search.length >= 3}
                        onClick={() => {
                          props.searchUsersList(props.users.search);
                        }}
                      />
                    }
                    value={props.users.search}
                    onChange={props.onSearchValueChange}
                    onKeyPress={onKeyPress}
                    placeholder="Search..."
                    className="searchBox"
                    style={{ width: "100%", alignItems: "center" }}
                  />
                </div>
              </div>
              <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={Math.ceil(props.users?.size / 28)}
                marginPagesDisplayed={2}
                pageRangeDisplayed={2}
                onPageChange={handlePageClick}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
                disabledLinkClassName="disableLink"
                nextLinkClassName="nextLink"
                previousLinkClassName="previousLink"
                forcePage={state.currentPage}
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div>
                <p className="userText">Users Listing</p>
                <p className="pageText">
                  Showing results{" "}
                  {(state?.currentPage + 1) * 28 > props.users?.size
                    ? props.users?.size
                    : (state?.currentPage + 1) * 28}{" "}
                  of {props.users?.size}{" "}
                </p>
              </div>
              <div style={{ display: "flex" }}>
                <SortComponent
                  offset={state?.currentPage * 28}
                  users={this?.props?.users}
                />
                <Dropdown
                  text="Collection Type"
                  className="icon sortBox"
                  floating
                  labeled
                  button
                  icon={
                    <div className="iconBox" style={{ paddingTop: "10px" }}>
                      <Image src={collection} width="12px" height="15px" />
                    </div>
                  }
                >
                  <Dropdown.Menu className="left">
                    <Dropdown.Item>
                      <Icon name="dropdown" />
                      <span className="text">Left</span>
                      <Dropdown.Menu>
                        <Dropdown.Item>
                          <Icon name="dropdown" />
                          <span className="text">Still Left</span>
                          <Dropdown.Menu>
                            <Dropdown.Item>1</Dropdown.Item>
                            <Dropdown.Item>2</Dropdown.Item>
                            <Dropdown.Item>3</Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown.Item>
                        <Dropdown.Item>2</Dropdown.Item>
                        <Dropdown.Item>3</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Icon name="left dropdown" />
                      <span className="text">Left</span>
                      <Dropdown.Menu>
                        <Dropdown.Item>1</Dropdown.Item>
                        <Dropdown.Item>2</Dropdown.Item>
                        <Dropdown.Item>3</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
            <UserList
              users={props.users?.users}
              isAdmin={isAdmin}
              columns={5}
              editPath={"/users/edit/"}
              onChat={(user) => {
                setState((state) => ({ ...state, open: true }));
                setState((state) => ({ ...state, user: user }));
              }}
              languagePresets={state.languagePresets}
              heightPresets={state.heightPresets}
              subscriptionPresets={state.subscriptionPresets}
              ethnicityPresets={state.ethnicityPresets}
              familyPresets={state.familyPresets}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "35px 0px",
              }}
            >
              <div style={{ display: "flex", width: "100%" }}></div>
              <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={Math.ceil(props.users?.size / 28)}
                marginPagesDisplayed={2}
                pageRangeDisplayed={2}
                onPageChange={handlePageClick}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
                disabledLinkClassName="disableLink"
                nextLinkClassName="nextLink"
                previousLinkClassName="previousLink"
                forcePage={state.currentPage}
              />
            </div>
          </div>
        </Dimmer.Dimmable>
      ) : (
        <Dimmer.Dimmable>
          <div style={{ padding: "0px 30px 0px 23px" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-start",
                padding: "35px 0px",
              }}
            >
              <div style={{ display: "flex", width: "100%" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "35%",
                    position: "relative",
                  }}
                >
                  <Input
                    fluid
                    icon={
                      <Image
                        src={searchIcon}
                        width="18px"
                        height="18px"
                        style={{ position: "absolute", right: "2%" }}
                      />
                    }
                    value={props.users.search}
                    onChange={props.onSearchValueChange}
                    onKeyPress={onKeyPress}
                    placeholder="Search..."
                    className="searchBox"
                    style={{ width: "100%", alignItems: "center" }}
                  />
                </div>
              </div>
              <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={Math.ceil(props.users?.size / 28)}
                marginPagesDisplayed={2}
                pageRangeDisplayed={2}
                onPageChange={handlePageClick}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
                disabledLinkClassName="disableLink"
                nextLinkClassName="nextLink"
                previousLinkClassName="previousLink"
                forcePage={state.currentPage}
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div>
                <p className="userText">Users Listing</p>
                <p className="pageText">
                  Showing results{" "}
                  {(state?.currentPage + 1) * 28 > props.users?.size
                    ? props.users?.size
                    : (state?.currentPage + 1) * 28}{" "}
                  of {props.users?.size}{" "}
                </p>
              </div>
              <div style={{ display: "flex" }}>
                <SortComponent
                  offset={state?.currentPage * 28}
                  users={this?.props?.users}
                />
                <Dropdown
                  text="Collection Type"
                  className="icon sortBox"
                  floating
                  labeled
                  button
                  icon={
                    <div className="iconBox" style={{ paddingTop: "10px" }}>
                      <Image src={collection} width="12px" height="15px" />
                    </div>
                  }
                >
                  <Dropdown.Menu className="left">
                    <Dropdown.Item>
                      <Icon name="dropdown" />
                      <span className="text">Left</span>
                      <Dropdown.Menu>
                        <Dropdown.Item>
                          <Icon name="dropdown" />
                          <span className="text">Still Left</span>
                          <Dropdown.Menu>
                            <Dropdown.Item>1</Dropdown.Item>
                            <Dropdown.Item>2</Dropdown.Item>
                            <Dropdown.Item>3</Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown.Item>
                        <Dropdown.Item>2</Dropdown.Item>
                        <Dropdown.Item>3</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Icon name="left dropdown" />
                      <span className="text">Left</span>
                      <Dropdown.Menu>
                        <Dropdown.Item>1</Dropdown.Item>
                        <Dropdown.Item>2</Dropdown.Item>
                        <Dropdown.Item>3</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
        </Dimmer.Dimmable>
      )}
      {state?.open ? (
        <ModeratorListModal
          getModeratorsList={props.getModeratorsList}
          chat={props.chat}
          userDetails={props.userDetails}
          addFakeUsers={props.addFakeUsers}
          selectChatFakeUser={props.selectChatFakeUser}
          startNewChat={props.startNewChat}
          open={state?.open}
          user={state?.user}
          onSearchValue={props.onSearchValueChange}
          setOpen={(data) => setState((state) => ({ ...state, open: data }))}
        />
      ) : null}
    </div>
  );
};

export default withRouter(Users);
