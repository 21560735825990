import { presetsActionTypes } from "../../constants/actions/presets";
import { Api } from "../../utils/api";

export const fetchPresets = () => {
  return async (dispatch) => {
    try {
      const presets = await Api.getPresets();
      dispatch({
        type: presetsActionTypes.GET_PRESETS,
        payload: presets.defaultPickers,
      });
    } catch (error) {
      console.log(error);
    }
  };
};
