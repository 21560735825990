import React from "react";
import { connect, useDispatch } from "react-redux";
import MainMenu from "../../components/mainMenu";
import ModeratorUser from "../../components/moderators";

import {
  changeSubscription,
  uploadAvatarPhoto,
  changeName,
  changeLastName,
  changeAge,
  changeAbout,
  changeNote,
  changeGender,
  onAddImage,
  onRemoveImage,
  changeZodiacSign,
  changeHeight,
  changeFamilyPlans,
  changeEthnicity,
  changeEducation,
  changeInterestedIn,
  changePolitics,
  changeReligious,
  changeWork,
  changeTags,
  updateUser,
  setWorkerId,
  setFakeUserId,
  onChangeItem,
  hideError,
  changeAddress,
  changeLocation,
  getFakeUserDetails,
  resetSettings,
  getStories,
  uploadStory,
  uploadMoment,
  getMoments,
  uploadScheduleMoment,
  onRemoveScheduleMoment,
  getAllScheduleStories,
  uploadScheduleStories,
  onRemoveMoment,
  getScheduleMoments,
  getAllUserMoments,
  uploadAllUserMoments,
  onRemoveUserAllMoments,
} from "../../actions/fakeUsers";
import { setActiveMenuPosition } from "../../actions/menu";
import { logoutAction } from "../../actions/session";
import { useEffect } from "react";
import { getModeratorsList } from "../../actions/moderators";
import { fetchPresets } from "../../actions/presets";

const ModeratorListView = (props) => {
  const { id } = props.match.params;

  const onuploadAvatarPhoto = (params) => {
    props.uploadAvatarPhoto(props.fakeUsers?.currentUser?.id, params);
  };
  const onUploadStory = (params) => {
    props.uploadStory(props.fakeUsers?.currentUser?.id, params);
  };
  const onUploadMoment = (params) => {
    props.uploadMoment(props.fakeUsers?.currentUser?.id, params);
  };

  const onUploadScheduleMoment = (params) => {
    props.uploadScheduleMoment(props.fakeUsers?.currentUser?.id, params);
    props.getScheduleMoments(props.fakeUsers?.currentUser?.id);
  };

  const onuploadScheduleStories = (params) => {
    props.uploadScheduleStories(props.fakeUsers?.currentUser?.id, params);
  };

  const onUploadAllUserMoments = async (params) => {
    props.uploadAllUserMoments(props.fakeUsers?.currentUser?.id, params);
    props.getAllUserMoments(id);
  };

  useEffect(() => {
    if (id) {
      props.getStories(id);
      props.getMoments(id);
      props.getFakeUserDetails(id);
      props.setFakeUserId(id);
      props.getScheduleMoments(id);
      props.getAllScheduleStories(id);
      props.getAllUserMoments(id);
      props.fetchPresets();
    }
  }, [id]);

  return (
    <div>
      <MainMenu
        needShowPrivateItems={props.session.isAuthenticated}
        menu={props.menu}
        onLogout={props.onLogout}
        setActiveMenuPosition={props.setActiveMenuPosition}
        userDetails={props.session.userDetails}
      />
      <ModeratorUser
        fakeUsers={props.fakeUsers}
        changeName={props.changeName}
        userDetails={props.session.userDetails}
        changeLastName={props.changeLastName}
        changeAge={props.changeAge}
        changeGender={props.changeGender}
        changeSubscription={props.changeSubscription}
        changeAbout={props.changeAbout}
        changeNote={props.changeNote}
        changeEmail={props.changeEmail}
        changePhone={props.changePhone}
        changeAddress={props.changeAddress}
        changeLocation={props.changeLocation}
        updateUser={props.updateUser}
        hideError={props.hideError}
        onuploadAvatarPhoto={onuploadAvatarPhoto}
        onChangeItem={props.onChangeItem}
        onAddNewPhoto={props.onAddNewPhoto}
        onUploadStory={onUploadStory}
        onUploadMoment={onUploadMoment}
        onRemovePhoto={props.onRemovePhoto}
        onRemoveMoment={props.onRemoveMoment}
        changeEducation={props.changeEducation}
        changeEthnicity={props.changeEthnicity}
        changeFamilyPlans={props.changeFamilyPlans}
        changeHeight={props.changeHeight}
        changeInterestedIn={props.changeInterestedIn}
        changePolitics={props.changePolitics}
        changeReligious={props.changeReligious}
        changeTags={props.changeTags}
        changeWork={props.changeWork}
        changeZodiacSign={props.changeZodiacSign}
        stories={props.fakeUsers.stories}
        moments={props.fakeUsers.moments}
        schedulemoments={props.fakeUsers.schedulemoments}
        allmoments={props.fakeUsers.allmoments}
        schedulestories={props.fakeUsers.schedulestories}
        getFakeUserDetails={props.getFakeUserDetails}
        presets={props.presets}
        getModeratorsList={props.getModeratorsList}
        onUploadScheduleMoment={onUploadScheduleMoment}
        onUploadAllUserMoments={onUploadAllUserMoments}
        onRemoveScheduleMoment={props.onRemoveScheduleMoment}
        onRemoveUserAllMoments={props.onRemoveUserAllMoments}
        onuploadScheduleStories={onuploadScheduleStories}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    menu: state.menu,
    users: state.users,
    session: state.session,
    fakeUsers: state.fakeUsers,
    presets: state.presets,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    uploadAvatarPhoto: (userId, params) => {
      uploadAvatarPhoto(userId, params)(dispatch);
    },
    uploadStory: (userId, params) => {
      uploadStory(userId, params)(dispatch);
    },
    uploadMoment: (userId, params) => {
      uploadMoment(userId, params)(dispatch);
    },
    setWorkerId: (id) => {
      setWorkerId(id)(dispatch);
    },
    setFakeUserId: (id) => {
      setFakeUserId(id)(dispatch);
    },
    changeName: (e, data) => {
      changeName(data.value)(dispatch);
    },
    changeLastName: (e, data) => {
      changeLastName(data.value)(dispatch);
    },
    changeAddress: (e, data) => {
      changeAddress(e.currentTarget.value)(dispatch);
    },
    changeLocation: (e, data) => {
      changeLocation(data.location)(dispatch);
    },
    changeAge: (e, data) => {
      changeAge(data.value)(dispatch);
    },
    changeGender: (e, data) => {
      changeGender(data.value)(dispatch);
    },
    changeSubscription: (name) => {
      changeSubscription(name)(dispatch);
    },
    changeAbout: (e, data) => {
      changeAbout(data.value)(dispatch);
    },
    changeNote: (e, data) => {
      changeNote(data.value)(dispatch);
    },
    changeEducation: (e, data) => {
      changeEducation(data.value)(dispatch);
    },
    changeEthnicity: (e, data) => {
      changeEthnicity(data.value)(dispatch);
    },
    changeFamilyPlans: (e, data) => {
      changeFamilyPlans(data.value)(dispatch);
    },
    changeHeight: (e, data) => {
      changeHeight(data.value)(dispatch);
    },
    changePolitics: (e, data) => {
      changePolitics(data.value)(dispatch);
    },
    changeReligious: (e, data) => {
      changeReligious(data.value)(dispatch);
    },
    changeInterestedIn: (e, data) => {
      changeInterestedIn(data.value)(dispatch);
    },
    changeTags: (e, data) => {
      changeTags(data.value)(dispatch);
    },
    changeWork: (e, data) => {
      changeWork(data.value)(dispatch);
    },
    changeZodiacSign: (e, data) => {
      changeZodiacSign(data.value)(dispatch);
    },
    onChangeItem: (item, type, action) => {
      onChangeItem(item, type, action)(dispatch);
    },
    onAddNewPhoto: (item) => {
      onAddImage(item)(dispatch);
    },
    onRemovePhoto: (userId, item) => {
      onRemoveImage(userId, item)(dispatch);
    },
    onRemoveMoment: (id) => {
      onRemoveMoment(id)(dispatch);
    },
    updateUser: (uid, user) => {
      updateUser(uid, user)(dispatch);
    },
    getFakeUserDetails: (id) => {
      getFakeUserDetails(id)(dispatch);
    },
    hideError: () => {
      hideError()(dispatch);
    },
    setActiveMenuPosition(activeMenu) {
      setActiveMenuPosition(activeMenu)(dispatch);
    },
    resetSettings: () => {
      resetSettings()(dispatch);
    },
    onLogout() {
      logoutAction()(dispatch);
    },
    getStories(moderatorId) {
      getStories(moderatorId)(dispatch);
    },
    getMoments(moderatorId) {
      getMoments(moderatorId)(dispatch);
    },
    getScheduleMoments(moderatorId) {
      getScheduleMoments(moderatorId)(dispatch);
    },
    uploadScheduleMoment: (userId, params) => {
      uploadScheduleMoment(userId, params)(dispatch);
    },
    onRemoveScheduleMoment: (id) => {
      onRemoveScheduleMoment(id)(dispatch);
    },
    getAllScheduleStories(moderatorId) {
      getAllScheduleStories(moderatorId)(dispatch);
    },
    uploadScheduleStories: (userId, params) => {
      uploadScheduleStories(userId, params)(dispatch);
    },
    getAllUserMoments: (id) => {
      getAllUserMoments(id)(dispatch);
    },
    uploadAllUserMoments: (userId, params) => {
      uploadAllUserMoments(userId, params)(dispatch);
    },
    onRemoveUserAllMoments: (id) => {
      onRemoveUserAllMoments(id)(dispatch);
    },
    getModeratorsList: (offset, ordered, orderedField, fields, search) => {
      getModeratorsList(
        offset,
        ordered,
        orderedField,
        fields,
        search
      )(dispatch);
    },
    fetchPresets() {
      fetchPresets()(dispatch);
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModeratorListView);
