import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { Message } from "semantic-ui-react";
import MainMenu from "../../components/mainMenu";
import {
  contactUsAction,
  contactUsSaveAction,
  enterContactEmailAction,
  enterContactNameAction,
  enterContactMessageAction,
} from "../../actions/contactUs/index";
import ContactUs from "../../components/contactUs/index";

const ContactUsView = (props) => {
  const {
    contact,
    menu,
    contactAction,
    contactSaveAction,
    enterName,
    enterEmail,
    enterMessage,
    logout,
  } = props;

  const submit = () => {
    var params = {
      name: contact.contactus.name,
      email: contact.contactus.email,
      message: contact.contactus.message,
    };
    contactSaveAction(params);
  };

  useEffect(() => {
    contactAction();
  }, []);

  if (contact.contactus.finished) {
    return <Redirect to={{ pathname: "/signIn" }} />;
  }

  if (
    contact.contactus.invitation_key.length === 0 &&
    contact.contactus.failed
  ) {
    return (
      <div>
        <MainMenu
          needShowPrivateItems={contact.isAuthenticated}
          menu={menu}
          onLogout={() => {}}
          setActiveMenuPosition={() => {}}
        />
        <Message warning>
          <Message.Header>Invalid invitation key</Message.Header>
          <p>You key is invalid or expired</p>
        </Message>
      </div>
    );
  }

  if (contact.isAuthenticated) {
    return (
      <div>
        <MainMenu
          needShowPrivateItems={contact.isAuthenticated}
          menu={menu}
          onLogout={logout}
          setActiveMenuPosition={() => {}}
        />
        <Message warning>
          <Message.Header>Cannot apply invitation link</Message.Header>
          <p>You should logout to use contact us link</p>
        </Message>
      </div>
    );
  }

  return (
    <div>
      <MainMenu
        needShowPrivateItems={contact.isAuthenticated}
        menu={menu}
        onLogout={() => {}}
        setActiveMenuPosition={() => {}}
      />
      <ContactUs
        onSubmit={submit}
        formData={contact}
        name={enterName}
        email={enterEmail}
        message={enterMessage}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    session: state.session,
    contact: state.contact,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    contactAction: () => {
      contactUsAction()(dispatch);
    },
    contactSaveAction: (params) => {
      contactUsSaveAction(params)(dispatch);
    },
    enterEmail: (event, data) => {
      dispatch(enterContactEmailAction(data.value));
    },
    enterName: (event, data) => {
      dispatch(enterContactNameAction(data.value));
    },
    enterMessage: (event, data) => {
      dispatch(enterContactMessageAction(data.value));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactUsView);
