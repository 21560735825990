import moment from "moment";
import React, { useEffect, useState } from "react";
import { Button, Grid, Icon, Image, Modal, Segment } from "semantic-ui-react";
import UserApi from "../../utils/users";
import cancelVector from "../../../src/assets/images/cancelvector.png";
import { getChatImageUrl } from "../../utils/helpers";

const StoryMomentModal = React.memo(
  ({
    open,
    setOpen,
    item,
    refetchMoments,
    refetchStories,
    likeable,
    commentable,
    currentFakeUser,
  }) => {
    const [active, setActive] = useState(0);
    const [storyData, setStoryData] = useState([]);
    const [comment, setComment] = useState("");
    const [momentData, setMomentData] = useState({ likes: [], comments: [] });

    const getLikesComments = async () => {
      const momentLikes = await UserApi.getMomentLikes(item?.id);
      const momentComments = await UserApi.getMomentComments(item?.id);
      setMomentData({ likes: momentLikes, comments: momentComments });
    };

    useEffect(() => {
      if (item?.moment) getLikesComments();
    }, [item?.id]);

    const setData = () => {
      if (!item?.moment)
        setStoryData(active === 0 ? item?.likes : item?.comments);
      else {
        setStoryData(active === 0 ? momentData?.likes : momentData?.comments);
      }
    };
    useEffect(() => {
      setData();
    }, [active, momentData]);

    const addLike = async () => {
      if (item?.moment) {
        await UserApi.addLikeOnMoment(item?.id, currentFakeUser?.id);
        refetchMoments();
      } else {
        await UserApi.addLikeOnStory(item?.pk, currentFakeUser?.id);
        refetchStories();
      }
      setOpen(false);
    };

    const addComment = async () => {
      if (item?.moment) {
        await UserApi.addCommnetOnMoment(
          item?.id,
          comment,
          currentFakeUser?.id
        );
        refetchMoments();
      } else {
        await UserApi.addCommnetOnStory(item?.pk, comment, currentFakeUser?.id);
        refetchStories();
      }
      setComment("");
      setOpen(false);
    };

    return (
      <Modal
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
        size={"tiny"}
      >
        <Modal.Header
          style={{
            background: "#FAA930",
            height: "40px",
            color: "white",
            display: "flex",
            alignItems: "center",
            fontSize: "14px",
          }}
        >
          {item?.moment
            ? `Moment title ${item?.momentDescription}`
            : `Story Title 123@`}
        </Modal.Header>
        {/* {error ? <Message negative style={{ margin: "0 10px" }} onDismiss={() => setError(false)}>
          <Message.Header>We're sorry we can't use current location </Message.Header>
          <p>User's location is not available</p>
        </Message> : null} */}
        <Modal.Content image style={{ paddingBottom: 0 }}>
          <div style={{ width: "100%" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
                marginBottom: "15px",
              }}
            >
              {item?.video ? (
                <video
                  src={getChatImageUrl(item?.video)}
                  width={"430px"}
                  height={"460px"}
                  onError={(err) => {}}
                  autoPlay
                ></video>
              ) : (
                <Image
                  src={getChatImageUrl(item?.file)}
                  width={"430px"}
                  height={"460px"}
                />
              )}
              {/* <Image src={item?.file} width={"430px"} height={"460px"} /> */}
            </div>
            <div style={{ margin: "0 15px" }}>
              <div>
                <Grid columns={2}>
                  <Grid.Column style={{ padding: "20px 0" }}>
                    <Button
                      className={`${
                        active === 0 ? "storyActive" : ""
                      } storyButton  `}
                      style={{ borderRadius: "5px 0px 0px 5px" }}
                      onClick={() => setActive(0)}
                    >
                      Likes{" "}
                      <span
                        className={`count`}
                        style={{
                          textAlign: "center",
                          fontSize: "14px",
                          marginLeft: "5px",
                        }}
                      >
                        {item?.likesCount ?? item?.likes}
                      </span>
                    </Button>
                  </Grid.Column>
                  <Grid.Column style={{ padding: "20px 0" }}>
                    <Button
                      className={`${
                        active === 1 ? "storyActive" : ""
                      } storyButton `}
                      style={{ borderRadius: "0px 5px 5px 0px" }}
                      onClick={() => setActive(1)}
                    >
                      Comments
                      <span
                        className={`count`}
                        style={{
                          textAlign: "center",
                          fontSize: "14px",
                          marginLeft: "5px",
                        }}
                      >
                        {item?.commentsCount ?? item?.comments}
                      </span>
                    </Button>
                  </Grid.Column>
                </Grid>
              </div>
              <div style={{ marginBottom: "20px" }}>
                <Segment style={{ paddingRight: "5px" }}>
                  {storyData?.map((data, i) => {
                    const user = data?.node?.user || data?.user;
                    return (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                          padding: "15px 5px",
                          borderBottom:
                            storyData?.length - 1 === i
                              ? "0px"
                              : "0.5px solid rgba(34,36,38,.15)",
                        }}
                        key={i}
                      >
                        <Image
                          src={
                            user?.avatar?.url ||
                            (user?.avatarPhotos && user?.avatarPhotos[0]?.url)
                          }
                          style={{ borderRadius: "50%" }}
                          width={"37px"}
                          height={"37px"}
                        />
                        <div
                          style={{
                            marginLeft: "10px",
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                          }}
                        >
                          <div>
                            <h4 style={{ marginBottom: "3px" }}>
                              {user?.fullName}
                            </h4>
                            <p style={{ color: "#8F8F8F", fontSize: "14px" }}>
                              {active === 0
                                ? `Liked at: ${moment(
                                    user?.lastSeen || data.createdDate
                                  ).format("D/MM/YYYY hh:mm:ss a")}`
                                : data?.node?.commentDescription ||
                                  data?.commentDescription}
                            </p>
                          </div>
                          {active === 1 ? (
                            <div>
                              <p style={{ color: "#8F8F8F", fontSize: "14px" }}>
                                {moment(
                                  data?.node?.createdDate || data?.createdDate
                                ).format("D/MM/YYYY hh:mm:ss a")}
                              </p>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    );
                  })}
                  {active && commentable ? (
                    <Segment className="commentBox">
                      <textarea
                        placeholder="Write your comment here"
                        value={comment}
                        onChange={(e) => setComment(e?.target?.value)}
                      ></textarea>
                    </Segment>
                  ) : null}
                </Segment>
              </div>
            </div>
          </div>
        </Modal.Content>
        {/* <div style={{ display: "flex", justifyContent: "flex-end", marginBottom: "10px", marginRight: "15px" }}>
          <Button id="currentLocation" color="blue" >Use current location</Button>
        </div> */}
        <Modal.Actions
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <Button
            onClick={() => setOpen(false)}
            icon={"window close outline"}
            style={{ background: "#3D3D3D", color: "white" }}
          >
            {/* <Icon name='window close outline' color="white" /> */}
            <span className="closeButton">
              <Image src={cancelVector} height={"16px"} width={"16px"} />
              Close
            </span>
          </Button>

          {active ? (
            <>
              {commentable && (
                <Button
                  style={{ background: "#11B767", color: "white" }}
                  disabled={!comment}
                  onClick={() => addComment()}
                >
                  <Icon
                    name="check circle outline"
                    color="white"
                    size="small"
                    style={{ fontSize: "16px" }}
                  />
                  Submit
                </Button>
              )}
            </>
          ) : (
            <>
              {likeable && (
                <Button
                  style={{ background: "#BE3FEB", color: "white" }}
                  onClick={() => addLike()}
                >
                  <Icon
                    name="heart outline"
                    color="white"
                    size="small"
                    style={{ fontSize: "16px" }}
                  />
                  Like
                </Button>
              )}
            </>
          )}
        </Modal.Actions>
      </Modal>
    );
  }
);

export default StoryMomentModal;
