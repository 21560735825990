import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Segment,
  Icon,
  Button,
  Dimmer,
  Loader,
  List,
  Grid,
  Header,
  Divider,
  Checkbox,
  Statistic,
} from "semantic-ui-react";
import { Line } from "react-chartjs-2";

import ErrorMessage from "../errorMessage";
import { isAllowedRole } from "../../utils/auth";
import { commonConstants } from "../../constants/common";
import ConfirmationPopup from "../confirmationPopup";
import moment from "moment";
import { connect } from "react-redux";
import {
  getMessagesStatistics,
  getWorkersList,
  sameDayMessagesStatistics,
  selectWorker,
} from "../../actions/workers";
import { getUsersList, hideError } from "../../actions/users";

const CHART_DEFAULT_MONTHLY_DATA = Array(31).fill(0);
const CHART_DEFAULT_DAILY_DATA = Array(24).fill(0);

const lineOptions = {
  legend: {
    display: true,
    position: "bottom",
  },
};

const Workers = (props) => {
  const {
    getWorkersList,
    getMessagesStatistics,
    workers,
    isMonthlyChartType,
    sameDayMessagesStatistics,
    labels,
    changeMonthlyChartType,
    selectWorker,
    hideError,
  } = props;

  const [messageStatistics, setMessageStatistics] = useState({
    sentData: [],
    receivedData: [],
    totalSentData: 0,
    totalReceivedData: 0,
  });

  useEffect(() => {
    getWorkersList();
  }, [getWorkersList]);

  // Worker monthly and hourly messages stats data is stored in same variable in redux store making it difficult to memorize
  // the previous values fetched for monthly and hourly, thats why we are re-fetching the data every time when monthly and hourly toggle.
  useEffect(() => {
    if (workers?.currentWorker?.id) {
      if (isMonthlyChartType) {
        getMessagesStatistics(workers.currentWorker.id, moment().month() + 1);
      } else {
        sameDayMessagesStatistics(workers.currentWorker.id);
      }
    }
  }, [getMessagesStatistics, workers.currentWorker.id, isMonthlyChartType]);

  useEffect(() => {
    if (workers?.currentWorker?.id) {
      if (isMonthlyChartType) {
        const newSentData = [...CHART_DEFAULT_MONTHLY_DATA];
        const newReceivedData = [...CHART_DEFAULT_MONTHLY_DATA];
        let newTotalSentData = 0;
        let newTotalReceivedData = 0;
        workers.workersStats.forEach((item) => {
          newSentData[moment.utc(item.day).date()] = item.sentCount;
          newReceivedData[moment.utc(item.day).date()] = item.receivedCount;
          newTotalSentData += item.sentCount;
          newTotalReceivedData += item.receivedCount;
        }); 
        setMessageStatistics({
          sentData: newSentData,
          receivedData: newReceivedData,
          totalSentData: newTotalSentData,
          totalReceivedData: newTotalReceivedData,
        });
      } else {
        const newSentData = [...CHART_DEFAULT_DAILY_DATA];
        const newReceivedData = [...CHART_DEFAULT_DAILY_DATA];
        let newTotalSentData = 0;
        let newTotalReceivedData = 0;
        workers.workersStats.forEach((item) => {
          const calculatedHour = moment
            .utc(`${moment().format("YYYY-MM-DD")} ${item.hour}`)
            .local()
            .hour();

          newSentData[calculatedHour] = item.sentCount;
          newReceivedData[calculatedHour] = item.receivedCount;
          newTotalSentData += item.sentCount;
          newTotalReceivedData += item.receivedCount;
        });
        setMessageStatistics({
          sentData: newSentData,
          receivedData: newReceivedData,
          totalSentData: newTotalSentData,
          totalReceivedData: newTotalReceivedData,
        });
      }
    }
  }, [workers.workersStats]);

  const data = {
    labels: labels || [],
    datasets: [
      {
        label: `Sent messages: ${messageStatistics.totalSentData} (out going chat)`,
        fill: false,
        lineTension: 0.4,
        backgroundColor: "rgba(75,192,192,0.4)",
        borderColor: "rgba(75,192,192,1)",
        borderCapStyle: "butt",
        pointBackgroundColor: "#fff",
        pointHoverRadius: 7,
        pointHitRadius: 20,
        pointRadius: 5,
        data: messageStatistics.sentData,
      },
      {
        label: `Received messages: ${messageStatistics.totalReceivedData} (incoming chat)`,
        fill: false,
        lineTension: 0.4,
        backgroundColor: "rgba(167,63,63,0.4)",
        borderColor: "rgba(167,63,63,1)",
        borderCapStyle: "butt",
        pointBackgroundColor: "#fff",
        pointHoverRadius: 7,
        pointHitRadius: 20,
        pointRadius: 5,
        data: messageStatistics.receivedData,
      },
    ],
  };

  const worker = workers.workers.find(
    (item, index) =>
      workers?.currentWorker?.id && item?.id === workers?.currentWorker?.id
  );

  const workerLastContact =
    workers?.workersLastContact[workers?.currentWorker?.id];

  const date = new Date(workerLastContact / 1000000);
  const now = new Date();
  const milisecondsDiff = now.getTime() - date.getTime();

  const workerLastMessage =
    workers.workersLastMessage[workers?.currentWorker?.id];

  const workerLastMessageDate = new Date(workerLastMessage / 1000000);

  const workerActiveChats = workers.workersActiveChats.find(
    (item) => workers?.currentWorker?.id === item?.worker_id
  );

  return (
    <Dimmer.Dimmable dimmed={props.workers.loading}>
      <Dimmer active={props.workers.loading} inverted>
        <Loader>Loading</Loader>
      </Dimmer>
      {props.workers.error && props.workers.error.visible && (
        <ErrorMessage error={props.workers.error} hideError={hideError} />
      )}
      <Grid columns={2}>
        <Grid.Row stretched>
          <Grid.Column width={4} floated="right">
            <Segment>
              <List divided relaxed selection>
                {props.workers.workers.map((item, index) => {
                  const workerLastContact =
                    props.workers.workersLastContact[item.id];
                  const date = new Date(workerLastContact / 1000000);
                  const now = new Date();
                  const milisecondsDiff = now.getTime() - date.getTime();
                  return (
                    <List.Item
                      active={props.workers.currentWorker.id === item.id}
                      key={index}
                      onClick={(e) => selectWorker(item.id)}
                    >
                      {isAllowedRole(
                        [commonConstants.SUPER_ADMIN_ROLE],
                        props.userDetails
                      ) && (
                        <List.Content floated="right">
                          <ConfirmationPopup
                            confirmationText="Are you sure? Delete worker?"
                            icon={
                              <Icon
                                name="close"
                                link
                                onClick={(e) => {
                                  e.stopPropagation();
                                }}
                              />
                            }
                            onSuccessConfirm={(e, id) => {
                              e.stopPropagation();
                              props.deleteWorker(id);
                            }}
                            entityData={item.id}
                            successConfirmButtonText="Delete"
                          />
                        </List.Content>
                      )}
                      <List.Content floated="right">
                        <Icon
                          name="circle"
                          size="tiny"
                          color={
                            !workerLastContact
                              ? "grey"
                              : milisecondsDiff < 300000
                              ? "green"
                              : "yellow"
                          }
                          corner
                        />
                      </List.Content>
                      <List.Content>
                        <List.Header>
                          {"ID: " +
                            " " +
                            (item.display_name ||
                              item.username ||
                              item.fullName ||
                              item.first_name + " " + item.last_name ||
                              item.id)}
                        </List.Header>
                        <List.Description>
                          {/* {new Date(item?.user_last_seen).toLocaleString()} */}
                          {item?.user_last_seen
                            ? moment(new Date(item?.user_last_seen)).format(
                                "DD-MMM-YYYY, hh:mm a"
                              )
                            : "Worker lastseen Nil"}
                        </List.Description>
                      </List.Content>
                    </List.Item>
                  );
                })}
              </List>
              <Grid>
                <Grid.Column textAlign="center">
                  <Link to={"/workers/newInvitation/"}>
                    <Button circular icon="add" positive />
                  </Link>
                </Grid.Column>
              </Grid>
            </Segment>
          </Grid.Column>
          <Grid.Column width={12}>
            {Boolean(workers?.currentWorker?.id) && (
              <Segment placeholder>
                <Grid>
                  <Grid.Row columns={2}>
                    <Grid.Column textAlign="right">
                      <Header
                        as={"h2"}
                        // content={
                        //   "ID: " +
                        //   worker.id +
                        //   " " +
                        //   worker.first_name +
                        //   " " +
                        //   worker.last_name
                        // }
                        content={`ID:  ${worker?.id} ${worker?.first_name} ${worker?.last_name}`}
                      />
                    </Grid.Column>
                    <Grid.Column floated="left">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <Icon
                          name="circle"
                          size="tiny"
                          color={
                            !workerLastContact
                              ? "grey"
                              : milisecondsDiff < 300000
                              ? "green"
                              : "yellow"
                          }
                          corner
                        />
                        <Checkbox
                          slider
                          checked={isMonthlyChartType}
                          onChange={changeMonthlyChartType}
                        />
                      </div>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row columns={2}>
                    <Grid.Column textAlign="center">
                      <Statistic size="mini">
                        <Statistic.Value>
                          {workerLastMessage
                            ? workerLastMessageDate.toLocaleString()
                            : "nil"}
                        </Statistic.Value>
                        <Statistic.Label>Last Message</Statistic.Label>
                      </Statistic>
                    </Grid.Column>
                    <Grid.Column textAlign="center">
                      <Statistic size="mini">
                        <Statistic.Value>
                          {workerActiveChats
                            ? workerActiveChats.active_chats
                            : 0}
                        </Statistic.Value>
                        <Statistic.Label>Active chats</Statistic.Label>
                      </Statistic>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
                <Divider hidden />
                <Line data={data} options={lineOptions} />
                <Header as="h2">Moderators (fake users)</Header>
                <List divided relaxed selection>
                  {workers.fakeUsers.map((item, index) => {
                    return (
                      <List.Item key={index}>
                        <List.Content floated="right">
                          <Link
                            to={
                              "/workers/" +
                              workers.currentWorker.id +
                              "/fakeUsers/edit/" +
                              item.id
                            }
                          >
                            <Icon
                              link
                              name="pencil"
                              size="large"
                              color="blue"
                            />
                          </Link>
                        </List.Content>
                        <List.Content>
                          <List.Header>{item.username}</List.Header>
                        </List.Content>
                      </List.Item>
                    );
                  })}
                </List>
              </Segment>
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Dimmer.Dimmable>
  );
};

const mapStatesToProps = (state) => {
  return {
    session: state.session,
    workers: state.workers,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUsersList: (offset, ordered, orderedField) => {
      getUsersList(offset, ordered, orderedField)(dispatch);
    },
    getMessagesStatistics(workerId, month) {
      getMessagesStatistics(workerId, month)(dispatch);
    },
    sameDayMessagesStatistics(workerId) {
      sameDayMessagesStatistics(workerId)(dispatch);
    },
    getWorkersList() {
      getWorkersList()(dispatch);
    },
    selectWorker(id) {
      selectWorker(id)(dispatch);
    },
    hideError: () => {
      hideError()(dispatch);
    },
  };
};

export default connect(mapStatesToProps, mapDispatchToProps)(Workers);
