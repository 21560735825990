export const workersActionTypes = {
  GET_WORKERS_LIST_REQUEST: "GET_WORKERS_LIST_REQUEST",
  GET_WORKERS_LIST_FAILURE: "GET_WORKERS_LIST_FAILURE",
  GET_WORKERS_LIST_SUCCESS: "GET_WORKERS_LIST_SUCCESS",
  DELETE_WORKER_REQUEST: "DELETE_WORKER_REQUEST",
  DELETE_WORKER_FAILURE: "DELETE_WORKER_FAILURE",
  DELETE_WORKER_SUCCESS: "DELETE_WORKER_SUCCESS",
  HIDE_ERROR: "HIDE_ERROR",
  CLEAR_INVITATION: "CLEAR_INVITATION",
  CHANGE_EMAIL: "CHANGE_INVITATION_EMAIL",
  UPDATE_MODERATOR_IN_QUEUE: "UPDATE_MODERATOR_IN_QUEUE",
  CHANGE_ADMIN_PERMISSION: "CHANGE_INVITATION_ADMIN_PERMISSION",
  CHANGE_CHAT_ADMIN_PERMISSION: "CHANGE_INVITATION_CHAT_ADMIN_PERMISSION",
  CREATE_INVITATION_REQUEST: "CREATE_INVITATION_REQUEST",
  CREATE_INVITATION_FAILURE: "CREATE_INVITATION_FAILURE",
  CREATE_INVITATION_SUCCESS: "CREATE_INVITATION_SUCCESS",
  SELECT_WORKER: "SELECT_WORKER",
  UPDATE_WORKER_STATS: "UPDATE_WORKER_STATS",
  INIT_WORKER_MESSAGES_STATISTICS: "INIT_WORKER_MESSAGES_STATISTICS",
  ADD_WORKER_MESSAGES_STATISTICS: "ADD_WORKER_MESSAGES_STATISTICS",
  UPDATE_WORKERS_STORE_FIELD: "UPDATE_WORKERS_STORE_FIELD",
  TOP_NAV_WORKER_STATS_REQUEST: "TOP_NAV_WORKER_STATS_REQUEST",
  TOP_NAV_WORKER_STATS_FAILURE: "TOP_NAV_WORKER_STATS_FAILURE",
  TOP_NAV_WORKER_STATS_SUCCESS: "TOP_NAV_WORKER_STATS_SUCCESS",
};
