import React, { Component } from "react";
import { useSelector } from "react-redux";
import Photo from "./photo";
import { Button, Grid, Loader, Icon, Image, Form } from "semantic-ui-react";
import SelectImageCont from "../chatImageUpload/selectImageCont";
import videoIcon from "../../assets/images/videoIcon.png";
import { isImage } from "../../utils/helpers";
import AvatarEditor from "react-avatar-editor";
import { setStockImage, uploadStockImage } from "../../actions/fakeUsers";
import UserApi from "../../utils/users";

export const cropImageStyle = {
  position: "fixed",
  zIndex: 9999999999,
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  background: "#fff",
  border: "1px solid #ddd",
  padding: "10px 16px",
};

class UploadScheduleImage extends Component {
  constructor(props) {
    super(props);

    this.editorRef = React.createRef(null);

    this.state = {
      cropImage: null,
      image: null,
      src: "",
      croppedImageId: "",
      progress: 0,
      isValid: false,
      loading: false,
      imageContOpen: false,
      play: false,
      dimentions: { width: 320, height: 340 },
      scale: 1,
    };
  }

  setUrl = (url) => {
    this.setState({ image: null, src: url });
  };

  selectImage = (e) => {
    this.inputRef.click();
  };

  handleChangeSelect = (image) => {
    if (image && !image?.type) {
      // this.loadImage(image.getAttribute("src"));
      // Image is not getting loaded using binary data
      this.setState({
        ...this.state,
        image: image,
        cropImage: image.getAttribute("src"),
        croppedImageId: "",
        src: image.getAttribute("src"),
        imageContOpen: false,
        isValid: true,
      });
    }
  };
  loadImage = async (image) => {
    try {
      const imageUrl = image.split("media");
      const response = await fetch(`/media` + imageUrl[1], {
        mode: "cors",
        headers: {
          Origin: imageUrl[0],
          "Access-Control-Request-Method": "GET",
          "Access-Control-Request-Headers": "Content-Type",
          "sec-fetch-dest": "image",
          "Sec-Fetch-Mode": "cors",
        },
      });

      const blob = await response.blob();
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64Data = reader.result;
        this.setState({
          cropImage: this.props.type === "profile" ? base64Data : null,
        });
      };
      reader.readAsDataURL(blob);
    } catch (error) {
      console.error("Error loading or converting image:", error);
    }
  };
  handleChange = (e) => {
    if (e.target.files[0]) {
      const image = e.target.files[0];
      this.setState(() => ({ image }));
      generatePreviewImgUrl(image, (previewImgUrl) => {
        // (assuming we use React)
        this.setState({ src: previewImgUrl, image: previewImgUrl });
      });
    }
  };

  reset = () => {
    this.setState({
      image: null,
      src: "",
      progress: 0,
      isValid: false,
      loading: false,
    });
  };

  onLoad = () => {
    this.setState({
      isValid: true,
      loading: false,
    });
  };
  skipCrop = () => {
    this.setState({
      ...this.state,
      cropImage: null,
    });
  };
  onError = () => {
    this.setState({
      isValid: false,
      loading: false,
    });
  };

  handleUpload = () => {
    this.setState({
      loading: true,
    });
    const { image, src } = this.state;
    const { folder } = this.props;
    const imageName = generateString(29) + ".jpg";
    const fileParams = {
      name: imageName,
      file: this.state?.croppedImageId
        ? this.state?.croppedImageId
        : this.props.stockImage
        ? image.getAttribute("data-id")
        : image,
      type: image?.type,
      size: image?.size,
      public: false,
      id: this.state?.croppedImageId
        ? this.state?.croppedImageId
        : this.props.stockImage
        ? image.getAttribute("data-id")
        : "",
    };

    this.props.onUpload(fileParams);
    this.setState({
      image: null,
      src: "",
      progress: 0,
      isValid: false,
      loading: false,
      croppedImageId: "",
    });
  };
  setImageContOpen = (value) => {
    this.setState({
      ...this.state,
      imageContOpen: value,
    });
  };

  dataURItoBlob = (dataURI) => {
    const byteString = atob(dataURI.split(",")[1]);
    const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);

    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ab], { type: mimeString });
  };

  handleScaleChange = (e) => {
    const newScale = parseFloat(e.target.value);
    this.setState({
      ...this.state,
      scale: newScale,
    });
  };

  handleCrop = async () => {
    if (this.editorRef.current) {
      const canvas = this.editorRef.current.getImageScaledToCanvas();

      const croppedImage = canvas.toDataURL("image/jpeg", 1);

      const file = this.dataURItoBlob(croppedImage);
      // Log the Blob object
      try {
        file.name = file.type.split("/")[1];
      } catch {}
      const mediaName = generateName(file);
      const fileParams = {
        name: mediaName,
        file: file,
        type: file.type,
        size: file.size,
        public: false,
      };
      // Use 'await' to wait for the resolution of the promise
      try {
        this.setState({
          cropImage: null,
          loading: true,
        });
        const response = await uploadStockImage(this.props.userId, fileParams);

        if (response) {
          this.setState({
            loading: false,
          });
        }
        this.setState({
          ...this.state,
          croppedImageId: response.id,
          src: response.file,
        });
      } catch (error) {
        console.error("Error in handleCrop:", error);
      }
      const res = await UserApi.getStockImages(this.props.userId);

      if (res?.data?.stockImages) {
        setStockImage(res?.data?.stockImages);
      }
    }
  };

  handleBlobData = (blob) => {
    if (blob) {
      const url = URL.createObjectURL(blob);
    }
  };
  render() {
    return (
      <div>
        <Loader active={this.state.loading} />
        <input
          type="file"
          onChange={this.handleChange}
          style={{ display: "none" }}
          ref={(input) => (this.inputRef = input)}
        />

        <Grid textAlign="center">
          <Grid.Row>
            <SelectImageCont
              isOpen={this.state.imageContOpen}
              setIsOpen={this.setImageContOpen}
              moderatorId={this.props?.moderatorId}
              // getStockImages={this.props?.getStockImages}
              getStockImages={() => {}}
              stockImages={this.props?.stockImages}
              position={this.props?.position}
              modal={true}
              onSelect={(image) => this.handleChangeSelect(image)}
            />

            {this.state.src ? (
              isImage(this.state.src) ? (
                <Photo
                  height={this.props.height}
                  src={this.state.src !== "" ? this.state.src : ""}
                  onLoad={this.onLoad}
                  onError={this.onError}
                  onClick={
                    this.props.disabled
                      ? () => {}
                      : this.props.stockImage
                      ? () => this.setImageContOpen(true)
                      : this.selectImage
                  }
                  disabled={this.props.disabled}
                />
              ) : (
                <div style={{ position: "relative" }}>
                  {!this.state.play ? (
                    <div
                      style={{
                        position: "absolute",
                        height: this.props.height,
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Image
                        src={videoIcon}
                        style={{
                          height: "25px",
                          width: "25px",
                          marginTop: "5px",
                        }}
                      />
                    </div>
                  ) : null}
                  <video
                    src={this.state.src}
                    height={this.props.height}
                    onClick={(e) => {
                      this.setState({ play: !this.state?.play });
                      if (e.target.paused) {
                        e.target.play();
                      } else {
                        e.target.pause();
                      }
                    }}
                    width={"95%"}
                  >
                    <source src={this.state.src} type="video/mp4" />
                  </video>
                </div>
              )
            ) : (
              <Photo
                height={this.props.height}
                src={this.state.src !== "" ? this.state.src : ""}
                onLoad={this.onLoad}
                onError={this.onError}
                onClick={
                  this.props.disabled
                    ? () => {}
                    : this.props.stockImage
                    ? () => this.setImageContOpen(true)
                    : this.selectImage
                }
                disabled={this.props.disabled}
              />
            )}

            {this.state.src !== "" && (
              <Icon link corner name={"close"} onClick={this.reset} />
            )}
          </Grid.Row>
          <Grid.Row>
            <Form.Group className="" style={{ width: "80%" }}>
              <Form.Input
                fluid
                name="publishAt"
                // label={"Publish At"}
                placeholder="Publish At"
                value={this.props.publishAt}
                onChange={this.props.handleChangeTime}
                type="datetime-local"
              />
            </Form.Group>
          </Grid.Row>
          <Grid.Row>
            <Button
              disabled={
                !this.state.isValid || this.props.disabled || this.state.loading
              }
              color="blue"
              content={this.props.header}
              onClick={(e, data) => {
                e.preventDefault();
                this.handleUpload();
              }}
            />
          </Grid.Row>
        </Grid>

        {isImage(this.state.cropImage) && (
          <div style={cropImageStyle}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <h2>Crop Image</h2>
              <Button onClick={() => this.skipCrop()}>X</Button>
            </div>
            <hr />
            <AvatarEditor
              ref={this.editorRef}
              image={this.state.cropImage}
              width={this.state.dimentions?.width}
              height={this.state.dimentions?.height}
              border={50}
              color={[0, 0, 0, 0.6]}
              scale={this.state.scale}
              rotate={0}
              crossOrigin="anonymous"
            />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  columnGap: "20px",
                }}
              >
                <label htmlFor="scale">Scale:</label>
                <input
                  type="range"
                  id="scale"
                  min="0.1"
                  max="3"
                  step="0.01"
                  value={this.state.scale}
                  onChange={this.handleScaleChange}
                />
              </div>
              <Button
                onClick={() =>
                  this.setState({
                    ...this.state,
                    dimentions: { width: 320, height: 320 },
                  })
                }
              >
                1:1
              </Button>
              <Button
                onClick={() =>
                  this.setState({
                    ...this.state,
                    dimentions: { width: 320, height: 640 },
                  })
                }
              >
                9:16
              </Button>
              <Button onClick={this.handleCrop}>Crop</Button>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default UploadScheduleImage;

function generatePreviewImgUrl(file, callback) {
  const reader = new FileReader();
  const url = reader.readAsDataURL(file);
  reader.onloadend = (e) => callback(reader.result);
}
function generateName(file) {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  for (var i = 0; i < 29; i++) {
    result += characters.charAt(Math.floor(Math.random() * 29));
  }
  const fileNameParts = file?.name?.split(".");
  return result + "." + fileNameParts[fileNameParts?.length - 1];
}

function generateString(length) {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}
