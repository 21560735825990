import { combineReducers } from "redux";

import session from "./session";
import menu from "./menu";
import users from "./users";
import admins from "./admins";
import fakeUsers from "./fakeUsers";
import workers from "./workers";
import chat from "./chat";
import moderators from "./moderators";
import contact from "./contact";
import pendingChats from "./pendingChats";
import presetsReducer from "./presets";

const reducers = combineReducers({
  session,
  menu,
  users,
  admins,
  moderators,
  fakeUsers,
  workers,
  chat,
  contact,
  pendingChats,
  presets: presetsReducer,
});

export default reducers;
