import React from "react";
import { connect } from "react-redux";

import MainMenu from "../../components/mainMenu";
import Moderator from "../../components/createModerator/createModerator";

import {
  uploadAvatarPhoto,
  changeName,
  changeLastName,
  changeAge,
  changeAbout,
  changeGender,
  changeSubscription,
  onAddImage,
  onRemoveImage,
  changeZodiacSign,
  changeHeight,
  changeFamilyPlans,
  changeEthnicity,
  changeEducation,
  changeInterestedIn,
  changePolitics,
  changeReligious,
  changeWork,
  changeTags,
  createUser,
  setWorkerId,
  setFakeUserId,
  onChangeItem,
  hideError,
  getPresets,
  getFakeUserDetails,
  resetSettings,
  getStories,
  uploadStory,
  uploadMoment,
  getMoments,
  onRemoveMoment,
} from "../../actions/fakeUsers";
import { setActiveMenuPosition } from "../../actions/menu";
import { logoutAction } from "../../actions/session";

const CreateModeratorView = (props) => {
  const onuploadAvatarPhoto = (params) => {
    props.uploadAvatarPhoto(props.fakeUsers.currentUser.id, params);
  };
  const onUploadStory = (params) => {
    props.uploadStory(props.fakeUsers.currentUser.id, params);
  };
  const onUploadMoment = (params) => {
    props.uploadMoment(props.fakeUsers.currentUser.id, params);
  };

  return (
    <div>
      <MainMenu
        needShowPrivateItems={props.session.isAuthenticated}
        menu={props.menu}
        onLogout={props.onLogout}
        setActiveMenuPosition={props.setActiveMenuPosition}
        userDetails={props.session.userDetails}
      />
      <Moderator
        fakeUsers={props.fakeUsers}
        changeName={props.changeName}
        changeLastName={props.changeLastName}
        changeAge={props.changeAge}
        changeGender={props.changeGender}
        changeSubscription={props.changeSubscription}
        changeAbout={props.changeAbout}
        changeEmail={props.changeEmail}
        changePhone={props.changePhone}
        createUser={props.createUser}
        hideError={props.hideError}
        onuploadAvatarPhoto={onuploadAvatarPhoto}
        onChangeItem={props.onChangeItem}
        onAddNewPhoto={props.onAddNewPhoto}
        onUploadStory={onUploadStory}
        onUploadMoment={onUploadMoment}
        onRemovePhoto={props.onRemovePhoto}
        onRemoveMoment={props.onRemoveMoment}
        changeEducation={props.changeEducation}
        changeEthnicity={props.changeEthnicity}
        changeFamilyPlans={props.changeFamilyPlans}
        changeHeight={props.changeHeight}
        changeInterestedIn={props.changeInterestedIn}
        changePolitics={props.changePolitics}
        changeReligious={props.changeReligious}
        changeTags={props.changeTags}
        changeWork={props.changeWork}
        changeZodiacSign={props.changeZodiacSign}
        stories={props.fakeUsers.stories}
        moments={props.fakeUsers.moments}
        userDetails={props.session.userDetails}
        presets={props.presets}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    menu: state.menu,
    users: state.users,
    session: state.session,
    fakeUsers: state.fakeUsers,
    presets: state.presets,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    uploadAvatarPhoto: (userId, params) => {
      uploadAvatarPhoto(userId, params)(dispatch);
    },
    uploadStory: (userId, params) => {
      uploadStory(userId, params)(dispatch);
    },
    uploadMoment: (userId, params) => {
      uploadMoment(userId, params)(dispatch);
    },
    setWorkerId: (id) => {
      setWorkerId(id)(dispatch);
    },
    setFakeUserId: (id) => {
      setFakeUserId(id)(dispatch);
    },
    changeName: (e, data) => {
      changeName(data.value)(dispatch);
    },
    changeLastName: (e, data) => {
      changeLastName(data.value)(dispatch);
    },
    changeAge: (e, data) => {
      changeAge(data.value)(dispatch);
    },
    changeGender: (e, data) => {
      changeGender(data.value)(dispatch);
    },
    changeSubscription: (e, data) => {
      changeSubscription(data.value)(dispatch);
    },
    changeAbout: (e, data) => {
      changeAbout(data.value)(dispatch);
    },
    changeEducation: (e, data) => {
      changeEducation(data.value)(dispatch);
    },
    changeEthnicity: (e, data) => {
      changeEthnicity(data.value)(dispatch);
    },
    changeFamilyPlans: (e, data) => {
      changeFamilyPlans(data.value)(dispatch);
    },
    changeHeight: (e, data) => {
      changeHeight(data.value)(dispatch);
    },
    changePolitics: (e, data) => {
      changePolitics(data.value)(dispatch);
    },
    changeReligious: (e, data) => {
      changeReligious(data.value)(dispatch);
    },
    changeInterestedIn: (e, data) => {
      changeInterestedIn(data.value)(dispatch);
    },
    changeTags: (e, data) => {
      changeTags(data.value)(dispatch);
    },
    changeWork: (e, data) => {
      changeWork(data.value)(dispatch);
    },
    changeZodiacSign: (e, data) => {
      changeZodiacSign(data.value)(dispatch);
    },
    onChangeItem: (item, type, action) => {
      onChangeItem(item, type, action)(dispatch);
    },
    onAddNewPhoto: (item) => {
      onAddImage(item)(dispatch);
    },
    onRemovePhoto: (userId, item) => {
      onRemoveImage(userId, item)(dispatch);
    },
    onRemoveMoment: (id) => {
      onRemoveMoment(id)(dispatch);
    },
    createUser: (workerId, user, callback) => {
      createUser(workerId, user, callback)(dispatch);
    },
    getFakeUserDetails: (id) => {
      getFakeUserDetails(id)(dispatch);
    },
    hideError: () => {
      hideError()(dispatch);
    },
    getPresets: () => {
      getPresets()(dispatch);
    },
    setActiveMenuPosition(activeMenu) {
      setActiveMenuPosition(activeMenu)(dispatch);
    },
    resetSettings: () => {
      resetSettings()(dispatch);
    },
    onLogout() {
      logoutAction()(dispatch);
    },
    getStories(moderatorId) {
      getStories(moderatorId)(dispatch);
    },
    getMoments(moderatorId) {
      getMoments(moderatorId)(dispatch);
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateModeratorView);
