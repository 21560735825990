import moment from "moment";
import React from "react";
import { Card, Grid, Image } from "semantic-ui-react";

import onlineIcon from "../../assets/images/onlineIcon.png";
import offlineIcon from "../../assets/images/offlineIcon.png";
import editIcon from "../../assets/images/editIcon.png";
import chatIcon from "../../assets/images/chatIcon.png";
import "../common/common.css";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import { connect } from "react-redux";
import { resetCurrentFakeUser } from "../../actions/fakeUsers";
import noImagePlaceHolder from "../../assets/images/no-image-placeholder.webp";
import { isVideo } from "../../utils/helpers";

const ModeratorsList = ({
  users,
  columns,
  isAdmin,
  onChat,
  editPath,
  history,
  resetCurrentFakeUser,
}) => {
  const getAddress = (user) => {
    if (user?.city) {
      return user?.city;
    } else if (user?.address) {
      if (user?.address.split(",").length < 2) {
        return user?.address.split(",")[user?.address.split(",").length - 1];
      }
      return user?.address.split(",")[user?.address.split(",").length - 3];
    } else {
      return "Not Avaliable";
    }
  };

  return (
    <Grid columns={columns} className="userContainer">
      <Grid.Row>
        {users.map((user, indx) => {
          let avatar = user?.avatar_photos?.length
            ? user?.avatar_photos[0]?.file
            : "";
          const primaryImage = user?.avatar_photos[user?.avatar_index]?.file;

          const displayMedia = (
            avatar ? (primaryImage ? primaryImage : avatar) : noImagePlaceHolder
          ).trim();

          const is_video = isVideo(displayMedia);

          const getReports = () => {
            const reports = [];
            user?.reports?.map((report) => reports.push(report?.reason ?? ""));
            return reports?.join(", ") ?? "";
          };

          return (
            <Grid.Column className="userInfo" key={user?.id}>
              <Card className="userCard">
                <div style={{ position: "relative" }}>
                  {is_video ? (
                    <video
                      key={`moderator-video-${indx}`}
                      style={{ width: "100%", height: "290px" }}
                      autoPlay
                      muted
                      loop
                    >
                      <source src={displayMedia} type="video/mp4" />
                    </video>
                  ) : (
                    <Image
                      src={displayMedia}
                      width={"100%"}
                      height={"220px"}
                      wrapped
                      ui={false}
                      className={"userImage"}
                      style={{ borderRadius: "8px" }}
                    />
                  )}
                  <div className="actionView">
                    <Image
                      src={editIcon}
                      width={"26px"}
                      height={"26px"}
                      wrapped
                      ui={false}
                      onClick={() => {
                        resetCurrentFakeUser();
                        history.push(`${editPath}${user?.id}`);
                      }}
                      style={{ cursor: "pointer" }}
                    />
                    {!isAdmin && (
                      <Image
                        src={chatIcon}
                        width={"26px"}
                        height={"26px"}
                        wrapped
                        ui={false}
                        className="chatIcon"
                        onClick={() => onChat(user)}
                      />
                    )}
                  </div>
                </div>
                <Card.Content
                  style={{ display: "block" }}
                  className="userContent"
                >
                  <Card.Header className="cardHeader">
                    <p
                      style={{
                        maxWidth: "60%",
                        minWidth: "60%",
                        wordWrap: "break-word",
                      }}
                    >
                      {user?.display_name}
                    </p>
                    {user?.isOnline ? (
                      <div>
                        <img
                          src={onlineIcon}
                          alt="Online Icon"
                          width="15px"
                          height="15px"
                        />{" "}
                        Online
                      </div>
                    ) : (
                      <div>
                        <img
                          src={offlineIcon}
                          alt="Online Icon"
                          width="15px"
                          height="15px"
                        />{" "}
                        Offline
                      </div>
                    )}
                  </Card.Header>
                  <Card.Description className="description">
                    <p>Age: {user?.age + 17}</p>
                    <p>City: {getAddress(user)}</p>
                    <p>
                      Joined :
                      {moment(user?.sign_up).format("DD/MM/YYYY,hh:mm:ss a")}
                    </p>
                    <p>
                      Last Login:
                      {user?.user_last_seen
                        ? moment(user?.user_last_seen).format(
                            "DD/MM/YYYY,hh:mm:ss a"
                          )
                        : "No activity"}
                    </p>
                    <p>Subscription: {user?.subscription || "UNTITLED"}</p>
                    <p>Disabled: {user?.is_blocked ? "True" : "False"}</p>
                    <p>Reports: {getReports() ? getReports() : "None"}</p>
                  </Card.Description>
                </Card.Content>
              </Card>
            </Grid.Column>
          );
        })}
      </Grid.Row>
    </Grid>
  );
};

export default connect(null, (dispatch) => {
  return {
    resetCurrentFakeUser() {
      resetCurrentFakeUser()(dispatch);
    },
  };
})(withRouter(ModeratorsList));
