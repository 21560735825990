import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Card,
  Form,
  Header,
  Icon,
  Image,
  Message,
  Modal,
  Popup,
  Segment,
} from "semantic-ui-react";
import {
  getAllScheduleStories,
  getAllStories,
  uploadStory,
} from "../../actions/fakeUsers";
import { selectFakeUsersState } from "../../store/selectors/fakeUsersSelectors";
import UserApi from "../../utils/users";
import AddNewPhoto from "../imageUpload/addNewPhoto";
import { useFormik } from "formik";
import * as yup from "yup";
import { baseUrl } from "../../utils/api";
import { getImageUrl, isVideo } from "../../utils/helpers";

const AddCommentModalFormState = yup.object().shape({
  comment: yup.string().required("Comment text is requiered!"),
});

const AddCommentModal = ({
  isModalOpen = false,
  handleClose,
  handleSuccess,
  momentId,
  comments,
}) => {
  // const [comments, setComments] = useState([]);

  const [state, setState] = useState({
    errors: [],
    isSubmitting: false,
  });

  const initialValues = {
    comment: "",
  };

  const handleFormSubmit = async (values) => {
    setState({
      ...state,
      errors: [],
      isSubmitting: true,
    });
    const res = await UserApi.addCommnetOnStory(momentId, values.comment);

    if (!res) {
      setState({
        ...state,
        isSubmitting: false,
        errors: [res?.message ?? "Something went wrong while posting comment!"],
      });
      return;
    }
    setState({
      ...state,
      isSubmitting: false,
      errors: [],
    });
    formik.resetForm();
    handleSuccess();
  };

  const formik = useFormik({
    initialValues,
    validationSchema: AddCommentModalFormState,
    validateOnChange: true,
    validateOnBlur: true,
    validateOnMount: false,
    onSubmit: handleFormSubmit,
  });

  const handleModalClose = () => {
    formik.resetForm();
    handleClose();
  };

  return (
    <Modal open={isModalOpen} onClose={handleModalClose}>
      <Header icon="comments" content="Comments" />
      <Modal.Content>
        <ul style={{ listStyle: "none", padding: "0", margin: "0" }}>
          {comments?.length === 0 && (
            <Header>No Comments for this Story</Header>
          )}
          {/* {comments?.map((comment) => (
            <li
              style={{
                display: "flex",
                padding: "5px 0px",
              }}
            >
              <Image
                avatar
                src={comment?.node?.user?.avatarPhotos[0]?.url || ""}
              />
              <div>
                <Header as="a">{comment.node.user.username}</Header>
                <p style={{ color: "gray" }}>
                  <small>{comment?.node?.commentDescription}</small>
                  <br />
                  <small>
                    Commented at:{" "}
                    {new Date(comment?.node?.createdDate).toLocaleString()}
                  </small>
                </p>
              </div>
            </li>
          ))} */}
        </ul>
        {/* <Form style={{ padding: 0 }}>
          <Form.Group widths="equal">
            <Form.TextArea
              label="Comment"
              name="comment"
              placeholder="Write your comment here"
              value={formik.values.comment}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.comment && formik.errors.comment}
            />
          </Form.Group>
        </Form> */}
      </Modal.Content>
      <Modal.Actions>
        {state?.errors?.length
          ? state.errors.map((error) => (
              <Message
                style={{ display: "block", textAlign: "start" }}
                error={true}
              >
                {error}{" "}
              </Message>
            ))
          : null}
        <Button color="red" onClick={() => handleModalClose()}>
          <Icon name="remove" /> Cancel
        </Button>
        {/* <Button
          loading={state.isSubmitting}
          type="submit"
          color="green"
          onClick={formik.handleSubmit}
        >
          <Icon name="checkmark" /> Submit
        </Button> */}
      </Modal.Actions>
    </Modal>
  );
};

const LikeModal = ({
  isModalOpen = false,
  handleClose,
  handleSuccess,
  momentId,
  likes,
}) => {
  const [state, setState] = useState({
    errors: [],
    isSubmitting: false,
  });

  // const initialValues = {
  //   comment: "",
  // };

  const handleLikeMoment = async (values) => {
    setState({
      ...state,
      errors: [],
      isSubmitting: true,
    });
    const res = await UserApi.addLikeOnStory(momentId);

    if (!res) {
      setState({
        ...state,
        isSubmitting: false,
        errors: [res?.message ?? "Something went wrong while posting like!"],
      });
      handleSuccess();
      return;
    }
    setState({
      ...state,
      isSubmitting: false,
      errors: [],
    });
    handleSuccess();
  };

  const handleModalClose = () => {
    handleClose();
  };

  return (
    <Modal open={isModalOpen} onClose={handleModalClose}>
      <Header icon="like" content="Likes" />
      <Modal.Content>
        <ul style={{ listStyle: "none", padding: "0", margin: "0" }}>
          {likes?.length === 0 && <Header>No Likes for this Story</Header>}
          {/* {likes?.map((like) => (
            <li
              style={{
                display: "flex",
                padding: "5px 0px",
              }}
            >
              <Image avatar src={like.node.user.avatarPhotos[0]?.url || ""} />
              <div>
                <Header as="a">{like.node.user.username}</Header>
                <p style={{ color: "gray" }}>
                  <small>
                    Last seen at:{" "}
                    {new Date(like?.node?.user?.lastSeen).toLocaleString()}
                  </small>
                </p>
              </div>
            </li>
          ))} */}
        </ul>
      </Modal.Content>
      <Modal.Actions>
        {state?.errors?.length
          ? state.errors.map((error) => (
              <Message
                style={{ display: "block", textAlign: "start" }}
                error={true}
              >
                {error}{" "}
              </Message>
            ))
          : null}
        <Button color="red" onClick={() => handleModalClose()}>
          <Icon name="remove" /> Cancel
        </Button>
        {/* <Button
          loading={state.isSubmitting}
          type="submit"
          color="green"
          onClick={handleLikeMoment}
        >
          <Icon name="like" /> Like
        </Button> */}
      </Modal.Actions>
    </Modal>
  );
};

const FakeUserStories = () => {
  const dispatch = useDispatch();
  const fakeUsersState = useSelector(selectFakeUsersState);
  const userId = fakeUsersState?.currentUser?.id;
  const [stories, setStories] = useState(fakeUsersState.stories);
  const [likeOpen, setLikeOpen] = useState(false);
  const [commentOpen, setCommentOpen] = useState(false);
  const [story, setStory] = useState({});
  const currentTimestamp = new Date().getTime();

  const [state, setState] = useState({
    isDirty: false,
    storyId: "",
    isDeleteModalOpen: false,
    errors: [],
    userId,
    isLoading: false,
    momentId: "",
  });

  useEffect(() => {
    setStories(fakeUsersState?.stories);
  }, [fakeUsersState?.stories]);

  const removeUserStoryImage = async (storyID) => {
    setState({
      ...state,
      isDeleteModalOpen: true,
      momentId: storyID,
      errors: [],
    });
  };
  const handleDeleteStory = async () => {
    setState({
      ...state,
      isLoading: true,
    });
    const res = await UserApi.removeUserStory({
      momentId: state.momentId,
    });
    if (!res) {
      setState({
        ...state,
        isLoading: false,
        errors: [
          res?.message ?? "Something went wrong while removing user story!",
        ],
      });
      return;
    }
    if (res?.errors) {
      setState({
        ...state,
        isLoading: false,
        errors: res?.errors ?? [],
      });
      return;
    }
    setState({
      ...state,
      isLoading: false,
      isDeleteModalOpen: false,
      errors: [],
    });
    dispatch(getAllStories());
  };

  const onUploadStory = async (params) => {
    dispatch(
      uploadStory(userId, params, "", (data) => {
        setState({
          ...state,
          messages: [
            {
              text: data?.reason,
              type: "error",
            },
          ],
        });
      })
    );
    dispatch(getAllStories());
    dispatch(getAllScheduleStories(userId));
  };

  const handleCommentModalClose = () => {
    setCommentOpen(false);
  };

  const hanldeAddCommentSuccess = () => {
    setCommentOpen(false);
    dispatch(getAllStories());
  };

  const handleLikeModal = (state) => {
    setLikeOpen(state);
  };

  const handleLikeModalClose = () => {
    setLikeOpen(false);
  };

  const handleLikeSuccess = () => {
    setLikeOpen(false);
    dispatch(getAllStories());
  };

  const handleImageBrokenLink = (link) => {
    if (link?.includes(`${baseUrl}/media/${baseUrl}`)) {
      const removedBrokenPartLink = link?.replace(`${baseUrl}/media/`, "");
      return removedBrokenPartLink;
    }
    return link;
  };

  useEffect(() => {
    if (userId) {
      dispatch(getAllStories());
    }
  }, [userId]);
  return (
    <>
      <Modal open={state.isDeleteModalOpen}>
        <Header icon="archive" content="Are you sure want to delete ?" />
        <Modal.Content>
          <p>The file should be not recovered!</p>
        </Modal.Content>
        <Modal.Actions>
          {state?.errors?.length
            ? state.errors.map((error) => (
                <Message
                  style={{ display: "block", textAlign: "start" }}
                  error={true}
                >
                  {error}{" "}
                </Message>
              ))
            : null}
          <Button
            color="red"
            onClick={() => {
              setState({
                ...state,
                errors: [],
                isDeleteModalOpen: false,
                isLoading: false,
              });
            }}
          >
            <Icon name="remove" /> Cancel
          </Button>
          <Button color="green" onClick={handleDeleteStory}>
            <Icon name="checkmark" /> Delete
          </Button>
        </Modal.Actions>
      </Modal>
      <Segment
        inverted={state.isDirty && fakeUsersState?.avatar_photos?.length === 0}
        tertiary={state.isDirty && fakeUsersState?.avatar_photos?.length === 0}
        secondary={state.isDirty && fakeUsersState?.avatar_photos?.length > 0}
        color={
          state.isDirty && fakeUsersState?.avatar_photos?.length === 0
            ? "red"
            : "black"
        }
      >
        <Header
          size="large"
          color={
            state.isDirty && fakeUsersState?.photos?.length === 0
              ? "red"
              : "black"
          }
        >
          Story
        </Header>
        {stories?.length === 0 && <Header>No stories selected</Header>}
        <Card.Group itemsPerRow={4}>
          {stories
            ?.filter(
              (x) =>
                (!x.node?.publishAt ||
                  new Date(x.node?.publishAt).getTime() <= currentTimestamp) &&
                x?.node?.user?.id === userId
            )
            ?.map((item, index) => {
              return (
                <Card key={index} className="user-card-main-container">
                  <div
                    className="user-moment-image"
                    key={item?.file || "ScheduledStory"}
                  >
                    {isVideo(handleImageBrokenLink(item?.node?.file)) ? (
                      <video
                        style={{ width: "100%", height: "290px" }}
                        autoPlay
                        muted
                        loop
                      >
                        <source
                          src={handleImageBrokenLink(item?.node?.file)}
                          type="video/mp4"
                        />
                      </video>
                    ) : (
                      <Image
                        src={handleImageBrokenLink(item?.node?.file)}
                        centered
                      />
                    )}
                    <div>
                      <button
                        style={{
                          width: 30,
                          height: 30,
                          background: "#ffffff",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          position: "absolute",
                          borderRadius: "10px",
                          top: 4,
                          right: 79,
                          border: "none",
                          zIndex: 10,
                        }}
                        type="button"
                        onClick={() => {
                          setStory(item);
                          handleLikeModal(true);
                        }}
                      >
                        <div
                          style={{
                            position: "absolute",
                            top: "-5px",
                            right: "-3px",
                            width: "17px",
                            height: "17px",
                            borderRadius: "50%",
                            backgroundColor: "gold",
                            fontSize: "10px",
                            display: "grid",
                            placeItems: "center",
                            zIndex: 10,
                          }}
                        >
                          {item?.node?.likesCount || 0}
                        </div>
                        <div>
                          <Icon
                            className="create-fake-moderator-remove-moment-image-icon"
                            link
                            corner
                            name={"like"}
                          />
                        </div>
                      </button>
                      <button
                        style={{
                          width: 30,
                          height: 30,
                          background: "#ffffff",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          position: "absolute",
                          borderRadius: "10px",
                          top: 5,
                          right: 42,
                          border: "none",
                          zIndex: 10,
                        }}
                        type="button"
                        onClick={() => {
                          setStory(item);
                          setCommentOpen(!commentOpen);
                        }}
                      >
                        <div
                          style={{
                            position: "absolute",
                            top: "-5px",
                            right: "-3px",
                            width: "17px",
                            height: "17px",
                            borderRadius: "50%",
                            backgroundColor: "gold",
                            fontSize: "10px",
                            display: "grid",
                            placeItems: "center",
                          }}
                        >
                          {item?.node?.commentsCount || 0}
                        </div>
                        <div>
                          <Icon
                            className="create-fake-moderator-remove-moment-image-icon"
                            link
                            corner
                            name={"comments"}
                          />
                        </div>
                      </button>
                      <button
                        style={{
                          width: 30,
                          height: 30,
                          background: "#ffffff",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          position: "absolute",
                          borderRadius: "10px",
                          top: 5,
                          right: 5,
                          border: "none",
                        }}
                        type="button"
                        onClick={() => removeUserStoryImage(item?.node?.pk)}
                      >
                        <div>
                          <Icon
                            className="create-fake-moderator-remove-moment-image-icon"
                            link
                            corner
                            name={"trash"}
                          />
                        </div>
                      </button>
                    </div>
                  </div>
                  {item?.node?.createdBy !== null && (
                    <span style={{ textAlign: "center" }}>
                      <p>
                        <b style={{ textTransform: "uppercase" }}>
                          Created By:
                        </b>{" "}
                        &nbsp;
                        {item?.node?.createdBy?.fullName}
                        &nbsp;
                        <Popup
                          hideOnScroll
                          on="click"
                          content="ID Copied!"
                          position="top center"
                          trigger={
                            <Icon
                              name="id badge"
                              title="Click to copy ID"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                navigator.clipboard.writeText(
                                  item?.node?.createdBy?.id
                                );
                              }}
                            />
                          }
                        />
                      </p>
                    </span>
                  )}
                </Card>
              );
            })}
          <Card>
            <AddNewPhoto
              onUpload={onUploadStory}
              height={"200px"}
              header={"Add new image"}
              stockImage={true}
              stockImages={fakeUsersState?.stockImages?.map((image) => {
                const imageReplace = image?.file?.startsWith(
                  "https://djbbbm5n90p1g.cloudfront.net"
                )
                  ? image?.file
                  : image?.file?.replace(`${baseUrl}/media/`, "");

                return {
                  ...image,
                  file: image?.file?.startsWith(
                    "https://djbbbm5n90p1g.cloudfront.net"
                  )
                    ? imageReplace
                    : getImageUrl(image?.file),
                };
              })}
              moderatorId={fakeUsersState?.currentUser?.id}
              getStockImages={(id) => {}}
              folder={fakeUsersState?.currentUser?.id || "images"}
            />
          </Card>
        </Card.Group>
      </Segment>

      <AddCommentModal
        isModalOpen={commentOpen}
        momentId={story?.node?.pk}
        comments={story?.node?.comments || []}
        handleClose={handleCommentModalClose}
        handleSuccess={hanldeAddCommentSuccess}
      />

      <LikeModal
        isModalOpen={likeOpen}
        momentId={story?.node?.pk}
        likes={story?.node?.likes || []}
        handleClose={handleLikeModalClose}
        handleSuccess={handleLikeSuccess}
      />
    </>
  );
};

export default FakeUserStories;
