import _ from "lodash";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import {
  Accordion,
  Button,
  Card,
  Checkbox,
  Dimmer,
  Divider,
  Dropdown,
  Form,
  Grid,
  Header,
  Icon,
  Image,
  Label,
  Loader,
  Message,
  Modal,
  Popup,
  Rating,
  Segment,
  Tab,
} from "semantic-ui-react";
import { setErrorChat } from "../../../actions/chat";
import Notes from "../../../components/chat/notes";
import SliderParent from "../../../components/dashboard/slider/sliderParent";
import ErrorMessage from "../../../components/errorMessage";
import ChatBoxContent from "../../../components/imageUpload/chatBoxContent";
import Photo from "../../../components/imageUpload/photo";
import { Api } from "../../../utils/api";
import { getToken } from "../../../utils/auth";
import { ChatApi } from "../../../utils/chat_api";
import { isImage, isVideo } from "../../../utils/helpers";
import UserApi from "../../../utils/users";
import List from "../list";
import audioSrc from "../../../assets/media/Ring.wav";
import Photos_4 from "../../../assets/images/Rectangle 18.png";
import cancelVector from "../../../assets/images/cancelvector.png";
import userStory from "../../../assets/images/userStory.png";
import videoIcon from "../../../assets/images/videoIcon.png";
import MainMenu from "../../../components/mainMenu";

let map = null;
let marker = null;
let infoWindow = null;

const ShareLocation = ({ shareLocation, setOpen, open, location }) => {
  // const [open, setOpen] = React.useState(false)
  const [text, setText] = useState();
  const [error, setError] = useState(false);

  useEffect(() => {
    const coords = { lat: 40.749933, lng: -73.98633 };
    map = new window.google.maps.Map(
      document.getElementById("google-map-for-address"),
      {
        zoom: 17,
        center: coords,
      }
    );
    infoWindow = new window.google.maps.InfoWindow();
    marker = new window.google.maps.Marker({
      position: coords,
      map: map,
    });
    const infowindow = new window.google.maps.InfoWindow();
    map.addListener("click", (e) => {
      if (marker) {
        marker.setMap(null);
      }
      setText(`${e.latLng.lat()},${e.latLng.lng()}`);
      marker = new window.google.maps.Marker({
        position: e.latLng,
        map: map,
      });
    });

    marker.addListener("click", (e) => {
      infowindow.open(map, marker);
    });

    document
      .getElementById("currentLocation")
      .addEventListener("click", function () {
        if (location?.length && location[0] !== 0 && location[1] !== 0) {
          if (location[0] !== 0 && location[1] !== 0) {
            setText(`${location[0]},${location[1]}`);
            if (marker) {
              marker.setMap(null);
            }
            let pos = { lat: location[0], lng: location[1] };
            // let pos = { lat: 0, lng: 0 }
            infoWindow.setPosition(pos);
            map.setCenter(pos);

            marker = new window.google.maps.Marker({
              position: pos,
              map: map,
            });
          }
        } else {
          setError(true);
        }
      });
  }, [open]);

  return (
    <div>
      <Modal
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
        // size={"small"}
      >
        <Modal.Header>Select location</Modal.Header>
        {error ? (
          <Message
            negative
            style={{ margin: "0 10px" }}
            onDismiss={() => setError(false)}
          >
            <Message.Header>
              We're sorry we can't use current location{" "}
            </Message.Header>
            <p>User's location is not available</p>
          </Message>
        ) : null}
        <Modal.Content image style={{ paddingBottom: 0 }}>
          <div
            style={{ height: 600, width: "100%", marginBottom: "14px" }}
            id="google-map-for-address"
          ></div>
          <div id="infowindow-content">
            <span id="place-name" className="title"></span>
            <br />
            <span id="place-address"></span>
          </div>
        </Modal.Content>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginBottom: "10px",
            marginRight: "15px",
          }}
        >
          <Button id="currentLocation" color="blue">
            Use current location
          </Button>
        </div>
        <Modal.Actions>
          <Button color="black" onClick={() => setOpen(false)} basic>
            Close
          </Button>
          <Button
            content="Share"
            labelPosition="right"
            icon="checkmark"
            onClick={() => {
              setOpen();
              shareLocation(text);
            }}
            positive
          />
        </Modal.Actions>
      </Modal>
    </div>
  );
};

// *************************************

//    FAKE USERS COMPONENT

// *************************************

const FakeUserListItem = ({
  item,
  currentFakeUser,
  selectChatFakeUser,
  changeFakeUserOnlineStatus,
  workerId,
  usersList,
  closeUsersList,
  openUsersList,
  chatDialogues,
  index,
}) => {
  const [totalUsersHasUnreadMessage, setTotalUsersHasUnreadMessage] =
    useState(0);
  const isActive = currentFakeUser.id === item.id;

  useEffect(() => {
    if (index === 0 && !chatDialogues) {
      selectChatFakeUser(item.id);
    }
    if (chatDialogues) {
      setTotalUsersHasUnreadMessage(
        _.filter(
          chatDialogues,
          (chatDialogue) => chatDialogue.unread_messages_count > 0
        ).length
      );
    }
  }, [chatDialogues]);
  const showUsers = (id) => {
    selectChatFakeUser(id);
    // getAllDialogue(currentFakeUser.id);
  };
  // useEffect(() => {
  //   let unSubscribingRoom;
  //   if (item.id) {
  //     messageReceiveListener(item.id, currentChatId).then((e) => (unSubscribingRoom = e));
  //   }
  //   return () => {
  //     if (unSubscribingRoom) {
  //       unSubscribingRoom.unsubscribe();
  //     }
  //   };
  // }, [item.id]);

  const fullName = item?.first_name
    ? `${item.first_name} ${item.last_name} `
    : undefined;

  return (
    <List.Item
      active={isActive}
      onClick={() => showUsers(item.id)}
      key={item.id}
    >
      {
        <List.Content floated="right">
          <Link to={"/workers/" + workerId + "/fakeUsers/edit/" + item.id}>
            <Icon link name="pencil" size="large" color="blue" />
          </Link>
        </List.Content>
      }
      {
        <List.Content floated="right">
          <Popup
            trigger={
              <Icon
                link
                name="chat"
                size="large"
                color={usersList && isActive ? "yellow" : "blue"}
                onClick={(e) => {
                  usersList && isActive ? closeUsersList() : openUsersList();
                }}
              />
            }
            content="Start new chat"
            position="left center"
          />
        </List.Content>
      }
      {/* user online status is handled with QuickBlox https://docs.quickblox.com/docs/js-chat-user-presence */}
      <List.Content floated="right">
        <Checkbox
          slider
          checked={item.online}
          onChange={(e) => {
            e.stopPropagation();
            changeFakeUserOnlineStatus(item.id, !item.online);
          }}
        />
      </List.Content>
      <List.Content floated="right">
        <Label color={totalUsersHasUnreadMessage > 0 ? "yellow" : "grey"}>
          {totalUsersHasUnreadMessage}
        </Label>
      </List.Content>
      <List.Content>
        <List.Header>
          {item.fullName ?? fullName ?? item.name ?? item.username}
        </List.Header>
      </List.Content>
    </List.Item>
  );
};

/*************************************** 
 
*           CHAT INDOX ITEM 
 
 ***************************************/
const ChatInboxItem = ({
  dialogue,
  allUsers,
  selectChat,
  currentChat,
  item,
  index,
}) => {
  const [state, setState] = useState({
    address: {
      loading: true,
      city: "",
      state: "",
      country: "",
    },
  });
  const user = allUsers[dialogue.friendId];
  const currentUnreadMessages = dialogue.unread_messages_count;
  const active = currentChat == dialogue._id;
  const locationdata = user?.location?.split(",");
  let params = {
    chat_dialog_id: dialogue._id,
    sort_desc: "date_sent",
    limit: 100,
    skip: 0,
  };

  const getAddressData = async () => {
    const data = await Api.getAddressDataFromLatLng({
      lat: locationdata[0],
      lng: locationdata[1],
    });

    if (data.status === "OK") {
      const addressData = data?.results?.[0];
      let tempAddress = { ...state.address };

      for (let address of addressData?.address_components) {
        const addTypes = address.types;
        if (addTypes.includes("locality")) {
          tempAddress.city = address.long_name;
        }

        if (addTypes.includes("country")) {
          tempAddress.country = address.long_name;
        }
        if (addTypes.includes("administrative_area_level_1")) {
          tempAddress.state = address.long_name;
        }
      }
      if (
        !state.address.city ||
        !state.address.state ||
        !state.address.country
      ) {
        setState({ ...state, address: tempAddress, loading: false });
      }
    }
  };

  useEffect(() => {
    if (index === 0 && !currentChat) {
      selectChat(dialogue._id, dialogue.occupants_ids);
    }

    if (!locationdata) return;
    getAddressData();
  }, [locationdata]);

  // selectChat(dialogue._id, dialogue.occupants_ids);

  if (!user) return <List.Item active={active}>Loading user data...</List.Item>;
  const clickHandler = () => selectChat(dialogue._id, dialogue.occupants_ids);
  return (
    <List.Item active={active} onClick={clickHandler}>
      {
        <List.Content floated="right">
          <Link to={"/users/edit/" + item.friendId}>
            <Icon link name="pencil" size="large" color="blue" />
          </Link>
        </List.Content>
      }
      <List.Content>
        <List.Content floated="right">
          <Label color={currentUnreadMessages > 0 ? "yellow" : "grey"}>
            {currentUnreadMessages}
          </Label>
        </List.Content>

        <List.Header>
          {dialogue.name ? dialogue.name : "No name found"}
        </List.Header>
        <List.Content floated="left" style={{ color: "rgba(0, 0, 0, .7)" }}>
          {user.location ? (
            <div style={{ marginTop: "5px", fontSize: "14px" }}>
              <p style={{ marginBottom: "0px" }}>
                {state.address.city}
                {state.address.city ? "," : ""} {state.address.state}
                {state.address.state ? "," : ""} {state.address.country}
              </p>
              {/* <p style={{ marginBottom: "0px" }}>
                  {state.address.state}, {state.address.country}.
                </p> */}
            </div>
          ) : (
            ""
          )}
        </List.Content>
      </List.Content>
    </List.Item>
  );
};

/************************************************************
 
* Display data from chat.fakeUserChatDialogues[currentlySelectedFakeUser]
 
 *************************************************************/
const ChatInboxList = ({
  allUsers,
  selectChat,
  currentChat,
  workerId,
  chatDialogues = [],
  userId,
}) => {
  return (
    <List divided relaxed selection>
      {chatDialogues.map((dialogue, i) => (
        <>
          <ChatInboxItem
            workerId={workerId}
            item={dialogue}
            currentChat={currentChat}
            key={dialogue._id}
            selectChat={selectChat}
            dialogue={dialogue}
            allUsers={allUsers}
            moderatorId={userId}
            index={i}
          />
        </>
      ))}
    </List>
  );
};

/****************************************
 *
 * DISPLAY ALL MESSAGE AGAINST A OPPONENT
 *
 ****************************************/

const MessageBox = ({
  currentChat,
  opponent,
  fakeUserId,
  chat,
  session,
  appendChatHistory,
  refreshChat,
  userDetails,
  fakeUser,
}) => {
  const videoRef = useRef();
  const [messages, setMessages] = useState([]);
  const messagesEndRef = React.useRef(null);
  const [previewUrl, setPreviewUrl] = useState("");
  const [videoPlay, setVideoPlay] = useState(false);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const scrollToBottom = React.useCallback(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messagesEndRef]);

  React.useEffect(() => {
    if (videoRef.current?.currentTime === videoRef.current?.duration) {
      setVideoPlay(false);
    }
  }, [videoRef.current?.currentTime]);

  React.useEffect(() => {
    setTimeout(() => {
      scrollToBottom();
    }, 100);
  }, [messages, scrollToBottom]);

  React.useEffect(() => {
    setMessages(_.sortBy(currentChat, (item) => new moment(item.created_at)));
    // setMessages([{ message: "{lat:21.168128,lng:72.8563712}" }]);
  }, [currentChat]);

  React.useEffect(() => {
    if (!currentChat) return;
    let unSubscribingRoom;
    const fn = async () => {
      const roomId = currentChat[0]?.room?.id;
      if (roomId) {
        const observerRoom = await ChatApi.addMessageReceiveListener(
          getToken(),
          fakeUserId,
          roomId
        );

        unSubscribingRoom = observerRoom.subscribe(async (result) => {
          const message = {
            id: result.data.onNewMessage.message?.id,
            created_at: result.data.onNewMessage.message.timestamp,
            room: result.data.onNewMessage.message.roomId,
            message: result.data.onNewMessage.message.content,
            recipient_id: result.data.onNewMessage.message.userId.id,
          };
          
          appendChatHistory(roomId, message);
          const sound = new Audio(audioSrc);
          await sound.play();
        });
      }
    };
    fn();
    return () => {
      if (unSubscribingRoom) {
        unSubscribingRoom.unsubscribe();
      }
    };
  }, [currentChat, fakeUserId]);

  const showVideo = (url) => {
    setPreviewUrl(url);
    setShowPreviewModal(true);
  };

  const handleMessageDelete = async (message) => {
    const res = await ChatApi.deleteMessage({ messageId: message.id });
    if (res?.success) {
      refreshChat();
    }
  };

  const user = opponent;
  const processContent = (string) => {
    const parts = string.split(" ");
    const url = parts[0];

    const content = parts?.slice(1)?.join(" ");
    return { url: url, msg: content };
  };
  return (
    <>
      <Segment
        style={{
          maxHeight: window.innerHeight - 270,
          height: window.innerHeight - 270,
          overflowX: "hidden",
          marginBottom: 0,
        }}
        color="teal"
        id="parentContaier"
      >
        {/* https://api.chatadmin-mod.click/media/chat_files/static/uploads/gift/4240ad9a-51db-48e1-adf4-e4f86620b356_1676120741.2591484.png Aneeq Dummy has offered you orange gift value 8.0 coins */}
        <Grid columns={2} className="asdasdasdada">
          {_.map(messages, (value) => {
            let content = value.message || value.recieved_message;
            let url = content?.includes("media/chat_files");
            let map = content?.includes("lat") && content?.includes("lng");
            const date = value.created_at ? new Date(value.created_at) : "";
            const { url: uri, msg } = processContent(content);
            const video = isVideo(uri);
            const popUp = (
              <>
                {/* {(value.ir && (
                    <Header
                      // icon={<Icon corner size="mini" name="check" />}
                      disabled
                      sub
                      floated="right"
                      content={date.toLocaleString()}
                    />
                  )) || (
                      <Header
                        disabled
                        sub
                        floated="right"
                        content={date.toLocaleString()}
                      />
                    )} */}
                {url && value?.messageType !== "G" ? (
                  <>
                    <Popup
                      trigger={
                        <Segment
                          size="tiny"
                          // className={`${value.recipient_id !== opponent?.user_id ? value.recipient_id === null ? "leftSide" : "rightSide" : "leftSide"}`}
                          className={`${
                            value.recipient_id !== opponent?.user_id
                              ? value.recipient_id === null
                                ? "leftSide"
                                : "rightSide"
                              : "leftSide"
                          }`}
                          // style={{ position: "relative", background: value.recipient_id !== opponent?.user_id ? value.recipient_id === null ? "#E7E7E7" : "#79BE80" : "#E7E7E7", }}
                          style={{
                            position: "relative",
                            background:
                              value.recipient_id !== opponent?.user_id
                                ? value.recipient_id === null
                                  ? "#E7E7E7"
                                  : "#79BE80"
                                : "#E7E7E7",
                            paddingTop: url ? 10 : 2,
                          }}
                        >
                          <Dropdown
                            pointing={"right"}
                            style={{
                              position: "absolute",
                              top: "5px",
                              right: "5px",
                            }}
                            trigger={<Icon name="ellipsis vertical" />}
                            options={[
                              {
                                key: "delete",
                                text: "Delete",
                                value: "delete",
                              },
                            ]}
                            onChange={(e, data) => {
                              if (data.value === "delete") {
                                handleMessageDelete(value);
                              }
                            }}
                          />

                          <ChatBoxContent
                            showVideo={showVideo}
                            content={uri}
                            privatePhotoRequestId={value.privatePhotoRequestId}
                            requestStatus={value.requestStatus}
                            messageType={value?.messageType}
                            height={video ? "100px" : "185px"}
                            width={"200px"}
                            id={value.id}
                            recipient_id={value?.recipient_id}
                            user_id={opponent?.user_id}
                          />
                          <Divider hidden fitted />
                          {/* {(value.ir && (
                          <Header
                            // icon={<Icon corner size="mini" name="check" />}
                            disabled
                            sub
                            floated="right"
                            content={date.toLocaleString()}
                          />
                        )) || (
                            <Header
                              disabled
                              sub
                              floated="right"
                              content={date.toLocaleString()}
                            />
                          )} */}
                        </Segment>
                      }
                      content={user && user.name ? user.name : "Undefined"}
                      basic
                      mouseEnterDelay={1000}
                      on="hover"
                      size={"mini"}
                      // position='bottom right'
                    />
                    <Popup
                      trigger={
                        <Segment
                          size="tiny"
                          // className={`${value.recipient_id !== opponent?.user_id ? value.recipient_id === null ? "leftSide" : "rightSide" : "leftSide"}`}
                          className={`${
                            value.recipient_id !== opponent?.user_id
                              ? value.recipient_id === null
                                ? "leftSide"
                                : "rightSide"
                              : "leftSide"
                          }`}
                          // style={{ position: "relative", background: value.recipient_id !== opponent?.user_id ? value.recipient_id === null ? "#E7E7E7" : "#79BE80" : "#E7E7E7", }}
                          style={{
                            position: "relative",
                            background:
                              value.recipient_id !== opponent?.user_id
                                ? value.recipient_id === null
                                  ? "#E7E7E7"
                                  : "#79BE80"
                                : "#E7E7E7",
                            paddingTop: url ? 10 : 2,
                          }}
                        >
                          {/* <Dropdown
                            pointing={"right"}
                            style={{
                              position: "absolute",
                              top: "5px",
                              right: "5px",
                            }}
                            trigger={
                              <Icon
                                name="ellipsis vertical"
                              />
                            }
                            options={[
                              { key: 'delete', text: 'Delete', value: "delete" },
                            ]}
                            onChange={(e, data) => {
                              if (data.value === "delete") {
                                handleMessageDelete(value)
                              }
                            }}
                          /> */}

                          <ChatBoxContent
                            showVideo={showVideo}
                            content={msg}
                            privatePhotoRequestId={value.privatePhotoRequestId}
                            requestStatus={value.requestStatus}
                            messageType={value?.messageType}
                            height={"185px"}
                            width={"200px"}
                            id={value.id}
                            recipient_id={value?.recipient_id}
                            user_id={opponent?.user_id}
                          />
                          <Divider hidden fitted />
                          {/* {(value.ir && (
                          <Header
                            // icon={<Icon corner size="mini" name="check" />}
                            disabled
                            sub
                            floated="right"
                            content={date.toLocaleString()}
                          />
                        )) || (
                            <Header
                              disabled
                              sub
                              floated="right"
                              content={date.toLocaleString()}
                            />
                          )} */}
                        </Segment>
                      }
                      content={user && user.name ? user.name : "Undefined"}
                      basic
                      mouseEnterDelay={1000}
                      on="hover"
                      size={"mini"}
                      // position='bottom right'
                    />
                  </>
                ) : (
                  <Popup
                    trigger={
                      <Segment
                        size="tiny"
                        // className={`${value.recipient_id !== opponent?.user_id ? value.recipient_id === null ? "leftSide" : "rightSide" : "leftSide"}`}
                        className={`${
                          value.recipient_id !== opponent?.user_id
                            ? value.recipient_id === null
                              ? "leftSide"
                              : "rightSide"
                            : "leftSide"
                        }`}
                        // style={{ position: "relative", background: value.recipient_id !== opponent?.user_id ? value.recipient_id === null ? "#E7E7E7" : "#79BE80" : "#E7E7E7", }}
                        style={{
                          position: "relative",
                          background:
                            value.recipient_id !== opponent?.user_id
                              ? value.recipient_id === null
                                ? "#E7E7E7"
                                : "#79BE80"
                              : "#E7E7E7",
                          paddingTop: url ? 10 : 2,
                        }}
                      >
                        <Dropdown
                          pointing={"right"}
                          style={{
                            position: "absolute",
                            top: "5px",
                            right: "5px",
                          }}
                          trigger={<Icon name="ellipsis vertical" />}
                          options={[
                            { key: "delete", text: "Delete", value: "delete" },
                          ]}
                          onChange={(e, data) => {
                            if (data.value === "delete") {
                              handleMessageDelete(value);
                            }
                          }}
                        />

                        <ChatBoxContent
                          showVideo={showVideo}
                          content={value.message || value.recieved_message}
                          privatePhotoRequestId={value.privatePhotoRequestId}
                          requestStatus={value.requestStatus}
                          messageType={value?.messageType}
                          height={"185px"}
                          width={"200px"}
                          id={value.id}
                          recipient_id={value?.recipient_id}
                          user_id={opponent?.user_id}
                        />
                        <Divider hidden fitted />
                        {/* {(value.ir && (
                        <Header
                          // icon={<Icon corner size="mini" name="check" />}
                          disabled
                          sub
                          floated="right"
                          content={date.toLocaleString()}
                        />
                      )) || (
                          <Header
                            disabled
                            sub
                            floated="right"
                            content={date.toLocaleString()}
                          />
                        )} */}
                      </Segment>
                    }
                    content={user && user.name ? user.name : "Undefined"}
                    basic
                    mouseEnterDelay={1000}
                    on="hover"
                    size={"mini"}
                    // position='bottom right'
                  />
                )}
                {/* <Popup
                    trigger={
                      <Segment
                        size="tiny"
                        // className={`${value.recipient_id !== opponent?.user_id ? value.recipient_id === null ? "leftSide" : "rightSide" : "leftSide"}`}
                        className={`${value.recipient_id !== opponent?.user_id ? value.recipient_id === null ? "leftSide" : "rightSide" : "leftSide"}`}
                        // style={{ position: "relative", background: value.recipient_id !== opponent?.user_id ? value.recipient_id === null ? "#E7E7E7" : "#79BE80" : "#E7E7E7", }}
                        style={{ position: "relative", background: value.recipient_id !== opponent?.user_id ? value.recipient_id === null ? "#E7E7E7" : "#79BE80" : "#E7E7E7", paddingTop: url ? 10 : 2 }}
                      >
                        <Dropdown
                          pointing={"right"}
                          style={{
                            position: "absolute",
                            top: "5px",
                            right: "5px",
                          }}
                          trigger={
                            <Icon
                              name="ellipsis vertical"
                            />
                          }
                          options={[
                            { key: 'delete', text: 'Delete', value: "delete" },
                          ]}
                          onChange={(e, data) => {
                            if (data.value === "delete") {
                              handleMessageDelete(value)
                            }
                          }}
                        />
  
                        <ChatBoxContent
                          showVideo={showVideo}
                          content={value.message || value.recieved_message}
                          privatePhotoRequestId={value.privatePhotoRequestId}
                          requestStatus={value.requestStatus}
                          messageType={value?.messageType}
                          height={"185px"}
                          width={"200px"}
                          id={value.id}
                          recipient_id={value?.recipient_id}
                          user_id={opponent?.user_id}
                        />
                        <Divider hidden fitted />
                        
                      </Segment>
                    }
                    content={user && user.name ? user.name : "Undefined"}
                    basic
                    mouseEnterDelay={1000}
                    on="hover"
                    size={"mini"}
                  // position='bottom right'
                  /> */}
              </>
            );
            if (
              value.recipient_id == opponent?.user_id ||
              !!value.recieved_message
            ) {
              // const user = props?.chat.allUsers[currentChat.userId];
              return value?.id ? (
                <Grid.Row
                  textAlign={value.url ? "center" : "left"}
                  key={value.id}
                >
                  <Grid.Column width={url ? 6 : 8}>
                    <Header
                      sub
                      floated="left"
                      content={`${user?.name || ""}`}
                    />
                    <Header
                      sub
                      floated="left"
                      style={{ color: "#3A3A3A" }}
                      content={`${date ? date.toLocaleString() : ""}`}
                    />
                    {popUp}
                  </Grid.Column>

                  <Grid.Column></Grid.Column>
                </Grid.Row>
              ) : null;
            } else {
              // const user = props?.chat.allFakeUsers[value.id];
              const user = opponent;
              return value?.id ? (
                <Grid.Row
                  textAlign={value.url ? "center" : "left"}
                  key={value.id}
                >
                  <Grid.Column></Grid.Column>
                  <Grid.Column>
                    <Header
                      sub
                      floated="right"
                      content={`${fakeUser?.fullName || ""}`}
                    />
                    <Header
                      sub
                      floated="right"
                      style={{ color: "#3A3A3A", fontWeight: 400 }}
                      content={`${date ? date.toLocaleString() : ""}`}
                    />
                    {popUp}
                  </Grid.Column>
                </Grid.Row>
              ) : null;
            }
          })}
        </Grid>
        <div style={{ float: "left", clear: "both" }} />
        <div ref={messagesEndRef} />
      </Segment>
      <Modal
        onClose={() => setShowPreviewModal(false)}
        open={showPreviewModal}
        // isOpen={showPreviewModal}
        // onRequestClose={() => setShowPreviewModal(false)}
        size={"small"}
        style={{
          zIndex: 9999,
        }}
        // contentLabel="Example Modal"
        // shouldCloseOnOverlayClick={true}
      >
        {isImage(previewUrl) ? (
          <Image
            src={previewUrl}
            style={{ width: "inherit", height: window?.innerHeight / 2 }}
            // onClick={props?.onClick}
          />
        ) : (
          <>
            {!videoPlay ? (
              <div
                style={{
                  position: "absolute",
                  height: window?.innerHeight / 2,
                  width: "inherit",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Image
                  src={videoIcon}
                  style={{ height: "40px", width: "40px", marginTop: "5px" }}
                  // onClick={props?.onClick}
                  // onClick={() => {
                  //   setVideoPlay(true)
                  //   videoRef.current.play()
                  // }}
                />
              </div>
            ) : null}
            <video
              controls
              style={{ width: "inherit", maxHeight: window?.innerHeight / 2 }}
              ref={videoRef}
              onClick={() => setVideoPlay(!videoPlay)}
            >
              <source src={previewUrl} />
            </video>
          </>
        )}
      </Modal>
    </>
  );
};

const SendGiftSection = ({
  senderId,
  receiverId,
  refreshChat,
  removeCurrentChats,
  currentChat,
  setOpenGift,
  setGift,
  gift,
}) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [realGifts, setRealGifts] = useState([]);
  const [virtualGifts, setVirtualGifts] = useState([]);
  const dispatch = useDispatch();

  const handleClick = (e, props) => {
    const { index } = props;
    const newIndex = activeIndex === index ? -1 : index;
    setActiveIndex(newIndex);
  };

  const handleGiftSend = async (giftId) => {
    const res = await ChatApi.sendGift({
      giftId: giftId,
      senderId: senderId,
      receiverId: receiverId,
    });
    if (!res?.error) {
      // refreshChat();
      removeCurrentChats();
    } else {
      dispatch(setErrorChat("API request failed", [res?.error]));
    }
    setOpenGift();
  };

  const getRealGifts = async () => {
    const res = await ChatApi.getAllRealGifts();
    if (res) {
      setRealGifts(res);
    }
  };

  const getVirtualGifts = async () => {
    const res = await ChatApi.getAllVirtualGifts();
    if (res?.length) {
      setVirtualGifts(res);
    }
  };

  useEffect(() => {
    // getRealGifts();
    getVirtualGifts();
  }, [senderId]);

  return (
    <div>
      <Modal
        onClose={() => setGift(false)}
        onOpen={() => setGift(true)}
        open={gift}
        size={"tiny"}
      >
        <Accordion
          styled
          style={{ marginRight: 0, width: "100%", boxShadow: "none" }}
        >
          <Accordion.Content active={activeIndex === 0}>
            <Tab
              panes={[
                {
                  menuItem: "Virtual",
                  render: () => (
                    <Tab.Pane>
                      <div
                        style={{
                          display: "flex",
                          gap: "5px",
                          flexWrap: "wrap",
                        }}
                      >
                        <Popup
                          content="Message should be minimum 50 characters long!"
                          style={{
                            background: "#E2B747",
                            color: "white",
                            marginBottom: "25px",
                          }}
                          open
                          trigger={<div></div>}
                        />
                        {virtualGifts.map((gift) => (
                          <div>
                            <div
                              style={{
                                width: 200,
                                height: 200,
                                borderRadius: "50%",
                                overflow: "none",
                                cursor: "pointer",
                              }}
                              onClick={() => setGift(gift)}
                              // onClick={() => handleGiftSend(gift)}
                            >
                              <img
                                src={gift.picture}
                                alt={gift.giftName}
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  objectFit: "cover",
                                }}
                              />
                            </div>
                            <p>{gift.giftName}</p>
                          </div>
                        ))}
                      </div>
                    </Tab.Pane>
                  ),
                },
              ]}
            />
          </Accordion.Content>
        </Accordion>
      </Modal>
    </div>
  );
};

/************************************
 *
 *  USER INFORMATION
 *
 ************************************/

const UserInfo = React.memo(
  ({ user, setActiveIndex, activeIndex, presets }) => {
    const options = {
      genderOptions: [],
      ethnicityOptions: [],
      zodiacSignsOptions: [],
      familyOptions: [],
      politicsOptions: [],
      religiousOptions: [],
      tagsOptions: [],
      ageOptions: [],
      heightOptions: [],
    };

    const {
      ageOptions,
      heightOptions,
      genderOptions,
      ethnicityOptions,
      zodiacSignsOptions,
      familyOptions,
      politicsOptions,
      religiousOptions,
      tagsOptions,
    } = options;

    if (presets) {
      const {
        ages,
        heights,
        genders,
        ethnicity,
        zodiacSigns,
        family,
        politics,
        religious,
        tags,
      } = presets;
      ageOptions.push(
        ...ages.map(({ id, age }, index) => ({
          key: index,
          value: id,
          text: age,
        }))
      );
      heightOptions.push(
        ...heights.map(({ id, height }, index) => ({
          key: index,
          value: id,
          text: height,
        }))
      );

      genderOptions.push(
        ...genders.map(({ id, gender }, index) => ({
          key: index,
          value: id,
          text: gender,
        }))
      );
      ethnicityOptions.push(
        ...ethnicity.map(({ id, ethnicity }, index) => ({
          key: index,
          value: id,
          text: ethnicity,
        }))
      );
      zodiacSignsOptions.push(
        ...zodiacSigns.map(({ id, zodiacSign }, index) => ({
          key: index,
          value: id,
          text: zodiacSign,
        }))
      );
      familyOptions.push(
        ...family.map(({ id, familyPlans }, index) => ({
          key: index,
          value: id,
          text: familyPlans,
        }))
      );
      politicsOptions.push(
        ...politics.map(({ id, politics }, index) => ({
          key: index,
          value: id,
          text: politics,
        }))
      );
      religiousOptions.push(
        ...religious.map(({ id, religious }, index) => ({
          key: index,
          value: id,
          text: religious,
        }))
      );
      tagsOptions.push(
        ...tags.map(({ id, tag }, index) => ({
          key: index,
          value: id,
          text: tag,
        }))
      );
    }

    return (
      <Form style={{ padding: "3px 10px" }}>
        <div
          className="tab_content User_info_btn"
          style={{ marginBottom: "10px" }}
        >
          <Grid columns={3} className="tabContent">
            <Grid.Column onClick={() => setActiveIndex(0)}>
              {/* <span className={`active_btn ${activeIndex === 0 ? "Personal_active" : ""}`} style={{ borderRadius: '5px 0px 0px 5px', marginRight: "5px" }}>Personal</span> */}
              <Button
                className={`active_btn ${
                  activeIndex === 0 ? "Personal_active" : ""
                }`}
                style={{ borderRadius: "5px 0px 0px 5px", marginRight: "5px" }}
              >
                Personal
              </Button>
            </Grid.Column>
            <Grid.Column onClick={() => setActiveIndex(1)}>
              <Button
                className={`Hobbies_btn ${
                  activeIndex === 1 ? "Personal_active" : ""
                }`}
                style={{ borderRadius: "0px" }}
              >
                Hobbies
              </Button>
            </Grid.Column>
            <Grid.Column onClick={() => setActiveIndex(2)}>
              <Button
                className={`Address_btn ${
                  activeIndex === 2 ? "Personal_active" : ""
                }`}
                style={{ borderRadius: "0px 5px 5px 0px" }}
              >
                Address
              </Button>
            </Grid.Column>
          </Grid>
        </div>

        {activeIndex === 0 ? (
          <div>
            <div className="fieldsUser">
              <label>Desciption</label>
            </div>
            <div className="textbox">
              <textarea></textarea>
            </div>

            <Form.Group
              widths="equal"
              className="fieldsUser"
              style={{ display: "block" }}
            >
              <Form.Input fluid label={"Name"} value={user?.fullName || ""} />
            </Form.Group>
            <Form.Group
              widths="equal"
              className="fieldsUser"
              style={{ display: "block" }}
            >
              <label style={{ paddingLeft: "10px" }}>Age</label>
              <Form.Select
                fluid
                selection
                placeholder="Age"
                options={ageOptions}
                value={user?.age}
              />
            </Form.Group>
            <Form.Group
              widths="equal"
              className="fieldsUser"
              style={{ display: "block", paddingLeft: "10px" }}
            >
              <label>Gender</label>
              <div className="tab_content User_info_btn">
                <Grid columns={3} className="tabContent">
                  <Grid.Column>
                    <Button
                      className={`male_btn ${
                        user?.gender === 0 ? "gender_active" : ""
                      }`}
                    >
                      Male
                    </Button>
                  </Grid.Column>
                  <Grid.Column>
                    <Button
                      className={`female_btn femaleBtn ${
                        user?.gender === 1 ? "gender_active" : ""
                      }`}
                    >
                      Female
                    </Button>
                  </Grid.Column>
                  <Grid.Column>
                    <Button
                      className={`other_btn  ${
                        user?.gender === 2 ? "gender_active" : ""
                      } `}
                      style={{ paddingRight: "30px" }}
                    >
                      Other
                    </Button>
                  </Grid.Column>
                </Grid>
              </div>
            </Form.Group>

            <Form.Group
              widths="equal"
              inline
              style={{ display: "flex", justifyContent: "space-between" }}
              className="fieldsUser"
            >
              <div style={{ width: "50%", marginRight: "10px" }}>
                <label>Height</label>
                <Form.Select
                  fluid
                  selection
                  options={heightOptions}
                  placeholder="Height"
                  value={user?.height}
                />
              </div>
              <div style={{ width: "50%" }}>
                <label>Ethnicity</label>
                <Form.Select
                  fluid
                  selection
                  options={ethnicityOptions}
                  placeholder="Ethnicity"
                  value={user?.ethnicity}
                />
              </div>
            </Form.Group>

            <Form.Group
              widths="equal"
              inline
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: 0,
              }}
              className="fieldsUser"
            >
              <div style={{ width: "50%", marginRight: "10px" }}>
                <label>Zodiac Sign</label>
                <Form.Select
                  fluid
                  selection
                  options={zodiacSignsOptions}
                  placeholder="Zodiac Sign"
                  value={user?.zodiacSign}
                />
              </div>
              <div style={{ width: "50%" }}>
                <label>Religious Beliefs</label>
                <Form.Select
                  fluid
                  selection
                  options={religiousOptions}
                  placeholder="Religious Beliefs"
                  value={user?.religion}
                />
              </div>
            </Form.Group>

            <Form.Group widths="equal" inline className="fieldsUser">
              <div style={{ width: "50%", marginRight: "10px" }}>
                <Form.Input
                  fluid
                  label={"Education"}
                  // placeholder='Last Name'
                  value={user?.education}
                />
              </div>
              <div style={{ width: "50%" }}>
                <Form.Input
                  fluid
                  label={"Work"}
                  // placeholder='Last Name'
                  value={user?.work}
                />
              </div>
            </Form.Group>
          </div>
        ) : activeIndex === 1 ? (
          <div>
            <Form.Group
              widths="equal"
              className="fieldsUser"
              style={{ display: "block" }}
            >
              <label style={{ paddingLeft: "10px" }}>Politics</label>
              <Form.Select
                fluid
                selection
                placeholder="Politics"
                options={politicsOptions}
                value={user?.politics}
              />
            </Form.Group>
            <Form.Group
              widths="equal"
              className="fieldsUser"
              style={{ display: "block" }}
            >
              <Form.Input
                fluid
                label={"Books"}
                // placeholder='Last Name'
                value={user?.books?.toString()}
              />
            </Form.Group>
            <Form.Group
              widths="equal"
              className="fieldsUser"
              style={{ display: "block" }}
            >
              <Form.Input
                fluid
                label={"Music"}
                // placeholder='Last Name'
                value={user?.music?.toString()}
              />
            </Form.Group>
            <Form.Group
              widths="equal"
              className="fieldsUser"
              style={{ display: "block" }}
            >
              <Form.Input
                fluid
                label={"Movies"}
                // placeholder='Last Name'
                value={user?.movies?.toString()}
              />
            </Form.Group>
            <Form.Group
              widths="equal"
              className="fieldsUser"
              style={{ display: "block" }}
            >
              <Form.Input
                fluid
                label={"Tv Shows"}
                // placeholder='Last Name'
                value={user?.tvShows?.toString()}
              />
            </Form.Group>
            <Form.Group
              widths="equal"
              className="fieldsUser"
              style={{ display: "block" }}
            >
              <Form.Input
                fluid
                label={"Sport Teams"}
                // placeholder='Last Name'
                value={user?.sportsTeams?.toString()}
              />
            </Form.Group>
          </div>
        ) : activeIndex === 2 ? (
          <div>
            {/* <Form.Group widths="equal" className="fieldsUser" style={{ display: "block" }}>
          <Form.Input
            fluid
            label={"Address 1"}
            // placeholder='Last Name'
            value={user?.address}
          />
        </Form.Group> */}
            {/* <Form.Group widths="equal" className="fieldsUser" style={{ display: "block" }}>
          <Form.Input
            fluid
            label={"Address 2"}
            // placeholder='Last Name'
            value={user?.address}
          />
        </Form.Group> */}
            {/* <Form.Group widths="equal" className="fieldsUser" style={{ display: "block" }}>
          <Form.Input
            fluid
            label={"Pincode"}
          // placeholder='Last Name'
          // value={props?.fakeUsers.currentUser?.fullName.split(" ")[1] || ''}
          // onChange={props?.changeLastName}
          />
        </Form.Group> */}
            <Form.Group
              widths="equal"
              className="fieldsUser"
              style={{ display: "block" }}
            >
              <Form.Input
                fluid
                label={"City"}
                // placeholder='Last Name'
                value={user?.city}
              />
            </Form.Group>
            <Form.Group
              widths="equal"
              className="fieldsUser"
              style={{ display: "block" }}
            >
              <Form.Input
                fluid
                label={"State"}
                // placeholder='Last Name'
                value={user?.state}
              />
            </Form.Group>
            <Form.Group
              widths="equal"
              className="fieldsUser"
              style={{ display: "block" }}
            >
              <Form.Input
                fluid
                label={"Country"}
                // placeholder='Last Name'
                value={user?.country}
              />
            </Form.Group>
          </div>
        ) : null}
      </Form>
    );
  }
);

/************************************
 *
 *  MAIN CLASS COMPONENT WORKER DASHBOARD
 *
 ************************************/

const StoryMomentModal = React.memo(
  ({ open, setOpen, item, refetchMoments, refetchStories }) => {
    const [active, setActive] = useState(0);
    const [storyData, setStoryData] = useState([]);
    const [comment, setComment] = useState("");
    const [momentData, setMomentData] = useState({ likes: [], comments: [] });

    const getLikesComments = async () => {
      const momentLikes = await UserApi.getMomentLikes(item?.id);
      const momentComments = await UserApi.getMomentComments(item?.id);
      setMomentData({ likes: momentLikes, comments: momentComments });
    };

    useEffect(() => {
      if (item?.moment) getLikesComments();
    }, [item?.id]);

    const setData = () => {
      if (!item?.moment)
        setStoryData(active === 0 ? item?.likes : item?.comments);
      else {
        setStoryData(active === 0 ? momentData?.likes : momentData?.comments);
      }
    };
    useEffect(() => {
      setData();
    }, [active, momentData]);

    const addLike = async () => {
      if (item?.moment) {
        await UserApi.addLikeOnMoment(item?.id);
        refetchMoments();
      } else {
        await UserApi.addLikeOnStory(item?.pk);
        refetchStories();
      }
      setOpen(false);
    };

    const addComment = async () => {
      if (item?.moment) {
        await UserApi.addCommnetOnMoment({
          momentId: item?.id,
          comment: comment,
        });
        refetchMoments();
      } else {
        await UserApi.addCommnetOnStory(item?.pk, comment);
        refetchStories();
      }
      setComment("");
      setOpen(false);
    };

    // let datas = active === 0 ? item?.likes : item?.comments

    return (
      <Modal
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
        size={"tiny"}
      >
        <Modal.Header
          style={{
            background: "#FAA930",
            height: "40px",
            color: "white",
            display: "flex",
            alignItems: "center",
            fontSize: "14px",
          }}
        >
          {item?.moment
            ? `Moment title ${item?.momentDescription}`
            : `Story Title 123@`}
        </Modal.Header>
        {/* {error ? <Message negative style={{ margin: "0 10px" }} onDismiss={() => setError(false)}>
          <Message.Header>We're sorry we can't use current location </Message.Header>
          <p>User's location is not available</p>
        </Message> : null} */}
        <Modal.Content image style={{ paddingBottom: 0 }}>
          <div style={{ width: "100%" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
                marginBottom: "15px",
              }}
            >
              {item?.video ? (
                <video
                  src={item?.video}
                  width={"430px"}
                  height={"460px"}
                  onError={(err) => {}}
                  autoPlay
                ></video>
              ) : (
                <Image src={item?.file} width={"430px"} height={"460px"} />
              )}
              {/* <Image src={item?.file} width={"430px"} height={"460px"} /> */}
            </div>
            <div style={{ margin: "0 15px" }}>
              <div>
                <Grid columns={2}>
                  <Grid.Column style={{ padding: "20px 0" }}>
                    <Button
                      className={`${
                        active === 0 ? "storyActive" : ""
                      } storyButton  `}
                      style={{ borderRadius: "5px 0px 0px 5px" }}
                      onClick={() => setActive(0)}
                    >
                      Likes{" "}
                      <span
                        className={`count`}
                        style={{
                          textAlign: "center",
                          fontSize: "14px",
                          marginLeft: "5px",
                        }}
                      >
                        {item?.likesCount ?? item?.likes}
                      </span>
                    </Button>
                  </Grid.Column>
                  <Grid.Column style={{ padding: "20px 0" }}>
                    <Button
                      className={`${
                        active === 1 ? "storyActive" : ""
                      } storyButton `}
                      style={{ borderRadius: "0px 5px 5px 0px" }}
                      onClick={() => setActive(1)}
                    >
                      Comments
                      <span
                        className={`count`}
                        style={{
                          textAlign: "center",
                          fontSize: "14px",
                          marginLeft: "5px",
                        }}
                      >
                        {item?.commentsCount ?? item?.comments}
                      </span>
                    </Button>
                  </Grid.Column>
                </Grid>
              </div>
              <div style={{ marginBottom: "20px" }}>
                <Segment style={{ paddingRight: "5px" }}>
                  {storyData?.map((data, i) => {
                    const user = data?.node?.user || data?.user;
                    return (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                          padding: "15px 5px",
                          borderBottom:
                            storyData?.length - 1 === i
                              ? "0px"
                              : "0.5px solid rgba(34,36,38,.15)",
                        }}
                      >
                        <Image
                          src={
                            user?.avatar?.url ||
                            (user?.avatarPhotos && user?.avatarPhotos[0]?.url)
                          }
                          style={{ borderRadius: "50%" }}
                          width={"37px"}
                          height={"37px"}
                        />
                        <div
                          style={{
                            marginLeft: "10px",
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                          }}
                        >
                          <div>
                            <h4 style={{ marginBottom: "3px" }}>
                              {user?.username}
                            </h4>
                            <p style={{ color: "#8F8F8F", fontSize: "14px" }}>
                              {active === 0
                                ? `Liked at: ${moment(user?.lastSeen).format(
                                    "D/MM/YYYY hh:mm:ss a"
                                  )}`
                                : data?.node?.commentDescription ||
                                  data?.commentDescription}
                            </p>
                          </div>
                          {active === 1 ? (
                            <div>
                              <p style={{ color: "#8F8F8F", fontSize: "14px" }}>
                                {moment(
                                  data?.node?.createdDate || data?.createdDate
                                ).format("D/MM/YYYY hh:mm:ss a")}
                              </p>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    );
                  })}
                  {active ? (
                    <Segment className="commentBox">
                      <textarea
                        placeholder="Write your comment here"
                        value={comment}
                        onChange={(e) => setComment(e?.target?.value)}
                      ></textarea>
                    </Segment>
                  ) : null}
                </Segment>
              </div>
            </div>
          </div>
        </Modal.Content>
        {/* <div style={{ display: "flex", justifyContent: "flex-end", marginBottom: "10px", marginRight: "15px" }}>
          <Button id="currentLocation" color="blue" >Use current location</Button>
        </div> */}
        <Modal.Actions
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <Button
            onClick={() => setOpen(false)}
            icon={"window close outline"}
            style={{ background: "#3D3D3D", color: "white" }}
          >
            {/* <Icon name='window close outline' color="white" /> */}
            <span className="closeButton">
              <Image src={cancelVector} height={"16px"} width={"16px"} />
              Close
            </span>
          </Button>

          {active ? (
            <Button
              style={{ background: "#11B767", color: "white" }}
              disabled={!comment}
              onClick={() => addComment()}
            >
              <Icon
                name="check circle outline"
                color="white"
                size="small"
                style={{ fontSize: "16px" }}
              />
              Submit
            </Button>
          ) : (
            <Button
              style={{ background: "#BE3FEB", color: "white" }}
              onClick={() => addLike()}
            >
              <Icon
                name="heart outline"
                color="white"
                size="small"
                style={{ fontSize: "16px" }}
              />
              Like
            </Button>
          )}
        </Modal.Actions>
      </Modal>
    );
  }
);

const Index = (props) => {
  const [loader, setLoader] = useState(false);
  const [adminUser, setAdminUser] = useState(false);
  const { id } = useParams();
  const { session, menu, workers } = useSelector((state) => state);
  const [privateFakeUserPhoto] = useState(false);
  const [userInfoOpen] = useState(false);
  const [fakeUserInfoOpen] = useState(false);
  const [allStories, setAllStories] = useState([]);
  const [allMoments, setAllMoments] = useState([]);
  const [currentChatId] = useState(false);
  const [opponent] = useState(false);
  const [usersList] = useState(false);
  const [openGift] = useState(false);
  const [privatePhoto] = useState(false);
  const [currentUserDetails, setCurrentUserDetails] = useState(false);
  const [currentChatMessages] = useState(false);
  const [messages] = useState(false);
  const [moderatorId] = useState(false);
  const [currentUserStories, setCurrentUserStories] = useState([]);
  const [currentUserMoments, setCurrentUserMoments] = useState([]);
  const [user] = useState(false);

  const updateMessages = (data) => {
    this.setState({ ...this.state, messages: data });
  };

  const getAdminUserStories = async (id) => {
    // const currentUserStories = await UserApi.getUserStories(id)
    const currentUserStories = await ChatApi.getStories(
      session?.userDetails?.id
    );
    setAllStories(currentUserStories);
  };
  const getUserStories = async () => {
    const chatUserStories = await ChatApi.getStories(id);
    setCurrentUserStories(chatUserStories);
  };

  const getAdminUserMoments = async (id) => {
    const currentUserMoments = await ChatApi.getMoments(
      session?.userDetails?.id
    );
    setAllMoments(currentUserMoments);
  };
  const getUserMoments = async () => {
    const chatUserMoments = await ChatApi.getMoments(id);
    setCurrentUserMoments(chatUserMoments);
  };

  const getAdminUserDetails = async (id) => {
    const user = await UserApi.getUser(id);
    setAdminUser(user?.data?.user);
  };

  const getUserDetails = async () => {
    const user = await UserApi.getUser(id);
    setCurrentUserDetails(user?.data?.user);
  };

  useEffect(() => {
    getAdminUserDetails(session?.userDetails?.id);
    getAdminUserMoments(session?.userDetails?.id);
    getAdminUserStories(session?.userDetails?.id);
  }, []);

  useEffect(() => {
    if (id) {
      getUserDetails();
      getUserMoments();
      getUserStories();
    }
  }, [id]);

  const renderImage = (data = []) => {
    return data?.slice(0, 3)?.map((item) => {
      let image = item?.node?.thumbnail
        ? `/media/${item?.node?.thumbnail}`
        : item?.node?.file
        ? `/media/${item?.node?.file}`
        : item?.file?.includes("media")
        ? item?.file
        : `/media/${item?.file}`;
      return item?.node?.file || item?.file || item?.thumbnail ? (
        <img
          width={"120px"}
          height={"150px"}
          key={item?.id}
          src={item?.file}
          alt=""
          onClick={() => this.setState({ story: item })}
        />
      ) : null;
    });
  };
  const onSelectUser = (user) => {};
  const handleMessageSend = (msg, moderatorId, messageType) => {
    props?.sendTextMessage(
      props?.chat?.currentChat,
      msg || props?.chat.chatMessage,
      moderatorId || props?.chat.adminUser.id,
      props?.workers,
      messageType
    );
    this.setState({ currentUserDetails: {} });
  };
  return (
    <div>
      <div>
        <Dimmer.Dimmable dimmed={loader}>
          <Dimmer active={loader} inverted>
            <Loader>Loading</Loader>
          </Dimmer>

          <Grid columns={2} stretched>
            <Grid.Row style={{ paddingBottom: 0 }}>
              <Grid.Column width={4} floated="right">
                <Segment
                  style={{
                    padding: 0,
                    maxHeight: window.innerHeight - 50,
                    height: window.innerHeight - 50,
                    overflowY: "scroll",
                  }}
                >
                  <Grid.Column
                    width={9}
                    style={{
                      height: "100%",
                      overflowY: "scroll",
                      overflowX: "hidden",
                      padding: "0px 15px",
                    }}
                  >
                    <></>
                    <div className="cardWrapper">
                      <div className="card_inner">
                        <div className="card_header">
                          Keyword info for Session 86989
                        </div>
                        <div className="card_body">
                          <h4 className="card_body_heding">DEFAULT</h4>
                          <p className="card_body_description">
                            ABC Dating app, rated 12 chat without sex! Only with
                            longer dialogues, if can be a little sexy!
                          </p>
                        </div>
                        <div className="card_footer">
                          Customer Rating
                          <Rating maxRating={5} clearable icon="star" />
                        </div>
                      </div>
                    </div>
                    <div className="samedave">
                      {adminUser?.fullName || adminUser?.username}
                    </div>
                    <div className="moderat">MODERATOR</div>
                    <div className="moderator_container">
                      {adminUser?.avatar?.url ? (
                        <img
                          style={{
                            width: "85%",
                            border: "2px solid #d5d5d5",
                            maxHeight: "291px",
                          }}
                          src={adminUser?.avatar?.url}
                          // src={baseUrl + (adminUser?.avatar_photos[0]?.file || adminUser?.avatar_photos[0]?.file_url)}
                          // height={props?.height}
                          // onClick={props?.onClick}
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = Photos_4;
                          }}
                          alt=""
                        />
                      ) : null}
                    </div>
                    <Segment className="photos_container">
                      <Card>
                        <Card.Content className="photos_content">
                          <Card.Header className="photos_header">
                            <div>Photo's</div>
                            <div className="tab_content">
                              <span
                                className={`tab_name ${
                                  !privateFakeUserPhoto ? "active" : ""
                                }`}
                                style={{ borderRadius: "5px 0px 0px 5px" }}
                                onClick={() =>
                                  this.setState({
                                    privateFakeUserPhoto: false,
                                    selectedUser:
                                      adminUser?.avatar_photos?.find(
                                        (item) => item?.is_admin_approved
                                      ),
                                  })
                                }
                              >
                                Public
                              </span>
                              <span
                                className={`tab_name ${
                                  privateFakeUserPhoto ? "active" : ""
                                }`}
                                style={{ borderRadius: "0px 5px 5px 0px" }}
                                onClick={() =>
                                  this.setState({
                                    privateFakeUserPhoto: true,
                                    selectedUser:
                                      adminUser?.avatar_photos?.find(
                                        (item) => !item?.is_admin_approved
                                      ),
                                  })
                                }
                              >
                                Private
                              </span>
                            </div>
                          </Card.Header>

                          <SliderParent
                            data={adminUser?.avatarPhotos?.filter((item) =>
                              privateFakeUserPhoto
                                ? item?.type === "PRIVATE"
                                : item?.type === "PUBLIC"
                            )}
                            onSelect={onSelectUser}
                          />
                        </Card.Content>
                      </Card>
                    </Segment>
                    <Dropdown
                      className="UserDropdown"
                      text="User Info"
                      onClick={() =>
                        this.setState({ fakeUserInfoOpen: !fakeUserInfoOpen })
                      }
                      icon={!fakeUserInfoOpen ? "caret down" : "caret up"}
                    >
                      <Dropdown.Menu></Dropdown.Menu>
                    </Dropdown>
                    <div></div>
                    <div className="UserStory">
                      <span className="story">Story</span>
                      <span className="view">View All</span>
                    </div>
                    <Card.Description className="slider_content img">
                      {renderImage(allStories)}
                    </Card.Description>
                    <div className="UserMoment">
                      <span className="moment">Moment</span>
                      <span className="view1">View All</span>
                    </div>
                    <Card.Description className="slider_content img">
                      {renderImage(allMoments)}
                    </Card.Description>
                    <Notes
                      id={currentChatId}
                      value={opponent ? opponent.moderatorNote : ""}
                      saveValue={(roomId, content, callback) =>
                        props?.createNotes(roomId, content, false, callback)
                      }
                    />
                  </Grid.Column>
                </Segment>
              </Grid.Column>
              <Grid.Column width={8}>
                {!usersList && (
                  <MessageBox
                    currentChat={currentChatMessages}
                    appendChatHistory={props?.appendChatHistory}
                    messages={messages}
                    opponent={opponent}
                    currentChatId={currentChatId}
                    fakeUser={adminUser}
                    fakeUserId={props?.chat?.currentFakeUser?.id}
                    session={props?.session}
                    userDetails={user}
                    refreshChat={props?.refreshChat}
                  />
                )}
                {!usersList && (
                  <>
                    <Card className="messageBox">
                      <Popup
                        content="Message should be minimum 50 characters long!"
                        style={{
                          background: "#E2B747",
                          color: "white",
                          marginBottom: "25px",
                        }}
                        disabled={props?.chat?.chatMessage?.length >= 50}
                        open
                        trigger={<div></div>}
                      />
                      <textarea
                        placeholder="Type here"
                        position="top left"
                        // value={this?.props?.chat?.chatMessage}
                        // onChange={this?.props?.changeChatMessage}
                        disabled={!props?.chat?.currentChat}
                        value={props?.chat?.chatMessage || ""}
                        onChange={props?.changeChatMessage}
                        maxLength="150"
                        minLength="50"
                        rows={5}
                      ></textarea>
                      <Grid columns={12}>
                        <Grid.Row style={{ paddingTop: 0 }}>
                          <Grid.Column
                            width={4}
                            style={{ padding: "3px" }}
                          ></Grid.Column>
                          <Grid.Column width={4} style={{ padding: "3px" }}>
                            <div style={{ height: "90px" }}></div>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "end",
                                paddingTop: "10px",
                              }}
                            >
                              <Button
                                className="sendGift"
                                disabled={!Boolean(props?.chat?.currentChat)}
                                onClick={() =>
                                  this.setState({ openGift: !openGift })
                                }
                              >
                                Send Gift
                              </Button>
                            </div>
                          </Grid.Column>
                          <Grid.Column
                            width={5}
                            style={{
                              padding: "3px",
                              display: "flex",
                              alignItems: "end",
                            }}
                          >
                            <Button
                              className="shareLocation"
                              onClick={() => this.setState({ modal: true })}
                              disabled={!Boolean(props?.chat?.currentChat)}
                            >
                              Share Location
                            </Button>
                          </Grid.Column>
                          <Grid.Column
                            width={3}
                            style={{
                              padding: "3px",
                              display: "flex",
                              alignItems: "end",
                            }}
                          >
                            <Button
                              className="send"
                              onClick={() => {
                                if (this.imageRef.current?.state?.src) {
                                  this.imageRef.current.handleUpload();
                                } else {
                                  this.state?.gift?.id
                                    ? this.sendGift(
                                        opponent?.user_id,
                                        opponent?.friendId,
                                        this.state?.gift?.id,
                                        "G"
                                      )
                                    : this.handleMessageSend(
                                        `${props?.chat?.chatMessage}`,
                                        "",
                                        "C"
                                      );
                                  // this.handleMessageSend(`${this.state?.gift?.picture}   ${props?.chat.chatMessage}`, "", this.state?.gift?.id ? "G" : "C")
                                }
                              }}
                              disabled={
                                !props?.chat?.currentChat ||
                                props?.chat?.chatMessage === "" ||
                                props?.chat?.chatMessage?.length < 50
                              }
                            >
                              Send
                            </Button>
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    </Card>
                    {openGift ? (
                      <SendGiftSection
                        receiverId={opponent?.friendId}
                        senderId={opponent?.user_id}
                        setGift={(data) => this.setState({ gift: data })}
                        refreshChat={props?.refreshChat}
                        gift={this.state?.gift}
                        setOpenGift={() => this.setState({ openGift: false })}
                        removeCurrentChats={props?.removeCurrentChats}
                      />
                    ) : null}

                    {this?.state?.modal ? (
                      <ShareLocation
                        moderatorId={moderatorId}
                        shareLocation={(text) =>
                          this.shareLocation(moderatorId, text, "GL")
                        }
                        setOpen={() =>
                          this.setState({ modal: !this?.state?.modal })
                        }
                        open={this?.state?.modal}
                        location={adminUser?.location}
                      />
                    ) : null}
                  </>
                )}
              </Grid.Column>
              <Grid.Column
                width={4}
                floated="left"
                style={{
                  maxHeight: window.innerHeight - 50,
                  height: window.innerHeight - 50,
                  overflowY: "scroll",
                  overflowX: "hidden",
                  padding: 0,
                }}
              >
                <div>
                  <div className="yannmartin">
                    {currentUserDetails?.fullName ||
                      currentUserDetails?.username}
                  </div>
                  <div className="headeruser">USER</div>
                  {currentUserDetails?.avatar?.url ? (
                    <img
                      style={{ width: "100%" }}
                      src={currentUserDetails?.avatar?.url}
                      // src={(user?.avatar_photos[0]?.file || user?.avatar_photos[0]?.file_url)?.includes(baseUrl) ? (user?.avatar_photos[0]?.file || user?.avatar_photos[0]?.file_url) : baseUrl + (user?.avatar_photos[0]?.file || user?.avatar_photos[0]?.file_url)}
                      // height={props?.height}
                      // onClick={props?.onClick}
                      alt=""
                    />
                  ) : null}
                  <Segment className="photos_container">
                    <Card>
                      <Card.Content className="photos_content">
                        <Card.Header className="photos_header">
                          <div>Photo's</div>
                          <div className="tab_content">
                            <span
                              className={`tab_name ${
                                !privatePhoto ? "active" : ""
                              }`}
                              style={{ borderRadius: "5px 0px 0px 5px" }}
                              onClick={() =>
                                this.setState({ privatePhoto: false })
                              }
                            >
                              Public
                            </span>
                            <span
                              className={`tab_name ${
                                privatePhoto ? "active" : ""
                              }`}
                              style={{ borderRadius: "0px 5px 5px 0px" }}
                              onClick={() =>
                                this.setState({ privatePhoto: true })
                              }
                            >
                              Private
                            </span>
                          </div>
                        </Card.Header>
                        <SliderParent
                          data={currentUserDetails?.avatarPhotos?.filter(
                            (item) =>
                              privatePhoto
                                ? item?.type === "PRIVATE"
                                : item?.type === "PUBLIC"
                          )}
                        />
                      </Card.Content>
                    </Card>
                  </Segment>
                  <div className="userInfo">
                    <Segment style={{ padding: "0px 0px" }}>
                      <Dropdown
                        className="UserDropdown"
                        text="User Info"
                        onClick={() =>
                          this.setState({ userInfoOpen: !userInfoOpen })
                        }
                        pointing={"top"}
                        icon={!userInfoOpen ? "caret down" : "caret up"}
                      >
                        <div></div>
                      </Dropdown>
                    </Segment>
                  </div>
                  <div className="UserStory">
                    <span className="story">Story</span>
                    <span className="view">View All</span>
                  </div>
                  <Card.Description className="slider_content img">
                    {renderImage(currentUserStories)}
                  </Card.Description>
                  <div className="UserMoment">
                    <span className="moment">Moment</span>
                    <span className="view1">View All</span>
                  </div>
                  <Card.Description className="slider_content img">
                    {renderImage(currentUserMoments)}
                  </Card.Description>
                </div>
              </Grid.Column>

              {this?.state?.story ? (
                <StoryMomentModal
                  open={this?.state?.story ? true : false}
                  setOpen={() => this.setState({ story: "" })}
                  item={this?.state?.story}
                  refetchMoments={() => {}}
                  refetchStories={() => {
                    this.getAdminUserStories(currentUserDetails?.id);
                    this.getUserStories(adminUser?.id);
                  }}
                />
              ) : null}
            </Grid.Row>
          </Grid>
        </Dimmer.Dimmable>
      </div>
    </div>
  );
};

export default Index;
