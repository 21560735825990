export const usersActionTypes = {
  GET_USERS_LIST_REQUEST: "GET_USERS_LIST_REQUEST",
  GET_USERS_LIST_FAILURE: "GET_USERS_LIST_FAILURE",
  GET_USERS_LIST_SUCCESS: "GET_USERS_LIST_SUCCESS",
  SEARCH_VALUE_CHANGE: "SEARCH_VALUE_CHANGE",
  SEARCH_USERS_LIST_REQUEST: "SEARCH_USERS_LIST_REQUEST",
  SEARCH_USERS_LIST_FAILURE: "SEARCH_USERS_LIST_FAILURE",
  SEARCH_USERS_LIST_SUCCESS: "SEARCH_USERS_LIST_SUCCESS",
  SORT_USERS_LIST: "SORT_USERS_LIST",
  REMOVE_USER_REQUEST: "REMOVE_USER_REQUEST",
  REMOVE_USER_SUCCESS: "REMOVE_USER_SUCCESS",
  REMOVE_USER_FAILURE: "REMOVE_USER_FAILURE",
  REMOVE_USER_REPORTS_REQUEST: "REMOVE_USER_REPORTS_REQUEST",
  REMOVE_USER_REPORTS_SUCCESS: "REMOVE_USER_REPORTS_SUCCESS",
  REMOVE_USER_REPORTS_FAILURE: "REMOVE_USER_REPORTS_FAILURE",
  EDIT_USER_MAIN_REQUEST: "EDIT_USER_MAIN_REQUEST",
  EDIT_USER_MAIN_SUCCESS: "EDIT_USER_MAIN_SUCCESS",
  EDIT_USER_MAIN_FAILURE: "EDIT_USER_MAIN_FAILURE",
  EDIT_USER_DETAILS_REQUEST: "EDIT_USER_DETAILS_REQUEST",
  EDIT_USER_DETAILS_SUCCESS: "EDIT_USER_DETAILS_SUCCESS",
  EDIT_USER_DETAILS_FAILURE: "EDIT_USER_DETAILS_FAILURE",
  CHANGE_NAME: "CHANGE_NAME",
  CHANGE_PHONE: "CHANGE_PHONE",
  CHANGE_EMAIL: "CHANGE_EMAIL",
  CHANGE_AGE: "CHANGE_AGE",
  CHANGE_GENDER: "CHANGE_GENDER",
  CHANGE_ABOUT: "CHANGE_ABOUT",
  CHANGE_NOTE: "CHANGE_NOTE",
  CHANGE_CITY: "CHANGE_CITY",
  CHANGE_COUNTRY: "CHANGE_COUNTRY",
  REMOVE_IMAGE: "REMOVE_IMAGE",
  CHANGE_LANGUAGES: "CHANGE_LANGUAGES",
  NEW_USER: "NEW_USER",
  CHANGE_NEW_USER_EMAIL: "CHANGE_NEW_USER_EMAIL",
  CHANGE_NEW_USER_ADDRESS: "CHANGE_NEW_USER_ADDRESS",
  CREATE_USER_REQUEST: "CREATE_USER_REQUEST",
  CREATE_USER_SUCCESS: "CREATE_USER_SUCCESS",
  CREATE_USER_FAILURE: "CREATE_USER_FAILURE",
  BLOCK_USER_REQUEST: "BLOCK_USER_REQUEST",
  BLOCK_USER_SUCCESS: "BLOCK_USER_SUCCESS",
  BLOCK_USER_FAILURE: "BLOCK_USER_FAILURE",
  UNBLOCK_USER_REQUEST: "UNBLOCK_USER_REQUEST",
  UNBLOCK_USER_SUCCESS: "UNBLOCK_USER_SUCCESS",
  UNBLOCK_USER_FAILURE: "UNBLOCK_USER_FAILURE",
  SAVE_USER_REQUEST: "SAVE_USER_REQUEST",
  SAVE_USER_SUCCESS: "SAVE_USER_SUCCESS",
  SAVE_USER_FAILURE: "SAVE_USER_FAILURE",
  HIDE_ERROR: "HIDE_ERROR",
  CLEAR_USER: "CLEAR_USER",
  GET_USERS_IN_ROOM: "GET_USERS_IN_ROOM",
  UPDATE_EDIT_USER: "UPDATE_EDIT_USER",
};
