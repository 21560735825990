import React, { useEffect } from "react";
import { connect } from "react-redux";
import MainMenu from "../../components/mainMenu";
import {
  newUser,
  removeUserReports,
  changeName,
  changeAge,
  changeAbout,
  changeGender,
  changeEmail,
  changePhone,
  removeImage,
  fetchUserDetails,
  fetchUserMain,
  setUserMain,
  blockUser,
  unblockUser,
  saveUser,
  hideError,
  changeLanguages,
  changeAddress,
  changeCity,
  changeCountry,
  updateEditUser,
  getUsersList,
} from "../../actions/users";
import { changeLocation } from "../../actions/fakeUsers";
import { setActiveMenuPosition } from "../../actions/menu";
import { logoutAction } from "../../actions/session";
import { getPresets } from "../../actions/fakeUsers";
import User from "./User";

const Edituser = (props) => {
  useEffect(() => {
    const { id } = props.match.params;
    if (props.menu.activeMenu !== "users") {
      props.setActiveMenuPosition("users");
    }
    props.fetchUserMain(id);
  }, []);

  return (
    <div>
      <MainMenu
        needShowPrivateItems={props.session.isAuthenticated}
        menu={props.menu}
        onLogout={props.onLogout}
        setActiveMenuPosition={props.setActiveMenuPosition}
        userDetails={props.session.userDetails}
      />
      <User
        users={props.users}
        removeUserReports={props.removeUserReports}
        changeName={props.changeName}
        changeAge={props.changeAge}
        changeGender={props.changeGender}
        changeAbout={props.changeAbout}
        changeCity={props.changeCity}
        changeCountry={props.changeCountry}
        changeEmail={props.changeEmail}
        changePhone={props.changePhone}
        removeImage={props.removeImage}
        blockUser={props.blockUser}
        unblockUser={props.unblockUser}
        saveUser={props.saveUser}
        hideError={props.hideError}
        session={props.session}
        fakeUsers={props.fakeUsers}
        changeLanguages={props.changeLanguages}
        changeAddress={props.changeAddress}
        presets={props.presets}
        updateEditUser={props.updateEditUser}
        getUsersList={props.getUsersList}
        changeInterestedIn={props.changeInterestedIn}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    menu: state.menu,
    users: state.users,
    session: state.session,
    fakeUsers: state.fakeUsers,
    presets: state.presets,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    removeUserReports: (id) => {
      removeUserReports(id)(dispatch);
    },
    getUsersList: (offset, ordered, orderedField) => {
      getUsersList(offset, ordered, orderedField)(dispatch);
    },
    changeName: (e, data) => {
      changeName(data.value)(dispatch);
    },
    changeAge: (e, data) => {
      changeAge(data.value)(dispatch);
    },
    changeGender: (e, data) => {
      changeGender(data.value)(dispatch);
    },
    changeAbout: (e, data) => {
      changeAbout(data.value)(dispatch);
    },
    changeCountry: (e, data) => {
      changeCountry(data.value)(dispatch);
    },
    changeCity: (e, data) => {
      changeCity(data.value)(dispatch);
    },
    changePhone: (e, data) => {
      changePhone(data.value)(dispatch);
    },
    changeEmail: (e, data) => {
      changeEmail(data.value)(dispatch);
    },
    removeImage: (image) => {
      removeImage(image)(dispatch);
    },
    blockUser: (id) => {
      blockUser(id)(dispatch);
    },
    unblockUser: (id) => {
      unblockUser(id)(dispatch);
    },
    saveUser: (id, user) => {
      saveUser(id, user)(dispatch);
    },
    newUser: () => {
      newUser()(dispatch);
    },
    fetchUserDetails: (id) => {
      fetchUserDetails(id)(dispatch);
    },
    setUserMain: (user) => {
      setUserMain(user)(dispatch);
    },
    fetchUserMain: (id) => {
      fetchUserMain(id)(dispatch);
    },
    hideError: () => {
      hideError()(dispatch);
    },
    setActiveMenuPosition(activeMenu) {
      setActiveMenuPosition(activeMenu)(dispatch);
    },
    onLogout() {
      logoutAction()(dispatch);
    },
    getPresets() {
      getPresets()(dispatch);
    },
    changeLanguages: (e, data) => {
      changeLanguages(data.value)(dispatch);
    },
    changeAddress: (e, data) => {
      changeAddress(e.currentTarget.value)(dispatch);
    },
    changeLocation: (e, data) => {
      changeLocation(data.location)(dispatch);
    },
    updateEditUser: (payload) => {
      updateEditUser(payload)(dispatch);
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Edituser);
