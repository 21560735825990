import React, { Component } from "react";
import Photo from "./photo";
import { Button, Grid, Loader, Icon } from "semantic-ui-react";
import SelectImageCont from "./selectImageCont";
import UserApi from "../../utils/users";
import { isImage } from "../../utils/helpers";

class ChatImageUpload extends Component {
  constructor(props) {
    super(props);

    this.state = {
      image: null,
      src: "",
      progress: 0,
      isValid: false,
      loading: false,
      imageContOpen: false,
    };

    this.getStockImages = this.getStockImages.bind(this);
  }

  setUrl = (url) => {
    this.setState({ image: null, src: url });
  };

  selectImage = (e) => {
    if (this.props?.moderatorId) {
      this.setState({
        ...this.state,
        imageContOpen: true,
      });
      this.props.onChange && this.props.onChange(true);
      this.setState({
        ...this.state,
        imageContOpen: true,
      });
    }
  };

  handleChange = (e) => {
    if (e.target.files[0]) {
      const image = e.target.files[0];
      this.setState(() => ({ image }));
      generatePreviewImgUrl(image, (previewImgUrl) => {
        // (assuming we use React)
        this.setState({ src: previewImgUrl });
      });
    }
  };

  handleChangeSelect = (image) => {
    if (image && !image?.type) {
      this.props.onChange && this.props.onChange(false);
      this.setState({
        ...this.state,
        image: image,
        src: image.getAttribute("src"),
        imageContOpen: false,
      });
    }
  };

  reset = () => {
    this.setState({
      image: null,
      src: "",
      progress: 0,
      isValid: false,
      loading: false,
    });
  };

  onLoad = () => {
    this.setState({
      isValid: true,
      loading: false,
    });
  };

  onError = () => {
    this.setState({
      isValid: false,
      loading: false,
    });
  };

  handleUpload = () => {
    this.setState({
      loading: true,
    });
    const { image } = this.state;
    const { folder } = this.props;

    const imageName = generateString(29) + ".jpg";
    const fileParams = {
      name: imageName,
      file: image ? image.getAttribute("data-id") : image,
      type: image?.type ?? "",
      size: image?.size ?? "",
      imageId: image.getAttribute("data-id"),
      public: false,
    };
    this.props.onUpload(fileParams);
    // this.setState({
    //   image: null,
    //   src: "",
    //   progress: 0,
    //   isValid: false,
    //   loading: false,
    // });
  };

  setImageContOpen = (value) => {
    this.setState({
      ...this.state,
      imageContOpen: value,
    });
    this.props.onChange && this.props.onChange(value);
  };

  getStockImages = async () => {
    const res = await UserApi.getStockImages(this.props?.moderatorId);
    this.setState({
      ...this.state,
      stockImages: res?.data?.stockImages || [],
    });
  };

  componentDidMount() {
    this.getStockImages(this.props?.moderatorId);
  }

  openSelectImage = () => {
    this.setState({ ...this.state, src: "" });
  };

  render() {
    return (
      <div style={{ position: "relative" }}>
        <Loader active={this.state.loading} />
        {/* <input type="file" this.props.onChange && this.props.onChange={this.handleChange} style={{ display: "none" }} ref={input => this.inputRef = input} /> */}

        {/* <input type="file" onChange={this.handleChange} style={{ width: "100%", height:this.props.height, opacity: 0, overflow: "hidden", position:"absolute", right:0,bottom:0}} ref={input => this.inputRef = input} /> */}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: "10px",
            height: this.props.height,
          }}
        >
          {this.state.src ? (
            isImage(this.state.src) ? (
              <Photo
                height={this.props.height}
                width={"95%"}
                src={this.state.src}
                onLoad={this.onLoad}
                onError={this.onError}
                onClick={() => { }}
                // onClick={this.selectImage}
                disabled={this.props.disabled}
                style={{
                  objectFit: "contain",
                  marginTop: "7vh",
                  opacity: "1",
                  zIndex: "1000",
                }}
              />
            ) : (
              <video
                src={this.state.src}
                height={this.props.height}
                autoPlay
                onLoadedData={(e) => {
                  e.target.play();
                }}
                width={"95%"}
                muted
                playsInline
              ></video>
            )
          ) : null}
          {this.state.src && (
            <div className="123456789">
              <Icon
                link
                corner
                name={"close"}
                onClick={() => this.openSelectImage()}
              />
            </div>
          )}
        </div>
        {/* {this.state.src !== '' && <Icon link corner name={'close'} onClick={this.reset} />} */}

        {/* <Button
              disabled={!this.state.isValid || this.props.disabled}
              style={{ background: "#21AFDC", color: "white", width: "260px", height: "42px" ,position:"relative"}}
              content={this.props.header}
              onClick={(e, data) => { e.preventDefault(); this.handleUpload() }}
            /> */}
        <SelectImageCont
          isOpen={this.state.imageContOpen}
          setIsOpen={this.setImageContOpen}
          moderatorId={this.props?.moderatorId}
          getStockImages={this.props?.getStockImages}
          stockImages={this.props?.stockImages}
          onSelect={(image) => this.handleChangeSelect(image)}
        >
          <Button
            style={{
              background: "#21AFDC",
              color: "white",
              width: "99%",
              height: "42px",
              position: "relative",
            }}
            onClick={this.props.disabled ? () => { } : this.selectImage}
            disabled={Boolean(this.props.disabled || this.state.src)}
          >
            {this.props.header}
            {/* <input type={"file"} style={{ width: "100%", height:"42px", opacity: 0, overflow: "hidden", position: "absolute", right:0,bottom:0}} onChange={this.handleChange} /> */}
          </Button>
        </SelectImageCont>
      </div>
    );
  }
}

export default ChatImageUpload;

function generatePreviewImgUrl(fileUrl, callback) {
  const reader = new FileReader();
  const url = reader.readAsDataURL(fileUrl);
  reader.onloadend = (e) => callback(reader.result);
}

function generateString(length) {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}
