import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Card,
  Header,
  Icon,
  Message,
  Modal,
  Popup,
} from "semantic-ui-react";
import { onAddImage } from "../../actions/fakeUsers";
import { selectFakeUsersState } from "../../store/selectors/fakeUsersSelectors";
import UserApi from "../../utils/users";
import AddNewPhoto from "../imageUpload/addNewPhoto";
import { baseUrl } from "../../utils/api";
import { getImageUrl, isVideo } from "../../utils/helpers";

const FakeUserPrivatePhotos = ({ userId }) => {
  const dispatch = useDispatch();
  const fakeUsersState = useSelector(selectFakeUsersState);
  // const userId = fakeUsersState?.currentUser?.id;
  // const isfakeUsersStateLoading = fakeUsersState?.loading;
  // const avatarPhotos = fakeUsersState?.currentUser?.avatar_photos ?? [];

  const [state, setState] = useState({
    isDeleteModalOpen: false,
    imageId: "",
    isLoading: false,
    errors: [],
    isUpdatingImageIndex: false,
    messages: [],
  });

  const [privateImages, setPrivateImages] = useState([]);

  const handleDeleteModal = ({ imageId, isDeleteModalOpen }) => {
    setState({
      ...state,
      imageId,
      isDeleteModalOpen,
      errors: [],
    });
  };

  const getPrivateImages = async () => {
    try {
      const res = await UserApi.getPrivateImages(userId);
      if (
        res?.data?.privateUserPhotos &&
        Array.isArray(res?.data?.privateUserPhotos)
      ) {
        setPrivateImages([...res?.data?.privateUserPhotos]);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const removeUserPrivateImage = async () => {
    setState({
      ...state,
      isLoading: true,
    });
    const res = await UserApi.removeUserPrivateImage({
      imageId: state.imageId,
      moderatorId: userId,
    });
    if (!res?.success) {
      setState({
        ...state,
        isLoading: false,
        errors: [
          res?.message ?? "Something went wrong while removing profile image!",
        ],
      });
      return;
    }
    setState({
      ...state,
      isLoading: false,
      isDeleteModalOpen: false,
      errors: [],
    });
    window.location.reload();
  };

  const handleRemoveMessage = (messageIndex) => {
    let updatedMessages = state.messages.filter(
      (msg, idx) => idx !== messageIndex
    );

    setState({
      ...state,
      messages: updatedMessages,
    });
  };

  const onAddNewPhoto = (item) => {
    dispatch(onAddImage(item));
  };

  const onuploadAvatarPhoto = async (params) => {
    const data = await UserApi.uploadPrivatePicture(
      params?.id ? "" : params.file,
      userId,
      params?.id
    );
    getPrivateImages();
  };

  useEffect(() => {
    getPrivateImages();
    setInterval(() => {
      getPrivateImages();
    }, 10000);
  }, [userId]);

  return (
    <div style={{ width: "100%" }}>
      <div style={{ width: "100%", marginBottom: 15 }}>
        {state?.messages?.length
          ? state.messages.map((message, idx) => (
              <Message
                style={{ display: "block", textAlign: "start" }}
                error={message.type === "error"}
                success={message.type === "success"}
                onDismiss={() => handleRemoveMessage(idx)}
              >
                {message.text}
              </Message>
            ))
          : null}
      </div>
      <Modal open={state.isDeleteModalOpen}>
        <Header icon="archive" content="Are you sure want to delete ?" />
        <Modal.Content>
          <p>The file should be not recovered!</p>
        </Modal.Content>
        <Modal.Actions>
          {state?.errors?.length
            ? state.errors.map((error) => (
                <Message
                  style={{ display: "block", textAlign: "start" }}
                  error={true}
                >
                  {error}{" "}
                </Message>
              ))
            : null}
          <Button
            color="red"
            onClick={() =>
              handleDeleteModal({ imageId: "", isDeleteModalOpen: false })
            }
          >
            <Icon name="remove" /> Cancel
          </Button>
          <Button
            loading={state.loading}
            color="green"
            onClick={() => removeUserPrivateImage()}
          >
            <Icon name="checkmark" /> Delete
          </Button>
        </Modal.Actions>
      </Modal>
      <Card.Group itemsPerRow={4}>
        {privateImages?.map((image, avatarIndex) => {
          return (
            <UsersPhoto
              key={image?.id ?? avatarIndex}
              photoData={image}
              photoIndex={avatarIndex}
              handleDeleteModal={handleDeleteModal}
            />
          );
        })}
        <Card>
          <AddNewPhoto
            onUpload={onuploadAvatarPhoto}
            onAdd={onAddNewPhoto}
            height={"200px"}
            header={"Add new image"}
            stockImage={true}
            stockImages={fakeUsersState?.stockImages?.map((image) => {
              const imageReplace = image?.file?.startsWith(
                "https://djbbbm5n90p1g.cloudfront.net"
              )
                ? image?.file
                : image?.file?.replace(`${baseUrl}/media/`, "");

              return {
                ...image,
                file: image?.file?.startsWith(
                  "https://djbbbm5n90p1g.cloudfront.net"
                )
                  ? imageReplace
                  : getImageUrl(image?.file),
              };
            })}
            moderatorId={fakeUsersState.currentUser.id}
            getStockImages={(id) => {}}
            folder={fakeUsersState.currentUser.id || "images"}
          />
        </Card>
      </Card.Group>
    </div>
  );
};

/**
 *
 * UsersPhoto component
 */

const UsersPhoto = ({ photoData, photoIndex, handleDeleteModal }) => {
  const getImageUrl = (fileUrl) => {
    if (fileUrl?.startsWith("https://djbbbm5n90p1g.cloudfront.net")) {
      return fileUrl;
    } else {
      return baseUrl + fileUrl;
    }
  };
  return (
    <Card className="user-details-form_images-list">
      <div className="image-container">
        {isVideo(getImageUrl(photoData?.file)) ? (
          <video style={{ width: "100%", height: "290px" }} autoPlay muted loop>
            <source src={getImageUrl(photoData?.file)} type="video/mp4" />
          </video>
        ) : (
          <img src={getImageUrl(photoData?.file)} alt="Private" />
        )}
        <button
          style={{
            width: 30,
            height: 30,
            background: "#ffffff",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            borderRadius: "10px",
            top: 5,
            right: 5,
            border: "none",
          }}
          type="button"
          onClick={() =>
            handleDeleteModal({
              imageId: photoData?.id,
              isDeleteModalOpen: true,
            })
          }
        >
          <div>
            <Icon
              className="create-fake-moderator-remove-moment-image-icon"
              link
              corner
              name={"trash"}
            />
          </div>
        </button>
      </div>
      {photoData?.createdBy !== null && (
        <span style={{ textAlign: "center" }}>
          <p>
            <b style={{ textTransform: "uppercase" }}>Created By:</b> &nbsp;
            {photoData?.createdBy?.fullName}
            &nbsp;
            <Popup
              hideOnScroll
              on="click"
              content="ID Copied!"
              position="top center"
              trigger={
                <Icon
                  name="id badge"
                  title="Click to copy ID"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    navigator.clipboard.writeText(photoData?.createdBy?.id);
                  }}
                />
              }
            />
          </p>
        </span>
      )}
    </Card>
  );
};

export default FakeUserPrivatePhotos;
