export const fakeUsersActionTypes = {
  GET_FAKE_USERS_LIST_REQUEST: "GET_FAKE_USERS_LIST_REQUEST",
  GET_FAKE_USERS_LIST_FAILURE: "GET_FAKE_USERS_LIST_FAILURE",
  GET_FAKE_USERS_LIST_SUCCESS: "GET_FAKE_USERS_LIST_SUCCESS",
  GET_FAKE_USER_REQUEST: "GET_FAKE_USER_REQUEST",
  GET_FAKE_USER_SUCCESS: "GET_FAKE_USER_SUCCESS",
  GET_FAKE_USER_FAILURE: "GET_FAKE_USER_FAILURE",
  FAKE_USER_SET_WORKER_ID: "FAKE_USER_SET_WORKER_ID",
  FAKE_USER_SET_ID: "FAKE_USER_SET_ID",
  CHANGE_FAKE_USER_NAME: "CHANGE_FAKE_USER_NAME",
  CHANGE_FAKE_USER_LAST_NAME: "CHANGE_FAKE_USER_LAST_NAME",
  CHANGE_FAKE_USER_EMAIL: "CHANGE_FAKE_USER_EMAIL",
  CHANGE_FAKE_USER_AGE: "CHANGE_FAKE_USER_AGE",
  CHANGE_FAKE_USER_GENDER: "CHANGE_FAKE_USER_GENDER",
  CHANGE_FAKE_USER_ABOUT: "CHANGE_FAKE_USER_ABOUT",
  CHANGE_FAKE_USER_NOTE: "CHANGE_FAKE_USER_NOTE",
  CHANGE_FAKE_USER_EDUCATION: "CHANGE_FAKE_USER_EDUCATION",
  CHANGE_FAKE_USER_ETHNICITY: "CHANGE_FAKE_USER_ETHNICITY",
  CHANGE_FAKE_USER_FAMILY_PLANS: "CHANGE_FAKE_USER_FAMILY_PLANS",
  CHANGE_FAKE_USER_HEIGHT: "CHANGE_FAKE_USER_HEIGHT",
  CHANGE_FAKE_USER_INTERESTED_IN: "CHANGE_FAKE_USER_INTERESTED_IN",
  CHANGE_FAKE_USER_POLITICS: "CHANGE_FAKE_USER_POLITICS",
  CHANGE_FAKE_USER_RELIGIOUS: "CHANGE_FAKE_USER_RELIGIOUS",
  CHANGE_FAKE_USER_TAGS: "ADD_FAKE_USER_TAGS",
  CHANGE_FAKE_USER_WORK: "CHANGE_FAKE_USER_WORK",
  CHANGE_FAKE_USER_ADDRESS: "ADD_FAKE_USER_ADDRESS",
  CHANGE_FAKE_USER_CHANGE_LOCATION: "ADD_FAKE_CHANGE_LOCATION",
  CHANGE_FAKE_USER_ZODIAC_SIGN: "CHANGE_FAKE_USER_ZODIAC_SIGN",
  ADD_FAKE_USER_BOOK: "ADD_FAKE_USER_BOOK",
  REMOVE_FAKE_USER_BOOK: "REMOVE_FAKE_USER_BOOK",
  ADD_FAKE_USER_TV_SHOW: "ADD_FAKE_USER_TV_SHOW",
  REMOVE_FAKE_USER_TV_SHOW: "REMOVE_FAKE_USER_TV_SHOW",
  ADD_FAKE_USER_MOVIE: "ADD_FAKE_USER_MOVIE",
  REMOVE_FAKE_USER_MOVIE: "REMOVE_FAKE_USER_MOVIE",
  ADD_FAKE_USER_SPORT_TEAM: "ADD_FAKE_USER_SPORT_TEAM",
  REMOVE_FAKE_USER_SPORT_TEAM: "REMOVE_FAKE_USER_SPORT_TEAM",
  ADD_FAKE_USER_MUSIC: "ADD_FAKE_USER_MUSIC",
  REMOVE_FAKE_USER_MUSIC: "REMOVE_FAKE_USER_MUSIC",
  ADD_FAKE_USER_PHOTO: "ADD_FAKE_USER_PHOTO",
  REMOVE_FAKE_USER_PHOTO: "REMOVE_FAKE_USER_PHOTO",
  CREATE_FAKE_USER_REQUEST: "CREATE_FAKE_USER_REQUEST",
  CREATE_FAKE_USER_SUCCESS: "CREATE_FAKE_USER_SUCCESS",
  CREATE_FAKE_USER_FAILURE: "CREATE_FAKE_USER_FAILURE",
  SAVE_FAKE_USER_REQUEST: "SAVE_FAKE_USER_REQUEST",
  SAVE_FAKE_USER_SUCCESS: "SAVE_FAKE_USER_SUCCESS",
  SAVE_FAKE_USER_FAILURE: "SAVE_FAKE_USER_FAILURE",
  GET_FAKE_USER_PRESETS_REQUEST: "GET_FAKE_USER_PRESETS_REQUEST",
  GET_FAKE_USER_PRESETS_SUCCESS: "GET_FAKE_USER_PRESETS_SUCCESS",
  GET_FAKE_USER_PRESETS_FAILURE: "GET_FAKE_USER_PRESETS_FAILURE",
  GET_WORKER_FAKE_USERS_REQUEST: "GET_WORKER_FAKE_USERS_REQUEST",
  GET_WORKER_FAKE_USERS_FAILURE: "GET_WORKER_FAKE_USERS_FAILURE",
  GET_WORKER_FAKE_USERS_SUCCESS: "GET_WORKER_FAKE_USERS_SUCCESS",
  GET_ALL_FAKE_USERS_LIST_REQUEST: "GET_ALL_FAKE_USERS_LIST_REQUEST",
  GET_ALL_FAKE_USERS_LIST_FAILURE: "GET_ALL_FAKE_USERS_LIST_FAILURE",
  GET_ALL_FAKE_USERS_UPDATE: "GET_ALL_FAKE_USERS_UPDATE",
  FAKE_USER_RESET_SETTINGS: "FAKE_USER_RESET_SETTINGS",
  HIDE_ERROR: "HIDE_ERROR",
  CLEAR_USER: "CLEAR_USER",
  UPDATE_LATEST_TIME_FAKE_USERS: "UPDATE_LATEST_TIME_FAKE_USERS",
  INIT_MOMENTS: "INIT_MOMENTS",
  ADD_MOMENT: "ADD_MOMENT",
  REMOVE_MOMENT: "REMOVE_MOMENT",
  INIT_STORIES: "INIT_STORIES",
  ADD_STORY: "ADD_STORY",
  GET_STOCK_IMAGE: "GET_STOCK_IMAGE",
  CHANGE_FAKE_USER_SUBSCRIPTION: "CHANGE_FAKE_USER_SUBSCRIPTION",
  RESET_FAKE_USER: "RESET_FAKE_USER",
  UPDATE_CREATE_MODERATOR: "UPDATE_CREATE_MODERATOR",
  
  INIT_ALL_MOMENTS: "INIT_ALL_MOMENTS",
  ADD_ALL_MOMENTS: "ADD_ALL_MOMENTS",
  REMOVE_ALL_MOMENTS: "REMOVE_ALL_MOMENTS",
  INIT_SCHEDULE_STORIES: "INIT_SCHEDULE_STORIES",
  ADD_SCHEDULE_STORY: "ADD_SCHEDULE_STORY",
  INIT_SCHEDULE_MOMENT: "INIT_SCHEDULE_MOMENT",
  ADD_SCHEDULE_MOMENT: "ADD_SCHEDULE_MOMENT",
  REMOVE_SCHEDULE_MOMENT: "REMOVE_SCHEDULE_MOMENT",
};
